import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DashboardService } from '../services/dashboard.service';
@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
    
    userId: any = window.localStorage.getItem("UserId");
    firstName: string = window.localStorage['FirstName'];
    lastName: string = window.localStorage['LastName'];
    userProfileId: string = window.localStorage['UserProfileId'];
    isHiAUser: string = window.localStorage['IsHiAUser'];

    constructor(
        private router: Router,
        private dashboardService: DashboardService
    ) { }

    isExpanded = false;
    isHiddenLangMdl: boolean = false;

    allLanguageList: any = [];
    staticTextData: any;
    selectedLanguageFlag: string = "UK.png";

    ngOnInit() {
        this.addUserLanguage();
        this.getAllLanguages();
        this.getLanguageStaticText();

        $('.cstm-Nav-Item').click(function () {
            $('.cstm-Nav-Item.active').removeClass('active');
            $(this).addClass('active');
        });

        if (window.localStorage.getItem("SelectedLanguageFlag") != null) {
            this.selectedLanguageFlag = window.localStorage.getItem("SelectedLanguageFlag");
        }
    }

    getAllLanguages() {
        this.allLanguageList = [];
        var objParam = {
            UserId: parseInt(this.userId),
        }
        this.dashboardService.getAllLanguages(objParam).subscribe((result: any) => {
            this.allLanguageList = result;
        }, error => console.error(error));
    }

    onLanguageSelect(language) {
        window.localStorage.setItem("SelectedLanguage", language.languageCode);
        window.localStorage.setItem("SelectedLanguageFlag", language.imageUrl);
        this.closeLanguageModal();
        this.addUserLanguage();
        window.location.reload();
    }

    addUserLanguage() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.addUserLanguage(objParam).subscribe((result: any) => {
            //this.staticTextData = result;
        }, error => console.error(error));
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
        }, error => console.error(error));
    }

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    clickOnLogo() {
        var profielId = window.localStorage.getItem('UserProfileId');

        if (profielId != null && (profielId == '6')) {
            localStorage.setItem('mapdrill', '0')
            window.localStorage.removeItem('CountryKey');
            window.localStorage.removeItem('SelectedClientId');
            $('.uk-map').hide();
            $('.w-map_1').show();
            $('.rep-template').hide();
            this.router.navigate(["/dashboard/OA"]);
        }
    }

    logout() {
        window.localStorage.removeItem('UserId');
        window.localStorage.removeItem('UserProfileId');
        window.localStorage.removeItem('AccessToken');
        window.localStorage.removeItem('FirstName');
        window.localStorage.removeItem('LastName');
        window.localStorage.removeItem('CountryKey');
        window.localStorage.removeItem('ViewType');
        window.localStorage.removeItem("ClientId");
        window.localStorage.removeItem('IsHiAUser');
        window.localStorage.removeItem('CountryKey');
        window.localStorage.removeItem('SelectedClientId');
        window.localStorage.removeItem('ClientLogo');
        window.localStorage.removeItem("mapdrill");
        window.localStorage.removeItem('UserClientId');
        window.localStorage.removeItem('UserClientDomain');
        window.localStorage.removeItem('SelectedProductId');
        window.localStorage.removeItem('SelectedFranchiseId');
        window.localStorage.removeItem('SelectedLanguage');
        window.localStorage.removeItem('SelectedLanguageFlag');
        this.router.navigate(["/login"]);
    }

    showLanguageModal() {
        this.isHiddenLangMdl = true;
    }

    closeLanguageModal() {
        this.isHiddenLangMdl = false;
    }
}
