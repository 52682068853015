import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-bayer',
  templateUrl: './bayer.component.html',
  styleUrls: ['./bayer.component.css']
})
export class BayerComponent implements OnInit {
constructor() { }

    @Input() ClientRegionData: any;
    @Input() CurrentPage: any;

    currentPageName: string = "";
    clientName: string = "";

    region1: any = {};
    region2: any = {};

    ngOnInit() {
        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
    }

    ngOnChanges() {
        this.region1 = {};
        this.region2 = {};
        if (this.ClientRegionData != undefined && this.ClientRegionData != null && this.ClientRegionData.length > 0 && this.clientName == 'bayer.com') {
            var region1 = this.ClientRegionData.find(x => x.regionName == "Region 1");
            if (region1 != undefined) {
                this.region1 = region1;
            }
            var region2 = this.ClientRegionData.find(x => x.regionName == "Region 2");
            if (region2 != undefined) {
                this.region2 = region2;
            }
        }

        if (this.CurrentPage != undefined) {
            this.currentPageName = this.CurrentPage;
        }
    }
}
