import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { HighchartsChartModule } from 'highcharts-angular';

import { StaticdashboardukRoutingModule } from './staticdashboarduk-routing.module';
import { StaticnavComponent } from './staticnav/staticnav.component';
import { CallactionAnalysisComponent } from './callaction-analysis/callaction-analysis.component';
import { CallCharacteristicsComponent } from './call-characteristics/call-characteristics.component';
import { CampaignelementComponent } from './campaignelement/campaignelement.component';
import { CampaignImplementationComponent } from './campaign-implementation/campaign-implementation.component';
import { GlanceComponent } from './glance/glance.component';
import { SharedTopComponent } from './shared-top/shared-top.component';
import { OutcomeAnalysisComponent } from './outcome-analysis/outcome-analysis.component';
import { SellingskillselementComponent } from './sellingskillselement/sellingskillselement.component';
import { SellingskillsComponent } from './sellingskills/sellingskills.component';
import { UkComponent } from './Region/UK-Region/uk/uk.component';


@NgModule({
    declarations: [StaticnavComponent, CallactionAnalysisComponent, CallCharacteristicsComponent, CampaignelementComponent, CampaignImplementationComponent, GlanceComponent, SharedTopComponent, OutcomeAnalysisComponent, SellingskillselementComponent, SellingskillsComponent, UkComponent],
  imports: [
      CommonModule,
      MatButtonModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      BrowserModule,
      BrowserAnimationsModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatChipsModule,
      MatIconModule,
      MatListModule,
      DragDropModule,
      MatTabsModule,
      HighchartsChartModule,
      MatCheckboxModule,
      FormsModule,
      MatTooltipModule,
      ReactiveFormsModule,
    StaticdashboardukRoutingModule
  ]
})
export class StaticdashboardukModule { }
