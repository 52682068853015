import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';

export interface SellingSkills {
    country: string;
    opening: number;
    questioning: number;
    presenting: number;
    closing: number;
    outcome: number;
    drVoice: number;
    callLength: number;
    targetPatient: number;
    message1: number;
    message2: number;
    message3: number;
    others: number;
    callAction: string
}

const SS_DATA: SellingSkills[] = [
    { country: 'England', opening: 3.5, questioning: 3.2, presenting: 3.5, closing: 3.2, outcome: 12, callLength: 11.3, drVoice: 14.1, targetPatient: 50, message1: 25, message2: 25, message3: 45, others: 50, callAction: 'Prescribe' },
    { country: 'Scotland', opening: 2.5, questioning: 2.5, presenting: 2.5, closing: 2.2, outcome: 20, callLength: 15, drVoice: 30, targetPatient: 20, message1: 50, message2: 50, message3: 55, others: 40, callAction: 'Guidelines' },
    { country: 'Wales', opening: 3.5, questioning: 3.5, presenting: 2.9, closing: 2.8, outcome: 15, callLength: 2, drVoice: 50, targetPatient: 40, message1: 45, message2: 45, message3: 35, others: 20, callAction: 'Advocacy' },
    { country: 'Northern Ireland', opening: 2.8, questioning: 2.8, presenting: 2.8, closing: 3.5, outcome: 9, callLength: 19.3, drVoice: 34.1, targetPatient: 50, message1: 55, message2: 55, message3: 45, others: 50, callAction: 'Prescribe' },
    { country: 'Region 1', opening: 1.7, questioning: 2.7, presenting: 3.5, closing: 2.0, outcome: 9, callLength: 19.3, drVoice: 34.1, targetPatient: 61, message1: 23, message2: 81, message3: 39, others: 19, callAction: 'Patient ID' },

];




@Component({
  selector: 'app-rep-glance',
  templateUrl: './rep-glance.component.html',
  styleUrls: ['./rep-glance.component.css']
})
export class RepGlanceComponent implements OnInit {
    @ViewChild('table', { static: false }) table: MatTable<SellingSkills>;

    displayedColumns: string[] = ['country', 'opening', 'presenting', 'questioning', 'closing', 'outcome', 'callLength', 'drVoice', 'targetPatient', 'message1', 'message2', 'message3', 'others', 'callAction'];

    dataSource = new MatTableDataSource(SS_DATA);

    @ViewChild('table', { read: MatSort, static: true }) sort1: MatSort;

    constructor() { }
  

    ngOnInit() {
        const sortState: Sort = { active: 'opening', direction: 'asc' };
        this.sort1.active = sortState.active;
        this.sort1.direction = sortState.direction;
        this.sort1.sortChange.emit(sortState);
        this.dataSource.sort = this.sort1;
  


    }


}
