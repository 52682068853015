export class Country {
    CountryId: number;
    CountryName: string;
    CountryCode: string;
    CountryLogo: string;
    IsActive: boolean;
}

export class Region {
    RegionId: number;
    RegionName: string;
    RegionCode: string;
    CountryId: number;
    IsActive: boolean;
}