import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { CallanalysisComponent } from './callanalysis/callanalysis.component'
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [
    { path: 'coach/home', component: HomeComponent, canActivate: [AuthGuardService] },
    { path: 'coach/callanalysis', component: CallanalysisComponent, canActivate: [AuthGuardService] },
    { path: 'coach/reports', component: ReportsComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoachRoutingModule { }
