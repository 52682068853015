import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AccountModule } from './account/account.module';
import { CMSModule } from './cms/cms.module';
import { SLSMModule } from './slsm/slsm.module';
import { FlsmModule } from './flsm/flsm.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { DemoModule } from './demo/demo.module';
import { DemoliveModule } from './demolive/demolive.module';
import { CoachModule } from './coach/coach.module';
import { DashboardModule } from './dashboard/dashboard.module'
import { StaticdashboardModule } from './staticdashboard/staticdashboard.module';
import { StaticdashboardukModule } from './staticdashboarduk/staticdashboarduk.module';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        AccountModule,
        CMSModule,
        SLSMModule,
        FlsmModule,
        DemoModule,
        DemoliveModule,
        CoachModule,
        DashboardModule,
        StaticdashboardModule,
        StaticdashboardukModule,
        RouterModule.forRoot([]),
        BrowserAnimationsModule,
        ReactiveFormsModule
      
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
