import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { CoachService } from '../../services/coach.service';
import { CoachVerifiedCallsReport } from '../../models/report/report.model';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    displayedColumns: string[] = ['coachName', 'client', 'totalCalls', 'callsVerified'];
    dataSource: CoachVerifiedCallsReport;
    selectedStartDate: string = "";
    selectedEndDate: string = "";

    constructor(
        private coachService: CoachService
    ) { }

    ngOnInit() {
        var objParam = {
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate
        };
        this.getCoachVerifiedCalls(objParam);
    }

    getCoachVerifiedCalls(objParam) {
        $('.loder-outer').show();
        this.coachService.GetCoachVerifiedCalls(objParam).subscribe((result: any) => {
            this.dataSource = result;
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    onStartDateChange(value) {
        this.selectedStartDate = value;
        var objParam = {
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate
        };
        if (this.selectedStartDate != "" && this.selectedEndDate != "") {
            if (new Date(this.selectedStartDate) > new Date(this.selectedEndDate)) {
                Swal.fire({
                    icon: 'info',
                    title: 'Start Date can not be greater than End Date.',
                });
                return false;
            }
        }
        else {
            this.getCoachVerifiedCalls(objParam);
        }
    }

    onEndDateChange(value) {
        this.selectedEndDate = value;
        if (this.selectedStartDate != "" && this.selectedEndDate != "") {
            if (new Date(this.selectedStartDate) > new Date(this.selectedEndDate)) {
                Swal.fire({
                    icon: 'info',
                    title: 'Start Date can not be greater than End Date.',
                });
                return false;
            }
            else {
                var objParam = {
                    StartDate: this.selectedStartDate,
                    EndDate: this.selectedEndDate
                };
                this.getCoachVerifiedCalls(objParam);
            }
        }
    }
}
