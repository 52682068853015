import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlsmRoutingModule } from './flsm-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { HighchartsChartModule } from 'highcharts-angular';
import { HomeComponent } from './home/home.component';
import { LeftnavComponent } from './leftnav/leftnav.component';
import { MapComponent } from './map/map.component';
import { ChangeoverTimeComponent } from './changeover-time/changeover-time.component';


@NgModule({
  declarations: [HomeComponent, LeftnavComponent, MapComponent, ChangeoverTimeComponent],
  imports: [
    CommonModule,
      FlsmRoutingModule,
      MatButtonModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      BrowserModule,
      BrowserAnimationsModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatChipsModule,
      MatIconModule,
      MatListModule,
      DragDropModule,
      MatTabsModule,
      HighchartsChartModule,
  ]
})
export class FlsmModule { }
