import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { LeftMenuData } from '../../models/slsm/left-menu-data.model';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
interface product {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-liveleftnav',
    templateUrl: './liveleftnav.component.html',
    styleUrls: ['./liveleftnav.component.css']
})
export class LiveleftnavComponent implements OnInit {

    @Input() DemoLiveLeftMenuData: any;
    @ViewChild('LiveDemoSellingSkillsAvg', { static: true }) SellingSkillsAvg: ElementRef;
    @ViewChild('LiveDemoCampaignImpAvg', { static: true }) CampaignImplementationAvg: ElementRef;

    leftMenuData: LeftMenuData;

    constructor() { }

    ngOnInit() {
    }

    selectedDataViewType: number = 1;

    ngOnChanges() {
        this.leftMenuData = new LeftMenuData();
        if (this.DemoLiveLeftMenuData != undefined) {
            this.leftMenuData.SellingSkillAvg = this.DemoLiveLeftMenuData.SellingSkillAvg;
            this.leftMenuData.CampaignImpAvg = this.DemoLiveLeftMenuData.CampaignImpAvg;
            this.leftMenuData.TotalAudioCallAvg = this.DemoLiveLeftMenuData.TotalAudioCallAvg;
            this.leftMenuData.DoctorSpeakTimeAvg = this.DemoLiveLeftMenuData.DoctorSpeakTimeAvg;
            this.leftMenuData.RepSpeakTimeAvg = this.DemoLiveLeftMenuData.RepSpeakTimeAvg;
            this.leftMenuData.SellingSkillMonthlyAvg = this.DemoLiveLeftMenuData.SellingSkillMonthlyAvg;
            
            //Selling Skill Overall Avg. By Month
            if (this.leftMenuData.SellingSkillMonthlyAvg != null) {
                var eleData = this.getMonthWiseData(this.leftMenuData.SellingSkillMonthlyAvg);
                this.drawSellingSkillGraph(eleData);
            }

            this.drawCampaignGraph(this.leftMenuData.CampaignImpAvg);
        }
    }

    public gaugeOptions: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        }
    
    };

    drawSellingSkillGraph(data) {
        Highcharts.chart(this.SellingSkillsAvg.nativeElement,
            Highcharts.merge(this.gaugeOptions,
                {
                    title: {
                        text: 'Selling Skills',
                        align: 'left',
                        style: {
                            color: '#012072',
                            fontWeight: 'bold',
                            fontSize: '13px',

                        }
                    },
                    xAxis: {
                        categories: data.map(function (a) { return a.Month }),
                        labels: {
                            formatter: function () {
                                var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                                return string;
                            },
                            style: {
                                color: '#66707f',
                                fontSize: '10px',
                            }
                        }

                    },
                    yAxis: {
                        min: 0,
                        max: 4,
                        tickInterval: 1,
                        title: {
                            text: null
                        },
                        labels: {
                            style: {
                                color: '#66707f',
                                fontSize: '10px',
                            }
                        },
                        reversedStacks: false
                    },
                    tooltip: {
                        valueDecimals: 1
                    },
                    series: [{
                        name: 'Selling Skills',
                        color: '#0abfb2',
                        data: data.map(function (a) { return a.Data })
                    }],
                }
            )
        );
    }

    drawCampaignGraph(data) {
        Highcharts.chart(this.CampaignImplementationAvg.nativeElement,
            Highcharts.merge(this.gaugeOptions,
                {
                    title: {
                        text: 'Campaign Implementation',
                        align: 'left',
                        style: {
                            color: '#012072',
                            fontWeight: 'bold',
                            fontSize: '13px',

                        }
                    },
                    xAxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        labels: {
                            formatter: function () {
                                var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                                return string;
                            },
                            style: {
                                color: '#66707f',
                                fontSize: '10px',
                            }
                        }

                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        tickInterval: 50,
                        title: {
                            text: null
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: '#66707f',
                                fontSize: '10px',
                            }
                        },
                        reversedStacks: false
                    },
                    tooltip: {
                        valueDecimals: 1
                    },
                    series: [{
                        name: 'Campaign Implementation',
                        color: '#0abfb2',
                        /*data: data*/
                        data: [20, 40, 60, 60, 50, 40, 40, 70, 90, 80]

                    }],
                }
            )
        );
    }

    getMonthWiseData(data) {

        var chartData = new Array();
        var selectedView = window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType;
        var isMat = selectedView == 1 ? true : false;
        var months = this.getMonthList(isMat);

        try {
            for (var i = 0; i < months.length; i++) {
                var month = months[i];
                var monthDataKey = 'month' + (i + 1);
                //var entry = [month, data[monthDataKey]];
                chartData.push({ Month: month, Data: data[monthDataKey] });
            }

            //if Qtr is selected
            if (selectedView == 3) {
                var qtr = [];
                for (var j = chartData.length - 2; j > chartData.length - 5; j--) {
                    qtr.push(chartData[j]);
                }
                chartData = [];
                chartData = qtr.reverse();
            }
            //if Month is selected
            if (selectedView == 4) {
                var lastMonth = [];
                lastMonth.push(chartData[chartData.length - 2]);
                chartData = [];
                chartData = lastMonth;
            }
        } catch (e) {

        }
        return chartData;
    }

    getMonthList(isMat) {
        var months = new Array();

        months[0] = 'Jan';
        months[1] = 'Feb';
        months[2] = 'Mar';
        months[3] = 'Apr';
        months[4] = 'May';
        months[5] = 'Jun';
        months[6] = 'Jul';
        months[7] = 'Aug';
        months[8] = 'Sep';
        months[9] = 'Oct';
        months[10] = 'Nov';
        months[11] = 'Dec';

        var date = new Date();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        var currentMonthList = new Array();

        if (monthIndex >= 0) {
            for (var i = monthIndex; i >= 0; i--) {
                var month = months[i] + "-" + year;
                currentMonthList.push(month);
            }
            if (isMat == true) {
                year--;
                for (var i = 11; i > monthIndex; i--) {
                    var month = months[i] + "-" + year;
                    currentMonthList.push(month);
                }
            }
        }
        return currentMonthList.reverse();
    }
}
