import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { AtGlanceTable } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';
import * as $ from 'jquery';
@Component({
    selector: 'app-glance',
    templateUrl: './glance.component.html',
    styleUrls: ['./glance.component.css']
})
export class GlanceComponent implements OnInit {

    constructor(
        private dashboardService: DashboardService
    ) { }

    ngOnInit() {
        this.getLanguageStaticText();

        const sortState: Sort = { active: 'opening', direction: 'asc' };
        this.sort1.active = sortState.active;
        this.sort1.direction = sortState.direction;
        this.sort1.sortChange.emit(sortState);
        this.dataSource.sort = this.sort1;

        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;
        
        this.getClientElementName();
        this.getCampaignColumnName();

        if (this.userProfileId == '6' || this.userProfileId == '7') {
            if (localStorage.getItem('SelectedClientId') != null) {
                var clientId = localStorage.getItem('SelectedClientId');
                this.getAtGlanceDataByRegion(clientId);
            }
            else {
                this.getAtGlanceData();
            }
        }
        else {
            this.getAtGlanceDataByUser();
        }
    }

    //------------New Work--------------
    userId: any = window.localStorage.getItem("UserId");
    userProfileId: any = window.localStorage.getItem("UserProfileId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    //selectedLanguage: string = "En";

    atGlanceDataList: any = [];
    elementNameList: any = {};
    campaignColumnName: any = {};
    staticTextData: any;

    @ViewChild('table', { static: false }) table: MatTable<AtGlanceTable>;

    displayedColumns: string[] = ['country', 'opening', 'questioning', 'presenting', 'closing', 'outcome', 'callLength', 'drVoice', 'targetPatient', 'message1', 'message2', 'message3', 'message4', 'callAction'];

    dataSource = new MatTableDataSource(this.atGlanceDataList);

    @ViewChild('table', { read: MatSort, static: true }) sort1: MatSort;

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
        }, error => console.error(error));
    }

    getClientElementName() {
        var paramObj = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage"),
            UserId: parseInt(this.userId)
        }
        this.dashboardService.getClientElementName(paramObj).subscribe((result: any) => {
           
            this.elementNameList = {
                Opening: result.find(x => x.aliasElementName == "Opening").elementNameToShow,
                Questioning: result.find(x => x.aliasElementName == "Questioning").elementNameToShow,
                Presenting: result.find(x => x.aliasElementName == "Presenting").elementNameToShow,
                Closing: result.find(x => x.aliasElementName == "Closing").elementNameToShow
            }
        },
            error => console.error(error));
    }

    getCampaignColumnName() {
        var paramObj = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage"),
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId
        }
        this.dashboardService.getCampaignColumnName(paramObj).subscribe((result: any) => {
            this.campaignColumnName = result;
        },
            error => console.error(error));
    }

    getAtGlanceData() {
        this.totalNoOfCalls = 0;
        var objParam = {
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId
        }
        $('.loder-outer').show();
        this.dashboardService.getClientAtGlanceData(objParam).subscribe((result: any) => {
            this.dataSource = result;
            if (result.length > 0) {
                this.clientLogo = result[0].clientLogo;
                result.forEach(obj => {
                    //this will help to pass the data from parent to its child class.
                    this.totalNoOfCalls = this.totalNoOfCalls + obj.totalCalls;
                })
            }
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    getAtGlanceDataByRegion(clientId) {
        this.totalNoOfCalls = 0;
        var objParam = {
            UserId: parseInt(this.userId),
            ClientId: parseInt(clientId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId
        }
        $('.loder-outer').show();
        this.dashboardService.getClientAtGlanceDataByRegion(objParam).subscribe((result: any) => {
            this.dataSource = result;
            if (result.length > 0) {
                this.clientLogo = result[0].clientLogo;
                result.forEach(obj => {
                    //this will help to pass the data from parent to its child class.
                    this.totalNoOfCalls = this.totalNoOfCalls + obj.totalCalls;
                })
            }
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    getAtGlanceDataByUser() {
        this.totalNoOfCalls = 0;
        var objParam = {
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId
        }
        $('.loder-outer').show();
        this.dashboardService.getClientAtGlanceDataByUser(objParam).subscribe((result: any) => {
            this.dataSource = result;
            if (result.length > 0) {
                this.clientLogo = result[0].clientLogo;
                window.localStorage.setItem("ClientLogo", this.clientLogo);
                result.forEach(obj => {
                    //this will help to pass the data from parent to its child class.
                    this.totalNoOfCalls = this.totalNoOfCalls + obj.totalCalls;
                })
            }
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        if (this.userProfileId == '6' || this.userProfileId == '7') {
            if (localStorage.getItem('SelectedClientId') != null) {
                var clientId = localStorage.getItem('SelectedClientId');
                this.getAtGlanceDataByRegion(clientId);
            }
            else {
                this.getAtGlanceData();
            }
        }
        else {
            this.getAtGlanceDataByUser();
        }
        this.getCampaignColumnName();
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        if (this.userProfileId == '6' || this.userProfileId == '7') {
            if (localStorage.getItem('SelectedClientId') != null) {
                var clientId = localStorage.getItem('SelectedClientId');
                this.getAtGlanceDataByRegion(clientId);
            }
            else {
                this.getAtGlanceData();
            }
        }
        else {
            this.getAtGlanceDataByUser();
        }
    }

}
