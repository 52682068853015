import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SellingskillsComponent } from './sellingskills/sellingskills.component';
import { OutcomeAnalysisComponent } from './outcome-analysis/outcome-analysis.component';
import { CampaignImplementationComponent } from './campaign-implementation/campaign-implementation.component'
import { GlanceComponent } from './glance/glance.component'
import { SellingskillselementComponent } from './sellingskillselement/sellingskillselement.component'
import { CampaignelementComponent } from './campaignelement/campaignelement.component'
import { CallactionAnalysisComponent } from './callaction-analysis/callaction-analysis.component';
import { CallCharacteristicsComponent } from './call-characteristics/call-characteristics.component';
import { RepGlanceComponent } from './rep-glance/rep-glance.component';
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
    { path: 'dashboard/SS', component: SellingskillsComponent, canActivate: [AuthGuardService]},
    { path: 'dashboard/OA', component: OutcomeAnalysisComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/CI', component: CampaignImplementationComponent, canActivate: [AuthGuardService]},
    { path: 'dashboard/AG', component: GlanceComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/SE', component: SellingskillselementComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/CE', component: CampaignelementComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/CAC', component: CallactionAnalysisComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/CC', component: CallCharacteristicsComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/rep/AG', component: RepGlanceComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
