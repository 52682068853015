import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);


@Component({
    selector: 'app-campaignelement',
    templateUrl: './campaignelement.component.html',
    styleUrls: ['./campaignelement.component.css']
})
export class CampaignelementComponent implements OnInit {
    chart: any;
    updateFlag = false;
    Highcharts: typeof Highcharts = Highcharts;
    mentionedvisibleonOff: any = true;
    notMentionedvisibleonOff: any = false;
    mentioned: any = true
    notMentioned = false
    totalNoOfCalls: number;
    constructor(
        private dashboardService: DashboardService
    ) { }

    staticTextData: any;

   

    ngOnInit() {
        this.getLanguageStaticText();

      
    }

    getLanguageStaticText() {
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.totalNoOfCalls = 501
            this.staticTextData = result;
            $('.loder-outer').hide();
        }, error => console.error(error));
    }



   

    chartOptions_1: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },

        },
        title: {
            text: 'Patient 1',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Mentioned in call', 'Not Mentioned in call'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: false
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 1
        },

        series: [{
            type: 'column',
            name: 'Mentioned in call',
            visible: this.mentionedvisibleonOff,
            color: '#078000',
            data: [61.50, 22.50, 72.00, 69.75, 62.00, 27.00]
        }, {
            type: 'column',
            name: 'Not Mentioned in call',
            visible: this.notMentionedvisibleonOff,
            color: '#fe0000',
            data: [38.5, 77.5, 28.0, 30.3, 38.0, 73.0]
        }],

    };
    chartOptions_2: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },

        },
        title: {
            text: 'Patient 2',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Mentioned in call', 'Not Mentioned in call'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: false
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },
        series: [{
            type: 'column',
            name: 'Mentioned in call',
             visible: this.mentionedvisibleonOff,
            color: '#078000',
            data: [15.25, 81.75, 53.50, 34.75, 15.25, 16.25]
        }, {
            type: 'column',
            name: 'Not Mentioned in call',
             visible: this.notMentionedvisibleonOff,
            color: '#fe0000',
            data: [84.8, 18.3, 46.5, 65.3, 84.8, 83.8]
        }],

    };
    chartOptions_3: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },

        },
        title: {
            text: 'Message 1',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Mentioned in call', 'Not Mentioned in call'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: false
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },
        series: [{
            type: 'column',
            name: 'Mentioned in call',
             visible: this.mentionedvisibleonOff,
            color: '#078000',
            data: [74.75, 50.00, 76.00, 88.00, 91.00, 43.75]
        }, {
            type: 'column',
            name: 'Not Mentioned in call',
             visible: this.notMentionedvisibleonOff,
            color: '#fe0000',
            data: [25.3, 50.0, 24.0, 12.0, 9.0, 56.3]
        }],

    };
    chartOptions_4: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },

        },
        title: {
            text: 'Message 2',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Mentioned in call', 'Not Mentioned in call'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: false
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },
        series: [{
            type: 'column',
            name: 'Mentioned in call',
             visible: this.mentionedvisibleonOff,
            color: '#078000',
            data: [49.75, 44.25, 52.33, 48.75, 62.00, 32.75]
        }, {
            type: 'column',
            name: 'Not Mentioned in call',
             visible: this.notMentionedvisibleonOff,
            color: '#fe0000',
            data: [50.3, 55.8, 47.7, 51.3, 38.0, 67.3]
        }],

    };
    chartOptions_5: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },

        },
        title: {
            text: 'Message 3',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Mentioned in call', 'Not Mentioned in call'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: false
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },
        series: [{
            type: 'column',
            name: 'Mentioned in call',
             visible: this.mentionedvisibleonOff,
            color: '#078000',
            data: [32.50, 82.75, 31.25, 25.00, 15.00, 74.00]
        }, {
            type: 'column',
            name: 'Not Mentioned in call',
             visible: this.notMentionedvisibleonOff,
            color: '#fe0000',
            data: [67.5, 17.3, 68.8, 75.0, 85.0, 26.0]
        }],

    };


   
    UpdateWeeK() {
        this.totalNoOfCalls = 223
        this.chartOptions_1.series[0] = {
            type: 'column',
            data: [90.0, 85.0, 70.0, 50.0, 25.0, 10.0, 8.0, 10.0, 20.0, 70.0, 95.0, 100.0]
        };
        this.chartOptions_1.series[1] = {
            type: 'column',
            data: [10.0, 15.0, 30.0, 50.0, 75.0, 90.0, 92.0, 90.0, 80.0, 30.0, 5.0, 0.0]
        };
        //graph 2
        this.chartOptions_2.series[0] = {
            type: 'column',
            data: [8.0, 15.0, 34.0, 60.0, 80.0, 90.0, 80.0, 60.0, 40.0, 30.0, 30.0, 30.0]
        };
        this.chartOptions_2.series[1] = {
            type: 'column',
            data: [92.0, 85.0, 66.0, 40.0, 20.0, 10.0, 20.0, 40.0, 60.0, 70.0, 70.0, 70.0]
        };
        //graph 3
        this.chartOptions_3.series[0] = {
            type: 'column',
            data: [100.0, 80.0, 60.0, 40.0, 30.0, 25.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0]
        };
        this.chartOptions_3.series[1] = {
            type: 'column',
            data: [0.0, 20.0, 40.0, 60.0, 70.0, 75.0, 80.0, 80.0, 80.0, 80.0, 80.0, 80.0]
        };
        //graph 4
        this.chartOptions_4.series[0] = {
            type: 'column',
            data: [40.0, 44.0, 48.0, 52.0, 56.0, 60.0, 64.0, 68.0, 72.0, 76.0, 80.0, 84.0]
        };
        this.chartOptions_4.series[1] = {
            type: 'column',
            data: [60.0, 56.0, 52.0, 48.0, 44.0, 40.0, 36.0, 32.0, 28.0, 24.0, 20.0, 16.0]
        };
        //graph 5
        this.chartOptions_5.series[0] = {
            type: 'column',
            data: [14.0, 30.0, 45.0, 55.0, 59.0, 63.0, 64.0, 65.0, 65.0, 65.0, 65.0, 65.0]
        };
        this.chartOptions_5.series[1] = {
            type: 'column',
            data: [86.0, 70.0, 55.0, 45.0, 41.0, 37.0, 36.0, 35.0, 35.0, 35.0, 35.0, 35.0]
        };

        this.updateFlag = true;
    }
    UpdateMonth() {
        this.totalNoOfCalls = 501

        this.chartOptions_1.series[0] = {
            type: 'column',
            data: [61.50, 22.50, 72.00, 69.75, 62.00, 27.00]
        };
        this.chartOptions_1.series[1] = {
            type: 'column',
            data: [38.5, 77.5, 28.0, 30.3, 38.0, 73.0]
        };


        //graph 2
        this.chartOptions_2.series[0] = {
            type: 'column',
            data: [15.25, 81.75, 53.50, 34.75, 15.25, 16.25]
        };
        this.chartOptions_2.series[1] = {
            type: 'column',
            data: [84.8, 18.3, 46.5, 65.3, 84.8, 83.8]
        };

        //graph 3
        this.chartOptions_3.series[0] = {
            type: 'column',
            data: [74.75, 50.00, 76.00, 88.00, 91.00, 43.75]
        };
        this.chartOptions_3.series[1] = {
            type: 'column',
            data: [25.3, 50.0, 24.0, 12.0, 9.0, 56.3]
        };


        //graph 4
        this.chartOptions_4.series[0] = {
            type: 'column',
            data: [49.75, 44.25, 52.33, 48.75, 62.00, 32.75]
        };
        this.chartOptions_4.series[1] = {
            type: 'column',
            data: [50.3, 55.8, 47.7, 51.3, 38.0, 67.3]
        };

        //graph 5


        this.chartOptions_5.series[0] = {
            type: 'column',
            data: [32.50, 82.75, 31.25, 25.00, 15.00, 74.00]
        };
        this.chartOptions_5.series[1] = {
            type: 'column',
            data: [67.5, 17.3, 68.8, 75.0, 85.0, 26.0]
        };
        this.updateFlag = true;
    }

    UpdateQTR() {
        this.totalNoOfCalls = 501
        this.chartOptions_1.series[0] = {
            type: 'column',
            data: [52.0, 52.9]  
        };
        this.chartOptions_1.series[1] = {
            type: 'column',
            data: [48.0, 47.1]
        };

//graph 2
        this.chartOptions_2.series[0] = {
            type: 'column',
            data: [50.2, 22.1]   
        };
        this.chartOptions_2.series[1] = {
            type: 'column',
            data: [49.8, 77.9]
        };

        //graph 3
        this.chartOptions_3.series[0] = {
            type: 'column',
            data: [66.9, 74.3]     
        };
        this.chartOptions_3.series[1] = {
            type: 'column',
            data: [33.1, 25.8]
        };

        //graph 4
        this.chartOptions_4.series[0] = {
            type: 'column',
            data: [48.8, 47.8]  
        };
        this.chartOptions_4.series[1] = {
            type: 'column',
            data: [51.2, 52.2]
        };
           //graph 5
        this.chartOptions_5.series[0] = {
            type: 'column',
            data: [48.8, 38.0]
            
        };
        this.chartOptions_5.series[1] = {
            type: 'column',
            data: [51.2, 62.0]
        };
        this.updateFlag = true;
    }
    seiresOnOff($event: Event, Valuecheck) {
        if (Valuecheck === 0) {
            this.mentionedvisibleonOff = this.mentioned,
                this.chartOptions_1.series[0] = {
                    type: 'column',
                    visible: this.mentioned
                };


            //graph 2
            this.chartOptions_2.series[0] = {
                type: 'column',
                visible: this.mentioned
            };


            //graph 3
            this.chartOptions_3.series[0] = {
                type: 'column',
                visible: this.mentioned
            };


            //graph 4
            this.chartOptions_4.series[0] = {
                type: 'column',
                visible: this.mentioned
            };

            //graph 5
            this.chartOptions_5.series[0] = {
                type: 'column',
                visible: this.mentioned
            };

            this.updateFlag = true;
        }
        else {
            this.notMentionedvisibleonOff = this.notMentioned
            this.chartOptions_1.series[1] = {
                type: 'column',
                visible: this.notMentioned
            };

            //graph 2
            this.chartOptions_2.series[1] = {
                type: 'column',
                visible: this.notMentioned
            };

            //graph 3
            this.chartOptions_3.series[1] = {
                type: 'column',
                visible: this.notMentioned
            };

            //graph 4

            this.chartOptions_4.series[1] = {
                type: 'column',
                visible: this.notMentioned
            };
            //graph 5
            this.chartOptions_5.series[1] = {
                type: 'column',
                visible: this.notMentioned
            };
            this.updateFlag = true;
        }


    }
}
