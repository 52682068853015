import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { element } from 'protractor';
import { LeftMenuData } from '../../models/slsm/left-menu-data.model';
import { SlsmService } from '../../services/slsm.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-livehome',
    templateUrl: './livehome.component.html',
    styleUrls: ['./livehome.component.css']
})
export class LivehomeComponent implements OnInit {

    userId: any = window.localStorage.getItem("UserId");

    constructor(
        private router: Router,
        private slsmService: SlsmService
    ) { }

    @ViewChild('SellingSkills', { static: true }) SellingSkills: ElementRef;

    selectedLanguage: string = "En";
    isHidden: boolean = false;
    sellingskillDataByElement: any = [];
    leftMenuData: LeftMenuData;
    demoLiveLeftMenuData: any;

    ngOnInit() {

        this.leftMenuData = new LeftMenuData();
        Highcharts.chart('CImplem', this.CampaignImpGraph);
        this.GetUserLastCallDetails();
    }

    GetUserLastCallDetails() {
        this.sellingskillDataByElement = [];
        var params = {
            ClientId: 0,
            UserId: parseInt(this.userId),
            ViewType: 1,
            LanguageCode: this.selectedLanguage
        };
        this.slsmService.GetUserLastCallDetails(params).subscribe((result: any) => {
            var elementArray = [];
            result.scoreList.forEach(obj => {
                var objEle = {
                    name: obj.elementName,
                    y: parseInt(obj.score)
                }
                elementArray.push(objEle);
            });

            var objFinalData = {
                name: 'Score',
                color: '#00b050',
                data: elementArray
            }
            this.sellingskillDataByElement.push(objFinalData);

            //getting audio calls avgs.
            if (result.audioDetails != null) {
                this.leftMenuData.SellingSkillAvg = result.audioDetails.sellingSkillAvg;
                this.leftMenuData.TotalAudioCallAvg = result.audioDetails.totalAudioCallAvg;
                this.leftMenuData.DoctorSpeakTimeAvg = result.audioDetails.doctorSpeakTimeAvg;
                this.leftMenuData.RepSpeakTimeAvg = result.audioDetails.repSpeakTimeAvg;
            }

            this.drawSellingSkillGraph(this.sellingskillDataByElement);

            //To get selling skill avg by month.
            this.slsmService.GetSellingSkillAvgByMonth(params).subscribe((resultSS: any) => {

                this.demoLiveLeftMenuData = { SellingSkillAvg: this.leftMenuData.SellingSkillAvg, CampaignImpAvg: 42, TotalAudioCallAvg: this.leftMenuData.TotalAudioCallAvg, DoctorSpeakTimeAvg: this.leftMenuData.DoctorSpeakTimeAvg, RepSpeakTimeAvg: this.leftMenuData.RepSpeakTimeAvg, SellingSkillMonthlyAvg: resultSS };
            });
        });
    }

    drawSellingSkillGraph(dataByElement) {
        Highcharts.chart(this.SellingSkills.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                },
                title: {
                    text: 'Selling Skills',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 4,
                    tickInterval: 1,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'top',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: -40,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataByElement,
            })
        );
    }

    public CampaignImpGraph: any = {
        chart: {
            type: 'column',
            marginBottom: 25,
            marginLeft: 60,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 10,
                depth: 0,
                ViewDistance: 50
            }
        },
        title: {
            text: 'Campaign Implementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            type: 'category',   /*categories: ['Positioning', 'Effcacy 1', 'Effcacy 2 ', 'Dosage'],*/
            gridLineColor: 'transparent',
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 10,
            title: {
                enabled: false
            },
            labels: {
                enabled: true,
                format: '{value}%'
            },

        },

        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                depth: 100,
                pointPadding: 0.25,
                borderWidth: 1,
                color: {
                    linearGradient: [40, 40, 40, 744],
                    stops: [
                        [0, '#01ebf6'],
                        [0.2, '#01ebf6'],
                        [0.4, '#046fbd'],
                        [0.6, '#046fbd'],
                        [0.8, '#046fbd'],
                        [0.9, 'black'],
                        [1, 'black']
                    ]
                }
            }
        },
        credits: {
            enabled: false
        },

        series: [{
            data: [{
                name: 'Positioning',
                y: 55,
                drilldown: 'Positioning'
            }, {
                name: 'Efficacy 1⁰',
                y: 82,
                drilldown: 'Efficacy 1'
            }, {
                name: 'Efficacy 2⁰',
                y: 33,
                drilldown: 'Efficacy 2'
            },
            {
                name: 'Safety',
                y: 24,
                drilldown: 'Dosage'
            }]
        }

        ],
        drilldown: {
            series: [
                {
                    depth: 50,
                    stacking: null,
                    id: 'Positioning',
                    name: 'Positioning',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 1',
                    name: 'Efficacy 1⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 2',
                    name: 'Efficacy 2⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Dosage',
                    name: 'Safety',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                }

            ]
        }

    }

    showGCallD() {
        this.isHidden = true;
    }

    hideGCallD() {
        this.isHidden = false;
    }
}
