import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'app-uk',
    templateUrl: './uk.component.html',
    styleUrls: ['./uk.component.css']
})

export class UkComponent implements OnInit {

    constructor() { }

    @Input() ClientRegionData: any;
    @Input() CurrentPage: any;

    currentPageName: string = "";
    clientName: string = "";

    scotlandRegion: any = {};//"Scotland"
    southEastRegion: any = {};//"S.East"
    midlandRegion: any = {};//"Midlands"
    northEastRegion: any = {}; //"N.East"
    southWestRegion: any = {};//"S.West"


    ngOnInit() {
        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
    }

    ngOnChanges() {
        this.scotlandRegion = {};
        this.southEastRegion = {};
        this.midlandRegion = {};
        this.northEastRegion = {};
        this.southWestRegion = {};
        if (this.ClientRegionData != undefined && this.ClientRegionData != null && this.ClientRegionData.length > 0 && this.clientName == 'dummy.com') {
            var scotlandRegion = this.ClientRegionData.find(x => x.regionName == "Scotland");
            if (scotlandRegion != undefined) {
                this.scotlandRegion = scotlandRegion;
            }
            var southEastRegion = this.ClientRegionData.find(x => x.regionName == "S.East");
            if (southEastRegion != undefined) {
                this.southEastRegion = southEastRegion;
            }
            var midlandRegion = this.ClientRegionData.find(x => x.regionName == "Midlands");
            if (midlandRegion != undefined) {
                this.midlandRegion = midlandRegion;
            }
            var northEastRegion = this.ClientRegionData.find(x => x.regionName == "N.East");
            if (northEastRegion != undefined) {
                this.northEastRegion = northEastRegion;
            }
            var southWestRegion = this.ClientRegionData.find(x => x.regionName == "S.West");
            if (southWestRegion != undefined) {
                this.southWestRegion = southWestRegion;
            }
        }

        if (this.CurrentPage != undefined) {
            this.currentPageName = this.CurrentPage;
        }
    }
}
