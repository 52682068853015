import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-sellingskillselement',
    templateUrl: './sellingskillselement.component.html',
    styleUrls: ['./sellingskillselement.component.css']
})
export class SellingskillselementComponent implements OnInit {

    constructor(
        private dashboardService: DashboardService,
        private slsmService: SlsmService
    ) { }

    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    selectedViewType: string = "WEEK";

    selectedLanguage: string = "En";
    sellingSkillLables: any = [];
    openingDataList: any = [];
    questioningDataList: any = [];
    presentingDataList: any = [];
    closingDataList: any = [];

    categorisedElementDataList: any = [];
    elementNameList: any = {};
    staticTextData: any;

    @ViewChild('SOpening', { static: true }) SOpening: ElementRef;
    @ViewChild('SQuestioning', { static: true }) SQuestioning: ElementRef;
    @ViewChild('SPresenting', { static: true }) SPresenting: ElementRef;
    @ViewChild('SClosing', { static: true }) SClosing: ElementRef;

    ngOnInit() {
        this.getLanguageStaticText();
        this.selectedLanguage = window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage");

        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        //this.getClientElementName();

        this.clientLogo = window.localStorage.getItem("ClientLogo");
        //this.getSellingSkillLables();
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;

            this.getClientElementName();
            this.getSellingSkillLables();
        }, error => console.error(error));
    }

    getClientElementName() {
        var paramObj = {
            LanguageCode: this.selectedLanguage,
            UserId: parseInt(this.userId)
        }
        this.dashboardService.getClientElementName(paramObj).subscribe((result: any) => {
            this.elementNameList = {
                Opening: result.find(x => x.aliasElementName == "Opening").elementNameToShow,
                Questioning: result.find(x => x.aliasElementName == "Questioning").elementNameToShow,
                Presenting: result.find(x => x.aliasElementName == "Presenting").elementNameToShow,
                Closing: result.find(x => x.aliasElementName == "Closing").elementNameToShow
            }
        },
            error => console.error(error));
    }

    getSellingSkillLables() {
        this.slsmService.GetSellingSkillLables(this.selectedLanguage).subscribe((result: any) => {
            if (result.length > 0) {
                this.sellingSkillLables = result;
                this.getSellingSkillCategorisedData();
            }
        }, error => console.error(error));
    }

    getSellingSkillCategorisedData() {
        this.totalNoOfCalls = 0;
        this.openingDataList = [];
        this.questioningDataList = [];
        this.presentingDataList = [];
        this.closingDataList = [];

        var obj = {
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ViewType: this.selectedViewType,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }
        $('.loder-outer').show();
        this.dashboardService.getSellingSkillCategorisedData(obj).subscribe((result: any) => {

            this.categorisedElementDataList = result;
            $('.loder-outer').hide();

            this.sellingSkillLables.forEach(objLabel => {

                //Opening Data section
                var openingDataArray = [];
                this.categorisedElementDataList.openingDataList.forEach(objOpening => {
                    var obj = objLabel.labelId == 1 ? objOpening.notObserved : objLabel.labelId == 2 ? objOpening.foundational : objLabel.labelId == 3 ? objOpening.developing : objOpening.accomplished;
                    //openingDataArray.push(obj);
                    openingDataArray.push({ totalCall: objOpening.totalCalls, score: obj });
                });

                //new opening code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newOpeningDataArray = this.selectedViewType == "MONTH" ? openingDataArray : openingDataArray.reverse();
                var newOpeningData = [];
                var newOpenIndex = 0;
                newOpeningDataArray.forEach(newOpen => {
                    if (newOpen.totalCall > 0 || newOpenIndex == 1) {
                        newOpenIndex = 1;
                        newOpeningData.push(newOpen.score);
                    }
                });     

                for (var i = newOpeningData.length; i < 12; i++) {
                    newOpeningData[i] = 0;
                }
                //end here.

                var dataOpeningObj = {
                    name: objLabel.labelName,
                    color: objLabel.color,
                    data: newOpeningData//this.selectedViewType == "MONTH" ? openingDataArray : openingDataArray.reverse()
                }
                this.openingDataList.push(dataOpeningObj);

                //Questioning Data section
                var questioningDataArray = [];
                this.categorisedElementDataList.questioningDataList.forEach(objQuestioning => {
                    var obj = objLabel.labelId == 1 ? objQuestioning.notObserved : objLabel.labelId == 2 ? objQuestioning.foundational : objLabel.labelId == 3 ? objQuestioning.developing : objQuestioning.accomplished;
                    //questioningDataArray.push(obj);
                    questioningDataArray.push({ totalCall: objQuestioning.totalCalls, score: obj });
                });

                //new questioning code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newQuestioningDataArray = this.selectedViewType == "MONTH" ? questioningDataArray : questioningDataArray.reverse();
                var newQuestioningData = [];
                var newOuestionIndex = 0;
                newQuestioningDataArray.forEach(newQuestion => {
                    if (newQuestion.totalCall > 0 || newOuestionIndex == 1) {
                        newOuestionIndex = 1;
                        newQuestioningData.push(newQuestion.score);
                    }
                });

                for (var i = newQuestioningData.length; i < 12; i++) {
                    newQuestioningData[i] = 0;
                }
                //end here.

                var dataQuestioningObj = {
                    name: objLabel.labelName,
                    color: objLabel.color,
                    data: newQuestioningData//this.selectedViewType == "MONTH" ? questioningDataArray : questioningDataArray.reverse()
                }
                this.questioningDataList.push(dataQuestioningObj);

                //Presenting Data section
                var presentingDataArray = [];
                this.categorisedElementDataList.presentingDataList.forEach(objPresenting => {
                    var obj = objLabel.labelId == 1 ? objPresenting.notObserved : objLabel.labelId == 2 ? objPresenting.foundational : objLabel.labelId == 3 ? objPresenting.developing : objPresenting.accomplished;
                    //presentingDataArray.push(obj);
                    presentingDataArray.push({ totalCall: objPresenting.totalCalls, score: obj });
                });

                //new presenting code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newPresentingDataArray = this.selectedViewType == "MONTH" ? presentingDataArray : presentingDataArray.reverse();
                var newPresentingData = [];
                var newPresentIndex = 0;
                newPresentingDataArray.forEach(newPresent => {
                    if (newPresent.totalCall > 0 || newPresentIndex == 1) {
                        newPresentIndex = 1;
                        newPresentingData.push(newPresent.score);
                    }
                });

                for (var i = newPresentingData.length; i < 12; i++) {
                    newPresentingData[i] = 0;
                }
                //end here.

                var dataPresentingObj = {
                    name: objLabel.labelName,
                    color: objLabel.color,
                    data: newPresentingData//this.selectedViewType == "MONTH" ? presentingDataArray : presentingDataArray.reverse()
                }
                this.presentingDataList.push(dataPresentingObj);

                //Closing Data section
                var closingDataArray = [];
                this.categorisedElementDataList.closingDataList.forEach(objClosing => {
                    var obj = objLabel.labelId == 1 ? objClosing.notObserved : objLabel.labelId == 2 ? objClosing.foundational : objLabel.labelId == 3 ? objClosing.developing : objClosing.accomplished;
                    //closingDataArray.push(obj);
                    closingDataArray.push({ totalCall: objClosing.totalCalls, score: obj });
                });

                //new closing code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newClosingDataArray = this.selectedViewType == "MONTH" ? closingDataArray : closingDataArray.reverse();
                var newClosingData = [];
                var newCloseIndex = 0;
                newClosingDataArray.forEach(newClose => {
                    if (newClose.totalCall > 0 || newCloseIndex == 1) {
                        newCloseIndex = 1;
                        newClosingData.push(newClose.score);
                    }
                });

                for (var i = newClosingData.length; i < 12; i++) {
                    newClosingData[i] = 0;
                }
                //end here.

                var dataClosingObj = {
                    name: objLabel.labelName,
                    color: objLabel.color,
                    data: newClosingData//this.selectedViewType == "MONTH" ? closingDataArray : closingDataArray.reverse()
                }
                this.closingDataList.push(dataClosingObj);

            });

            this.drawOpeningGraph(this.openingDataList);
            this.drawQuestioningGraph(this.questioningDataList);
            this.drawPresentingGraph(this.presentingDataList);
            this.drawClosingGraph(this.closingDataList);

            //this will help to pass the data from parent to its child class.
            this.categorisedElementDataList.openingDataList.forEach(objOpening => {
                this.totalNoOfCalls = this.totalNoOfCalls + objOpening.totalCalls;
            });

        }, error => console.error(error));
    }


    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getSellingSkillCategorisedData();
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getSellingSkillCategorisedData();
    }

    changeViewType(type) {
        this.selectedViewType = type;
        this.getSellingSkillCategorisedData();
    }

    drawOpeningGraph(dataValues) {
        Highcharts.chart(this.SOpening.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.elementNameList.Opening,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataValues
            })
        );
    }

    drawQuestioningGraph(dataValues) {
        Highcharts.chart(this.SQuestioning.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.elementNameList.Questioning,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataValues
            })
        );
    }

    drawPresentingGraph(dataValues) {
        Highcharts.chart(this.SPresenting.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.elementNameList.Presenting,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataValues
            })
        );
    }

    drawClosingGraph(dataValues) {
        Highcharts.chart(this.SClosing.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.elementNameList.Closing,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: [], //['Not observed', 'Foundational', 'Developing', 'Accomplished'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataValues
            })
        );
    }

}
