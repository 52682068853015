import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as $ from 'jquery';
import { MatChipsModule } from '@angular/material/chips';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import Swal from 'sweetalert2'
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ClientService } from '../../services/client.service';
import { CategoryModal, Client, ElementModal, SalesModal } from '../../models/cms/client.model';
import { TokenService } from '../../services/token.service';
import { MatListModule } from '@angular/material/list';
@Component({
    selector: 'app-client-management',
    templateUrl: './client-management.component.html',
    styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent implements OnInit {

    addClientForm = new FormGroup({
        clientName: new FormControl('', [Validators.required]),
        domainName: new FormControl('', [Validators.required]),
        productName: new FormControl('', [Validators.required]),
        franchiseName: new FormControl('', [Validators.required]),
        countryName: new FormControl('', [Validators.required]),
        addLogo: new FormControl('', [Validators.required]),
        languageId: new FormControl('', [Validators.required]),
        consentShortStatement: new FormControl('', [Validators.required]),
        consentExtendedStatement: new FormControl('', [Validators.required]),
        isActive: new FormControl(),
        showDoctorInfo: new FormControl(),
        showDashboard: new FormControl()
    });
    addSalesForm = new FormGroup({
        salesModalName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        salesFactor: new FormControl('', [Validators.required]),
        isActive: new FormControl()
    });
    addCategoriesForm = new FormGroup({
        categoriesName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        isActive: new FormControl()
    });
    addElementForm = new FormGroup({
        elementName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        elementType: new FormControl('', [Validators.required]),
        //scaleDefination: new FormControl('', [Validators.required, Validators.minLength(2)]),
        isActive: new FormControl()
    });

    sales_List: boolean = false
    catagories_List: boolean = false
    element_List: boolean = false

    add_ClientForm: boolean = false
    add_SalesForm: boolean = false
    add_CategoriesForm: boolean = false
    add_ElementForm: boolean = false

    selectedClientId: number;
    selectedSalesModalId: number;
    selectedCategoryId: number;
    selectedElementId: number;

    objClient: Client;
    clientList: any = [];
    clientLogoPath: any = "";
    countryList: any = [];
    languageList: any = [];

    objSalesModal: SalesModal;
    salesModalList: any = [];

    objCategory: CategoryModal;
    categoryList: any = [];

    objElement: ElementModal;
    elementList: any = [];
    scaleElementList: any = [];
    scaleFactorCount: number = 0;
    disableAddScaleButton: boolean = false;
    elementTypeList: any = [];
    activeElement1 = ''
    activeElement2 = ''
    activeElement3 = ''
    activeElement4 = ''


    constructor(
        private clientService: ClientService,
        private tokenService: TokenService
    ) { }

    ngOnInit() {
        this.objClient = new Client();
        this.objSalesModal = new SalesModal();
        this.objCategory = new CategoryModal();
        this.objElement = new ElementModal();

        this.clientLogoPath = "/ClientLogo/uploaddefault.png";
        this.getMasterDataList();
        this.getClientList();
    }
  
    clientForm() {
        this.sales_List = false;
        this.catagories_List = false;
        this.element_List = false;

        this.add_ClientForm = true;
        this.add_SalesForm = false;
        this.add_CategoriesForm = false;
        this.add_ElementForm = false;
        this.resetClient();
    }
    salesForm() {
        this.sales_List = true;
        this.catagories_List = false;
        this.element_List = false;

        this.add_ClientForm = false;
        this.add_SalesForm = true;
        this.add_CategoriesForm = false;
        this.add_ElementForm = false;
        this.resetSalesModal();
    }
    catagoriesForm() {
        this.sales_List = true;
        this.catagories_List = true;
        this.element_List = false;

        this.add_ClientForm = false;
        this.add_SalesForm = false;
        this.add_CategoriesForm = true;
        this.add_ElementForm = false;
        this.resetCategory();
    }
    elementForm() {
        this.sales_List = true;
        this.catagories_List = true;
        this.element_List = true;

        this.add_ClientForm = false;
        this.add_SalesForm = false;
        this.add_CategoriesForm = false;
        this.add_ElementForm = true;
        this.resetElement();
    }

    editClient(client) {
        this.objClient = new Client();

        this.sales_List = true;
        this.catagories_List = false;
        this.element_List = false;

        this.add_ClientForm = true;
        this.add_SalesForm = false;
        this.add_CategoriesForm = false;
        this.add_ElementForm = false;

        this.addClientForm.controls["addLogo"].setErrors(null);
        this.addClientForm.controls["productName"].setErrors(null);
        this.addClientForm.controls["franchiseName"].setErrors(null);

        this.selectedClientId = client.clientId;
        this.getSalesModalList(this.selectedClientId);

        this.objClient.ClientId = client.clientId;
        this.objClient.ClientName = client.clientName;
        this.objClient.ClientLogo = client.clientLogo;
        this.clientLogoPath = "/ClientLogo/" + client.clientLogo;
        this.objClient.ClientDomain = client.clientDomain;
        this.objClient.CountryId = client.countryId;
        this.objClient.ConsentShortStatement = client.consentShortStatement;
        this.objClient.ConsentExtendedStatement = client.consentExtendedStatement;

        if (client.productName != "") {
            var splitProduct = client.productName.split(",");
            splitProduct.forEach(obj => {
                this.objClient.ProductName.push(obj);
            });
        }

        if (client.languageId != "") {
            var splitLanguage = client.languageId.split(",");
            splitLanguage.forEach(obj => {
                this.objClient.LanguageId.push(parseInt(obj));
            });
        }
        this.objClient.IsActive = client.isActive;
        this.objClient.ShowDoctorInfo = client.showDoctorInfo;
        this.objClient.ShowDashboard = client.showDashboard;

        if (client.franchiseName != "") {
            var splitFranchise = client.franchiseName.split(",");
            splitFranchise.forEach(obj => {
                this.objClient.FranchiseName.push(obj);
            });
        }
        this.activeElement1 = client
    }
    editSalesModal(salesModal) {
        this.objSalesModal = new SalesModal();

        this.sales_List = true;
        this.catagories_List = true;
        this.element_List = false;

        this.add_ClientForm = false;
        this.add_SalesForm = true;
        this.add_CategoriesForm = false;
        this.add_ElementForm = false;

        this.selectedSalesModalId = salesModal.salesModalId;
        this.getCategoryList(this.selectedSalesModalId);

        this.objSalesModal.SalesModalId = salesModal.salesModalId;
        this.objSalesModal.SalesModalName = salesModal.salesModalName;
        this.objSalesModal.ScaleFactor = salesModal.scaleFactor;
        this.objSalesModal.IsActive = salesModal.isActive;
        this.activeElement2 = salesModal
    }
    editCatagory(category) {
        this.objCategory = new CategoryModal();

        this.sales_List = true;
        this.catagories_List = true;
        this.element_List = true;

        this.add_ClientForm = false;
        this.add_SalesForm = false;
        this.add_CategoriesForm = true;
        this.add_ElementForm = false;

        this.selectedCategoryId = category.categoryId;
        this.getElementList(this.selectedCategoryId);

        this.objCategory.CategoryId = category.categoryId;
        this.objCategory.CategoryName = category.categoryName;
        this.objCategory.IsActive = category.isActive;
        this.activeElement3 = category
    }
    editElement(element) {
        this.objElement = new ElementModal();

        this.sales_List = true;
        this.catagories_List = true;
        this.element_List = true;

        this.add_ClientForm = false;
        this.add_SalesForm = false;
        this.add_CategoriesForm = false;
        this.add_ElementForm = true;

        this.selectedElementId = element.elementId;

        this.objElement.ElementId = element.elementId;
        this.objElement.ElementName = element.elementName;
        this.objElement.ElementTypeId = element.elementTypeId;
        this.objElement.IsActive = element.isActive;

        this.disableAddScaleButton = false;
        this.objElement.ScaleDefination = [];
        this.scaleFactorCount = 0;
        this.scaleElementList = [];

        if (element.elementScaleDefinationList.length > 0) {
            for (let index = 0; index < element.elementScaleDefinationList.length; index++) {
                const ele = element.elementScaleDefinationList[index];
                this.objElement.ScaleDefination[index] = ele.elementDescription;
                this.addScaleElement();
            }
        }
        this.activeElement4 = element
    }

    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    getMasterDataList() {
        this.clientService.getMasterDataList().subscribe((result: any) => {
            this.countryList = result.countryList;
            this.languageList = result.languageList;
            this.elementTypeList = result.elementTypeList;
        },
            error => console.error(error));
    }

    //Start - Client Section
    getClientList() {
        this.clientService.getClientList().subscribe((result: any) => {
            this.clientList = result;
        },
            error => console.error(error));
    }

    addProduct(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Add our product
        if (value) {
            this.objClient.ProductName.push(value);
        }
        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
    }

    removeProduct(item): void {
        const index = this.objClient.ProductName.indexOf(item);

        if (index >= 0) {
            this.objClient.ProductName.splice(index, 1);
        }
    }

    addFranchise(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Add our product
        if (value) {
            this.objClient.FranchiseName.push(value);
        }
        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
    }

    removeFranchise(item): void {
        const index = this.objClient.FranchiseName.indexOf(item);

        if (index >= 0) {
            this.objClient.FranchiseName.splice(index, 1);
        }
    }

    uploadClientLogo(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (event) {
                $('#imagePreview').attr('src', event.target["result"]);
            }

            var fileToUpload = input.files[0];
            reader.readAsDataURL(fileToUpload);

            var formData = new FormData();
            formData.append(fileToUpload.name, fileToUpload);

            this.objClient.ClientLogo = fileToUpload.name;

            $.ajax({
                url: "/api/UploadFile/UploadClientLogo/",
                data: formData,
                processData: false,
                contentType: false,
                headers: { 'Authorization': 'bearer ' + this.tokenService.getToken() },
                type: "POST",
                success: function (result) {
                    if (result.code == 202) {
                        Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
                    }
                }
            });

        }
    }

    submitClient(client) {
        if (client.IsActive == null) {
            client.IsActive = false;
        }
        if (client.ShowDoctorInfo == null) {
            client.ShowDoctorInfo = false;
        }
        if (client.ShowDashboard == null) {
            client.ShowDashboard = false;
        }
        client.ProductName = client.ProductName.toString();
        client.LanguageId = client.LanguageId.toString();
        client.FranchiseName = client.FranchiseName.toString();
        this.clientService.addUpateClient(client).subscribe((result: any) => {
            if (result.code == 200) {
                this.getClientList();
                this.resetClient();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        },
            error => console.error(error));
        this.objClient.ProductName = [];
        this.objClient.LanguageId = [];
        this.objClient.FranchiseName = [];
    }

    resetClient() {
        this.objClient = new Client();
        $('#imagePreview').attr('src', "/ClientLogo/uploaddefault.png");
        this.clientLogoPath = "/ClientLogo/uploaddefault.png";
        this.addClientForm.reset();
    }

    //End - Client Section

    //Start - Sales Modal Section
    getSalesModalList(clientId) {
        this.clientService.getSalesModalList(clientId).subscribe((result: any) => {
            this.salesModalList = result;
        },
            error => console.error(error));
    }

    submitSalesModal(salesModal) {
        salesModal.CreatedBy = this.tokenService.getUserId();
        salesModal.ClientId = this.selectedClientId;
        if (salesModal.IsActive == null) {
            salesModal.IsActive = false;
        }
        this.clientService.addUpdateSalesModal(salesModal).subscribe((result: any) => {
            if (result.code == 200) {
                this.getSalesModalList(this.selectedClientId);
                this.resetSalesModal();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }
            , error => console.error(error));
    }

    resetSalesModal() {
        this.objSalesModal = new SalesModal();
        this.addSalesForm.reset();
    }
    //End - Sales Modal Section

    //Start - Category Section
    getCategoryList(salesModalId) {
        this.clientService.getCategoryList(salesModalId).subscribe((result: any) => {
            this.categoryList = result;
        },
            error => console.error(error));
    }

    submitCategory(category) {
        category.CreatedBy = this.tokenService.getUserId();
        category.SalesModalId = this.selectedSalesModalId;
        if (category.IsActive == null) {
            category.IsActive = false;
        }
        this.clientService.addUpdateCategory(category).subscribe((result: any) => {
            if (result.code == 200) {
                this.getCategoryList(this.selectedSalesModalId);
                this.resetCategory();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }
            , error => console.error(error));
    }

    resetCategory() {
        this.objCategory = new CategoryModal();
        this.addCategoriesForm.reset();
    }
    //End - Category Section

    //Start - Element Section
    getElementList(categoryId) {
        this.clientService.getElementList(categoryId).subscribe((result: any) => {
            this.elementList = result;
        },
            error => console.error(error));
    }

    submitElement(element) {
        element.CreatedBy = this.tokenService.getUserId();
        element.CategoryId = this.selectedCategoryId;
        element.ScaleDefination = element.ScaleDefination.join('~');
        if (element.IsActive == null) {
            element.IsActive = false;
        }
        this.clientService.addUpdateElement(element).subscribe((result: any) => {
            if (result.code == 200) {
                this.getElementList(this.selectedCategoryId);
                this.resetElement();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }
            , error => console.error(error));
    }

    resetElement() {
        this.objElement = new ElementModal();
        this.scaleElementList = [];
        this.scaleFactorCount = 0;
        this.disableAddScaleButton = false;
        this.addElementForm.reset();
    }

    addScaleElement() {
        if (this.scaleFactorCount < this.objSalesModal.ScaleFactor) {
            this.scaleFactorCount = this.scaleFactorCount + 1;
            this.scaleElementList.push('text_area' + (this.scaleElementList.length + 1));
            if (this.scaleFactorCount === this.objSalesModal.ScaleFactor) {
                this.disableAddScaleButton = true;
            }
            else {
                this.disableAddScaleButton = false;
            }
        }
    }

    removeScaleElement(index) {
        this.scaleElementList.splice(index, 1);
        this.objElement.ScaleDefination.splice(index, 1);
        this.scaleFactorCount = this.scaleFactorCount - 1;
        this.disableAddScaleButton = false;
    }
    //End - Element Section
}