import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    getUserMasterList(params) {
        return this.http.get('api/Admin/GetUserMasterList/' + params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
    getUserList() {
        return this.http.get('api/Admin/GetUserList', { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
    addUpateUser(params) {
        return this.http.post('api/Admin/AddUpdateUser', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}