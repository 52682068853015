import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';

export interface SellingSkills {
    rep: string;
    country: string;
    opening: number;
    questioning: number;
    presenting: number;
    closing: number;
    drVoice: number;
    callLength: number;
    targetPatient: number; 
    outcome:number,
    message1: number;
    message2: number;
    message3: number;
    others: number;
    callAction: string;

}

const SS_DATA: SellingSkills[] = [
    {rep:'Demo', country: 'USA', opening: 2.0, questioning: 2.9, presenting: 3.5, closing: 2.9, callLength: 17.1, outcome: 23.0, drVoice: 30.8, targetPatient: 74.0, message1: 52.0, message2: 72.0, message3: 42.0, others: 27.0, callAction: 'Advocacy'},
    {rep:'Demo', country: 'Alaska', opening: 2.1, questioning: 3.3, presenting: 3.2, closing: 3.2, outcome: 24.9, callLength: 19.2, drVoice: 28.4, targetPatient: 64.2, message1: 49.3, message2: 78.0, message3: 39, others: 25.6, callAction: 'Patient Id' },
    {rep:'Demo', country: 'Midwest', opening: 2.4, questioning: 2.5, presenting: 3.8, closing: 2.6, outcome: 22.4, callLength: 16.3, drVoice: 32.5, targetPatient: 73.1, message1: 53.0, message2: 76.0, message3: 46.2, others: 24.3, callAction: 'Advocacy' },
    { rep: 'John Doe', country: 'North Central', opening: 1.8, questioning: 2.2, presenting: 2.9, closing: 3.1, outcome: 23.6, callLength: 15.0, drVoice: 29.3, targetPatient: 78.5, message1: 57.0, message2: 69.1, message3: 42.8, others: 29.5, callAction: 'Prescribe' },
    {rep:'Demo', country: 'North East', opening: 1.9, questioning: 3.4, presenting: 3.9, closing: 2.7, outcome: 21.1, callLength: 17.9, drVoice: 33.0, targetPatient: 80.2, message1: 48.7, message2: 65.0, message3: 40.0, others: 28.4, callAction: 'Guidelines' },
    {rep:'Demo', country: 'North West', opening: 2.4, questioning: 2.9, presenting: 3.5, closing: 2.9, outcome: 21.3, callLength: 17.3, drVoice: 30.2, targetPatient: 70.5, message1: 51.6, message2: 73.3, message3: 45.6, others: 29.5, callAction: 'Advocacy' },
    {rep:'Demo', country: 'Pacific Islands', opening: 1.8, questioning: 2.6, presenting: 2.6, closing: 3.1, outcome: 21.5, callLength: 14.6, drVoice: 30.6, targetPatient: 69.4, message1: 55.7, message2: 67.4, message3: 42.8, others: 24.7, callAction: 'MSL' },
    {rep:'Demo', country: 'South Central', opening: 2.3, questioning: 3.4, presenting: 2.8, closing: 1.9, outcome: 22.4, callLength: 15.8, drVoice: 31.4, targetPatient: 79.5, message1: 45.6, message2: 67.5, message3: 43.9, others: 30.4, callAction: 'Patient Id' },
    {rep:'Demo', country: 'South East', opening: 2.2, questioning: 2.7, presenting: 3.2, closing: 2.4, outcome: 23.4, callLength: 15.0, drVoice: 30.2, targetPatient: 78.5, message1: 57.0, message2: 68.2, message3: 44.6, others: 29.5, callAction: 'MSL' },
    {rep:'Demo', country: 'South West', opening: 1.7, questioning: 3.1, presenting: 3.6, closing: 2.1, outcome: 25.3, callLength: 19.3, drVoice: 35.4, targetPatient: 81.3, message1: 46.9, message2: 63.4, message3: 42.0, others: 29.5, callAction: 'Guidelines' },

];
@Component({
  selector: 'app-glance',
  templateUrl: './glance.component.html',
  styleUrls: ['./glance.component.css']
})
export class GlanceComponent implements OnInit {
    @ViewChild('table', { static: false }) table: MatTable<SellingSkills>;
    userProfileId: any = window.localStorage.getItem("UserProfileId");
    displayedColumns: string[] 


    dataSource = new MatTableDataSource(SS_DATA);

    @ViewChild('table', { read: MatSort, static: true }) sort1: MatSort;
    selectedView: string;

    constructor(private router: Router) {
        this.selectedView = localStorage.getItem('selectedView');
    }

    pageNavigateTo(value, data) {
        window.localStorage.setItem("FilterIdUS", data.country);

        this.router.navigate(["/staticdashboard/CAC"]);
    }
    displayeColumnData() {
        if (this.userProfileId == 4) {
            this.dataSource = new MatTableDataSource([SS_DATA[3]]);
            this.displayedColumns = ['rep', 'opening', 'questioning', 'presenting', 'closing', 'outcome', 'callLength', 'drVoice', 'targetPatient', 'message1', 'message2', 'message3', 'others', 'callAction'];
        }
        else {
            this.dataSource = new MatTableDataSource(SS_DATA);
            this.displayedColumns = ['country', 'opening', 'questioning', 'presenting', 'closing', 'outcome', 'callLength', 'drVoice', 'targetPatient', 'message1', 'message2', 'message3', 'others', 'callAction'];
        }
    }
    ngOnInit() {
        this.displayeColumnData();



    }
}
