import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as $ from 'jquery';
import { MatChipsModule } from '@angular/material/chips';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import Swal from 'sweetalert2'
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ClientService } from '../../services/client.service';
import { CategoryModal, Client, ElementModal, SalesModal } from '../../models/cms/client.model';
import { TokenService } from '../../services/token.service';
import { MatListModule } from '@angular/material/list';
import { Country, Region } from '../../models/cms/geo.model';
import { GeoService } from '../../services/geo.service';

@Component({
    selector: 'app-geography',
    templateUrl: './geography.component.html',
    styleUrls: ['./geography.component.css']
})
export class GeographyComponent implements OnInit {

    addCountryForm = new FormGroup({
        countryName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        countryCode: new FormControl('', [Validators.required]),
        //countryLogo: new FormControl('', [Validators.required]),
        isActive: new FormControl()
    });

    addRegionForm = new FormGroup({
        regionName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        regionCode: new FormControl('', [Validators.required]),
        isActive: new FormControl()
    });


    country_List: boolean = true
    region_List: boolean = false

    add_CountryForm: boolean = false
    add_RegionForm: boolean = false

    objCountry: Country;
    countryList: any = [];
    countryLogoPath: any = "";
    selectedCountryId: number = 0;

    objRegion: Region;
    regionList: any = [];
    selectedRegionId: number = 0;
    activeElement1 = ''
    activeElement2 = ''
    constructor(
        private clientService: ClientService,
        private geoService: GeoService,
        private tokenService: TokenService
    ) { }

    ngOnInit() {
        this.objCountry = new Country();
        this.objRegion = new Region();
        //this.countryLogoPath = "/ClientLogo/uploaddefault.png";

        this.getMasterDataList();
    }

    getMasterDataList() {
        this.clientService.getMasterDataList().subscribe((result: any) => {
            this.countryList = result.countryList;
        },
            error => console.error(error));
    }

    //Start - Country Section

    submitCountry(country) {

        if (country.IsActive == null) {
            country.IsActive = false;
        }

        this.geoService.addUpateCountry(country).subscribe((result: any) => {
            if (result.code == 200) {
                this.getMasterDataList();
                this.resetCountry();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }
            , error => console.error(error));
    }

    editCountry(country) {
        this.objCountry = new Country();

        this.country_List = true;
        this.region_List = true;

        this.add_CountryForm = true;
        this.add_RegionForm = false;

        //this.addCountryForm.controls["countryLogo"].setErrors(null);

        this.selectedCountryId = country.countryId;

        this.objCountry.CountryId = country.countryId;
        this.objCountry.CountryName = country.countryName;
        this.objCountry.CountryCode = country.countryCode;
        this.objCountry.IsActive = country.isActive;
        //this.objCountry.CountryLogo = country.countryLogo;
        //this.countryLogoPath = "/CountryFlags/" + country.countryLogo;

        this.getRegionList(this.selectedCountryId);
        this.activeElement1 = country
    }

    resetCountry() {
        this.objCountry = new Country();
        //$('#imagePreview').attr('src', "/ClientLogo/uploaddefault.png");
        //this.countryLogoPath = "/ClientLogo/uploaddefault.png";
        this.addCountryForm.reset();
    }

    //uploadCountryLogo(input) {
    //    if (input.files && input.files[0]) {
    //        var reader = new FileReader();
    //        reader.onload = function (event) {
    //            $('#imagePreview').attr('src', event.target["result"]);
    //        }

    //        var fileToUpload = input.files[0];
    //        reader.readAsDataURL(fileToUpload);

    //        var formData = new FormData();
    //        formData.append(fileToUpload.name, fileToUpload);

    //        this.objCountry.CountryLogo = fileToUpload.name;

    //        $.ajax({
    //            url: "/api/UploadFile/UploadClientLogo/",
    //            data: formData,
    //            processData: false,
    //            contentType: false,
    //            headers: { 'Authorization': 'bearer ' + this.tokenService.getToken() },
    //            type: "POST",
    //            success: function (result) {
    //                if (result.code == 202) {
    //                    Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
    //                }
    //            }
    //        });

    //    }
    //}

    countryForm() {
        this.country_List = true;
        this.region_List = false;

        this.add_CountryForm = true;
        this.add_RegionForm = false;

        this.resetCountry();
    }

    //End - Country Section

    //Start - Region Section

    getRegionList(countryId) {
        this.regionList = [];
        this.geoService.getRegionList(countryId).subscribe((result: any) => {
            this.regionList = result;
        },
            error => console.error(error));
    }

    submitRegion(region) {

        if (region.IsActive == null) {
            region.IsActive = false;
        }
        region.CountryId = this.selectedCountryId;
        this.geoService.addUpateRegion(region).subscribe((result: any) => {
            if (result.code == 200) {
                this.getRegionList(this.selectedCountryId);
                this.resetRegion();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }
            , error => console.error(error));
    }

    editRegion(region) {
        this.objRegion = new Region();

        this.country_List = true;
        this.region_List = true;

        this.add_CountryForm = false;
        this.add_RegionForm = true;

        this.selectedRegionId = region.regionId;

        this.objRegion.RegionId = region.regionId;
        this.objRegion.RegionName = region.regionName;
        this.objRegion.RegionCode = region.regionCode;
        this.objRegion.IsActive = region.isActive;
        this.activeElement2 = region
    }

    resetRegion() {
        this.objRegion = new Region();
        this.addRegionForm.reset();
    }

    regionForm() {
        
        this.country_List = true;
        this.region_List = true;

        this.add_CountryForm = false;
        this.add_RegionForm = true;

        this.resetRegion();
    }

    //End - Region Section
}
