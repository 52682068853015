import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { ClientManagementComponent } from './client-management/client-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { GeographyComponent } from './geography/geography.component'
import { ClientcampaignComponent } from './clientcampaign/clientcampaign.component';

const routes: Routes = [
    { path: 'client', component: ClientManagementComponent, canActivate: [AuthGuardService]},
    { path: 'user', component: UserManagementComponent, canActivate: [AuthGuardService] },
    { path: 'geo', component: GeographyComponent, canActivate: [AuthGuardService] },
    { path: 'cm', component: ClientcampaignComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CMSRoutingModule { }
