import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
declare var require: any;
import Drilldown from 'highcharts/modules/drilldown';
import { CoachService } from '../../services/coach.service';
import { error } from 'console';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client.service';
require('highcharts/highcharts-more')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Drilldown(Highcharts);
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
    selector: 'app-callanalysis',
    templateUrl: './callanalysis.component.html',
    styleUrls: ['./callanalysis.component.css']
})
export class CallanalysisComponent implements OnInit {

    @ViewChild('ReportedScores', { static: true }) ReportedScores: ElementRef;
    @ViewChild('OverallAccuracy', { static: true }) OverallAccuracy: ElementRef;
    @ViewChild('CampReportedScores', { static: true }) CampReportedScores: ElementRef;

    callElementList: any = [];
    callElementScore: any = [];
    callDeviationDiff: any = [];
    overallAccuracy: any = 0.0;
    clientList: any = [];
    productList: any = [];
    selectedClientId: number = 0;
    selectedProductId: number = 0;
    selectedStartDate: string = "";
    selectedEndDate: string = "";
    showIBMAccuracy: any = true;
    userDetailsList: any = [];

    selectedLanguage: string = "En";
    graphTypeList: any = [];
    selectedGraph: number = 0;
    ciCTAElementList: any = [];
    ciCTAElementScore: any = [];
    ciCTADeviationDiff: any = [];
    ciCTAOverallAccuracy: any = 0.0;
    callExportData: any = [];

    constructor(
        private coachService: CoachService,
        private clientService: ClientService
    ) { }

    ngOnInit() {
        //this.getClientList();
        //this.getProductList(0);
        //this.getAvgOfCallData();
        this.getGraphTypeList();
    }

    getGraphTypeList() {
        this.coachService.GetGraphTypeList(this.selectedLanguage).subscribe((result: any) => {
            this.graphTypeList = result;
            this.selectedGraph = result[0].id;
            this.getClientList();
        },
            error => console.error(error));
    }

    getClientList() {
        var objParam = {
            UserId: parseInt(window.localStorage['UserId'])
        }
        this.clientService.getClientList().subscribe((result: any) => {
            if (window.localStorage['UserProfileId'] == 9) {
                this.coachService.getCoachUserDetails(objParam).subscribe((userResult: any) => {
                    this.userDetailsList = userResult;
                    this.clientList = result.filter(x => x.clientId == this.userDetailsList[0].clientId);
                    this.selectedClientId = this.clientList[0].clientId;
                    this.getProductList(0, parseInt(window.localStorage['UserId']));
                },
                    error => console.error(error));
            }
            else {
                this.clientList = result;
                if (result.length > 0) {
                    this.selectedClientId = result[0].clientId;
                    this.getProductList(this.selectedClientId, 0);
                }
            }
        },
            error => console.error(error));
    }

    onSelectClient(clientId) {
        this.selectedClientId = clientId;
        this.getProductList(clientId,0);
        //if (this.showIBMAccuracy == true) {
        //    this.getAvgOfCallData();
        //}
        //else {
        //    this.getAvgOfCallHighScoreData();
        //}
    }

    getProductList(clientId, userId) {
        var objParam = {
            ClientId: clientId,
            UserId: userId
        }
        this.coachService.GetProductList(objParam).subscribe((result: any) => {
            this.productList = result;
            if (result.length > 0) {
                this.selectedProductId = result[0].productId;
                if (this.selectedGraph != 1) {
                    this.getAvgOfCICTAData();
                }
                else {
                    if (this.showIBMAccuracy == true) {
                        this.getAvgOfCallData();
                    }
                    else {
                        this.getAvgOfCallHighScoreData();
                    }
                }
            }
        },
            error => console.error(error));
    }

    onSelectProduct(productId) {
        this.selectedProductId = productId;
        if (this.selectedGraph != 1) {
            this.getAvgOfCICTAData();
        }
        else {
            if (this.showIBMAccuracy == true) {
                this.getAvgOfCallData();
            }
            else {
                this.getAvgOfCallHighScoreData();
            }
        }
    }

    getAvgOfCallData() {
        $("#imgcall").removeClass("selected-img-filter");
        $("#imgibm").addClass("selected-img-filter");
        this.showIBMAccuracy = true;
        this.callElementList = [];
        this.callElementScore = [];
        this.callDeviationDiff = [];
        this.overallAccuracy = 0.0;

        var objParam = {
            CallUniqueId: '',
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId,
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate
        };

        this.coachService.GetCallsDeviationAvgData(objParam).subscribe((response: any) => {
            if (response.length > 0) {
                var elementData = response.filter(x => x.elementId != 0);
                elementData.forEach(obj => {
                    this.callDeviationDiff.push([obj.elementName, obj.deviationDiff]);
                });
                this.overallAccuracy = response.filter(x => x.elementId == 0)[0].deviationDiff;
            }
            this.drawOverallAccuracyGraph(this.overallAccuracy, this.callDeviationDiff);
        }, error => console.error(error));

        this.coachService.GetCallsScoreAvgData(objParam).subscribe((response: any) => {
            if (response.length > 0) {
                response.forEach(obj => {
                    this.callElementList.push(obj.elementName);
                    this.callElementScore.push({ IBM_Score: obj.ibmScoreAvg, Coach_Score: obj.coachScoreAvg });
                });
            }
            this.drawReportedScoreGraph(this.callElementList, this.callElementScore);
        }, error => console.error(error));

        this.getCallDetailsToExport();
    }

    drawReportedScoreGraph(elementList, elementScore) {
        Highcharts.chart(this.ReportedScores.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Reported Scores & Accuracy',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                xAxis: {
                    categories: elementList,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 4,
                    tickInterval: 1,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'IBM Assessment',
                    color: '#4472c4',
                    data: elementScore.map(function (a) { return a.IBM_Score; })
                }, {
                    name: 'Correct Assessment',
                    color: '#00b050',
                    data: elementScore.map(function (a) { return a.Coach_Score; })
                }],
            })
        );
    }

    drawOverallAccuracyGraph(overallAccuracy, callDeviationDiff) {
        Highcharts.chart(this.OverallAccuracy.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Overall Accuracy',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Accuracy',
                    color: overallAccuracy >= 80 ? '#00b050' : 'red',
                    data: [{
                        name: 'Accuracy',
                        y: overallAccuracy,
                        drilldown: 'Accuracy'
                    }]
                }],
                drilldown: {
                    series: [{
                        stacking: null,
                        id: 'Accuracy',
                        data: callDeviationDiff,
                        zonesAxis: 'y',
                        zones: [{
                            value: 51,
                            color: 'red',
                        }, {
                            value: 101,
                            color: '#00b050',
                        }]
                    }]
                }
            })
        );
    }

    onStartDateChange(value) {
        this.selectedStartDate = value;
        if (this.selectedStartDate != "" && this.selectedEndDate != "") {
            if (new Date(this.selectedStartDate) > new Date(this.selectedEndDate)) {
                Swal.fire({
                    icon: 'info',
                    title: 'Start Date can not be greater than End Date.',
                });
                return false;
            }
        }
        if (this.selectedGraph != 1) {
            this.getAvgOfCICTAData();
        }
        else {
            if (this.showIBMAccuracy == true) {
                this.getAvgOfCallData();
            }
            else {
                this.getAvgOfCallHighScoreData();
            }
        }
    }

    onEndDateChange(value) {
        this.selectedEndDate = value;
        if (this.selectedStartDate != "" && this.selectedEndDate != "") {
            if (new Date(this.selectedStartDate) > new Date(this.selectedEndDate)) {
                Swal.fire({
                    icon: 'info',
                    title: 'Start Date can not be greater than End Date.',
                });
                return false;
            }
        }
        if (this.selectedGraph != 1) {
            this.getAvgOfCICTAData();
        }
        else {
            if (this.showIBMAccuracy == true) {
                this.getAvgOfCallData();
            }
            else {
                this.getAvgOfCallHighScoreData();
            }
        }
    }

    getAvgOfCallHighScoreData() {
        $("#imgcall").addClass("selected-img-filter");
        $("#imgibm").removeClass("selected-img-filter");
        this.showIBMAccuracy = false;
        this.callElementList = [];
        this.callElementScore = [];
        this.callDeviationDiff = [];
        this.overallAccuracy = 0.0;

        var objParam = {
            CallUniqueId: '',
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId,
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate
        };

        this.coachService.GetOverallCallsHighScoreAvgData(objParam).subscribe((response: any) => {
            var responseHighScore = response.overallHighScoreAvgList;
            var responseHighDeviation = response.overallHighDeviationAvgList;

            if (responseHighScore.length > 0) {
                responseHighScore.forEach(obj => {
                    this.callElementList.push(obj.elementName);
                    this.callElementScore.push({ IBM_Score: obj.ibmScoreAvg, Coach_Score: obj.coachScoreAvg });
                });
            }

            if (responseHighDeviation.length > 0) {
                var elementData = responseHighDeviation.filter(x => x.elementId != 0);
                elementData.forEach(obj => {
                    this.callDeviationDiff.push([obj.elementName, obj.deviationDiff]);
                });
                this.overallAccuracy = responseHighDeviation.filter(x => x.elementId == 0)[0].deviationDiff;
            }

            this.getCallDetailsToExport();
            this.drawReportedScoreGraph(this.callElementList, this.callElementScore);
            this.drawOverallAccuracyGraph(this.overallAccuracy, this.callDeviationDiff);

        }, error => console.error(error));
    }

    onGraphTypeSelection() {
        if (this.selectedGraph === 1) {
            $('#ReportedScores').removeClass('d-none');
            $('#CampReportedScores').addClass('d-none');
            $('#imgibm').removeClass('d-none');
            $('#imgcall').removeClass('d-none');
            if (this.showIBMAccuracy == true) {
                this.getAvgOfCallData();
            }
            else {
                this.getAvgOfCallHighScoreData();
            }
        }
        else {
            $('#ReportedScores').addClass('d-none');
            $('#CampReportedScores').removeClass('d-none');
            $('#imgibm').addClass('d-none');
            $('#imgcall').addClass('d-none');
            this.getAvgOfCICTAData();
        }
    }

    getAvgOfCICTAData() {
        this.ciCTAElementList = [];
        this.ciCTAElementScore = [];
        this.ciCTADeviationDiff = [];
        this.ciCTAOverallAccuracy = 0.0;

        var objParam = {
            CallUniqueId: '',
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId,
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate,
            GraphTypeId: this.selectedGraph
        };

        this.coachService.GetCICTAAvgData(objParam).subscribe((response: any) => {
            var responseScore = response.scoreAvgList;
            var responseDeviation = response.deviationAvgList;

            if (responseScore.length > 0) {
                responseScore.forEach(obj => {
                    this.ciCTAElementList.push(obj.elementName);
                    this.ciCTAElementScore.push({ IBM_Score: obj.ibmScoreAvg, Coach_Score: obj.coachScoreAvg });
                });
            }

            if (responseDeviation.length > 0) {
                var elementData = responseDeviation.filter(x => x.elementId != 0);
                elementData.forEach(obj => {
                    this.ciCTADeviationDiff.push([obj.elementName, obj.deviationDiff]);
                });
                this.ciCTAOverallAccuracy = responseDeviation.filter(x => x.elementId == 0)[0].deviationDiff;
            }

            this.getCallDetailsToExport();
            this.drawCampReportedScoreGraph(this.ciCTAElementList, this.ciCTAElementScore);
            this.drawOverallAccuracyGraph(this.ciCTAOverallAccuracy, this.ciCTADeviationDiff);

        }, error => console.error(error));
    }

    getCallDetailsToExport() {
        var objParam = {
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId,
            GraphType: this.selectedGraph,
            StartDate: this.selectedStartDate,
            EndDate: this.selectedEndDate,
        };
        this.callExportData = [];
        $('.loder-outer').show();
        this.coachService.GetCallDetailsToExport(objParam).subscribe((response: any) => {
            if (response.length > 0) {
                response.forEach(obj => {
                    this.callExportData.push({CallDate: obj.callDate, CallUniqueId: obj.callUniqueId, ElementName: obj.elementName, TranscriptChunk: obj.transcriptChunk, IBMScore: obj.ibmScore, CoachScore: obj.coachScore, CoachComment: obj.coachComment});
                });
            }
        $('.loder-outer').hide();
        }, error => console.error(error));
    }

    exportTableToCsv(table_id, separator = ',') {
        // Select rows from table_id
        var rows = document.querySelectorAll('table#' + table_id + ' tr');
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll<HTMLElement>('td, th');
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        csv = csv.filter(x => x);
        var csv_string = csv.join('\n');
        // Download it
        var filename = 'CallTranscriptDetails_' + '_' + new Date().toLocaleDateString() + '_' + new Date().toTimeString().split(" ")[0] + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    drawCampReportedScoreGraph(elementList, elementScore) {
        Highcharts.chart(this.CampReportedScores.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Reported Scores & Accuracy',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                xAxis: {
                    categories: elementList,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'IBM Assessment',
                    color: '#4472c4',
                    data: elementScore.map(function (a) { return a.IBM_Score; })
                }, {
                    name: 'Correct Assessment',
                    color: '#00b050',
                    data: elementScore.map(function (a) { return a.Coach_Score; })
                }],
            })
        );
    }

}
