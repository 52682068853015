import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import * as $ from 'jquery';
import { SellingSkillElements } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';

@Component({
    selector: 'app-sellingskills',
    templateUrl: './sellingskills.component.html',
    styleUrls: ['./sellingskills.component.css']
})
export class SellingskillsComponent implements OnInit {

    event: any;
    element: any;
    valueToCheck: any;
    totalNoOfCalls: any;
    selectedCategory: any = 'Overall'
    selectedViewtype: any = 'WEEK';
    sliderValue: any;
    selectedView: string;
    constructor(
        private route: Router,
        private dashboardService: DashboardService
    ) {

        this.selectedView = localStorage.getItem('selectedView');
        this.updateElementNames();
    }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";


    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }
        if (value >= 1) {
            return ' - ' + Math.round(value / 1) + '';

        }
        return value;
    }

    ngOnInit() {
        //$('.uk-map').hide();
        //if (localStorage.getItem('mapdrill') == '1') {
        //    $('.uk-map').show();
        //    $('.w-map_1').hide();
        //}
        this.getSellingSkillDataByClient(this.valueToCheck)
        this.StaticonWeekChange(this.event)

        this.onSelectElement(this.element, event);
        this.selectedCategory = 'Overall';
        this.selectedViewtype = 'WEEK';
        this.showMapData();


    }



    mapData: any = [['us', 3.1]];
    selectedElementList: any = [];

    updateFlag = false;
    currentPage: string = "SS";

    clientRegionDataList: any = [];
    clientRegionData: any = [];
    staticTextData: any;
    isMonthlySelected = true;
    value: number = 0
    @ViewChild('table', { static: false }) table: MatTable<SellingSkillElements>;

    displayedColumns: string[] = ['ElementName', 'Score', 'Checked'];

    dataSource = [{
        "elementNameToShow": "Overall",
        "aliasElementName": "Overall",
        "score": 3.1
    },
    {
        "elementNameToShow": "Opening",
        "aliasElementName": "Opening",
        "score": 2.4
    },
    {
        "elementNameToShow": "Questioning",
        "aliasElementName": "Questioning",
        "score": 3.5
    },
    {
        "elementNameToShow": "Presenting",
        "aliasElementName": "Presenting",
        "score": 3.3
    },
    {
        "elementNameToShow": "Closing",
        "aliasElementName": "Closing",
        "score": 3.1
    }

    ];
    updateElementNames() {
        if (this.selectedView === "Msl") {
            this.dataSource.forEach(item => {
                if (item.aliasElementName === "Opening") {
                    item.elementNameToShow = "Engage";
                    item.aliasElementName = "Engage";
                }
                if (item.aliasElementName === "Questioning") {
                    item.elementNameToShow = "Explore";
                    item.aliasElementName = "Explore";
                }
                if (item.aliasElementName === "Presenting") {
                    item.elementNameToShow = "Exchange";
                    item.aliasElementName = "Exchange"
                }
                if (item.aliasElementName === "Closing") {
                    item.elementNameToShow = "Evolve";
                    item.aliasElementName = "Evolve"
                }
            });
        }
        else {
            this.dataSource.forEach(item => {
                if (item.aliasElementName === "Engage") {
                    item.elementNameToShow = "Opening";
                    item.aliasElementName = "Opening";
                }
                if (item.aliasElementName === "Explore") {
                    item.elementNameToShow = "Questioning";
                    item.aliasElementName = "Questioning";
                }
                if (item.aliasElementName === "Exchange") {
                    item.elementNameToShow = "Presenting";
                    item.aliasElementName = "Presenting"
                }
                if (item.aliasElementName === "Evolve") {
                    item.elementNameToShow = "Closing";
                    item.aliasElementName = "Closing"
                }
            });
        }
    }
    getSellingSkillDataByClient(valueToCheck) {
        $('.loder-outer').show();
        var objParam = {

        }
        this.dashboardService.getSellingSkillDataByClient(objParam).subscribe((result: any) => {

            this.totalNoOfCalls = 19



            ////called Region method
            //this.getClientSellingSkillDataByRegion();
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }
    updateScoreValues(sc1, sc2, sc3, sc4, sc5) {
        this.dataSource.forEach(item => {
            if (['Overall'].includes(item.aliasElementName)) {
                item.score = sc1

            }
            if (['Opening', 'Engage'].includes(item.aliasElementName)) {
                item.score = sc2

            }
            if (['Questioning', 'Explore'].includes(item.aliasElementName)) {
                item.score = sc3

            }
            if (['Presenting', 'Exchange'].includes(item.aliasElementName)) {
                item.score = sc4

            }
            if (['Closing', 'Evolve'].includes(item.aliasElementName)) {
                item.score = sc5

            }
        });

    }
    changeViewType(view) {
        this.selectedViewtype = view;
        this.value = 0
        if (this.selectedViewtype == 'WEEK') {
            this.mapData = [['us', 3.1]];
            this.totalNoOfCalls = 19;
            this.updateScoreValues(3.1, 2.4, 3.5, 3.3, 3.1)



            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['us', score]],
                name: Selectedname,

            }
            this.updateFlag = true;

        }
        if (this.selectedViewtype == 'MONTH') {

            this.mapData = [['us', 2.9]];
            this.updateScoreValues(2.9, 2.4, 3.2, 3.5, 2.7)
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['us', score]],
                name: Selectedname,

            }
            this.updateFlag = true;
            this.totalNoOfCalls = 76;
        }
    }
    onSelectElement(element, event) {
        this.selectedElementList = [];
        this.selectedElementList.push(element.aliasElementName);
        this.selectedCategory = element.aliasElementName

        this.chartOptions.series[0] = {
            type: 'map',
            data: [['us', element.score]
            ],
            name: element.aliasElementName
        }
        this.updateFlag = true;

    }

    showMapData() {
        this.mapData = [];
        var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
        var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['us', score]],
            name: Selectedname,

        }
        this.updateFlag = true;
        this.totalNoOfCalls = 19;
    }


    StaticonWeekChange(event) {
        this.sliderValue = event.value
        if (this.selectedViewtype == 'WEEK') {
            if (event.value === 0) {
                this.totalNoOfCalls = 19;
                this.updateScoreValues(3.1, 2.4, 3.5, 3.3, 3.1)

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: this.selectedCategory,

                }

                this.updateFlag = true;

            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 19;
                this.updateScoreValues(3.0, 2.3, 3.3, 3.4, 2.8)

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: this.selectedCategory,

                }


                this.updateFlag = true;

            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 19;

                this.updateScoreValues(2.9, 2.4, 3.6, 2.5, 3.2)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: this.selectedCategory,

                }

                this.updateFlag = true;
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 20;


                this.updateScoreValues(2.8, 2.3, 2.9, 3.5, 2.4)

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 19;
                this.updateScoreValues(2.7, 2.2, 2.9, 3.5, 2.3)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20;
                this.updateScoreValues(2.7, 1.7, 2.8, 3.5, 2.6)
                
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20;
                this.updateScoreValues(2.7, 1.8, 2.8, 3.5, 2.6)
   
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 15;
                this.updateScoreValues(2.7, 1.7, 2.5, 3.5, 2.9)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 8) {
                this.totalNoOfCalls = 20;
                this.updateScoreValues(2.7, 1.8, 2.6, 3.5, 3.0)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20;
                this.updateScoreValues(2.9, 2.0, 2.7, 3.5, 3.2)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 20;
                this.updateScoreValues(2.7, 1.7, 2.7, 3.3, 3.0)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }

                this.updateFlag = true;
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 4;
                this.updateScoreValues(2.8, 1.5, 2.8, 3.5, 3.4)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
        }

        if (this.selectedViewtype == 'MONTH') {
            if (event.value === 0) {
                this.totalNoOfCalls = 76;
                this.updateScoreValues(2.9, 2.4, 3.2, 3.5, 2.7)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: this.selectedCategory,

                }
                this.updateFlag = true;
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 75;
                this.updateScoreValues(2.7, 1.9, 2.8, 3.5, 2.6)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname,

                }
                this.updateFlag = true;
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 64;
                this.updateScoreValues(2.8, 1.8, 2.7, 3.5, 3.2)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname,

                }
                this.updateFlag = true;
            }
            else {
                this.totalNoOfCalls = 0;
                this.updateScoreValues(0, 0, 0 , 0, 0)
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }

        }
    }


    chartOptions: Options = {

        chart: {
            map: worldMap as any,
            inverted: false,
            animation: false,
        },
        colors: ['#ffcccc', '#ffb3b3', '#ff9999', '#ff0000', '#ffecb3', '#ffe599', '#ffd966', '#ffbf00', '#d1ffd1', '#b3ffb3', '#94ff94', '#66ff66', '#078000', '#83c080', '#6ab366', '#51a64d', '#399933', '#208d1a', '#078000'],
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [{
                from: 0.0,
                to: 0.6,

            }, {
                from: 0.6,
                to: 0.8,
            },
            {
                from: 1.0,
                to: 1.2,
            },
            {
                from: 1.2,
                to: 1.4,

            },
            {
                from: 1.5,
                to: 1.8,
            },
            {
                from: 1.8,
                to: 2.0,
            },
            {
                from: 2.0,
                to: 2.2,

            }, {
                from: 2.2,
                to: 2.4,
            },
            {
                from: 2.5,
                to: 2.8,
            },
            {
                from: 2.8,
                to: 3.0,
            },
            {
                from: 3.0,
                to: 3.2,

            }, {
                from: 3.2,
                to: 3.4,
            },
            {
                from: 3.5,
                to: 3.7,
            },
            {
                from: 3.7,
                to: 3.9,
            },
            {
                from: 3.9,
                to: 4.1,
            },
            {
                from: 4.1,
                to: 4.3,
            },
            {
                from: 4.3,
                to: 4.5,
            },
            {
                from: 4.5,
                to: 4.8,
            },
            {
                from: 4.8,
                to: 5.0,
            }
            ]
        },
        series: [{
            animation: false,
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        /*this.getDataByCountryKey(e.point['hc-key'])*/

                    }
                }
            },
            type: "map",
            name: "Selling Skills",
            states: {
                hover: {
                    enabled: false,
                }
            },
            tooltip: {
                valueDecimals: 1,
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },
            allAreas: false,
            data: this.mapData
        }]
    };
}


