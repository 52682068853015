import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-call-characteristics',
    templateUrl: './call-characteristics.component.html',
    styleUrls: ['./call-characteristics.component.css']
})
export class CallCharacteristicsComponent implements OnInit {
    chart: any;
    updateFlag = false;
    Highcharts: typeof Highcharts = Highcharts;
    totalNoOfCalls: number;
    selectedView: string;
    constructor(
        private dashboardService: DashboardService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
        this.chartOptions_2.yAxis.title.text = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
        this.chartOptions_2.series[0].name = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
        this.chartOptions_2.series[1].name = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
    }

   


    staticTextData: any;

 

    ngOnInit() {
        this.getLanguageStaticText();

       
        //this.getCategorisedAudioCallData();
    }

    getLanguageStaticText() {
        var objParam = {
           
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.totalNoOfCalls = 501
            this.staticTextData = result;
            
        }, error => console.error(error));
    }


    //chartOptions_1: Highcharts.Options = {
    //    chart: {
    //        type: 'spline',
    //        style: {
    //            fontFamily: 'inherit',
    //            fontWeight: 'normal',
    //        }
    //    },
    //    title: {
    //        text: '',
    //        style: {
    //            color: '#00b050',
    //            fontWeight: 'normal',
    //        }
    //    },
    //    xAxis: {
    //        min: 1,
    //        max: 12,
    //        labels: {
    //            style: {
    //                color: '#66707f'
    //            }
    //        }
    //    },
    //    yAxis: {
    //        lineWidth: 1,
    //        minorGridLineWidth: 1,
    //        minorTickLength: 1,
    //        tickLength: 1,
    //        gridLineWidth: 1,
    //        min: 0,
    //        max: 60,
    //        tickInterval: 20,
    //        title: {
    //            text: '% ' + 'HCP Share of Voice',
    //            style: {
    //                color: '#012072',
    //                fontSize: '14px'

    //            },
    //        },
    //        labels: {
    //            style: {
    //                color: '#999999'

    //            },
    //            format: '{value}%'
    //        },
    //        reversedStacks: false,
    //    },
    //    legend: {
    //        enabled: false
    //    },
    //    plotOptions: {
    //        series: {
    //            color: '#09d6cc',
    //            lineWidth: 1,
    //            borderColor: 'red',
    //            pointStart: 1,
    //        }
    //    },
    //    credits: {
    //        enabled: false
    //    },
    //    tooltip: {
    //        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} %'
    //    },
    //    series: [{
    //        type:'spline',
    //        name: '% ' + 'HCP Share of Voice',
    //        data: [32, 17, 28, 35, 29, 31]
    //    }]
   
       
    //}
    
    //chartOptions_2: Highcharts.Options = {
    //    chart: {
    //        type: 'spline',
    //        style: {
    //            fontFamily: 'inherit',
    //            fontWeight: 'normal',
    //        }
    //    },
    //    title: {
    //        text: '',
    //        style: {
    //            color: '#00b050',
    //            fontWeight: 'normal',
    //        }
    //    },
    //    xAxis: {
    //        min: 1,
    //        max: 12,
    //        labels: {
    //            style: {
    //                color: '#66707f'
    //            }
    //        }
    //    },
    //    yAxis: {
    //        lineWidth: 1,
    //        minorGridLineWidth: 1,
    //        minorTickLength: 1,
    //        tickLength: 1,
    //        gridLineWidth: 1,
    //        min: 0,
    //        max: 50,
    //        tickInterval: 10,
    //        title: {
    //            text: 'Length of call',
    //            style: {
    //                color: '#012072',
    //                fontSize: '14px'
    //            },
    //        },
    //        labels: {
    //            style: {
    //                color: '#999999'

    //            },
    //            format: '{value}Mins'
    //        },
    //        reversedStacks: false,
    //    },
    //    legend: {
    //        enabled: false
    //    },
    //    plotOptions: {
    //        series: {
    //            color: '#012072',
    //            lineWidth: 1,
    //            borderColor: null,
    //            pointStart: 1,
    //        }
    //    },
    //    credits: {
    //        enabled: false
    //    },
    //    tooltip: {
    //        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ' 
    //    },
    //    series: [{
    //        type: 'spline',
    //        name: 'Length of call',
    //        data: [20, 19, 21, 23, 20, 21]
    //    }]

    //}
  
    //UpdateWeeK() {
    //    this.chartOptions_1.series[0] = {
    //        type: 'spline',
    //        data: [34, 38, 35, 33, 28, 30, 29, 30, 22, 20, 22, 20]
    //    };
    //    this.chartOptions_2.series[0] = {
    //        type: 'spline',
    //        data: [20, 22, 25, 24, 20, 18, 19, 21, 15, 14, 16, 17]
    //    };
    //    this.updateFlag = true;
    //}

    //UpdateMonth() {
    //    this.chartOptions_1.series[0] = {
    //        type: 'spline',
    //        data: [32, 17, 28, 35, 29, 31]
    //    };
    //    this.chartOptions_2.series[0] = {
    //        type: 'spline',
    //        data: [20, 19, 21, 23, 20, 21]
    //    };
    
    //    this.updateFlag = true;
    //}

    //UpdateQTR() {
    //    this.chartOptions_1.series[0] = {
    //        type: 'spline',
    //        data: [26, 28]  
    //    };
    //    this.chartOptions_2.series[0] = {
    //        type: 'spline',
    //        data: [20, 19]
    //    };
      
    //    this.updateFlag = true;
    //}
    chartOptions_1 = {
        chart: {
            type: 'columnrange',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            style: {
                color: '#00b050',
                fontWeight: 'normal',
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            lineWidth: 1,
            minorGridLineWidth: 1,
            minorTickLength: 1,
            tickLength: 1,
            gridLineWidth: 1,
            min: 0,
            max: 100,
            tickInterval: 20,
            title: {
                text: '% ' + 'HCP Share of Voice',
                style: {
                    color: '#012072',
                    fontSize: '14px'

                },
            },
            labels: {
                style: {
                    color: '#999999'

                },
                format: '{value}%'
            },
            reversedStacks: false,
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                groupPadding: 0,
                borderWidth: 0,
                pointStart: 1,
            },
            columnrange: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        if (this.y > 0) {
                            if (this.y === this.point.high) {
                                return '<span style="color:#00b050">Max :' + Highcharts.numberFormat(this.y, 1) + '</span>';
                            }
                            if (this.y === this.point.low) {
                                return '<span style="color:#ff0000">Min :' + Highcharts.numberFormat(this.y, 1) + '</span>';
                            }
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return this.series.tooltipOptions.customTooltipPerSeries.call(this);
            }
        },
        series: [{
            name: '% ' + 'HCP Share of Voice',
            color: '#09d6cc',
            data: [
                [6, 65],
                [3, 62],
                [4, 72],
                [4, 62],
                [5, 65],
                [3, 50]
       
            ],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b><br/>' + '<br/>' + 'Max: ' + Highcharts.numberFormat(this.point.high, 1) + '<br/>' + 'Min: ' + Highcharts.numberFormat(this.point.low, 1) + '<br>'
                }
            },
        },
        {
            type: 'spline',
            name: '% ' + 'HCP Share of Voice',
            color: '#012072',
            data: [32, 17, 28, 35, 29, 31],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b>' + ' : ' + Highcharts.numberFormat(this.point.y, 1)
                },
            }
        }
        ]
    };
    chartOptions_2 = {
        chart: {
            type: 'columnrange',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            style: {
                color: '#00b050',
                fontWeight: 'normal',
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            lineWidth: 1,
            minorGridLineWidth: 1,
            minorTickLength: 1,
            tickLength: 1,
            gridLineWidth: 1,
            min: 0,
            max: 50,
            tickInterval: 10,
            title: {
                text: 'Length of call',
                style: {
                    color: '#012072',
                    fontSize: '14px'
                },
            },
            labels: {
                style: {
                    color: '#999999'

                },
                format: '{value}' + 'Mins'
            },
            reversedStacks: false,
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                groupPadding: 0,
                borderWidth: 0,
                pointStart: 1,
            },
            columnrange: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        if (this.y > 0) {
                            if (this.y === this.point.high) {
                                return '<span style="color:#00b050">Max :' + Highcharts.numberFormat(this.y, 1) + '</span>';
                            }
                            if (this.y === this.point.low) {
                                return '<span style="color:#ff0000">Min :' + Highcharts.numberFormat(this.y, 1) + '</span>';
                            }
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return this.series.tooltipOptions.customTooltipPerSeries.call(this);
            }
        },
        series: [{
            name: 'Length Of Call',
            color: '#09d6cc',
            data: [
                [3, 45],
                [1, 38],
                [2, 40],
                [1, 41],
                [3, 43],
                [2, 41]
            ],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b><br/>' + '<br/>' + 'Max: ' + Highcharts.numberFormat(this.point.high, 1) + '<br/>' + 'Min: ' + Highcharts.numberFormat(this.point.low, 1) + '<br>'
                }
            },
        },
        {
            type: 'spline',
            name: 'Length of call',
            color: '#012072',
            data: [20, 19, 21, 23, 20, 21],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b>' + ' : ' + Highcharts.numberFormat(this.point.y, 1)
                },
            }
        }
        ]
    };
    UpdateWeeK() {
        this.totalNoOfCalls = 223
        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data = [
            [11, 30],
            [5, 47],
            [4, 40],
            [10, 62],
            [6, 60],
            [9, 65],
            [11, 30],
            [5, 47],
            [3, 50],
            [5, 43],
            [6, 40],
            [4, 35]
        ];
        this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [34, 38, 35, 33, 28, 30, 29, 30, 22, 20, 22, 20];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [1, 41],
            [3, 30],
            [4, 18],
            [5, 32],
            [3, 43],
            [6, 26],
            [7, 29],
            [3, 32],
            [9, 41],
            [4, 22],
            [2, 31],
            [5, 37]
        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [20, 22, 25, 24, 20, 18, 19, 21, 15, 14, 16, 17],

        this.updateFlag = true;
    }

    UpdateMonth() {
        this.totalNoOfCalls = 501
        //this.chartOptions_1.series[0] = {
        //    type: 'columnrange',
        //    data: [27, 30, 35]
        //};
        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data = [
            [6, 65],
            [3, 62],
            [4, 72],
            [4, 62],
            [5, 65],
            [3, 50]
        ];
        this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [32, 17, 28, 35, 29, 31];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [3, 45],
            [1, 38],
            [2, 40],
            [1, 41],
            [3, 43],
            [2, 41]
        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [20, 19, 21, 23, 20, 21]


        this.updateFlag = true;
    }

    UpdateQTR() {
        this.totalNoOfCalls = 501
        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data = [
            [1, 45],
            [1, 43]
        ];
        this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [20, 19];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [3, 72],
            [3, 65]

        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [26, 28]
        //this.chartOptions_1.series[0] = {
        //    type: 'columnrange',
        //    data: [31]
        //};
        //this.chartOptions_2.series[0] = {
        //    type: 'columnrange',
        //     data: [17]
        //};

        this.updateFlag = true;
    }

}

