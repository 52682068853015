import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

interface product {
    value: string;
    viewValue: string;
}


@Component({
    selector: 'app-changeover-time',
    templateUrl: './changeover-time.component.html',
    styleUrls: ['./changeover-time.component.css']
})
export class ChangeoverTimeComponent implements OnInit {
    userId: any = window.localStorage.getItem("UserId");

    //Declaration of Selling Skill Data avg by elements
    @ViewChild('SSkills_Opening', { static: true }) SSkills_Opening: ElementRef;
    @ViewChild('SSkills_Questioning', { static: true }) SSkills_Questioning: ElementRef;
    @ViewChild('SSkills_Presenting', { static: true }) SSkills_Presenting: ElementRef;
    @ViewChild('SSkills_Closing', { static: true }) SSkills_Closing: ElementRef;
    @ViewChild('SSkills_Outcome', { static: true }) SSkills_Outcome: ElementRef;

    //Declaration of Selling Skill Data avg. by months.
    @ViewChild('ProgSSkills', { static: true }) ProgSSkills: ElementRef;

    //Declaration of Audio Call Data avg. by months.
    @ViewChild('Lcall', { static: true }) Lcall: ElementRef;
    @ViewChild('DocTime', { static: true }) DocTime: ElementRef;
    @ViewChild('RepTime', { static: true }) RepTime: ElementRef;

    selectedLanguage: string = "En";
    selectedDataViewType: number = 1;
    audioCallAvg: any = {};
    cotGrowth: any = [];
    Math: any;

    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];


    constructor(
        private router: Router,
        private slsmService: SlsmService
    ) { }

    ngOnInit() {
        Highcharts.chart('ProgCImplem', this.optinoins);
        Highcharts.chart('Campaign-Positioning', this.CmplinePositioning);
        Highcharts.chart('Campaign-Efficacyone', this.CmplineEfficacyone);
        Highcharts.chart('Campaign-Efficacytwo', this.CmplineEfficacytwo);
        Highcharts.chart('Campaign-Safety', this.CmplineSafety);

        $('.cstm-Item').click(function () {
            $('.cstm-Item.Selected').removeClass('Selected');
            $(this).addClass('Selected');
        });

        if (window.localStorage.getItem("ViewType") != null) {
            $('.cstm-Item.Selected').removeClass('Selected');
            $('#id' + window.localStorage.getItem("ViewType")).addClass('Selected');
        }

        this.Math = Math;
        this.getCOTData();
    }

    dataViewType(type) {
        window.localStorage.setItem("ViewType", type);
        this.selectedDataViewType = type;
        this.getCOTData();
    }

    getCOTData() {
        this.audioCallAvg = {};
        var params = {
            ClientId: window.localStorage.getItem("ClientId") != null ? parseInt(window.localStorage.getItem("ClientId")) : 0,
            UserId: parseInt(this.userId),
            ViewType: window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType,
            LanguageCode: this.selectedLanguage
        };

        this.slsmService.GetCOTData(params).subscribe((result: any) => {

            //Selling Skill Elements Avg. By Month
            if (result.sellingSkillCOTByElement.length > 0) {
                result.sellingSkillCOTByElement.forEach(obj => {
                    var eleData = this.getMonthWiseData(obj);
                    if (obj.elementName == "Opening") {
                        this.drawSellingSkillOpeningGraph(eleData);
                    }
                    else if (obj.elementName == "Questioning") {
                        this.drawSellingSkillQuestioningGraph(eleData);
                    }
                    else if (obj.elementName == "Presenting") {
                        this.drawSellingSkillPresentingGraph(eleData);
                    }
                    else if (obj.elementName == "Closing") {
                        this.drawSellingSkillClosingGraph(eleData);
                    }
                    else if (obj.elementName == "Outcome") {
                        this.drawSellingSkillOutcomeGraph(eleData);
                    }
                });
            }

            //Selling Skill Overall Avg. By Month
            if (result.sellingSkillCOTByMonth != null) {
                var eleData = this.getMonthWiseData(result.sellingSkillCOTByMonth);
                this.drawSellingSkillOverallAvgGraph(eleData);
            }

            //Audio Timings Avg. By Month
            if (result.audioTimingCOTByMonth.length > 0) {
                result.audioTimingCOTByMonth.forEach(obj => {
                    var audioData = this.getMonthWiseData(obj);
                    if (obj.elementName == "TotalAudioAvg") {
                        this.drawTotalAudioAvgGraph(audioData);
                    }
                    else if (obj.elementName == "DoctorAudioAvg") {
                        this.drawDoctorAudioAvgGraph(audioData);
                    }
                    else if (obj.elementName == "RepAudioAvg") {
                        this.drawRepAudioAvgGraph(audioData);
                    }
                });
            }

            //Audio Time Avg.
            if (result.audioCallAverage != null) {
                this.audioCallAvg = result.audioCallAverage;
            }

        }, error => console.error(error));

        //this is to get the growth ovetime.
        this.slsmService.GetCOTGrowth(params).subscribe((result: any) => {
            this.cotGrowth = result;
        }, error => console.error(error));
    }

    drawSellingSkillOpeningGraph(data) {

        Highcharts.chart(this.SSkills_Opening.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',

                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),

                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '9px',
                        }
                    },
                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    tickInterval: 0.5,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value:.1f}',
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    lineWidth: 1,
                },
                legend: {
                    enabled: true,
                    symbolRadius: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 20,
                    itemStyle: {
                        fontSize: '11px'
                    }

                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointWidth: 100,
                    },
                },
                series: [{
                    name: 'Opening',
                    color: '#012072',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawSellingSkillQuestioningGraph(data) {
        Highcharts.chart(this.SSkills_Questioning.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    backgroundColor: '#e9fffe',
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }

                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    tickInterval: 0.5,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    reversedStacks: false
                },
                legend: {
                    enabled: true,
                    symbolRadius: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 20,
                    itemStyle: {
                        fontSize: '11px'
                    }

                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Questioning',
                    color: '#09d6cc',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawSellingSkillPresentingGraph(data) {
        Highcharts.chart(this.SSkills_Presenting.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }

                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    tickInterval: 0.5,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    reversedStacks: false
                },
                legend: {
                    enabled: true,
                    symbolRadius: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 20,
                    itemStyle: {
                        fontSize: '11px'
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Presenting ',
                    color: '#4e7efc',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawSellingSkillClosingGraph(data) {
        Highcharts.chart(this.SSkills_Closing.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    backgroundColor: '#e9fffe',
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }

                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    tickInterval: 0.5,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    reversedStacks: false
                },
                legend: {
                    enabled: true,
                    symbolRadius: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 20,
                    itemStyle: {
                        fontSize: '11px'
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Closing ',
                    color: '#fce315',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawSellingSkillOutcomeGraph(data) {
        Highcharts.chart(this.SSkills_Outcome.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }

                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    tickInterval: 0.5,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    reversedStacks: false
                },
                legend: {
                    enabled: true,
                    symbolRadius: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 20,
                    itemStyle: {
                        fontSize: '11px'
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Outcome',
                    color: '#32697f',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawSellingSkillOverallAvgGraph(data) {
        Highcharts.chart(this.ProgSSkills.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginBottom: 27,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                },
                title: {
                    text: 'Selling Skills',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '13px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }

                },
                tooltip: {
                    valueDecimals: 1
                },
                yAxis: {
                    min: 0,
                    max: 4,
                    tickInterval: 1,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                    reversedStacks: false
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Selling Skills',
                    color: '#0abfb2',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawTotalAudioAvgGraph(data) {
        Highcharts.chart(this.Lcall.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginBottom: 27,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '13px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        formatter: function () {
                            var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                            return string;
                        },
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    }
                },
                tooltip: {
                    valueDecimals: 1,
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    style: {
                        padding: 0
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 20,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        },
                        format: '{value}%'
                    },
                    tickWidth: 0,
                    crosshair: false,
                    lineWidth: 0,
                    gridLineWidth: 0,//Set this to zero
                    reversedStacks: false
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    }
                },
                series: [{
                    name: 'Length of call',
                    color: '#0abfb2',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawDoctorAudioAvgGraph(data) {
        Highcharts.chart(this.DocTime.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginBottom: 0,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '13px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    gridLineWidth: 0,//Set this to zero
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },
                },
                tooltip: {
                    valueDecimals: 1,
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    style: {
                        padding: 0
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 20,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        },
                        format: '{value}%'
                    },
                    tickWidth: 0,
                    crosshair: false,
                    lineWidth: 0,
                    gridLineWidth: 0,//Set this to zero
                    //labels: {
                    //    format: '{value}%'
                    //},
                    reversedStacks: false
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    }
                },
                series: [{
                    name: 'Doctor',
                    color: '#0abfb2',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }

    drawRepAudioAvgGraph(data) {
        Highcharts.chart(this.RepTime.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'line',
                    marginBottom: 0,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '13px',

                    }
                },
                xAxis: {
                    categories: data.map(function (a) { return a.Month }),
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        }
                    },


                },
                tooltip: {
                    valueDecimals: 1,
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    style: {
                        padding: 0
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 20,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f',
                            fontSize: '10px',
                        },
                        format: '{value}%'
                    },
                    tickWidth: 0,
                    crosshair: false,
                    lineWidth: 0,
                    gridLineWidth: 0,//Set this to zero
                    reversedStacks: false
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    }
                },
                series: [{
                    type: 'line',
                    name: 'Rep',
                    color: '#0abfb2',
                    data: data.map(function (a) { return a.Data })
                }],
            })
        );
    }


    public optinoins: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },
        title: {
            text: 'Campaign lmplementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '13px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },

        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                },
                format: '{value}%'
            },
            //labels: {
            //    format: '{value}%'
            //},
            reversedStacks: false
        },
        legend: {
            enabled: false
        }
        ,
        credits: {
            enabled: false
        },
        series: [{
            name: 'Campaign lmplementation',
            color: '#0abfb2',
            data: [20, 40, 60, 60, 50, 40, 40, 70, 90, 80]
        }],

    };

    //Campign graph set
    public CmplinePositioning: any = {
        chart: {
            type: 'line',

            marginRight: 0,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '9px',
                }
            },
        },

        yAxis: {
            min: 0,
            max: 5,
            tickInterval: 0.5,
            title: {
                text: null
            },
            labels: {
                format: '{value:.1f}',
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            //labels: {
            //    format: '{value}%'
            //},
            lineWidth: 1,

        },
        legend: {
            enabled: true,
            symbolRadius: 0,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            itemMarginRight: 0,
            itemMarginLeft: 0,
            floating: false,
            y: 20,
            itemStyle: {
                fontSize: '11px'
            }

        }
        ,
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointWidth: 100,
            },
        },
        series: [{
            name: 'Positioning',
            color: '#4c3208',
            data: [1, 2, 3, 2, 1, 2, 3, 1.5, 3, 1, 1.5, 2.5]
        }],

    }
    public CmplineEfficacyone: any = {
        chart: {
            type: 'line',
            backgroundColor: '#e9fffe',
            marginLeft: 0,
            marginRight: 0,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },

        yAxis: {
            min: 0,
            max: 5,
            tickInterval: 0.5,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            //labels: {
            //    format: '{value}%'
            //},
            reversedStacks: false
        },
        legend: {
            enabled: true,
            symbolRadius: 0,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            itemMarginRight: 0,
            itemMarginLeft: 0,
            floating: false,
            y: 20,
            itemStyle: {
                fontSize: '11px'
            }

        }
        ,
        credits: {
            enabled: false
        },
        series: [{
            name: 'Efficacy 1⁰',
            color: '#754908',
            data: [1, 2, 3, 2, 1, 2, 3, 1.5, 3, 1, 1.5, 2.5]
        }],

    }
    public CmplineEfficacytwo: any = {
        chart: {
            type: 'line',
            marginLeft: 0,
            marginRight: 0,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },

        yAxis: {
            min: 0,
            max: 5,
            tickInterval: 0.5,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            //labels: {
            //    format: '{value}%'
            //},
            reversedStacks: false
        },
        legend: {
            enabled: true,
            symbolRadius: 0,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            itemMarginRight: 0,
            itemMarginLeft: 0,
            floating: false,
            y: 20,
            itemStyle: {
                fontSize: '11px'
            }
        }
        ,
        credits: {
            enabled: false
        },
        series: [{
            name: 'Efficacy 2⁰ ',
            color: '#a5640e',
            data: [1, 2, 3, 2, 1, 2, 3, 1.5, 3, 1, 1.5, 2.5]
        }],

    }
    public CmplineSafety: any = {
        chart: {
            type: 'line',
            backgroundColor: '#e9fffe',
            marginLeft: 0,
            marginRight: 0,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },

        yAxis: {
            min: 0,
            max: 5,
            tickInterval: 0.5,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            //labels: {
            //    format: '{value}%'
            //},
            reversedStacks: false
        },
        legend: {
            enabled: true,
            symbolRadius: 0,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            itemMarginRight: 0,
            itemMarginLeft: 0,
            floating: false,
            y: 20,
            itemStyle: {
                fontSize: '11px'
            }
        }
        ,
        credits: {
            enabled: false
        },
        series: [{
            name: 'Safety ',
            color: '#d87b0d',
            data: [1, 2, 3, 2, 1, 2, 3, 1.5, 3, 1, 1.5, 2.5]
        }],

    }
    public optinoi: any = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            marginBottom: 18,
            type: 'pie',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },
        title: {
            text: 'Good Call Distribution',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            }

        },
        plotOptions: {
            pie: {
                cursor: 'pointer',

                dataLabels: {
                    enabled: true,
                    inside: true,
                    format: '{point.y} %',
                    distance: -50,
                    style: {
                        fontSize: '20px',
                        color: 'white',
                        textShadow: false,
                        textOutline: false
                    }
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'Series 1',
                y: 29,
                color: '#0abfb2',
                sliced: true,
                selected: true
            }, {
                name: 'Series 2',
                y: 71,
                color: '#85a7ff',
            }]
        }]
    }


    getMonthWiseData(data) {

        var chartData = new Array();
        var selectedView = window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType;
        var isMat = selectedView == 1 ? true : false;
        var months = this.getMonthList(isMat);

        try {
            for (var i = 0; i < months.length; i++) {
                var month = months[i];
                var monthDataKey = 'month' + (i + 1);
                //var entry = [month, data[monthDataKey]];
                chartData.push({ Month: month, Data: data[monthDataKey] });
            }

            //if Qtr is selected
            if (selectedView == 3) {
                var qtr = [];
                for (var j = chartData.length - 2; j > chartData.length - 5; j--) {
                    qtr.push(chartData[j]);
                }
                chartData = [];
                chartData = qtr.reverse();
            }
            //if Month is selected
            if (selectedView == 4) {
                var lastMonth = [];
                lastMonth.push(chartData[chartData.length - 2]);
                chartData = [];
                chartData = lastMonth;
            }
        } catch (e) {

        }
        return chartData;
    }

    getMonthList(isMat) {
        var months = new Array();

        months[0] = 'Jan';
        months[1] = 'Feb';
        months[2] = 'Mar';
        months[3] = 'Apr';
        months[4] = 'May';
        months[5] = 'Jun';
        months[6] = 'Jul';
        months[7] = 'Aug';
        months[8] = 'Sep';
        months[9] = 'Oct';
        months[10] = 'Nov';
        months[11] = 'Dec';

        var date = new Date();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        var currentMonthList = new Array();

        if (monthIndex >= 0) {
            for (var i = monthIndex; i >= 0; i--) {
                var month = months[i] + "-" + year;
                currentMonthList.push(month);
            }
            if (isMat == true) {
                year--;
                for (var i = 11; i > monthIndex; i--) {
                    var month = months[i] + "-" + year;
                    currentMonthList.push(month);
                }
            }
        }
        return currentMonthList.reverse();
    }
}
