import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
@Injectable({
    providedIn: 'root'
})

export class SlsmService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    GetSellingSkillLables(langCode) {
        return this.http.get('api/SLSM/GetSellingSkillLables/' + langCode, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetCountryWiseClientData(param) {
        return this.http.post('api/SLSM/GetCountryWiseClientData', param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetDashboardDataByClient(params) {
        return this.http.post('api/SLSM/GetDashboardDataByClient', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetSellingSkillDataByMonth(params) {
        return this.http.get('api/SLSM/GetSellingSkillDataByMonth' + params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetUserLastCallDetails(params) {
        return this.http.post('api/SLSM/GetUserLastCallDetails', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetUserWiseUserData(param) {
        return this.http.post('api/SLSM/GetUserWiseUserData', param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetLablesDescription(langCode) {
        return this.http.get('api/SLSM/GetLablesDescription/' + langCode, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetCOTData(params) {
        return this.http.post('api/SLSM/GetCOTData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetCOTGrowth(params) {
        return this.http.post('api/SLSM/GetCOTGrowth', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    GetSellingSkillAvgByMonth(params) {
        return this.http.post('api/SLSM/GetSellingSkillAvgByMonth', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
