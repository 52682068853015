import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { debug } from 'console';

export interface SellingSkills {
    country: string;
    opening: number;
    questioning: any;
    presenting: number;
    closing: number;
    outcome: number;
    drVoice: number;
    callLength: number;
    targetPatient: number;
    message1: number;
    message2: number;
    message3: number;
    others: number;       
    callAction: string;
}

const SS_DATA: SellingSkills[] = [
    
    { country: 'UK', opening: 3.1, questioning: 3.0, presenting: 3.2, closing: 2.5, outcome: 28.0, callLength: 19.0, drVoice: 28.0, targetPatient: 53.0, message1: 22.0, message2: 74.0, message3: 48.0, others: 38.0, callAction: 'Prescribe'},
      {country: 'France', opening: 1.4, questioning: 1.4, presenting: 1.5, closing: 1.6, outcome: 27.6, callLength: 19.7, drVoice: 29.2, targetPatient: 27.1, message1: 22.1, message2: 73.5, message3: 45.5, others: 38.4, callAction: 'Prescribe' },
      {country: 'Germany', opening: 3.0, questioning: 2.9, presenting: 3.1, closing: 3.1, outcome: 35.4, callLength: 21.1, drVoice: 30.2, targetPatient: 65, message1: 56.4, message2: 80.1, message3: 55.6, others: 48.7, callAction: 'Patient Id' },
      {country: 'Japan', opening: 2.7, questioning: 2.6, presenting: 2.6, closing: 2.7, outcome: 30.5, callLength: 21.4, drVoice: 30.7, targetPatient: 65, message1: 56.4, message2: 79.2, message3: 61.0, others: 50.2, callAction: 'Prescribe' },
      {country: 'USA', opening: 3.2, questioning: 3.3, presenting: 3.1, closing: 3.1, outcome: 38.5, callLength: 19.3, drVoice: 34.1, targetPatient: 59.3, message1: 53.8, message2: 78.6, message3: 56.3, others: 49, callAction: 'Guidelines' },
    //{ country: 'Brazil', opening: 2.7, questioning: 2.7, presenting: 2.5, closing: 2.6, outcome: 31.1, callLength: 23.1, drVoice: 30, targetPatient: 65, message1: 56.9, message2: 77.1, message3: 61.8, others: 51.2, callAction: 'Prescribe'},
    //{ country: 'Australia', opening: 1.7, questioning: 1.9, presenting: 1.9, closing: 2.1, outcome: 31.1, callLength: 23.1, drVoice: 30, targetPatient: 57.8, message1: 56.9, message2: 77.1, message3: 61.5, others: 52.2, callAction: 'Patient Id'},

];
@Component({
  selector: 'app-glance',
  templateUrl: './glance.component.html',
  styleUrls: ['./glance.component.css']
})
export class GlanceComponent implements OnInit {
    @ViewChild('table', { static: false }) table: MatTable<SellingSkills>;

    displayedColumns: string[] = ['country', 'opening', 'questioning', 'presenting', 'closing', 'outcome', 'callLength', 'drVoice', 'targetPatient', 'message1', 'message2', 'message3', 'others','callAction'];

    dataSource = new MatTableDataSource(SS_DATA);

    @ViewChild('table', { read: MatSort, static: true }) sort1: MatSort;
    selectedView: string;

    constructor(private router: Router) {
        this.selectedView = localStorage.getItem('selectedView');
    }
    pageNavigateTo(value, data) {
        window.localStorage.setItem("FilterId", data.country);

        this.router.navigate(["/staticdashboarduk/CAC"]);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort1;

        const sortState: Sort = { active: 'country', direction: 'asc' };
        this.sort1.active = sortState.active;
        this.sort1.direction = sortState.direction;
        this.sort1.sortChange.emit(sortState);
    }
}
