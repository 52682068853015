import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    getCampaignList(productId) {
        return this.http.get('api/Admin/GetCampaignListByProduct/' + productId, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpateCampaign(params) {
        return this.http.post('api/Admin/AddUpdateCampaign', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
