import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SLSMRoutingModule } from './slsm-routing.module';
import { HomeComponent } from './home/home.component';
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfilepageComponent } from './profilepage/profilepage.component';
import { WorldmapComponent } from './worldmap/worldmap.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LeftnavComponent } from './leftnav/leftnav.component';
import { ChangeoverTimeComponent } from './changeover-time/changeover-time.component';

@NgModule({
    declarations: [HomeComponent, ProfilepageComponent, WorldmapComponent, LeftnavComponent, ChangeoverTimeComponent],
    imports: [
        CommonModule,
        SLSMRoutingModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        DragDropModule,
        MatTabsModule,
        HighchartsChartModule
    ],
    exports: [
        HomeComponent,

    ]
})
export class SLSMModule { }
