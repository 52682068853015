import { Component, Input, OnInit } from '@angular/core';



@Component({
    selector: 'app-republicm',
    templateUrl: './republicm.component.html',
    styleUrls: ['./republicm.component.css']
})
export class RepublicmComponent implements OnInit {

    constructor() { }

    @Input() ClientRegionData: any;
    @Input() CurrentPage: any;

    currentPageName: string = "";
    clientName: string = "";

    londonRegion: any = {};

    ngOnInit() {
        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
    }

    ngOnChanges() {
        this.londonRegion = {};
        if (this.ClientRegionData != undefined && this.ClientRegionData != null && this.ClientRegionData.length > 0 /*&& (this.clientName == 'republic-m.com' || this.clientName == 'bayernubeqa.com')*/) {
            var londonRegion = this.ClientRegionData.find(x => x.regionName == "National");
            if (londonRegion != undefined) {
                this.londonRegion = londonRegion;
            }
        }

        if (this.CurrentPage != undefined) {
            this.currentPageName = this.CurrentPage;
        }
    }
}
