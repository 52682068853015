import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custo)*/
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CampaignElements } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';

@Component({
    selector: 'app-campaign-implementation',
    templateUrl: './campaign-implementation.component.html',
    styleUrls: ['./campaign-implementation.component.css']
})
export class CampaignImplementationComponent implements OnInit {
    sliderValue: any;
    selectedView: string;
    constructor(
        private route: Router,
        private dashboardService: DashboardService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
    }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    clientName: string = "";
    selectedCategory: any = 'Patient 1'
    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }
        if (value >= 1) {
            return ' - ' + Math.round(value / 1) + '';
        }
        return value;
    }

    ngOnInit() {
        this.getLanguageStaticText();
        this.selectedCategory = 'Patient 1'
        this.selectedViewtype = 'MONTH';
    }

    totalNoOfCalls: any;
    clientCountryDataList: any = [];
    clientCountryDataListByElement: any = [];
    mapData: any = [];
    selectedElementList: any = [];

    updateFlag = false;
    currentPage: string = "CI";
    staticTextData: any;
    isMonthlySelected: boolean = true;
    selectedViewtype = 'MONTH';
    value: number = 0;


    @ViewChild('table', { static: false }) table: MatTable<CampaignElements>;

    displayedColumns: string[] = ['ElementName', 'Score', 'Checked'];

    dataSource = [{
        "elementNameToShow": "Patient 1",
        "aliasElementName": "Patient 1",
        "score": 27.0
    },
    {
        "elementNameToShow": " Patient 2",
        "aliasElementName": "Patient 2",
        "score": 16.3
    },
    {
        "elementNameToShow": " Message 1",
        "aliasElementName": "Message 1",
        "score": 43.8
    }, {
        "elementNameToShow": " Message 2",
        "aliasElementName": "Message 2",
        "score": 32.8
    },
    {
        "elementNameToShow": " Message 3",
        "aliasElementName": "Message 3",
        "score": 74.0
    }];


    getLanguageStaticText() {
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;

            this.getCampaignDataByClient(0);
        }, error => console.error(error));
    }


    changeViewType(view) {
        this.selectedViewtype = view;
        this.value = 0;
        this.sliderValue = 0;
        this.selectedElementList = [];
        this.selectedCategory = 'Patient 1'
        if (this.selectedViewtype == 'WEEK') {
            this.mapData = [['gb', 28.0], ['fr', 19.4], ['de', 73.6], ['jp', 68.2], ['us', 52.0], ];
            this.totalNoOfCalls = 20;
            this.dataSource = [{

                "elementNameToShow": "Patient 1",
                "aliasElementName": "Patient 1",
                "score": 28
            },
            {
                "elementNameToShow": " Patient 2",
                "aliasElementName": "Patient 2",
                "score": 17
            },
            {
                "elementNameToShow": " Message 1",
                "aliasElementName": "Message 1",
                "score": 37
            }, {
                "elementNameToShow": " Message 2",
                "aliasElementName": "Message 2",
                "score": 24
            },
            {
                "elementNameToShow": " Message 3",
                "aliasElementName": "Message 3",
                "score": 82
            }];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', 28.0], ['fr', 19.4], ['de', 73.6], ['jp', 68.2], ['us', 52.0], ],
                name: Selectedname,

            }
            this.updateFlag = true;
        }
        if (this.selectedViewtype == 'MONTH') {
            this.totalNoOfCalls = 84;
            this.mapData = [['gb', 27.0], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], ];
            this.dataSource = [{
                "elementNameToShow": "Patient 1",
                "aliasElementName": "Patient 1",
                "score": 27.0
            },
            {
                "elementNameToShow": " Patient 2",
                "aliasElementName": "Patient 2",
                "score": 16.3
            },
            {
                "elementNameToShow": " Message 1",
                "aliasElementName": "Message 1",
                "score": 43.8
            }, {
                "elementNameToShow": " Message 2",
                "aliasElementName": "Message 2",
                "score": 32.8
            },
            {
                "elementNameToShow": " Message 3",
                "aliasElementName": "Message 3",
                "score": 74.0
            }];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', score], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], 
                ],
                name: Selectedname,

            }
            this.updateFlag = true;
            
        }
    }
    onSelectElement(element, event) {
        this.selectedElementList = [];
        this.selectedElementList.push(element.aliasElementName);
        this.selectedCategory = element.aliasElementName;
        if (this.sliderValue === 0 && this.selectedViewtype == 'WEEK') {
      
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 28.0], ['fr', 19.4], ['de', 73.6], ['jp', 68.2], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 17.0], ['fr', 17.0], ['de', 68.0], ['jp', 65.3], ['us', 60.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 37.0], ['fr', 43.0], ['de', 81.0], ['jp', 81.0], ['us', 76.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 24.0], ['fr', 30.0], ['de', 64.0], ['jp', 68.0], ['us', 68.6], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 82.0], ['fr', 79.0], ['de', 79.0], ['jp', 74.0], ['us', 69.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 1 && this.selectedViewtype == 'WEEK') {
     
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 25.0], ['fr', 21.2], ['de', 72.2], ['jp', 71.2], ['us', 72.2], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 18.0], ['fr', 18.0], ['de', 69.0], ['jp', 64.1], ['us', 62.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 43.0], ['fr', 51.0], ['de', 75.0], ['jp', 75.0], ['us', 74.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 28.0], ['fr', 30.0], ['de', 67.0], ['jp', 67.0], ['us', 70.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 77.0], ['fr', 67.0], ['de', 67.0], ['jp', 61.0], ['us', 70.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 2 && this.selectedViewtype == 'WEEK') {
    
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 25.0], ['fr', 20.6], ['de', 67.0], ['jp', 65.4], ['us', 45.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 13.0], ['fr', 13.0], ['de', 62.0], ['jp', 60.0], ['us', 67.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 38.0], ['fr', 49.0], ['de', 78.0], ['jp', 78.0], ['us', 78.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 48.0], ['fr', 28.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 72.0], ['fr', 80.0], ['de', 80.0], ['jp', 74.0], ['us', 76.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 3 && this.selectedViewtype == 'WEEK') {
            
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 30.0], ['fr', 19.0], ['de', 68.0], ['jp', 68.0], ['us', 52.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 17.0], ['fr', 17.0], ['de', 59.0], ['jp', 67.0], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 50.0], ['fr', 56.0], ['de', 64.0], ['jp', 64.0], ['us', 64.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 41.0], ['fr', 35.0], ['de', 52.0], ['jp', 65.0], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 56.0], ['de', 56.0], ['jp', 60.0], ['us', 60.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 4 && this.selectedViewtype == 'WEEK') {
           
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 22.1], ['de', 73.0], ['jp', 71.0], ['us', 73.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 22.0], ['fr', 22.0], ['de', 71.0], ['jp', 67.0], ['us', 56.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 91.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 45.0], ['de', 45.0], ['jp', 56.0], ['us', 45.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 25.0], ['fr', 30.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 5 && this.selectedViewtype == 'WEEK') {
        
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 61.0], ['fr', 24.0], ['de', 75.0], ['jp', 67.0], ['us', 75.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 9.0], ['fr', 9.0], ['de', 67.0], ['jp', 56.0], ['us', 56.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 91.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 57.0], ['fr', 57.0], ['de', 57.0], ['jp', 57.0], ['us', 57.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 22.0], ['fr', 30.0], ['de', 45.0], ['jp', 45.0], ['us', 45.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 6 && this.selectedViewtype == 'WEEK') {
       
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 57.0], ['fr', 22.0], ['de', 58.0], ['jp', 54.0], ['us', 58.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 13.0], ['fr', 13.0], ['de', 64.0], ['jp', 64.0], ['us', 64.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 82.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 65.0], ['de', 65.0], ['jp', 65.0], ['us', 65.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 4.0], ['fr', 10.0], ['de', 10.0], ['jp', 30.0], ['us', 10.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 7 && this.selectedViewtype == 'WEEK') {
          
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 16.0], ['de', 54.0], ['jp', 65.0], ['us', 50.2], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 17.0], ['fr', 17.0], ['de', 45.0], ['jp', 45.0], ['us', 45.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 80.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 61.0], ['fr', 61.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 9.0], ['fr', 9.0], ['de', 42.0], ['jp', 42.0], ['us', 42.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 8 && this.selectedViewtype == 'WEEK') {
     
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 61.0], ['fr', 24.0], ['de', 67.0], ['jp', 71.0], ['us', 62.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 22.0], ['fr', 22.0], ['de', 55.0], ['jp', 55.0], ['us', 55.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 78.0], ['de', 78.0], ['jp', 78.0], ['us', 78.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 65.0], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 9.0], ['fr', 9.0], ['de', 34.0], ['jp', 45.0], ['us', 34.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 9 && this.selectedViewtype == 'WEEK') {
        
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 65.0], ['fr', 65.0], ['de', 64.0], ['jp', 60.0], ['us', 61.0],                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 39.0], ['fr', 39.0], ['de', 39.0], ['jp', 39.0], ['us', 45.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 87.0], ['fr', 87.0], ['de', 67.0], ['jp', 67.4], ['us', 78.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 48.0], ['fr', 48.0], ['de', 48.0], ['jp', 48.0], ['us', 48.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 52.0], ['us', 55.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 10 && this.selectedViewtype == 'WEEK') {
      
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 70.0], ['fr', 29.0], ['de', 65.0], ['jp', 65.0], ['us', 68.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 43.0], ['fr', 43.0], ['de', 43.0], ['jp', 49.0], ['us', 48.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 87.0], ['fr', 87.0], ['de', 87.0], ['jp', 87.0], ['us', 82.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 52.0], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 26.0], ['fr', 26.0], ['de', 45.0], ['jp', 45.0], ['us', 50.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 11 && this.selectedViewtype == 'WEEK') {
       
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 83.0], ['fr', 43.0], ['de', 43.0], ['jp', 54.0], ['us', 43.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 35.0], ['fr', 35.0], ['de', 35.0], ['jp', 45.0], ['us', 35.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 87.0], ['fr', 67.0], ['de', 67.0], ['jp', 56.0], ['us', 67.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 43.0], ['fr', 43.0], ['de', 43.0], ['jp', 67.0], ['us', 43.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 13.0], ['fr', 13.0], ['de', 13.0], ['jp', 13.0], ['us', 13.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }

        //Month
        if (this.sliderValue === 0 && this.selectedViewtype == 'MONTH') {
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 27.0], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 16.3], ['fr', 16.3], ['de', 64.5], ['jp', 64.1], ['us', 60.3]
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 43.8], ['fr', 49.8], ['de', 74.5], ['jp', 74.5], ['us', 73.2], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 32.8], ['fr', 30.8], ['de', 61.0], ['jp', 65.3], ['us', 63.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 74.0], ['fr', 70.5], ['de', 70.5], ['jp', 67.3], ['us', 69.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 1 && this.selectedViewtype == 'MONTH') {
   
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 62.0], ['fr', 21.0], ['de', 65.0], ['jp', 64.3], ['us', 64.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 15.3], ['fr', 15.3], ['de', 61.8], ['jp', 58.0], ['us', 55.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 86.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 62.0], ['fr', 57.0], ['de', 57.0], ['jp', 59.8], ['us', 57.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 15.0], ['fr', 19.8], ['de', 39.5], ['jp', 44.5], ['us', 39.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 2 && this.selectedViewtype == 'MONTH') {
    
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 69.8], ['fr', 40.3], ['de', 59.8], ['jp', 62.5], ['us', 58.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 34.8], ['fr', 34.8], ['de', 43.0], ['jp', 47.0], ['us', 46.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 88.0], ['fr', 79.8], ['de', 74.8], ['jp', 72.0], ['us', 76.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 48.8], ['fr', 48.8], ['de', 48.8], ['jp', 58.0], ['us', 48.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 25.0], ['fr', 25.0], ['de', 36.0], ['jp', 38.8], ['us', 38.2], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 3 && this.selectedViewtype == 'MONTH') {
     
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 72.0], ['fr', 72.0], ['de', 72.0], ['jp', 72.0], ['us', 72.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 53.5], ['fr', 53.5], ['de', 53.5], ['jp', 53.5], ['us', 53.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 76.0], ['fr', 76.0], ['de', 76.0], ['jp', 76.0], ['us', 76.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 45.8], ['fr', 45.8], ['de', 45.8], ['jp', 45.8], ['us', 45.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 31.3], ['fr', 31.3], ['de', 31.3], ['jp', 31.3], ['us', 31.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 4 && this.selectedViewtype == 'MONTH') {

            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 22.5], ['fr', 22.5], ['de', 22.5], ['jp', 22.5], ['us', 22.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 81.8], ['fr', 81.8], ['de', 81.8], ['jp', 81.8], ['us', 81.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 50.0], ['fr', 50.0], ['de', 50.0], ['jp', 50.0], ['us', 50.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 44.3], ['fr', 44.3], ['de', 44.3], ['jp', 44.3], ['us', 44.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 82.8], ['fr', 82.8], ['de', 82.8], ['jp', 82.8], ['us', 82.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
        if (this.sliderValue === 5 && this.selectedViewtype == 'MONTH') {
       
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 61.5], ['fr', 61.5], ['de', 61.5], ['jp', 61.5], ['us', 61.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 15.3], ['fr', 15.3], ['de', 15.3], ['jp', 15.3], ['us', 15.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 74.8], ['fr', 74.8], ['de', 74.8], ['jp', 74.8], ['us', 74.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 49.8], ['fr', 49.8], ['de', 49.8], ['jp', 49.8], ['us', 49.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 32.5], ['fr', 32.5], ['de', 32.5], ['jp', 32.5], ['us', 32.5], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }



        if (this.sliderValue === undefined && this.selectedViewtype == 'WEEK') {
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 28.0], ['fr', 19.4], ['de', 73.6], ['jp', 68.2], ['us', 52.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 17.0], ['fr', 17.0], ['de', 68.0], ['jp', 65.3], ['us', 60.1], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 37.0], ['fr', 43.0], ['de', 81.0], ['jp', 81.0], ['us', 76.4], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 24.0], ['fr', 30.0], ['de', 64.0], ['jp', 68.0], ['us', 68.6], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 82.0], ['fr', 79.0], ['de', 79.0], ['jp', 74.0], ['us', 69.8], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }

        if (this.sliderValue === undefined && this.selectedViewtype == 'MONTH') {
            if (this.selectedCategory == "Patient 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 27.0], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Patient 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 16.3], ['fr', 16.3], ['de', 64.5], ['jp', 64.1], ['us', 60.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 1") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 43.8], ['fr', 49.8], ['de', 74.5], ['jp', 74.5], ['us', 73.2], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 2") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 32.8], ['fr', 30.8], ['de', 61.0], ['jp', 65.3], ['us', 63.0], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
            if (this.selectedCategory == "Message 3") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 74.0], ['fr', 70.5], ['de', 70.5], ['jp', 67.3], ['us', 69.3], 
                    ],
                    name: element.aliasElementName,
                }
                this.updateFlag = true;
            }
        }
    }

    getCampaignDataByClient(valueToCheck) {
        var objParam = {}
        $('.loder-outer').show();
        this.dashboardService.getCampaignDataByClient(objParam).subscribe((result: any) => {

            this.showMapData();
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    showMapData() {
        this.mapData = [['gb', 27.0], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], ];
        var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
        var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['gb', score], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], ],
            name: Selectedname
        }
        this.updateFlag = true;
        this.totalNoOfCalls = 84
    }


    StaticonWeekChange(event) {
        this.sliderValue = event.value
        if (this.selectedViewtype == 'WEEK') {
            if (event.value === 0) {
                this.totalNoOfCalls = 20
                this.dataSource = [
                    {

                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 28
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 17
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 37
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 24
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 82
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 28.0], ['fr', 19.4], ['de', 73.6], ['jp', 68.2], ['us', 52.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 17.0], ['fr', 17.0], ['de', 68.0], ['jp', 65.3], ['us', 60.1], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 37.0], ['fr', 43.0], ['de', 81.0], ['jp', 81.0], ['us', 76.4], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 24.0], ['fr', 30.0], ['de', 64.0], ['jp', 68.0], ['us', 68.6], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 82.0], ['fr', 79.0], ['de', 79.0], ['jp', 74.0], ['us', 69.8], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 25
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 18
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 43
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 28
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 77
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 25.0], ['fr', 21.2], ['de', 72.2], ['jp', 71.2], ['us', 72.2], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 18.0], ['fr', 18.0], ['de', 69.0], ['jp', 64.1], ['us', 62.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 43.0], ['fr', 51.0], ['de', 75.0], ['jp', 75.0], ['us', 74.4], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 28.0], ['fr', 30.0], ['de', 67.0], ['jp', 67.0], ['us', 70.4], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 77.0], ['fr', 67.0], ['de', 67.0], ['jp', 61.0], ['us', 70.4], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 22
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 25
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 13
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 38
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 48
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 72
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 25.0], ['fr', 20.6], ['de', 67.0], ['jp', 65.4], ['us', 45.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 13.0], ['fr', 13.0], ['de', 62.0], ['jp', 60.0], ['us', 67.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 38.0], ['fr', 49.0], ['de', 78.0], ['jp', 78.0], ['us', 78.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 48.0], ['fr', 28.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 72.0], ['fr', 80.0], ['de', 80.0], ['jp', 74.0], ['us', 76.5], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 22
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 30
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 17
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 50
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 41
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 65
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 30.0], ['fr', 19.0], ['de', 68.0], ['jp', 68.0], ['us', 52.1], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 17.0], ['fr', 17.0], ['de', 59.0], ['jp', 67.0], ['us', 52.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 50.0], ['fr', 56.0], ['de', 64.0], ['jp', 64.0], ['us', 64.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 41.0], ['fr', 35.0], ['de', 52.0], ['jp', 65.0], ['us', 52.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 56.0], ['de', 56.0], ['jp', 60.0], ['us', 60.5], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 65
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 22
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 65
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 25
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 22.1], ['de', 73.0], ['jp', 71.0], ['us', 73.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 22.0], ['fr', 22.0], ['de', 71.0], ['jp', 67.0], ['us', 56.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 91.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 45.0], ['de', 45.0], ['jp', 56.0], ['us', 45.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 25.0], ['fr', 30.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 61
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 9
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 57
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 22
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 61.0], ['fr', 24.0], ['de', 75.0], ['jp', 67.0], ['us', 75.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 9.0], ['fr', 9.0], ['de', 67.0], ['jp', 56.0], ['us', 56.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 91.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 57.0], ['fr', 57.0], ['de', 57.0], ['jp', 57.0], ['us', 57.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 22.0], ['fr', 30.0], ['de', 45.0], ['jp', 45.0], ['us', 45.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 57
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 13
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 65
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 4
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 57.0], ['fr', 22.0], ['de', 58.0], ['jp', 54.0], ['us', 58.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 13.0], ['fr', 13.0], ['de', 64.0], ['jp', 64.0], ['us', 64.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 82.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 65.0], ['de', 65.0], ['jp', 65.0], ['us', 65.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 4.0], ['fr', 10.0], ['de', 10.0], ['jp', 30.0], ['us', 10.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 15
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 65
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 17
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 61
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 9
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 16.0], ['de', 54.0], ['jp', 65.0], ['us', 50.2], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 17.0], ['fr', 17.0], ['de', 45.0], ['jp', 45.0], ['us', 45.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 80.4], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 61.0], ['fr', 61.0], ['de', 61.0], ['jp', 61.0], ['us', 61.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 9.0], ['fr', 9.0], ['de', 42.0], ['jp', 42.0], ['us', 42.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 8) {

                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 61
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 22
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 52
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 9
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 61.0], ['fr', 24.0], ['de', 67.0], ['jp', 71.0], ['us', 62.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 22.0], ['fr', 22.0], ['de', 55.0], ['jp', 55.0], ['us', 55.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 78.0], ['de', 78.0], ['jp', 78.0], ['us', 78.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 65.0], ['us', 52.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 9.0], ['fr', 9.0], ['de', 34.0], ['jp', 45.0], ['us', 34.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 65
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 39
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 87
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 48
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 52
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 65.0], ['fr', 65.0], ['de', 64.0], ['jp', 60.0], ['us', 61.0],                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 39.0], ['fr', 39.0], ['de', 39.0], ['jp', 39.0], ['us', 45.5], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 87.0], ['fr', 87.0], ['de', 67.0], ['jp', 67.4], ['us', 78.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 48.0], ['fr', 48.0], ['de', 48.0], ['jp', 48.0], ['us', 48.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 52.0], ['us', 55.5], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 19
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 70
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 43
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 87
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 52
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 26
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 70.0], ['fr', 29.0], ['de', 65.0], ['jp', 65.0], ['us', 68.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 43.0], ['fr', 43.0], ['de', 43.0], ['jp', 49.0], ['us', 48.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 87.0], ['fr', 87.0], ['de', 87.0], ['jp', 87.0], ['us', 82.1], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 52.0], ['fr', 52.0], ['de', 52.0], ['jp', 52.0], ['us', 52.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 26.0], ['fr', 26.0], ['de', 45.0], ['jp', 45.0], ['us', 50.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 4
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 83
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 87
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 43
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 13
                }];


                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 83.0], ['fr', 43.0], ['de', 43.0], ['jp', 54.0], ['us', 43.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 35.0], ['fr', 35.0], ['de', 35.0], ['jp', 45.0], ['us', 35.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 87.0], ['fr', 67.0], ['de', 67.0], ['jp', 56.0], ['us', 67.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 43.0], ['fr', 43.0], ['de', 43.0], ['jp', 67.0], ['us', 43.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 13.0], ['fr', 13.0], ['de', 13.0], ['jp', 13.0], ['us', 13.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }


        }
        if (this.selectedViewtype == 'MONTH') {
            if (event.value === 0) {
                this.totalNoOfCalls = 84
                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 27.0
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 16.3
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 43.8
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 32.8
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 74.0
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 27.0], ['fr', 20.1], ['de', 70.2], ['jp', 68.2], ['us', 55.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 16.3], ['fr', 16.3], ['de', 64.5], ['jp', 64.1], ['us', 60.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 43.8], ['fr', 49.8], ['de', 74.5], ['jp', 74.5], ['us', 73.2], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 32.8], ['fr', 30.8], ['de', 61.0], ['jp', 65.3], ['us', 63.0], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 74.0], ['fr', 70.5], ['de', 70.5], ['jp', 67.3], ['us', 69.3], 
                        ],
                        name: Selectedname
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 75
                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 62.0
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 15.3
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 91.0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 62.0
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 15.0
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 62.0], ['fr', 21.0], ['de', 65.0], ['jp', 64.3], ['us', 64.1], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 15.3], ['fr', 15.3], ['de', 61.8], ['jp', 58.0], ['us', 55.3], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 91.0], ['fr', 91.0], ['de', 91.0], ['jp', 91.0], ['us', 86.1], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 62.0], ['fr', 57.0], ['de', 57.0], ['jp', 59.8], ['us', 57.0], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 15.0], ['fr', 19.8], ['de', 39.5], ['jp', 44.5], ['us', 39.5], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 64
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 69.8
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 34.8
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 88.0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 48.8
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 25.0
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName

                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 69.8], ['fr', 40.3], ['de', 59.8], ['jp', 62.5], ['us', 58.5], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 34.8], ['fr', 34.8], ['de', 43.0], ['jp', 47.0], ['us', 46.0], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 88.0], ['fr', 79.8], ['de', 74.8], ['jp', 72.0], ['us', 76.4], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 48.8], ['fr', 48.8], ['de', 48.8], ['jp', 58.0], ['us', 48.8], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 25.0], ['fr', 25.0], ['de', 36.0], ['jp', 38.8], ['us', 38.2], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 93
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 72.0
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 53.5
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 76.0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 45.8
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 31.3
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 72.0], ['fr', 72.0], ['de', 72.0], ['jp', 72.0], ['us', 72.0], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 53.5], ['fr', 53.5], ['de', 53.5], ['jp', 53.5], ['us', 53.5], 
                        ],
                        name:Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 76.0], ['fr', 76.0], ['de', 76.0], ['jp', 76.0], ['us', 76.0], 
                        ],
                        name:Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 45.8], ['fr', 45.8], ['de', 45.8], ['jp', 45.8], ['us', 45.8], 
                        ],
                        name:Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 31.3], ['fr', 31.3], ['de', 31.3], ['jp', 31.3], ['us', 31.3], 
                        ],
                        name:Selectedname,
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 77
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 22.5
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 81.8
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 50.0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 44.3
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 82.8
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 22.5], ['fr', 22.5], ['de', 22.5], ['jp', 22.5], ['us', 22.5], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 81.8], ['fr', 81.8], ['de', 81.8], ['jp', 81.8], ['us', 81.8], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 50.0], ['fr', 50.0], ['de', 50.0], ['jp', 50.0], ['us', 50.0], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 44.3], ['fr', 44.3], ['de', 44.3], ['jp', 44.3], ['us', 44.3], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 82.8], ['fr', 82.8], ['de', 82.8], ['jp', 82.8], ['us', 82.8], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 108
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 61.5
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 15.3
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 74.8
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 49.8
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 32.5
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName

                if (this.selectedCategory == "Patient 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 61.5], ['de', 61.5], ['jp', 61.5], ['us', 61.5], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Patient 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 15.3], ['de', 15.3], ['jp', 15.3], ['us', 15.3], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 1") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 74.8], ['de', 74.8], ['jp', 74.8], ['us', 74.8], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 2") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 49.8], ['de', 49.8], ['jp', 49.8], ['us', 49.8], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Message 3") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 32.5], ['de', 32.5], ['jp', 32.5], ['us', 32.5], 
                        ],
                        name: Selectedname,
                    }
                    this.updateFlag = true;
                }
            }
            else {
                this.totalNoOfCalls = 0
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 0
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 0
                }];

                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
        }
    }
    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
            animation: false,
        },
        colors: ['#ffcccc', '#ffb3b3', '#ff9999', '#ff0000', '#ffecb3', '#ffe599', '#ffd966', '#ffbf00', '#cde6cc', '#b5d9b3', '#9ccc99', '#51a64d', '#6ab366', '#399933', '#078000'],
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [{
                from: 0,
                to: 10,
            },
            {
                from: 10,
                to: 20,

            }, {
                from: 20,
                to: 30,
            },
            {
                from: 30,
                to: 33,
            },
            {
                from: 33.1,
                to: 40,
            },
            {
                from: 40,
                to: 50,
            },
            {
                from: 50,
                to: 60,

            }, {
                from: 60,
                to: 65.9,
            },
            {
                from: 66,
                to: 70,
            },
            {
                from: 70,
                to: 75,
            },
            {
                from: 75,
                to: 80,

            }, {
                from: 80,
                to: 85,

            },
            {
                from: 85,
                to: 90,

            },
            {
                from: 90,
                to: 95,

            },
            {
                from: 95,
                to: 100,
            },

            ]
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true
                }
            }
        },
        series: [{
            nullColor: '#c9d1d9',
            type: "map",
            name: "Campaign Implementation",
            states: {
                hover: {
                    enabled: false,
                }
            },
            tooltip: {
                valueDecimals: 1,
                valueSuffix: '%'
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        if (this.point['hc-key'] === 'gb') {
                            return 'U.K....'
                        }
                        return this.point.name;
                    }
                }
            },
            allAreas: true,
            data: this.mapData
        }]

    };


}
