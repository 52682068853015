import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { ProfilepageComponent } from './profilepage/profilepage.component';
import { WorldmapComponent } from './worldmap/worldmap.component';
import { ChangeoverTimeComponent } from './changeover-time/changeover-time.component';
const routes: Routes = [
    { path: 'slsm/home', component: HomeComponent, canActivate: [AuthGuardService]},
    { path: 'slsm/ppage', component: ProfilepageComponent, canActivate: [AuthGuardService]},
    { path: 'slsm/wmap', component: WorldmapComponent, canActivate: [AuthGuardService] },
    { path: 'slsm/cot', component: ChangeoverTimeComponent, canActivate: [AuthGuardService] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SLSMRoutingModule { }
