import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-callaction-analysis',
    templateUrl: './callaction-analysis.component.html',
    styleUrls: ['./callaction-analysis.component.css']
})
export class CallactionAnalysisComponent implements OnInit {

    constructor(
        private dashboardService: DashboardService,
    ) { }

    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    selectedLanguage: string = "En";
    selectedLevelId: number = 3;
    outcomeDataList: any;
    ctaDataList: any = [];
    staticTextData: any;

    @ViewChild('CallAnalysisPie', { static: true }) CallAnalysisPie: ElementRef;
    @ViewChild('CallAnalysisColumn1', { static: true }) CallAnalysisColumn1: ElementRef;
    @ViewChild('CallAnalysisColumn2', { static: true }) CallAnalysisColumn2: ElementRef;
    @ViewChild('CallAnalysisColumn3', { static: true }) CallAnalysisColumn3: ElementRef;

    ngOnInit() {
        this.getLanguageStaticText();
        this.selectedLanguage = window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage");

        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        this.clientLogo = window.localStorage.getItem("ClientLogo");
        //this.getOutcomeDataShare();
    }
    
    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getOutcomeDataShare();
        }, error => console.error(error));
    }

    getOutcomeDataShare() {
        this.totalNoOfCalls = 0;
        this.selectedLevelId = 3;
        $('#CallAnalysisColumn1').hide();
        $('#CallAnalysisColumn2').hide();
        $('#CallAnalysisColumn3').show();

        var obj = {
            LanguageCode: this.selectedLanguage,
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            LevelId: this.selectedLevelId,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }

        this.dashboardService.getOutcomeDataShare(obj).subscribe((result: any) => {
            this.outcomeDataList = result;
            this.totalNoOfCalls = this.totalNoOfCalls + result.totalCalls;
            this.drawOutcomePieGraph(result);
            this.getCTADataShare();

        }, error => console.error(error));
    }

    getCTADataShare() {
        var obj = {
            LanguageCode: this.selectedLanguage,
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            LevelId: this.selectedLevelId,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }

        this.dashboardService.getCTADataShare(obj).subscribe((result: any) => {
            this.ctaDataList = result;
            var dataArray = [];
            if (result.length > 0) {
                result.forEach(obj => {
                    var data = {
                        name: obj.elementNameToShow,
                        y: obj.score,
                        color: this.selectedLevelId == 3 ? '#00b050' : this.selectedLevelId == 2 ? '#ffc00094' : 'red'
                    }
                    dataArray.push(data);
                });
            }
            if (this.selectedLevelId == 1) {
                this.drawCTALevel1Graph(dataArray);
            }
            else if (this.selectedLevelId == 2) {
                this.drawCTALevel2Graph(dataArray);
            }
            else {
                this.drawCTALevel3Graph(dataArray);
            }
        }, error => console.error(error));
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getOutcomeDataShare();
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getOutcomeDataShare();
    }

    drawOutcomePieGraph(dataValues) {
        const reference = this; // code added
        Highcharts.chart(this.CallAnalysisPie.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    marginBottom: 20,
                    type: 'pie',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                },
                legend: {
                    enabled: true,
                    y: 20,
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                title: {
                    enabled: false,
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    point: {
                        valueSuffix: '%',
                    }
                },
                plotOptions: {
                    pie: {
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        point: {
                            events: {
                                click: function (event) {
                                    if (this.id == 1) {
                                        this.series.update({ startAngle: 0 })
                                        $('#CallAnalysisColumn1').show();
                                        $('#CallAnalysisColumn2').hide();
                                        $('#CallAnalysisColumn3').hide();
                                        reference.selectedLevelId = 1;
                                        reference.getCTADataShare();
                                    }
                                    if (this.id == 2) {
                                        this.series.update({ startAngle: 195 })
                                        $('#CallAnalysisColumn1').hide();
                                        $('#CallAnalysisColumn2').show();
                                        $('#CallAnalysisColumn3').hide();
                                        reference.selectedLevelId = 2;
                                        reference.getCTADataShare();
                                    }
                                    if (this.id == 3) {
                                        this.series.update({ startAngle: 110 })
                                        $('#CallAnalysisColumn1').hide();
                                        $('#CallAnalysisColumn2').hide();
                                        $('#CallAnalysisColumn3').show();
                                        reference.selectedLevelId = 3;
                                        reference.getCTADataShare();
                                    }
                                }
                            }
                        },
                        startAngle: 110,
                        slicedOffset: 20,
                        cursor: 'pointer',
                        allowPointSelect: true,
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.y} %',
                            distance: -35,
                            style: {
                                fontSize: '20px',
                                color: 'white',
                                textShadow: false,
                                textOutline: false
                            },

                        },
                        showInLegend: true
                    }
                },
                series: [{
                    colorByPoint: true,
                    data: [
                        {
                            name: this.staticTextData.notObserved,//'Not Observed',
                            y: dataValues.notObserved,
                            color: '#ff0000',
                            id: 1,
                        },
                        {
                            name: this.staticTextData.outcomePrompted,//'Outcome Prompted',
                            y: dataValues.developing,
                            color: '#ffc000',
                            id: 2,
                        },
                        {
                            name: this.staticTextData.outcomeVolunteered,//'Outcome Volunteered',
                            y: dataValues.accomplished,
                            color: '#00b050',
                            id: 3,
                            sliced: true,
                            selected: true
                        }
                    ]
                }]
            })
        );
    }

    drawCTALevel1Graph(dataValues) {
        Highcharts.chart(this.CallAnalysisColumn1.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.notObserved,//'Not Observed',
                    style: {
                        color: '#ff0000',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    type: 'category',/*: ['Prescribe', 'Advocacy', 'Guidelines', 'Patient Id'],*/
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'
                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    data: dataValues
                }]
            })
        );
    }

    drawCTALevel2Graph(dataValues) {
        Highcharts.chart(this.CallAnalysisColumn2.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.outcomePrompted,//'Outcome Prompted',
                    style: {
                        color: '#ffc000',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    type: 'category',/*: ['Prescribe', 'Advocacy', 'Guidelines', 'Patient Id'],*/
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'

                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    data: dataValues
                }]
            })
        );
    }

    drawCTALevel3Graph(dataValues) {
        Highcharts.chart(this.CallAnalysisColumn3.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.outcomeVolunteered,//'Outcome Volunteered',
                    style: {
                        color: '#00b050',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    type: 'category',/*: ['Prescribe', 'Advocacy', 'Guidelines', 'Patient Id'],*/
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'
                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    data: dataValues
                }]
            })
        );
    }
}
