import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DemoRoutingModule } from './demo-routing.module';
import { DemohomeComponent } from './demohome/demohome.component';
import { DemoleftnavComponent } from './demoleftnav/demoleftnav.component';
import { DemoprofilepageComponent } from './demoprofilepage/demoprofilepage.component';
import { DemoworldmapComponent } from './demoworldmap/demoworldmap.component';
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [DemohomeComponent, DemoleftnavComponent, DemoprofilepageComponent, DemoworldmapComponent],
  imports: [
    CommonModule,
      DemoRoutingModule,
      MatButtonModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      BrowserModule,
      BrowserAnimationsModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatChipsModule,
      MatIconModule,
      MatListModule,
      DragDropModule,
      MatTabsModule,
      HighchartsChartModule
  ]
})
export class DemoModule { }
