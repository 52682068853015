import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallCharacteristicsComponent } from './call-characteristics/call-characteristics.component';
import { CallactionAnalysisComponent } from './callaction-analysis/callaction-analysis.component';
import { CampaignImplementationComponent } from './campaign-implementation/campaign-implementation.component';
import { CampaignelementComponent } from './campaignelement/campaignelement.component';
import { GlanceComponent } from './glance/glance.component';
import { OutcomeAnalysisComponent } from './outcome-analysis/outcome-analysis.component';
import { SellingskillsComponent } from './sellingskills/sellingskills.component';
import { SellingskillselementComponent } from './sellingskillselement/sellingskillselement.component';


const routes: Routes = [
    { path: 'staticdashboard/SS', component: SellingskillsComponent},
    { path: 'staticdashboard/OA', component: OutcomeAnalysisComponent},
    { path: 'staticdashboard/CI', component: CampaignImplementationComponent},
    { path: 'staticdashboard/AG', component: GlanceComponent},
    { path: 'staticdashboard/SE', component: SellingskillselementComponent},
    { path: 'staticdashboard/CE', component: CampaignelementComponent},
    { path: 'staticdashboard/CAC', component: CallactionAnalysisComponent},
    { path: 'staticdashboard/CC', component: CallCharacteristicsComponent},
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticdashboardRoutingModule { }
