import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { LeftMenuData } from '../../models/slsm/left-menu-data.model';
import { CountryComparison } from '../../models/slsm/country-comparison.model';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

interface product {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    userId: any = window.localStorage.getItem("UserId");

    constructor(
        private router: Router,
        private slsmService: SlsmService
    ) { }

    ngOnInit() {

        Highcharts.chart('CImplem', this.optino);

        $('.cstm-Item').click(function () {
            $('.cstm-Item.Selected').removeClass('Selected');
            $(this).addClass('Selected');
        });
        if (window.localStorage.getItem("ViewType") != null) {
            $('.cstm-Item.Selected').removeClass('Selected');
            $('#id' + window.localStorage.getItem("ViewType")).addClass('Selected');
        }

        this.leftMenuData = new LeftMenuData();
        this.getSellingSkillLables();
        this.getLablesDescription();
        this.getCountryData();
    }

    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];

    @ViewChild('SSkills', { static: true }) SSkills: ElementRef;
    @ViewChild('BProfile', { static: true }) BProfile: ElementRef;
    @ViewChild('GCallD', { static: true }) GCallD: ElementRef;

    @ViewChild('countryComparisonTable', { static: false }) table: MatTable<CountryComparison>;
    displayedColumns: string[] = ['position', 'imageUrl', 'CountryName', 'DSSkil', 'DCImplem'];
    dataSource;

    isHidden: boolean = false;
    isHiddenMdl: boolean = false;
    isHiddenInfoMdl: boolean = false;
    slsmLeftMenuData: any;
    leftMenuData: LeftMenuData;

    countryList: any = [];
    selectedCountry: any = {};
    selectedCountryLogo: any = "";

    sellingSkillLables: any = [];
    sellingskillDataByElement: any = [];
    sellingskillDataByMonth: any = [];
    goodCallCategories: any = [];
    goodCallData: any = [];
    goodCallAverageData: any = {};
    countryComparisonData: CountryComparison[] = [];

    selectedLanguage: string = "En";
    selectedDataViewType: number = 1;

    elementDescription: any = [];
    labelsDescription: any = [];

    dataViewType(type) {
        window.localStorage.setItem("ViewType", type);
        this.selectedDataViewType = type;
        this.getCountryData();
    }

    getSellingSkillLables() {
        this.slsmService.GetSellingSkillLables(this.selectedLanguage).subscribe((result: any) => {
            if (result.length > 0) {
                this.sellingSkillLables = result;
            }
        }, error => console.error(error));
    }

    getLablesDescription() {
        this.slsmService.GetLablesDescription(this.selectedLanguage).subscribe((result: any) => {
            if (result.length > 0) {
                this.elementDescription = result.filter(x => x.isElement == true);
                this.labelsDescription = result.filter(x => x.isElement == false);
            }
        }, error => console.error(error));
    }

    getCountryData() {
        this.countryComparisonData = [];
        var param = {
            UserId: parseInt(this.userId),
            ViewType: window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType
        };
        this.slsmService.GetCountryWiseClientData(param).subscribe((result: any) => {
            if (result.length > 0) {
                this.countryList = result.filter(x => x.clientId != 0);
                let i = 0;
                this.countryList.forEach(objCountry => {
                    i = i + 1;
                    var country = {
                        imageUrl: "/CountryFlags/" + objCountry.countryName + ".png",
                        position: i,
                        CountryName: objCountry.countryName,
                        DSSkil: objCountry.sellingSkillScore,
                        DCImplem: 53
                    };
                    this.countryComparisonData.push(country);
                });
                this.dataSource = this.countryComparisonData;

                if (window.localStorage.getItem("CountryKey") != null) {
                    this.selectedCountry = this.countryList.find(x => x.countryCode == window.localStorage.getItem("CountryKey"));
                    this.selectedCountryLogo = "/CountryFlags/" + this.selectedCountry.countryName + ".png";
                    this.getProducts(this.selectedCountry.clientId);
                }

            }
        }, error => console.error(error));
    }

    openDialog() {
        this.isHiddenMdl = true;
    }

    hideDailog() {
        this.isHiddenMdl = false;
        this.isHiddenInfoMdl = false;
    }

    onCountrySelect(country) {
        window.localStorage.setItem("CountryKey", country.countryCode);
        this.selectedCountry = this.countryList.find(x => x.countryCode == country.countryCode);
        this.selectedCountryLogo = "/CountryFlags/" + this.selectedCountry.countryName + ".png";
        this.hideDailog();
        this.getProducts(this.selectedCountry.clientId);
    }

    getProducts(clientId) {
        window.localStorage.setItem("ClientId", clientId);
        this.getDashboardDataByClient(clientId);
        //call api to get products list based on client.
    }

    getDashboardDataByClient(clientId) {
        this.sellingskillDataByElement = [];
        this.sellingskillDataByMonth = [];
        this.goodCallCategories = [];
        this.goodCallData = [];

        var params = {
            ClientId: clientId,
            UserId: parseInt(this.userId),
            ViewType: window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType,
            LanguageCode: this.selectedLanguage
        };
        this.slsmService.GetDashboardDataByClient(params).subscribe((result: any) => {
            //filtering selling skills data by elements and its labels.
            this.sellingSkillLables.forEach(objLabel => {
                var elementArray = [];
                result.sellingSkillsByElement.forEach(obj => {
                    var objEle = {
                        name: obj.elementName,
                        y: objLabel.labelId == 1 ? obj.notObserved : objLabel.labelId == 2 ? obj.foundational : objLabel.labelId == 3 ? obj.developing : obj.accomplished,
                        drilldown: obj.elementName + "-" + objLabel.labelId,
                    }
                    elementArray.push(objEle);
                });
                var dataObj = {
                    visible: objLabel.labelId == 4 ? true : false,
                    name: objLabel.labelName,
                    color: objLabel.color,
                    data: elementArray
                }
                this.sellingskillDataByElement.push(dataObj);
            });

            //filtering selling skill data by month.
            if (result.sellingSkillsByMonth.length > 0) {
                result.sellingSkillsByMonth.forEach(obj => {
                    if (obj.length > 0) {
                        obj.forEach(objdata => {
                            var eleLabelData = {
                                stacking: null,
                                id: objdata.elementName + "-" + objdata.labelId,
                                name: objdata.labelName,
                                data: this.getMonthWiseData(objdata)
                            }
                            this.sellingskillDataByMonth.push(eleLabelData);
                        });
                    }
                });
            }

            //filtering good calls data.
            if (result.goodCallsByElement.length > 0) {
                var filterGoodCallData = result.goodCallsByElement.filter(x => x.outcomeValue == 4);
                filterGoodCallData.forEach(obj => {
                    this.goodCallCategories.push(obj.elementName);
                    this.goodCallData.push(obj.score);
                });
            }

            //getting audio calls avgs.
            if (result.audioCallAverage != null) {
                this.leftMenuData.TotalAudioCallAvg = result.audioCallAverage.totalAudioCallAvg;
                this.leftMenuData.DoctorSpeakTimeAvg = result.audioCallAverage.doctorSpeakTimeAvg;
                this.leftMenuData.RepSpeakTimeAvg = result.audioCallAverage.repSpeakTimeAvg;
            }

            //getting good calls avgs.
            if (result.goodCallAverage != null) {
                this.goodCallAverageData = result.goodCallAverage;
            }

            this.drawSellingSkillGraph(this.sellingskillDataByElement, this.sellingskillDataByMonth);
            this.drawGoodCallGraph(this.goodCallCategories, this.goodCallData);
            this.drawGoodCallDistributionPieChartGraph(this.goodCallAverageData);

            //To get selling skill avg by month.
            this.slsmService.GetSellingSkillAvgByMonth(params).subscribe((resultSS: any) => {

                this.slsmLeftMenuData = { SellingSkillAvg: this.selectedCountry.sellingSkillScore, CampaignImpAvg: 42, TotalAudioCallAvg: this.leftMenuData.TotalAudioCallAvg, DoctorSpeakTimeAvg: this.leftMenuData.DoctorSpeakTimeAvg, RepSpeakTimeAvg: this.leftMenuData.RepSpeakTimeAvg, SellingSkillMonthlyAvg: resultSS };
            });

        }, error => console.error(error));
    }

    getMonthWiseData(data) {
        var chartData = new Array();
        var selectedView = window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : this.selectedDataViewType;
        var isMat = selectedView == 1 ? true : false;
        var months = this.getMonthList(isMat);

        try {
            for (var i = 0; i < months.length; i++) {
                var month = months[i];
                var monthDataKey = 'month' + (i + 1);
                var entry = [month, data[monthDataKey]];
                chartData.push(entry);
            }

            //if Qtr is selected
            if (selectedView == 3) {
                var qtr = [];
                for (var j = chartData.length - 2; j > chartData.length - 5; j--) {
                    qtr.push(chartData[j]);
                }
                chartData = [];
                chartData = qtr.reverse();
            }
            //if Month is selected
            if (selectedView == 4) {
                var lastMonth = [];
                lastMonth.push(chartData[chartData.length - 2]);
                chartData = [];
                chartData = lastMonth;
            }
        } catch (e) {

        }
        return chartData;
    }

    

    drawSellingSkillGraph(dataByElement, dataByMonth) {
        Highcharts.chart(this.SSkills.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                    //events: {
                    //    load: function (event) {
                    //        this.series[1].data[1].doDrilldown();
                    //    }
                    //}
                },
                title: {
                    text: 'Selling Skills',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'top',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: -40,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: dataByElement,
                drilldown: {
                    series: dataByMonth
                }
            })
        );
    }

    drawGoodCallGraph(categories, data) {
        Highcharts.chart(this.BProfile.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'bar',
                    marginBottom: 10,
                    marginLeft: 25,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Accomplished Outcomes - Call Profile',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        enabled: true,
                        align: 'left',
                        style: {

                            textOverflow: 'none',
                            whiteSpace: "nowrap"
                        },
                        x: 10,
                        y: -16
                    },

                    title: {
                        text: null
                    },
                    gridLineColor: 'transparent',
                },
                yAxis: {

                    title: false,
                    labels: {
                        enabled: false,
                        format: '{value}%'
                    },
                    min: 0,
                    max: 100,
                    gridLineColor: 'transparent',
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    bar: {

                        borderRadius: 5
                    },
                    series: {
                        pointPadding: 0.23,
                        dataLabels: {
                            enabled: true,
                            format: '{y}% ',
                            x: 7,
                            style: {
                                color: '#012072',
                                fontSize: '15px',
                                fontWeight: 'bolder',
                            }
                        }
                    }
                },
                series: [{
                    color: '#09d6cc',
                    data: data
                }]
            })
        );
    }

    drawGoodCallDistributionPieChartGraph(GoodCallAvgData) {
        Highcharts.chart(this.GCallD.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    marginBottom: 18,
                    type: 'pie',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                },
                title: {
                    text: 'Positive Call Outcome',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    point: {
                        valueSuffix: '%',
                    }
                },
                plotOptions: {
                    pie: {
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.y} %',
                            distance: -50,
                            style: {
                                fontSize: '20px',
                                color: 'white',
                                textShadow: false,
                                textOutline: false
                            }
                        },
                        showInLegend: false
                    }
                },
                series: [{
                    name: 'Avg. Score',
                    colorByPoint: true,
                    data: [{
                        name: 'No Commitment',
                        y: GoodCallAvgData.notGoodCallAvg,
                        color: '#0abfb2',
                        sliced: true,
                        selected: true
                    }, {
                        name: 'Volunteered Commitment',
                        y: GoodCallAvgData.goodCallAvg,
                        color: '#85a7ff',
                    }]
                }]
            })
        );
    }

    public optino: any = {
        chart: {
            type: 'column',
            marginBottom: 18,
            marginLeft: 60,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 10,
                depth: 0,
                ViewDistance: 50
            }
        },
        title: {
            text: 'Campaign Implementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            type: 'category',   /*categories: ['Positioning', 'Effcacy 1', 'Effcacy 2 ', 'Dosage'],*/
            gridLineColor: 'transparent',
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 10,
            title: {
                enabled: false
            },
            labels: {
                enabled: true,
                format: '{value}%'
            },
            gridLineColor: 'transparent',
        },

        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                depth: 100,
                pointPadding: 0.25,
                borderWidth: 1,
                color: {
                    linearGradient: [40, 40, 40, 744],
                    stops: [
                        [0, '#01ebf6'],
                        [0.4, '#046fbd'],
                        [0.6, 'black'],
                        [0.8, 'black'],
                        [1, 'black']
                    ]
                }
            }
        },
        credits: {
            enabled: false
        },

        series: [{
            data: [{
                name: 'Positioning',
                y: 55,
                drilldown: 'Positioning'
            }, {
                name: 'Efficacy 1⁰',
                y: 82,
                drilldown: 'Efficacy 1'
            }, {
                name: 'Efficacy 2⁰',
                y: 33,
                drilldown: 'Efficacy 2'
            },
            {
                name: 'Safety',
                y: 24,
                drilldown: 'Dosage'
            }]
        }

        ],
        drilldown: {
            series: [
                {
                    depth: 50,
                    stacking: null,
                    id: 'Positioning',
                    name: 'Positioning',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 1',
                    name: 'Efficacy 1⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 2',
                    name: 'Efficacy 2⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Dosage',
                    name: 'Safety',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                }

            ]
        }

    }

    dropTable(event: CdkDragDrop<CountryComparison[]>) {
        const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
        moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
        this.table.renderRows();
    }

    showGCallD() {
        this.isHidden = true;
    }

    hideGCallD() {
        this.isHidden = false;
    }
    categoryinfoDialog() {
        this.isHiddenInfoMdl = true;
    }
    getMonthList(isMat) {
        var months = new Array();

        months[0] = 'Jan';
        months[1] = 'Feb';
        months[2] = 'Mar';
        months[3] = 'Apr';
        months[4] = 'May';
        months[5] = 'Jun';
        months[6] = 'Jul';
        months[7] = 'Aug';
        months[8] = 'Sep';
        months[9] = 'Oct';
        months[10] = 'Nov';
        months[11] = 'Dec';

        var date = new Date();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        var currentMonthList = new Array();

        if (monthIndex >= 0) {
            for (var i = monthIndex; i >= 0; i--) {
                var month = months[i] + "-" + year;
                currentMonthList.push(month);
            }
            if (isMat == true) {
                year--;
                for (var i = 11; i > monthIndex; i--) {
                    var month = months[i] + "-" + year;
                    currentMonthList.push(month);
                }
            }
        }
        return currentMonthList.reverse();
    }

}
