import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CampaignElements } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';

@Component({
    selector: 'app-campaign-implementation',
    templateUrl: './campaign-implementation.component.html',
    styleUrls: ['./campaign-implementation.component.css']
})
export class CampaignImplementationComponent implements OnInit {
    sliderValue: any;
    selectedView: string;
  
    constructor(
        private route: Router,
        private dashboardService: DashboardService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
    }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    clientName: string = "";
    selectedCategory: any = 'Patient 1'
    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }
        if (value >= 1) {
            return ' - ' + Math.round(value / 1) + '';
        }
        return value;
    }

    ngOnInit() {
        this.getLanguageStaticText();
        this.selectedCategory = 'Patient 1'
        this.selectedViewtype = 'WEEK';

    }

    totalNoOfCalls: any;
    clientCountryDataList: any = [];
    clientCountryDataListByElement: any = [];
    mapData: any = [];
    selectedElementList: any = [];

    updateFlag = false;
    currentPage: string = "CI";
    staticTextData: any;
    isMonthlySelected: boolean = true;
    selectedViewtype = 'WEEK';
    value: number = 0;
    @ViewChild('table', { static: false }) table: MatTable<CampaignElements>;

    displayedColumns: string[] = ['ElementName', 'Score', 'Checked'];

    dataSource = [{
        "elementNameToShow": "Patient 1",
        "aliasElementName": "Patient 1",
        "score": 65
    },
    {
        "elementNameToShow": " Patient 2",
        "aliasElementName": "Patient 2",
        "score": 80
    },
    {
        "elementNameToShow": " Message 1",
        "aliasElementName": "Message 1",
        "score": 45
    }, {
        "elementNameToShow": " Message 2",
        "aliasElementName": "Message 2",
        "score": 35
    },
    {
        "elementNameToShow": " Message 3",
        "aliasElementName": "Message 3",
        "score": 20
    }
    ];


    getLanguageStaticText() {
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getCampaignDataByClient(0);
        }, error => console.error(error));
    }


    changeViewType(view) {
        this.selectedViewtype = view;
        this.value = 0
        if (this.selectedViewtype == 'WEEK') {
            this.mapData = [['us', 65]];
            this.totalNoOfCalls = 19;

            this.dataSource = [{
                "elementNameToShow": "Patient 1",
                "aliasElementName": "Patient 1",
                "score": 65
            },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 80
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 45
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 20
                }
            ];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['us', score]],
                name: Selectedname,

            }
            this.updateFlag = true;
        }
        if (this.selectedViewtype == 'MONTH') {

            this.mapData = [['us', 78.5]];
            this.dataSource = [{
                "elementNameToShow": "Patient 1",
                "aliasElementName": "Patient 1",
                "score": 78.5
            },
            {
                "elementNameToShow": " Patient 2",
                "aliasElementName": "Patient 2",
                "score": 73.5
            },
            {
                "elementNameToShow": " Message 1",
                "aliasElementName": "Message 1",
                "score": 62.3
            }, {
                "elementNameToShow": " Message 2",
                "aliasElementName": "Message 2",
                "score": 45.5
            },
            {
                "elementNameToShow": " Message 3",
                "aliasElementName": "Message 3",
                "score": 28.0
            }];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['us', score]],
                name: Selectedname,

            }
            this.updateFlag = true;
            this.totalNoOfCalls = 76;
        }
    }
    onSelectElement(element, event) {
        this.selectedElementList = [];
        this.selectedElementList.push(element.aliasElementName);
        this.selectedCategory = element.aliasElementName
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['us', element.score]
            ],
            name: element.aliasElementName,
        }
        this.updateFlag = true;

    }

    getCampaignDataByClient(valueToCheck) {
        var objParam = {}
        $('.loder-outer').show();
        this.dashboardService.getCampaignDataByClient(objParam).subscribe((result: any) => {

            this.showMapData();
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    showMapData() {
        this.mapData = [['us', 65]];
        var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
        var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
        this.chartOptions.series[0] = {
            type: 'map',
            data: this.mapData,
            name: Selectedname
        }
        this.updateFlag = true;
        this.totalNoOfCalls = 19
    }


    StaticonWeekChange(event) {
        this.sliderValue = event.value
        if (this.selectedViewtype == 'WEEK') {
            if (event.value === 0) {
                this.totalNoOfCalls = 19
                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 65
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 80
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 45
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 20
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 19

                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 77
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 65
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 65
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 55
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 30
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 19
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 90
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 75
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 65
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 50
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 30
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 19
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 82
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 74
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 74
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 42
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 32
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 84
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 95
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 85
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 5
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 88
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 70
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 70
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 50
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 0
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 40
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 30
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 65
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 55
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 5
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 15
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 47
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 47
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 60
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 53
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 7
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 8) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 75
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 45
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 80
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 25
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 75
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 30
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 85
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 35
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 15
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 20
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 65
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 65
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 55
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 60
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 4
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 95
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 23
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 100
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 100
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
        }

        if (this.selectedViewtype == 'MONTH') {
              if (event.value === 0) {
                this.totalNoOfCalls = 76
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 78.5
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 73.5
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 62.3
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 42.5
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 28.0
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
             else if (event.value === 1) {
                 this.totalNoOfCalls = 75

                 this.dataSource = [{
                     "elementNameToShow": "Patient 1",
                     "aliasElementName": "Patient 1",
                     "score": 64.8
                 },
                 {
                     "elementNameToShow": " Patient 2",
                     "aliasElementName": "Patient 2",
                     "score": 60.5
                 },
                 {
                     "elementNameToShow": " Message 1",
                     "aliasElementName": "Message 1",
                     "score": 70.0
                 }, {
                     "elementNameToShow": " Message 2",
                     "aliasElementName": "Message 2",
                     "score": 48.3
                 },
                 {
                     "elementNameToShow": " Message 3",
                     "aliasElementName": "Message 3",
                     "score": 4.3
                 }];
                 var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                 var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 this.chartOptions.series[0] = {
                     type: 'map',
                     data: [['us', score]],
                     name: Selectedname
                 }
                 this.updateFlag = true;
             }
            else if (event.value === 2) {
                this.totalNoOfCalls = 64
                this.dataSource = [{
                    "elementNameToShow": "Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 77.5
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 24.5
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 82.5
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 31.3
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 50.0
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
           
           
            else {
                this.totalNoOfCalls = 0
                this.dataSource = [{
                    "elementNameToShow": " Patient 1",
                    "aliasElementName": "Patient 1",
                    "score": 0
                },
                {
                    "elementNameToShow": " Patient 2",
                    "aliasElementName": "Patient 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 1",
                    "aliasElementName": "Message 1",
                    "score": 0
                }, {
                    "elementNameToShow": " Message 2",
                    "aliasElementName": "Message 2",
                    "score": 0
                },
                {
                    "elementNameToShow": " Message 3",
                    "aliasElementName": "Message 3",
                    "score": 0
                }];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', score]
                    ],
                    name: Selectedname
                }
                this.updateFlag = true;
            }
   
        }

    }
    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
            animation: false,
        },
        colors: ['#ffcccc', '#ffb3b3', '#ff9999', '#ff0000', '#ffecb3', '#ffe599', '#ffd966', '#ffbf00', '#cde6cc', '#b5d9b3', '#9ccc99', '#51a64d', '#6ab366', '#399933', '#078000'],
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [{
                from: 0,
                to: 10,
            },
            {
                from: 10,
                to: 20,

            }, {
                from: 20,
                to: 30,
            },
            {
                from: 30,
                to: 33,
            },
            {
                from: 33.1,
                to: 40,
            },
            {
                from: 40,
                to: 50,
            },
            {
                from: 50,
                to: 60,

            }, {
                from: 60,
                to: 65.9,
            },
            {
                from: 66,
                to: 70,
            },
            {
                from: 70,
                to: 75,
            },
            {
                from: 75,
                to: 80,

            }, {
                from: 80,
                to: 85,

            },
            {
                from: 85,
                to: 90,

            },
            {
                from: 90,
                to: 95,

            },
            {
                from: 95,
                to: 100,
            },

            ]
        },
        series: [{
            nullColor: '#c9d1d9',
            //point: {
            //    events: {
            //        click: (e) => {
            //            this.getDataByCountryKey(e.point['hc-key'])
            //        }
            //    }
            //},
            type: "map",
            name: "Campaign Implementation",
            states: {
                hover: {
                    enabled: false,
                }
            },
            tooltip: {
                valueDecimals: 1,
                valueSuffix: '%'
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },
            allAreas: false,
            data: this.mapData
        }]

    };


}
