import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-staticnav',
  templateUrl: './staticnav.component.html',
  styleUrls: ['./staticnav.component.css']
})
export class StaticnavComponent implements OnInit {

    selectedView: string; 
    constructor(private router: Router) {

        const localStorageValue = localStorage.getItem('selectedView');
        if (!localStorageValue) {
            // Set default value to "Rep" on first load
            this.selectedView = 'Rep';
            // Store default value in local storage
            localStorage.setItem('selectedView', this.selectedView);
        } else {
            // Retrieve value from local storage if exists
            this.selectedView = localStorageValue;
        }
}
    userProfileId: any = window.localStorage.getItem("UserProfileId");


    ngOnInit() {
       
 
  }


    clickOnLogo(){
        this.router.navigate(["/staticdashboard/OA"]);
}
    logout() {
        localStorage.removeItem('selectedView');
        this.router.navigate(["/login"]);
    }
    onSelectView(): void {
        // Update selected view
        localStorage.setItem('selectedView', this.selectedView);
        // Add additional logic here if needed
        window.location.reload();
    }
}
