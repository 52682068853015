import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");

@Component({
    selector: 'app-demoworldmap',
    templateUrl: './demoworldmap.component.html',
    styleUrls: ['./demoworldmap.component.css']
})
export class DemoworldmapComponent implements OnInit {

    constructor(private route: Router) { }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    updateFlag = false;

    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
        },

        colors: ['#F1433F', '#F7BB00', '#F7F700', '#00b050',],

        title: {
            text: ""
        },

        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {

            dataClassColor: 'category',
            dataClasses: [{

                from: 0.0,
                to: 1.5,
                name: 'Not Observed (0.0 - 1.5)'
            }, {
                from: 1.5,
                to: 2.5,
                name: 'Foundational (1.5 - 2.5)'
            }, {
                from: 2.5,
                to: 3.5,
                name: 'Developing (2.5 - 3.5)'
            }, {
                from: 3.5,
                to: 5.0,
                name: 'Accomplished (>3.5)'
            }
            ]
        },
        series: [{
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        this.getDataByCountryKey(e.point['hc-key'])
                    }
                }
            },
            type: "map",
            name: "Random Data",
            states: {
                hover: {
                    color: "#BADA55"
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },

            allAreas: true,
            data: [
                ["us", 2.1],
                ['gb', 2.6],
                ['ca', 2.2],
                ['au', 3.5],
                ['br', 1.8],
                ['fr', 1.8],
                ['es', 1.7],
                ['de', 2.3],
                ['it', 1.9],
                ['no', 2.7],
                ['dk', 2.8],
                ['fi', 2.9],
                ['se', 2.7],
                ['ar', 2.0],
                ['cl', 1.8],
                ['mx', 2.4],
                ['nz', 3.6],
                ['in', 2.2],
                ['za', 1.3],
                ['vn', 1.4]
            ]
        }]

    };



    getDataByCountryKey(ckey: any) {
        console.log('CountryKey:' + ckey);
        this.route.navigate(['/demo/demohome']);
    }




    ngOnInit() {
        Highcharts.chart('WProgSSkills', this.optinoin);
        Highcharts.chart('WProgCImplem', this.optinoins);
    }

    public optinoin: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Selling Skills',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '13px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },
        yAxis: {
            min: 0,
            max: 4,
            tickInterval: 1,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            reversedStacks: false
        },
        tooltip: {
            valueDecimals: 1
        },
        series: [{
            name: 'Selling Skills',
            color: '#0abfb2',
            /*data: data*/
            data: [1, 2, 3, 1, 2, 1, 4, 1, 2.5, 1, 2.5,]

        }],

    };
    public optinoins: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Campaign Implementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '13px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%',
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            reversedStacks: false
        },
        tooltip: {
            valueDecimals: 1
        },
        series: [{
            name: 'Campaign Implementation',
            color: '#0abfb2',
            /*data: data*/
            data: [20, 40, 60, 60, 50, 40, 40, 70, 90, 80]
        }]

    };

}
