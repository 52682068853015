import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DashboardService } from '../../services/dashboard.service';

@Component({
    selector: 'app-shared-top',
    templateUrl: './shared-top.component.html',
    styleUrls: ['./shared-top.component.css']
})
export class SharedTopComponent implements OnInit {

    constructor(
        private router: Router,
        private dashboardService: DashboardService
    ) { }

    ngOnInit() {
        this.getLanguageStaticText();

        $('.rep-template').hide();
        if (localStorage.getItem('mapdrill') == '1') {
            $('.rep-template').show();
        }
        else if (localStorage.getItem('mapdrill') == '0') {
            $('.rep-template').hide();
        }

        if (window.localStorage.getItem("ClientLogo") != null) {
            this.clientLogo = "ClientLogo/" + window.localStorage.getItem("ClientLogo");
        }

        this.getDataList();
        this.getCumulativeCalls();
    }
    //---NEw Code
    userId: any = window.localStorage.getItem("UserId");
    userProfileId: any = window.localStorage.getItem("UserProfileId");

    @Output() productEvent = new EventEmitter<number>();
    @Output() franchiseEvent = new EventEmitter<number>();
    @Input() TotalNoOfCalls: any;
    @Input() ClientLogo: any;

    productList: any = [];
    franchiseList: any = [];
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    clientLogo: string = "";
    totalCalls: number = 0;
    totalCumulativeCalls: number = 0;

    //selectedLanguage: string = "En";
    staticTextData: any;

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
        }, error => console.error(error));
    }

    ngOnChanges() {
        this.totalCalls = 0;
        if (this.TotalNoOfCalls != undefined && this.ClientLogo != undefined && this.ClientLogo != "") {
            //alert(this.TotalNoOfCalls);
            this.totalCalls = this.TotalNoOfCalls;
            this.clientLogo = "ClientLogo/" + this.ClientLogo;
        }
    }

    getDataList() {
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage"),
            UserId: parseInt(this.userId)
        }
        this.dashboardService.getDataList(objParam).subscribe((result: any) => {

            this.productList = result.productList;
            this.franchiseList = result.franchiseList;

            if (this.productList.length == 2) {
                this.productList = result.productList.filter(x => x.productId != 0);
            }

            if (this.franchiseList.length == 2) {
                this.franchiseList = result.franchiseList.filter(x => x.franchiseId != 0);
            }

            if (this.productList.length > 0) {
                this.selectedProductId = this.productList[0].productId;
                if (localStorage.getItem('SelectedProductId') != null) {
                    var checkProductExists = this.productList.filter(x => x.productId == localStorage.getItem('SelectedProductId'));
                    this.selectedProductId = checkProductExists.length > 0 ? checkProductExists[0].productId : this.selectedProductId;
                }
                window.localStorage.setItem("SelectedProductId", this.selectedProductId.toString());

                //calling it by default to initialize and sync productid on all other landing page in terms of single product.
                if (this.productList.length == 1) {
                    this.onSelectProduct();
                }
            }
            if (this.franchiseList.length > 0) {
                this.selectedFranchiseId = this.franchiseList[0].franchiseId;
                if (localStorage.getItem('SelectedFranchiseId') != null) {
                    var checkFranchiseExists = this.franchiseList.filter(x => x.franchiseId == localStorage.getItem('SelectedFranchiseId'));
                    this.selectedFranchiseId = checkFranchiseExists.length > 0 ? checkFranchiseExists[0].franchiseId : this.selectedFranchiseId;
                }
                window.localStorage.setItem("SelectedFranchiseId", this.selectedFranchiseId.toString());
            }
        },
            error => console.error(error));
    }

    //this is called when franchise will be changed and also trigger an event to its parent class.
    onSelectFranchise() {
        window.localStorage.setItem("SelectedFranchiseId", this.selectedFranchiseId.toString());
        this.franchiseEvent.next(this.selectedFranchiseId);
    }

    //this is called when product will be changed and also trigger an event to its parent class.
    onSelectProduct() {
        window.localStorage.setItem("SelectedProductId", this.selectedProductId.toString());
        this.productEvent.next(this.selectedProductId);
    }

    getCumulativeCalls() {
        var objParam = {
            UserId: parseInt(this.userId)
        }
        this.dashboardService.getCumulativeCalls(objParam).subscribe((result: any) => {
            this.totalCumulativeCalls = result.totalCumulativeCalls;
        },
            error => console.error(error));
    }
}
