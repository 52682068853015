import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { DemohomeComponent } from './demohome/demohome.component';
import { DemoprofilepageComponent } from './demoprofilepage/demoprofilepage.component';
import { DemoworldmapComponent } from './demoworldmap/demoworldmap.component';


const routes: Routes = [
    { path: 'demo/demohome', component: DemohomeComponent, canActivate: [AuthGuardService] },
    { path: 'demo/demoppage', component: DemoprofilepageComponent, canActivate: [AuthGuardService] },
    { path: 'demo/demowmap', component: DemoworldmapComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DemoRoutingModule { }
