import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { UserService } from '../../services/user.service';
import { DashboardService } from '../../services/dashboard.service';
import { ImpactFactorElement } from '../../models/dashboard/dashboard.model';

@Component({
    selector: 'app-outcome-analysis',
    templateUrl: './outcome-analysis.component.html',
    styleUrls: ['./outcome-analysis.component.css']
})
export class OutcomeAnalysisComponent implements OnInit {


    constructor(
        private route: Router,
        private userService: UserService,
        private dashboardService: DashboardService
    ) { }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";

    updateFlag = false;

    value: number[] = [10, 90];
    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }

        if (value >= 1) {
            return Math.round(value / 1) + '';
        }

        return value;
    }

    @ViewChild('table', { static: false }) table: MatTable<ImpactFactorElement>;
    displayedColumns: string[] = ['ElementName', 'Score', 'QTR'];
    dataSource;
    dropTable(event: CdkDragDrop<ImpactFactorElement[]>) {
        const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
        moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
        this.table.renderRows();
    }

    clientName: string = "";

    ngOnInit() {
        this.getLanguageStaticText();

        $('.uk-map').hide();
        if (localStorage.getItem('mapdrill') == '1') {
            $('.uk-map').show();
            $('.w-map_1').hide();
        }
        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        this.selectedClientId = 0;
        this.selectedImpactFactorLevelValue = 4;
        this.getClientWiseOutcomeData(0);
    }

    //------------New Work--------------
    userId: any = window.localStorage.getItem("UserId");
    userProfileId: any = window.localStorage.getItem("UserProfileId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedWeekDay: number = 0;
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;

    selectedClientId: number = 0;
    selectedImpactFactorLevelValue: number = 4;

    clientCountryDataList: any = [];
    mapData: any = [];
    dataRangeValue: number = 20;
    currentPage: string = "OA";

    clientRegionDataList: any = [];
    clientRegionData: any = [];
    clientRegionDataToFilter: any = [];
    //selectedLanguage: string = "En";
    staticTextData: any;

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
        }, error => console.error(error));
    }

    getOutcomeImpactFactorData() {
        $('.loder-outer').show();
        var objParam = {
            UserId: parseInt(this.userId),
            ClientId: this.selectedClientId,
            LevelValue: this.selectedImpactFactorLevelValue,
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getOutcomeImpactFactorData(objParam).subscribe((result: any) => {
            this.dataSource = result;
            $('.loder-outer').hide();
        }, error => console.error(error));
    }

    changeImpactFactorView(value) {
        this.selectedImpactFactorLevelValue = value;
        this.getOutcomeImpactFactorData();
    }

    getClientWiseOutcomeData(valueToCheck) {
        this.dataRangeValue = 20;
        var objParam = {
            UserId: parseInt(this.userId),
            WeekDay: this.selectedWeekDay + 1,
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId
        }
        this.dashboardService.getClientWiseOutcomeData(objParam).subscribe((result: any) => {

            this.clientCountryDataList = result;
            //this.showDataByLabel(0);
            $('#positiveOutcome').prop('checked', true);

            if (this.userProfileId == '7' && this.clientCountryDataList.length > 0) {
                localStorage.setItem('CountryKey', this.clientCountryDataList[0].countryCode);
                localStorage.setItem('SelectedClientId', this.clientCountryDataList[0].clientId);
                this.getDataAndFillMap(valueToCheck);
            }
            else {
                this.getDataAndFillMap(valueToCheck);
            }
        },
            error => console.error(error));
    }

    getDataAndFillMap(valueToCheck) {

        //call client region method.
        if (localStorage.getItem('CountryKey') != null) {
            this.selectedClientId = this.clientCountryDataList.find(x => x.countryCode == localStorage.getItem('CountryKey')).clientId;
            this.getClientOutcomeDataByRegion();
            if (valueToCheck == 0) {
                this.getOutcomeImpactFactorData();
            }
        }
        else {
            if (valueToCheck == 0) {
                this.getOutcomeImpactFactorData();
            }
            this.showDataByLabel(1);
        }
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getClientWiseOutcomeData(1);
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getClientWiseOutcomeData(1);
    }

    //this is called when week slider will be changed.
    onWeekChange(event) {
        this.selectedWeekDay = event.value;
        this.getClientWiseOutcomeData(1);
    }

    showDataByLabel(type) {
        this.totalNoOfCalls = 0;
        this.mapData = [];
        if (this.clientCountryDataList.length > 0) {
            this.clientLogo = this.clientCountryDataList[0].clientLogo;
            window.localStorage.setItem("ClientLogo", this.clientLogo);
            this.clientCountryDataList.forEach(obj => {
                var data = [obj.countryCode, type == 0 ? obj.level4Score : obj.level3Score];
                this.mapData.push(data);

                //this will help to pass the data from parent to its child class.
                this.totalNoOfCalls = this.totalNoOfCalls + obj.totalCalls;
            });
            var filterdData = this.mapData.filter(x => x[1] >= this.dataRangeValue);
            this.chartOptions.series[0] = {
                type: 'map',
                data: filterdData,
                name: this.staticTextData.outcomeAgreed
            }
        }
        this.updateFlag = true;

        //this code for region map
        this.clientRegionData = [];
        this.clientRegionDataToFilter = [];
        if (localStorage.getItem('mapdrill') == '1') {
            if (this.clientRegionDataList.length > 0) {
                this.clientRegionDataList.forEach(objRegion => {
                    this.clientRegionData.push({ regionName: objRegion.regionName, score: (type == 0 ? objRegion.level4Score : objRegion.level3Score) });
                });
                this.clientRegionDataToFilter = this.clientRegionData;
                this.clientRegionData = this.clientRegionDataToFilter.filter(x => x.score >= this.dataRangeValue);
            }
        }
    }

    onRangeChange(event) {
        var filterdData = this.mapData.filter(x => x[1] >= event.value);
        this.dataRangeValue = event.value;
        this.chartOptions.series[0] = {
            type: 'map',
            data: filterdData,
            name: this.staticTextData.outcomeAgreed
        }
        this.updateFlag = true;

        //this block of code help to filter data by data range.
        this.clientRegionData = this.clientRegionDataToFilter.filter(x => x.score >= event.value);
    }

    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
        },
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        series: [{
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        this.getDataByCountryKey(e.point['hc-key'])
                    }
                }
            },
            color: '#00b050',
            type: "map",
            name: "Outcomes agreed",
            states: {
                hover: {
                    color: "#BADA55"
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },
            allAreas: true,
            data: this.mapData
        }]
    };

    getDataByCountryKey(ckey: any) {
        this.selectedClientId = this.clientCountryDataList.find(x => x.countryCode == ckey).clientId;
        localStorage.setItem('CountryKey', ckey);
        localStorage.setItem('SelectedClientId', this.clientCountryDataList.find(x => x.countryCode == ckey).clientId);
        localStorage.setItem('mapdrill', '1');
        this.getOutcomeImpactFactorData();
        this.getClientOutcomeDataByRegion();
        if (ckey == 'gb') {
            $('.uk-map').show();
            $('.w-map_1').hide();
            $('.rep-template').show();
            //$('.g2').show();
            //$('.g1').hide();
        }
    }

    getClientOutcomeDataByRegion() {
        this.clientRegionData = [];
        var objParam = {
            UserId: parseInt(this.userId),
            WeekDay: this.selectedWeekDay + 1,
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ClientId: this.selectedClientId
        }
        this.dashboardService.getClientOutcomeDataByRegion(objParam).subscribe((result: any) => {
            this.clientRegionDataList = result;
            this.showDataByLabel(1);
        },
            error => console.error(error));
    }

}
