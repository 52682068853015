import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    constructor(
        private accountService: AccountService
    ) { }

    forgotPasswordForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });
    emailAddress: string;
    isEmailSent: boolean;
    emailText: string;

    ngOnInit() {
        this.emailAddress = "";
        this.isEmailSent = false;
        this.emailText = "";
    }

    Submit(data) {
        this.accountService.forgotPassword(data).subscribe((result: any) => {
            if (result.code == 200) {
                this.isEmailSent = true;
                this.emailAddress = "";
            }
            else {
                this.isEmailSent = false;
            }
            this.forgotPasswordForm.reset();
            this.emailText = result.description;
        });
    }
}
