import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CMSRoutingModule } from './cms-routing.module';
import { ClientManagementComponent } from './client-management/client-management.component';
import { UserManagementComponent } from './user-management/user-management.component';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { GeographyComponent } from './geography/geography.component';
import { ClientcampaignComponent } from './clientcampaign/clientcampaign.component'
@NgModule({
    declarations: [ClientManagementComponent, UserManagementComponent, GeographyComponent, ClientcampaignComponent],
    imports: [
        CommonModule,
        CMSRoutingModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
    ],
    exports: [
        ClientManagementComponent,
        UserManagementComponent,

    ]
})
export class CMSModule { }
