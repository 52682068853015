import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { UserService } from '../../services/user.service';
import { DashboardService } from '../../services/dashboard.service';
import { ImpactFactorElement } from '../../models/dashboard/dashboard.model';

@Component({
    selector: 'app-outcome-analysis',
    templateUrl: './outcome-analysis.component.html',
    styleUrls: ['./outcome-analysis.component.css']
})
export class OutcomeAnalysisComponent implements OnInit {
    selectedView: string;

    constructor(
        private route: Router,
        private userService: UserService,
        private dashboardService: DashboardService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
    }
    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    aboveCountry: number = 20;
    updateFlag = false;
    tottalcalls: number
    value: number[] = [10, 90];
    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }

        if (value >= 1) {
            return ' - ' + Math.round(value / 1);
        }

        return value;
    }
    formatLabel1(value: number | null) {
        if (!value) {
            return 0;
        }

        if (value >= 1) {
            return Math.round(value / 1);
        }

        return value;
    }

    @ViewChild('table', { static: false }) table: MatTable<ImpactFactorElement>;
    displayedColumns: string[] = ['ElementName', 'Score', 'QTR'];
    dataSource = []
    //dropTable(event: CdkDragDrop<ImpactFactorElement[]>) {
    //    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    //    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    //    this.table.renderRows();
    //}

    /*    clientName: string = "";*/

    ngOnInit() {
        this.getLanguageStaticText();

        //$('.uk-map').hide();
        //if (localStorage.getItem('mapdrill') == '1') {
        //    $('.uk-map').show();
        //    ;
        //}
        //if (localStorage.getItem('UserClientDomain') != null) {
        //    this.clientName = localStorage.getItem('UserClientDomain');
        //}
        //this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        //this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        /*        this.selectedClientId = 0;*/
        this.selectedImpactFactorLevelValue = 4;
        this.getOutcomeImpactFactorData();
        this.selectedViewtype = 'MONTH';

        /*this.getClientWiseOutcomeData(0);*/
    }

    //------------New Work--------------
    //userId: any = window.localStorage.getItem("UserId");
    //userProfileId: any = window.localStorage.getItem("UserProfileId");

    totalNoOfCalls: any;
    //clientLogo: string = "";
    selectedWeekDay: number = 0;
    //selectedProductId: number = 0;
    //selectedFranchiseId: number = 0;

    selectedClientId: number = 0;
    selectedImpactFactorLevelValue: number = 4;
    labelValue: number = 1;
    clientCountryDataList: any = [];
    mapData: any = [];
    dataRangeValue: number = 20;
    currentPage: string = "OA";
    isMonthlySelected: boolean = true
    selectedViewtype = 'MONTH';
    Slidervalue: number = 0;

    staticTextData: any;

    getLanguageStaticText() {
        var objParam = {
            /* UserId: parseInt(this.userId),*/
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.showMapData();
        }, error => console.error(error));
    }
    changeViewType(view) {
        this.selectedViewtype = view;
        this.Slidervalue = 0
        if (this.selectedViewtype == 'WEEK') {
            this.totalNoOfCalls = 20
            if (this.labelValue === 0) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 15], ['fr', 16], ['de', 17.2], ['jp', 18.3], ['us', 16.1], /*['br', 18.1], ['au',18.1]*/],
                    name: 'Outcome volunteered'
                }
                this.updateFlag = true;
                ;
            }
            if (this.labelValue === 1) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 20], ['fr', 19], ['de', 21.4], ['jp', 19.2], ['us', 24.4], /*['br', 19.4], ['au', 19.0]*/],
                    name: 'Positive outcome'
                }
                this.updateFlag = true;

            }
        }
        if (this.selectedViewtype == 'MONTH') {
            this.totalNoOfCalls = 84
            if (this.labelValue === 0) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 17.5], ['fr', 18.1], ['de', 18.3], ['jp', 18.5], ['us', 17.3], /*['br', 18.7], ['au', 18.7]*/],
                    name: 'Outcome volunteered'
                }
                this.updateFlag = true;

            }
            if (this.labelValue === 1) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 20.8], ['fr', 21.0], ['de', 21.8], ['jp', 20.1], ['us', 25.1], /*['br', 20.2], ['au', 20.1]*/],
                    name: 'Positive outcome'
                }
                this.updateFlag = true;

            }

        }
    }
    getOutcomeImpactFactorData() {
        $('.loder-outer').show();
        this.dataSource = [{
            "elementName": "Questioning",
            "score": 1.6,
            "qtrScore": 1.6,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/SE"
        }, {
            "elementName": "HCP Share of Voice",
            "score": 1.4,
            "qtrScore": 1.4,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/CC"
        },
        {
            "elementName": "Patient 1",
            "score": 1.3,
            "qtrScore": 1.3,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/CE"
        },
        {
            "elementName": "Opening",
            "score": 1.3,
            "qtrScore": 1.3,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/SE"
        },
        {
            "elementName": "Message 1",
            "score": 1.2,
            "qtrScore": 1.2,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/CE"
        }, {
            "elementName": "Message 2",
            "score": 1.2,
            "qtrScore": 1.2,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/CE"
        }, {
            "elementName": "Presenting",
            "score": 1.1,
            "qtrScore": 1.1,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/SE"
        },
        {
            "elementName": "Closing",
            "score": 1.1,
            "qtrScore": 1.1,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/SE"
        }, {
            "elementName": "Message 3",
            "score": 1.0,
            "qtrScore": 1.0,
            "ctaElement": "Patient Id",
            "qtrCTAElement": "",
            "redirectLink": ""
        }, {
            "elementName": "Length of call",
            "score": 1.0,
            "qtrScore": 1.0,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboarduk/CC"
        },
        {

            "elementName": "Most common CTA",
            "score": -1,
            "qtrScore": 0,
            "ctaElement": "Advocacy",
            "qtrCTAElement": "",
            "redirectLink": ""

        },



        ];
        if (this.selectedView === "Msl") {
            this.dataSource = this.dataSource.map(item => {
                if (item.elementName === "Opening") {
                    return { ...item, elementName: "Engage" };
                }
                if (item.elementName === "Questioning") {
                    return { ...item, elementName: "Explore" };
                }
                if (item.elementName === "Presenting") {
                    return { ...item, elementName: "Exchange" };
                }
                if (item.elementName === "Closing") {
                    return { ...item, elementName: "Evolve" };
                }
                if (item.elementName === "Most common CTA") {
                    return { ...item, elementName: "Most common next steps" };
                }
                if (item.elementName === "Length of call") {
                    return { ...item, elementName: "Length of interaction" };
                }
                // Add more conditions for other elements if needed
                return item;
            });
        }
        $('.loder-outer').hide();

    }

    changeImpactFactorView(value) {
        this.selectedImpactFactorLevelValue = value;
        if (this.selectedImpactFactorLevelValue === 4) {
            this.dataSource = [{
                "elementName": "Questioning",
                "score": 1.6,
                "qtrScore": 1.6,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "HCP Share of Voice",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            },
            {
                "elementName": "Patient 1",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            },
            {
                "elementName": "Opening",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Message 1",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Message 2",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Presenting",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Closing",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Message 3",
                "score": 1.0,
                "qtrScore": 1.0,
                "ctaElement": "Patient Id",
                "qtrCTAElement": "",
                "redirectLink": ""
            }, {
                "elementName": "Length of call",
                "score": 1.0,
                "qtrScore": 1.0,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            },
            {

                "elementName": "Most common CTA",
                "score": -1,
                "qtrScore": 0,
                "ctaElement": "Advocacy",
                "qtrCTAElement": "",
                "redirectLink": ""

            },



            ];
        }
        if (this.selectedImpactFactorLevelValue === 3) {
            this.dataSource = [{
                "elementName": "Questioning",
                "score": 1.6,
                "qtrScore": 1.6,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "HCP Share of Voice",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            },
            {
                "elementName": "Patient 1",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            },
            {
                "elementName": "Opening",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Message 1",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Message 2",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Presenting",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Closing",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Message 3",
                "score": 1.0,
                "qtrScore": 1.0,
                "ctaElement": "Patient Id",
                "qtrCTAElement": "",
                "redirectLink": ""
            }, {
                "elementName": "Length of call",
                "score": 1.0,
                "qtrScore": 1.0,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            },
            {

                "elementName": "Most common CTA",
                "score": -1,
                "qtrScore": 0,
                "ctaElement": "Advocacy",
                "qtrCTAElement": "",
                "redirectLink": ""

            },
            ];
        }
        if (this.selectedView === "Msl") {
            this.dataSource = this.dataSource.map(item => {
                if (item.elementName === "Opening") {
                    return { ...item, elementName: "Engage" };
                }
                if (item.elementName === "Questioning") {
                    return { ...item, elementName: "Explore" };
                }
                if (item.elementName === "Presenting") {
                    return { ...item, elementName: "Exchange" };
                }
                if (item.elementName === "Closing") {
                    return { ...item, elementName: "Evolve" };
                }
                if (item.elementName === "Most common CTA") {
                    return { ...item, elementName: "Most common next steps" };
                }
                if (item.elementName === "Length of call") {
                    return { ...item, elementName: "Length of interaction" };
                }
                // Add more conditions for other elements if needed
                return item;
            });
        }

    }
    showMapData() {
        this.mapData = [];
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['gb', 20.8], ['fr', 21.0], ['de', 21.8], ['jp', 20.1], ['us', 25.1], /*['br', 20.2], ['au', 20.1]*/],
            name: 'Positive outcome',

        }
        this.totalNoOfCalls = 84
        this.updateFlag = true;

    }

    showDatalbl(value) {
        this.labelValue = value;
        this.Slidervalue = 0;
        if (this.labelValue === 0) {
            this.selectedWeekDay = 0
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', 17.5], ['fr', 18.1], ['de', 18.3], ['jp', 18.5], ['us', 17.3], /*['br', 18.7], ['au', 18.7]*/],
                name: 'Outcome volunteered'
            }
            this.updateFlag = true;
        }
        if (this.labelValue === 1) {
            this.selectedWeekDay = 0
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', 20.8], ['fr', 21.0], ['de', 21.8], ['jp', 20.1], ['us', 25.1],/* ['br', 20.2], ['au', 20.1]*/],
                name: 'Positive outcome'
            }
            this.updateFlag = true;

        }
    }

    onRangeChange(event) {
        //var filterdData = this.mapData.filter(x => x[1] >= event.value);
        //this.dataRangeValue = event.value;
        //this.chartOptions.series[0] = {
        //    type: 'map',
        //    data: filterdData,
        //    name: this.staticTextData.outcomeAgreed
        //}
        //this.updateFlag = true;

        //this block of code help to filter data by data range.
        /*this.clientRegionData = this.clientRegionDataToFilter.filter(x => x.score >= event.value);*/
    }

    StaticonWeekChange(event) {
        this.selectedWeekDay = event.value
        if (this.selectedViewtype == 'WEEK') {
            if (this.labelValue === 1) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 20], ['fr', 19], ['de', 21.4], ['jp', 19.2], ['us', 24.4],/* ['br', 19.4], ['au', 19.0]*/],

                    }
                    this.updateFlag = true;


                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 19],['fr', 21.4], ['de', 20.2], ['jp', 20.2], ['us', 26.2], /*['br', 20.2], ['au', 21.2]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 22
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 25],['fr', 22.0], ['de', 23.0], ['jp', 19.9], ['us', 25.2], /*['br', 21.1], ['au', 19.1]*/]

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 3) {
                    this.totalNoOfCalls = 22
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 19], ['fr', 23.6], ['de', 22.5], ['jp', 21.1], ['us', 24.5], /*['br', 21.1], ['au', 21.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 4) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 38], ['fr', 35.0], ['de', 31.0], ['jp', 28.2], ['us', 31.0], /*['br', 26.1], ['au', 26.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 5) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 38],['fr', 34.0], ['de', 34.0], ['jp', 29.1], ['us', 31.4], /*['br', 29.1], ['au', 29.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 6) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 38], ['fr', 33.0], ['de', 36.0], ['jp', 32.0], ['us', 32.0], /*['br', 30.4], ['au', 30.4]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 7) {
                    this.totalNoOfCalls = 15
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 29],['fr', 30.0], ['de', 24.0], ['jp', 23.4], ['us', 24.0], /*['br', 23.4], ['au', 23.4]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 8) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 29], ['fr', 29.0], ['de', 23.0], ['jp', 24.4], ['us', 24.5], /*['br', 24.4], ['au', 24.4]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 9) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 33], ['fr', 33.0], ['de', 33.0], ['jp', 28.3], ['us', 33.0], /*['br', 28.3], ['au', 28.3]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 10) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 33], ['fr', 33.0], ['de', 30.0], ['jp', 29.0], ['us', 30.0], /*['br', 28.4], ['au', 28.4]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 11) {
                    this.totalNoOfCalls = 4
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 33], ['fr', 33.0], ['de', 25.0], ['jp', 26.1], ['us', 28.4], /*['br', 25.2], ['au', 25.2]*/
                        ],

                    }
                    this.updateFlag = true;

                }

            }
            else if (this.labelValue === 0) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 15],['fr', 16], ['de', 17.2], ['jp', 18.3], ['us', 16.1], /*['br', 18.1], ['au', 18.1]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 15],['fr', 15], ['de', 14.8], ['jp', 16.4], ['us', 14.8], /*['br', 17.3], ['au', 17.3]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 20], , ['fr', 20], ['de', 20.2], ['jp', 20.0], ['us', 19.0],/* ['br', 20.0], ['au', 20.0]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 3) {
                    this.totalNoOfCalls = 19
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 20], ['fr', 21.2], ['de', 21.2], ['jp', 19.3], ['us', 19.1], /*['br', 19.3], ['au', 19.3]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 4) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 14], ['fr', 14.0], ['de', 12.1], ['jp', 12.1], ['us', 12.1], /*['br', 12.1], ['au', 12.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 5) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 14], ['fr', 14.0], ['de', 14.0], ['jp', 14.0], ['us', 14.0],/* ['br', 14.0], ['au', 14.0]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 6) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 10], ['fr', 10.0], ['de', 11.0], ['jp', 12.5], ['us', 11.8], /*['br', 12.5], ['au', 12.5]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 7) {
                    this.totalNoOfCalls = 15
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 10], ['fr', 10.0], ['de', 10.0], ['jp', 11.3], ['us', 14.0], /*['br', 11.3], ['au', 11.3]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 8) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 14], ['fr', 14], ['de', 13.3], ['jp', 13.3], ['us', 13.3], /*['br', 13.3], ['au', 13.3]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 9) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 10], ['fr', 10], ['de', 10.0], ['jp', 10.0], ['us', 16.0],/* ['br', 11.3], ['au', 11.3]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 10) {
                    this.totalNoOfCalls = 20
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 10], ['fr', 10], ['de', 11.2], ['jp', 11.2], ['us', 14.8], /*['br', 10.5], ['au', 10.5]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 11) {
                    this.totalNoOfCalls = 4
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 14], ['fr', 14], ['de', 14.2], ['jp', 14.0], ['us', 19.6], /*['br', 13.1], ['au', 13.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                //else if (event.value === 12) {
                //    this.totalNoOfCalls = 20
                //    this.chartOptions.series[0] = {
                //        type: 'map',
                //        data: [['gb', 2.5]
                //        ],

                //    }
                //    this.updateFlag = true;
                //}
                this.updateFlag = true;
            }
        }


        if (this.selectedViewtype == 'MONTH') {
            if (this.labelValue === 1) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 84
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 20.8],['fr', 21.0], ['de', 21.8], ['jp', 20.1], ['us', 25.1], /*['br', 20.2], ['au', 20.1]*/],

                    }
                    this.updateFlag = true;


                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 75
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 35.8], ['fr', 33.0], ['de', 31.3], ['jp', 28.2], ['us', 29.6], /*['br', 27.3], ['au', 27.3]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 64
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 32.0], ['fr', 32.0], ['de', 27.8], ['jp', 27.0], ['us', 29.0], /*['br', 26.6], ['au', 26.6]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 3) {
                    this.totalNoOfCalls = 93
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 30.0], ['fr', 30.0], ['de', 30.0], ['jp', 30.0], ['us', 28.1], /*['br', 30.5], ['au', 30.5]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 4) {
                    this.totalNoOfCalls = 77
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 18.8], ['fr', 18.8], ['de', 20.6], ['jp', 25.8], ['us', 22.7], /*['br', 26.8], ['au', 26.8]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 5) {
                    this.totalNoOfCalls = 108
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 34.0], ['fr', 34.0], ['de', 34.5], ['jp', 35.9], ['us', 31.9], /*['br', 35.0], ['au', 35.9]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else {
                    this.totalNoOfCalls = 0
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 0]
                        ],

                    }
                    this.updateFlag = true;
                }
            }
            if (this.labelValue === 0) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 84
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 17.5], ['fr', 18.1], ['de', 18.3], ['jp', 18.5], ['us', 17.3], /*['br', 18.7], ['au', 18.7]*/],

                    }
                    this.updateFlag = true;


                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 75
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 12.0], ['fr', 12.0], ['de', 11.8], ['jp', 12.5], ['us', 12.8], /*['br', 12.5], ['au', 12.5]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 64
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 12.0], ['fr', 12.0], ['de', 12.1], ['jp', 12.1], ['us', 15.9], /*['br', 12.1], ['au', 12.1]*/],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 3) {
                    this.totalNoOfCalls = 93
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 13.3], ['fr', 13.3], ['de', 13.3], ['jp', 14.1], ['us', 16.5], /*['br', 14.4], ['au', 14.1]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 4) {
                    this.totalNoOfCalls = 77
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 6.0], ['fr', 6.0], ['de', 7.7], ['jp', 9.1], ['us', 8.5], /*['br', 9.7], ['au', 9.7]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 5) {
                    this.totalNoOfCalls = 108
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 15.5], ['fr', 15.5], ['de', 15.5], ['jp', 15.5], ['us', 16.5], /*['br', 15.5], ['au', 15.5]*/
                        ],

                    }
                    this.updateFlag = true;

                }
                else {
                    this.totalNoOfCalls = 0
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 0]
                        ],

                    }
                    this.updateFlag = true;
                }
            }
        }
}
    chartOptions: Options = {
        chart: {
            map: worldMap,
            inverted: false,
            animation: false,
        },
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },

        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true
                }
            }
        },
        series: [{
            animation: false,
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        /* this.getDataByCountryKey(e.point['hc-key'])*/
                    }
                }
            },
            color: '#078000',
            type: "map",
            name: "Positive outcome",
            states: {
                hover: {
                    enabled: false,
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        if (this.point['hc-key'] === 'gb') {
                            return 'U.K....'
                        }
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                valueDecimals:1,
                valueSuffix: '%'
            },
            allAreas: true,
            data: this.mapData
        }]
    };

    //getDataByCountryKey(ckey: any) {
    //    this.selectedClientId = this.clientCountryDataList.find(x => x.countryCode == ckey).clientId;
    //    localStorage.setItem('CountryKey', ckey);
    //    localStorage.setItem('SelectedClientId', this.clientCountryDataList.find(x => x.countryCode == ckey).clientId);
    //    localStorage.setItem('mapdrill', '1');
    //    this.getOutcomeImpactFactorData();
    //    this.getClientOutcomeDataByRegion();
    //    if (ckey == 'gb') {
    //        $('.uk-map').show();
    //        ;
    //        $('.rep-template').show();
    //        //$('.g2').show();
    //        //$('.g1').hide();
    //    }
    //}

    //getClientOutcomeDataByRegion() {
    //    this.clientRegionData = [];
    //    var objParam = {
    //        UserId: parseInt(this.userId),
    //        WeekDay: this.selectedWeekDay + 1,
    //        ProductId: this.selectedProductId,
    //        FranchiseId: this.selectedFranchiseId,
    //        ClientId: this.selectedClientId
    //    }
    //    this.dashboardService.getClientOutcomeDataByRegion(objParam).subscribe((result: any) => {
    //        this.clientRegionDataList = result;
    //   /*     this.showDataByLabel(1);*/
    //    },
    //        error => console.error(error));
    //}
}
