import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    getMasterDataList() {
        return this.http.get('api/Admin/GetMasterDataList', { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpateCountry(params) {
        return this.http.post('api/Admin/AddUpdateCountry', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getRegionList(param) {
        return this.http.get('api/Admin/GetRegionList/' + param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpateRegion(params) {
        return this.http.post('api/Admin/AddUpdateRegion', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
