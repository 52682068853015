import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
declare var require: any;
import Drilldown from 'highcharts/modules/drilldown';
import { CoachService } from '../../services/coach.service';
import { error } from 'console';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client.service';
require('highcharts/highcharts-more')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Drilldown(Highcharts);

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    @ViewChild('ReportedScores', { static: true }) ReportedScores: ElementRef;
    @ViewChild('OverallAccuracy', { static: true }) OverallAccuracy: ElementRef;

    selectedLanguage: string = "En";
    selectedCallUniqueId: string = "";
    callUniqueIdToShow: string = "";
    callsList: any = [];
    clientList: any = [];
    callRepDetails: any = {};
    callTranscriptDetails: any = [];
    callElementList: any = [];
    callElementScore: any = [];
    callDeviationDiff: any = [];
    overallAccuracy: any = 0.0;
    viewAccuracy: boolean = true;
    productList: any = [];
    selectedClientId: number = 0;
    selectedProductId: number = 0;
    userDetailsList: any = [];
    isReportsButtonVisible = false;

    campaignTranscriptDetails: any = [];

    constructor(
        private coachService: CoachService,
        private clientService: ClientService
    ) { }

    ngOnInit() {
        //this.getAllCalls();
        this.getClientList();
        if (window.localStorage['UserProfileId'] == 5) {
            this.isReportsButtonVisible = true;
        }
        //this.getProductList(0);
    }


    getClientList() {
        var objParam = {
            UserId: parseInt(window.localStorage['UserId'])
        }
        this.clientService.getClientList().subscribe((result: any) => {
            if (window.localStorage['UserProfileId'] == 9) {
                this.coachService.getCoachUserDetails(objParam).subscribe((userResult: any) => {
                    this.userDetailsList = userResult;
                    this.clientList = result.filter(x => x.clientId == this.userDetailsList[0].clientId);
                    this.selectedClientId = this.clientList[0].clientId
                    this.getProductList(0, parseInt(window.localStorage['UserId']));
                },
                    error => console.error(error));

            }
            else {
                this.clientList = result;
                if (result.length > 0) {
                    this.selectedClientId = result[0].clientId;
                    this.getProductList(this.selectedClientId, 0);
                }
            }
        },
            error => console.error(error));
    }

    onSelectClient(clientId) {
        this.selectedClientId = clientId;
        this.getProductList(clientId, 0);
        //this.getAllCalls();
        this.afterClose();
    }

    getProductList(clientId, userId) {
        var objParam = {
            ClientId: clientId,
            UserId: userId
        }
        this.coachService.GetProductList(objParam).subscribe((result: any) => {
            this.productList = result;
            if (result.length > 0) {
                this.selectedProductId = result[0].productId;
                this.getAllCalls();
            }
        },
            error => console.error(error));
    }

    onSelectProduct(productId) {
        this.selectedProductId = productId;
        this.getAllCalls();
    }

    getAllCalls() {
        this.callsList = [];
        this.callRepDetails = {};
        this.callTranscriptDetails = [];
        this.callUniqueIdToShow = "";
        this.selectedCallUniqueId = "";

        this.campaignTranscriptDetails = [];

        var objparam = {
            LanguageCode: this.selectedLanguage,
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId
        }
        $('.loder-outer').show();
        this.coachService.GetCalls(objparam).subscribe((response: any) => {
            this.callsList = response;
            if (response.length > 0) {
                this.getCallTranscriptDetails(response[0].callUniqueId[0].callUniqueId, response[0].callUniqueId[0].overallAccuracy, 1);
            }
            $('.loder-outer').hide();
        }, error => console.error(error));
    }

    getCallTranscriptDetails(calluniqueId, overallAccuracy, callIndex) {
        this.callRepDetails = {};
        this.callTranscriptDetails = [];
        this.callUniqueIdToShow = "Call " + callIndex + "_Unique_Id_" + calluniqueId;
        this.selectedCallUniqueId = calluniqueId;
        this.viewAccuracy = true;
        var obj = {
            LanguageCode: this.selectedLanguage,
            CallUniqueId: calluniqueId
        };
        this.coachService.GetCallTranscriptDetails(obj).subscribe((response: any) => {
            this.callRepDetails = response.callRepDetails;
            this.callTranscriptDetails = response.callTranscriptDetails;

            //getting unique element id and returning data object of only sub chunks and main chunk(if main chunk has no sub chunk).
            var uniqueElementList = this.callTranscriptDetails.filter((item, i, arr) => arr.findIndex((t) => t.elementId === item.elementId) === i);

            uniqueElementList.forEach(objElement => {
                var elementCount = this.callTranscriptDetails.filter(x => x.elementId == objElement.elementId);
                if (elementCount.length > 1) {
                    var mainChunkObj = this.callTranscriptDetails.find(x => x.elementId == objElement.elementId && x.isMainChunk == true);
                    if (mainChunkObj != undefined) {
                        this.callTranscriptDetails = this.callTranscriptDetails.filter(function (obj) {
                            return obj.transcriptChunkId !== mainChunkObj.transcriptChunkId;
                        });
                    }
                }
            });
            //End

            //this is to show the coach score.
            if (overallAccuracy > 0) {
                var i = 1;
                this.callTranscriptDetails.forEach(obj => {
                    setTimeout(function () {
                        //$('#ibmScoreRadio' + obj.transcriptChunkId).click();
                        $('#box-' + obj.transcriptChunkId).click();
                        if (obj.coach_Score > 0) {
                            $('#radio-' + i + '-' + obj.transcriptChunkId).click();
                            $('.lbl' + obj.transcriptChunkId).css('background', 'red');
                        }
                        else {
                            $('.lbl' + obj.transcriptChunkId).css('background', '#00b050');
                        }
                        i = i + 1;
                    }, 200);
                });
            }
        }, error => console.error(error));

        $('.example-accordion-item-body').removeClass('call-active');
        $("#liCall" + calluniqueId).addClass('call-active');

        this.backScore();

        if (overallAccuracy == 0) {
            this.viewAccuracy = false;
        }

        //calling campaign method to get its chunks
        this.getCampaignTranscriptDetails(calluniqueId, overallAccuracy, callIndex);
    }

    getCampaignTranscriptDetails(calluniqueId, overallAccuracy, callIndex) {
        this.campaignTranscriptDetails = [];
        var obj = {
            LanguageCode: this.selectedLanguage,
            CallUniqueId: calluniqueId
        };
        this.coachService.GetCampaignTranscriptDetails(obj).subscribe((response: any) => {

            this.campaignTranscriptDetails = response.callTranscriptDetails;

            //getting unique element id and returning data object of only sub chunks and main chunk(if main chunk has no sub chunk).
            var uniqueElementList = this.campaignTranscriptDetails.filter((item, i, arr) => arr.findIndex((t) => t.elementId === item.elementId) === i);

            uniqueElementList.forEach(objElement => {
                var elementCount = this.campaignTranscriptDetails.filter(x => x.elementId == objElement.elementId);
                if (elementCount.length > 1) {
                    var mainChunkObj = this.campaignTranscriptDetails.find(x => x.elementId == objElement.elementId && x.isMainChunk == true);
                    if (mainChunkObj !== undefined) {
                        this.campaignTranscriptDetails = this.campaignTranscriptDetails.filter(function (obj) {
                            return obj.transcriptChunkId !== mainChunkObj.transcriptChunkId;
                        });
                    }
                }
            });
            //End

            //this is to show the coach score.
            var callCampaignSubmitted = this.campaignTranscriptDetails.filter(x => x.deviationDiff == 1);
            if (overallAccuracy > 0 && callCampaignSubmitted.length > 0) {
                var i = 1;
                this.campaignTranscriptDetails.forEach(obj => {
                    setTimeout(function () {
                        $('#camp-box-' + obj.transcriptChunkId).click();
                        if (obj.coach_Score > 0) {
                            $('#camp-radio-' + i + '-' + obj.transcriptChunkId).click();
                            $('.camp-lbl' + obj.transcriptChunkId).css('background', 'red');
                        }
                        else {
                            $('.camp-lbl' + obj.transcriptChunkId).css('background', '#00b050');
                        }
                        i = i + 1;
                    }, 200);
                });
            }
        }, error => console.error(error));
    }

    submit() {
        var objParam = {
            CoachDetails: {
                "UserId": parseInt(window.localStorage['UserId']),
                "ClientId": this.selectedClientId,
                "CallUniqueId": this.selectedCallUniqueId
            },
            CallTranscriptDetails: this.callTranscriptDetails
        }
        var radiolength = $('.cstm-radio2').not(':checked');
        if (radiolength.length) {
            Swal.fire({
                icon: 'info',
                title: 'Please verify all elements before submitting.',
            });
            var highlightUncheckd = $(radiolength).parent('.element_outer');
            $(highlightUncheckd).addClass('highlight-box');
            return;
        }

        this.coachService.AddCoachScore(objParam).subscribe((response: any) => {
            if (response == 1) {
                /*this.getAccuracyData();*/
                this.showCampaignchunks();
            }

        }, error => console.error(error));
    };

    showCampaignchunks() {
        $('.campaign_boxinner').removeClass('d-none')
        $('.score_boxinner').addClass('d-none');
        $('#btn_score').addClass('d-none');
        $('#btn_back_score').removeClass('d-none');
        $('#btn_final_score').removeClass('d-none');
        $('.camapign_heading').removeClass('d-none')
    }

    finalSubmit() {
        if (this.campaignTranscriptDetails.length == 0) {
            this.getAccuracyData();
            return false;
        }
        var Campradiolength = $('.cstm-radio3').not(':checked');
        if (Campradiolength.length) {
            Swal.fire({
                icon: 'info',
                title: 'Please verify all elements before submitting.',
            });
            var highlightUncheckd = $(Campradiolength).parent('.element_outer');
            $(highlightUncheckd).addClass('highlight-box');
            return;
        }
        this.coachService.AddCampaignCoachScore(this.campaignTranscriptDetails).subscribe((response: any) => {
            if (response == 1) {
                this.getAccuracyData();
            }
        }, error => console.error(error));
    };

    showAccuracyPage() {
        $("#imgcall").addClass("selected-img-filter");
        $("#imgibm").removeClass("selected-img-filter");
        $('#btn_score').addClass('d-none');
        this.getAccuracyData();
    }

    getAccuracyData() {
        this.callElementList = [];
        this.callElementScore = [];
        this.callDeviationDiff = [];

        var obj = {
            LanguageCode: this.selectedLanguage,
            CallUniqueId: this.selectedCallUniqueId
        };
        this.coachService.GetCallTranscriptDetails(obj).subscribe((response: any) => {
            if (response.callTranscriptDetails.length > 0) {
                var totalCount = 0;
                var sumOfDeviationDiff = 0;
                var finalCalls = [];
                //getting unique element id and returning data object of max coach score.
                var uniqueElementList = response.callTranscriptDetails.filter((item, i, arr) => arr.findIndex((t) => t.elementId === item.elementId) === i);

                uniqueElementList.forEach(objElement => {
                    var maxElementObj = response.callTranscriptDetails.filter(x => x.elementId == objElement.elementId).reduce((a, b) => a.coach_Score > b.coach_Score ? a : b);
                    finalCalls.push(maxElementObj);
                });
                //End

                finalCalls.forEach(obj => {
                    this.callElementList.push(obj.elementName);
                    this.callElementScore.push({ IBM_Score: obj.ibM_Score, Coach_Score: obj.coach_Score == 0 ? obj.ibM_Score : obj.coach_Score });
                    this.callDeviationDiff.push([obj.elementName, obj.coach_Score == 0 ? 100 : obj.deviationDiff]);
                    sumOfDeviationDiff = sumOfDeviationDiff + (obj.coach_Score == 0 ? 100 : obj.deviationDiff);
                    totalCount = totalCount + 1;
                });
            }
            this.overallAccuracy = sumOfDeviationDiff / totalCount;
            this.drawReportedScoreGraph(this.callElementList, this.callElementScore);
            this.drawOverallAccuracyGraph(this.overallAccuracy, this.callDeviationDiff);
            this.afterSubmit();
        }, error => console.error(error));
    }

    drawReportedScoreGraph(elementList, elementScore) {
        Highcharts.chart(this.ReportedScores.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Reported Scores & Accuracy',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                xAxis: {
                    categories: elementList,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 4,
                    tickInterval: 1,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'IBM Assessment',
                    color: '#4472c4',
                    data: elementScore.map(function (a) { return a.IBM_Score; })
                }, {
                    name: 'Correct Assessment',
                    color: '#00b050',
                    data: elementScore.map(function (a) { return a.Coach_Score; })
                }],
            })
        );
    }

    drawOverallAccuracyGraph(overallAccuracy, callDeviationDiff) {
        Highcharts.chart(this.OverallAccuracy.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: 'Overall Accuracy',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: true
                },
                legend: {
                    symbolRadius: 0,
                    align: 'right',
                    verticalAlign: 'bottom',
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Accuracy',
                    color: overallAccuracy >= 80 ? '#00b050' : 'red',
                    data: [{
                        name: 'Accuracy',
                        y: overallAccuracy,
                        drilldown: 'Accuracy'
                    }]
                }],
                drilldown: {
                    series: [{
                        stacking: null,
                        id: 'Accuracy',
                        data: callDeviationDiff,
                        zonesAxis: 'y',
                        zones: [{
                            value: 51,
                            color: 'red',
                        }, {
                            value: 101,
                            color: '#00b050',
                        }]
                    }]
                }
            })
        );
    }

    download() {
        //const blob = new Blob([this.callsList], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        var blob = new Blob([this.callsList], { type: "content-type" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xlsx`;
        link.click();

        //var obj = {
        //    LanguageCode: this.selectedLanguage
        //};
        //this.coachService.DownloadCoachReport(obj).subscribe((reponse: any) => {
        //    debugger;


        //}, error => console.error(error));
    }

    showNewScorePanel(elementName, value) {
        //if (value == 0) {
        //    $('#enterScore' + elementName).removeClass('d-none');
        //}
        //else {
        //    $('#enterScore' + elementName).addClass('d-none');
        //}
    }

    switchToggle(transcriptChunkId, i, score) {
        if (score == 0) {
            $('#radio-' + (i - 1) + '-' + transcriptChunkId).click();
            $('.lbl' + transcriptChunkId).css('background', '#00b050');
        }
        else {
            $('.lbl' + transcriptChunkId).css('background', 'red');
            $('#radio-' + i + '-' + transcriptChunkId).click();
            $('#box-' + transcriptChunkId).click();
            $('.unhilight' + transcriptChunkId).removeClass('highlight-box');
        }
        //$('#ibmScoreRadio' + transcriptChunkId).click();

    }

    verifyScored(transcriptChunkId) {
        $('#box-' + transcriptChunkId).click();
        $('.unhilight' + transcriptChunkId).removeClass('highlight-box')


    }

    campVerifyScored(transcriptChunkId) {
        $('#camp-box-' + transcriptChunkId).click();
        $('.camp-unhilight' + transcriptChunkId).removeClass('highlight-box');
    }

    afterSubmit() {
        $('.score_boxinner').addClass('d-none');
        $('.charts-box').removeClass('d-none');
        $('#btn_close').removeClass('d-none');
        $('#btn_back_score').addClass('d-none');
        $('#btn_final_score').addClass('d-none');
        $('.campaign_boxinner').addClass('d-none');
        $('.camapign_heading').addClass('d-none')
        this.viewAccuracy = true;
    }

    afterClose() {
        $('.score_boxinner').removeClass('d-none');
        $('.charts-box').addClass('d-none');
        $('#btn_score').removeClass('d-none');
        $('#btn_close').addClass('d-none');
        $('.camapign_heading').addClass('d-none')
        this.viewAccuracy = true;;
    };

    backScore() {
        $('.score_boxinner').removeClass('d-none');
        $('.charts-box').addClass('d-none');
        $('#btn_score').removeClass('d-none');
        $('#btn_back_score').addClass('d-none');
        $('#btn_final_score').addClass('d-none');
        $('#btn_close').addClass('d-none');
        $('.campaign_boxinner').addClass('d-none')
        $('.camapign_heading').addClass('d-none')
    }

    exportTableToExcel(tableID, filename = '') {
        var downloadLink;
        var dataType = 'application/vnd.ms-excel';
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

        // Specify file name
        filename = filename ? filename + '.xls' : 'excel_data.xls';

        // Create download link element
        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }
    }

    getAvgOfCallData() {
        $("#imgcall").removeClass("selected-img-filter");
        $("#imgibm").addClass("selected-img-filter");
        $('#btn_score').addClass('d-none');

        this.callElementList = [];
        this.callElementScore = [];
        this.callDeviationDiff = [];
        this.overallAccuracy = 0.0;
        var objParam = {
            CallUniqueId: this.selectedCallUniqueId,
            ClientId: this.selectedClientId,
            ProductId: this.selectedProductId,
            StartDate: '',
            EndDate: ''
        };

        this.coachService.GetCallsDeviationAvgData(objParam).subscribe((response: any) => {
            if (response.length > 0) {
                var elementData = response.filter(x => x.elementId != 0);
                elementData.forEach(obj => {
                    this.callDeviationDiff.push([obj.elementName, obj.deviationDiff]);
                });
                this.overallAccuracy = response.filter(x => x.elementId == 0)[0].deviationDiff;
            }
            this.drawOverallAccuracyGraph(this.overallAccuracy, this.callDeviationDiff);
        }, error => console.error(error));

        this.coachService.GetCallsScoreAvgData(objParam).subscribe((response: any) => {
            if (response.length > 0) {
                response.forEach(obj => {
                    this.callElementList.push(obj.elementName);
                    this.callElementScore.push({ IBM_Score: obj.ibmScoreAvg, Coach_Score: obj.coachScoreAvg });
                });
            }
            this.drawReportedScoreGraph(this.callElementList, this.callElementScore);
        }, error => console.error(error));

        this.afterSubmit();
    }

    campaignToggle(chunkId, value, i) {

        var indexValue = this.campaignTranscriptDetails.findIndex((obj => obj.transcriptChunkId == chunkId));
        if (value == 0) {
            this.campaignTranscriptDetails[indexValue].coach_Score = 1;
            $('.camp-lbl' + chunkId).css('background', 'red');
            $('#camp-radio-' + i + '-' + chunkId).click();
        }
        else {
            this.campaignTranscriptDetails[indexValue].coach_Score = 0;
            $('#camp-radio-' + (i - 1) + '-' + chunkId).click();
            $('.camp-lbl' + chunkId).css('background', '#00b050');
        }
        $('#camp-box-' + chunkId).click();
        $('.camp-unhilight' + chunkId).removeClass('highlight-box')
    }
}
