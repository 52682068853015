import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoachRoutingModule } from './coach-routing.module';
import { HomeComponent } from './home/home.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { CallanalysisComponent } from './callanalysis/callanalysis.component';
import { ReportsComponent } from './reports/reports.component';
@NgModule({
  declarations: [HomeComponent, CallanalysisComponent, ReportsComponent],
  imports: [
      CommonModule,
      CoachRoutingModule,
      CdkAccordionModule,
      MatExpansionModule,
      FormsModule,
      ReactiveFormsModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatListModule,
      MatRadioModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatNativeDateModule,
      MatTableModule
  ]
})
export class CoachModule { }
