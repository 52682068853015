import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import Swal from 'sweetalert2'
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { User } from '../../models/cms/user.model';
import { UserService } from '../../services/user.service';
@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent {

    addUserForm = new FormGroup({
        firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        userName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        password: new FormControl('', [Validators.required, Validators.minLength(2)]),
        profile: new FormControl('', Validators.required),
        client: new FormControl('', Validators.required),
        region: new FormControl('', Validators.required),
        franchise: new FormControl('', Validators.required),
        reportingManager: new FormControl('', null),
        associateProduct: new FormControl('', Validators.required),
        isActive: new FormControl()
    });

    @ViewChild('selectProduct', { static: true }) selectProduct: MatSelect;
    @ViewChild('selectManager', { static: true }) selectManager: MatSelect;
    displayedColumns = ['firstname', 'lastname', 'edit'];
    dataSource: any;
    objUser: User;
    usersList: any = {};
    profileList: any = [];
    clientList: any = [];
    managerList: any = [];
    productList: any = [];
    allSelectedManager = false;
    allSelectedProduct = false;
    hidePassword = true;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator; // For pagination
    @ViewChild(MatSort, { static: false }) sort: MatSort; // For Sort

    regionList: any = [];
    franchiseList: any = [];

    constructor(
        private userService: UserService
    ) { }

    ngOnInit() {
        this.objUser = new User();
        this.objUser.IsActive = true;
        this.getMasterData(0);
        this.getUserList();
    }

    getMasterData(clientId) {
        this.userService.getUserMasterList(clientId).subscribe((result: any) => {
            this.clientList = result.clientList.filter(x => x.isActive == true);
            this.profileList = result.profileList;
        },
            error => console.error(error));
    }

    getUserList() {
        this.userService.getUserList().subscribe((result: any) => {
            this.usersList = result;
            this.dataSource = new MatTableDataSource(this.usersList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        },
            error => console.error(error));
    }

    managerClick() {
        let managerStatus = true;
        this.selectManager.options.forEach((item: MatOption) => {
            if (!item.selected) {
                managerStatus = false;
            }
        });
        this.allSelectedManager = managerStatus;
    }

    productClick() {
        let productStatus = true;
        this.selectProduct.options.forEach((item: MatOption) => {
            if (!item.selected) {
                productStatus = false;
            }
        });
        this.allSelectedProduct = productStatus;
    }

    toggleAllSelection() {
        if (this.allSelectedManager) {
            this.selectManager.options.forEach((item: MatOption) => item.select());
        } else {
            this.selectManager.options.forEach((item: MatOption) => item.deselect());
        }
        if (this.allSelectedProduct) {
            this.selectProduct.options.forEach((item: MatOption) => item.select());
        } else {
            this.selectProduct.options.forEach((item: MatOption) => item.deselect());
        }
    }

    onSelectClient(clientId, userId) {
        this.userService.getUserMasterList(clientId).subscribe((result: any) => {
            this.managerList = result.managerList.filter(x => x.clientId == clientId && x.managerId != userId);
            this.productList = result.productList.filter(x => x.clientId == clientId);
            debugger;
            this.regionList = result.regionList;
            this.franchiseList = result.franchiseList;
        },
            error => console.error(error));
        this.allSelectedManager = false;
        this.allSelectedProduct = false;
    }

    submitUser(data) {
        if (data.IsActive == null) {
            data.IsActive = false;
        }
        data.ManagerId = data.ManagerId == undefined ? "" : data.ManagerId.toString();
        data.ProductId = data.ProductId == undefined ? "" : data.ProductId.toString();
        this.userService.addUpateUser(data).subscribe((result: any) => {
            if (result.code == 200) {
                this.resetUser();
                this.getUserList();
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
            } else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        },
            error => console.error(error));
        this.objUser.ManagerId = [];
        this.objUser.ProductId = [];
    }

    editUser(data) {
        this.objUser = new User();
        this.onSelectClient(data.clientId, data.userId);
        this.objUser.UserId = data.userId;
        this.objUser.FirstName = data.firstName;
        this.objUser.LastName = data.lastName;
        this.objUser.EmailId = data.emailId;
        this.objUser.UserName = data.userName;
        this.objUser.Password = data.password;
        this.objUser.ProfileId = data.profileId;
        this.objUser.ClientId = data.clientId;
        if (data.managerId != "") {
            var splitMananger = data.managerId.split(",");
            splitMananger.forEach(obj => {
                this.objUser.ManagerId.push(parseInt(obj));
            });
        }
        if (data.productId != "") {
            var splitProduct = data.productId.split(",");
            splitProduct.forEach(obj => {
                this.objUser.ProductId.push(parseInt(obj));
            });
        }
        this.objUser.IsActive = data.isActive;
        this.objUser.RegionId = data.regionId;
        this.objUser.FranchiseId = data.franchiseId;
    }

    resetUser() {
        this.objUser = new User();
        this.allSelectedManager = false;
        this.allSelectedProduct = false;
        this.addUserForm.reset();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    get passwordInput() {
        return this.addUserForm.get('password');
    }

}
