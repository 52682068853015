import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    authenticateUser(params) {
        return this.http.post('api/Authentication/', params).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    forgotPassword(params) {
        return this.http.get('api/Account/ForgotPassword/' + params).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
