import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
interface product {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'app-demoleftnav',
  templateUrl: './demoleftnav.component.html',
  styleUrls: ['./demoleftnav.component.css']
})
export class DemoleftnavComponent implements OnInit {
    constructor() { }

    isHiddenMdl: boolean = false;
    public optinoin: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Selling Skills',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '13px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },
        yAxis: {
            min: 0,
            max: 4,
            tickInterval: 1,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            reversedStacks: false
        },
        tooltip: {
            valueDecimals: 1
        },
        series: [{
            name: 'Selling Skills',
            color: '#0abfb2',
            /*data: data*/
            data: [1, 2, 3, 1, 2, 1, 4, 1, 2.5, 1, 2.5,]

        }],

    };
    public optinoins: any = {
        chart: {
            type: 'line',
            marginBottom: 27,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },

        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        title: {
            text: ' Campaign Implementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '13px',

            }
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                formatter: function () {
                    var string = (this.value.length < 1) ? this.value : this.value.substring(0, 1) + ''
                    return string;
                },
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            }

        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            }, 
            labels: {
                format: '{value}%',
                style: {
                    color: '#66707f',
                    fontSize: '10px',
                }
            },
            reversedStacks: false
        },
        tooltip: {
            valueDecimals: 1
        },
        series: [{
            name: ' Campaign Implementation',
            color: '#0abfb2',
            /*data: data*/
            data: [20, 40, 60, 60, 50, 40, 40, 70, 90, 80]

        }],

    };
   
    ngOnInit() {
        Highcharts.chart('ProgSSkills', this.optinoin);
        Highcharts.chart('ProgCImplem', this.optinoins);

        $('.cstm-Item').click(function () {
            $('.cstm-Item.Selected').removeClass('Selected');
            $(this).addClass('Selected');
        });
    }
    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];
    openDialog() {
        this.isHiddenMdl = true;
    }
    hideDailog() {
        this.isHiddenMdl = false;
    }
}
