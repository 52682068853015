import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import * as $ from 'jquery';
import { DashboardService } from '../../services/dashboard.service';
import { SellingSkillElements } from '../../models/dashboard/dashboard.model';

@Component({
    selector: 'app-sellingskills',
    templateUrl: './sellingskills.component.html',
    styleUrls: ['./sellingskills.component.css']
})

export class SellingskillsComponent implements OnInit {

    constructor(
        private route: Router,
        private dashboardService: DashboardService
    ) { }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    clientName: string = "";

    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }
        if (value >= 1) {
            return Math.round(value / 1) + '';
        }
        return value;
    }

    ngOnInit() {
        this.getLanguageStaticText();

        $('.uk-map').hide();
        if (localStorage.getItem('mapdrill') == '1') {
            $('.uk-map').show();
            $('.w-map_1').hide();
        }

        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        //this.getSellingSkillDataByClient(0);
    }

    //New Code
    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedWeekDay: number = 0;
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;

    //selectedLanguage: string = "En";

    clientCountryDataList: any = [];
    clientCountryDataListByElement: any = [];
    mapData: any = [];
    selectedElementList: any = [];

    updateFlag = false;
    currentPage: string = "SS";

    clientRegionDataList: any = [];
    clientRegionData: any = [];
    staticTextData: any;

    @ViewChild('table', { static: false }) table: MatTable<SellingSkillElements>;

    displayedColumns: string[] = ['ElementName', 'Score', 'Checked'];

    dataSource;;

    dropTable(event: CdkDragDrop<SellingSkillElements[]>) {
        const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
        moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
        this.table.renderRows();
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getSellingSkillDataByClient(0);
        }, error => console.error(error));
    }

    getClientSellingSkillDataByElement() {
        this.selectedElementList = [];
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage"),
            UserId: parseInt(this.userId),
            WeekDay: this.selectedWeekDay + 1,
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ClientId: localStorage.getItem('CountryKey') != null && this.clientCountryDataList.find(x => x.countryCode == localStorage.getItem('CountryKey')) != undefined ? this.clientCountryDataList.find(x => x.countryCode == localStorage.getItem('CountryKey')).clientId : 0
        }
        this.dashboardService.getSellingSkillDataByElement(objParam).subscribe((result: any) => {
            this.clientCountryDataListByElement = result;
            this.dataSource = result;
        },
            error => console.error(error));
    }

    onSelectElement(element, event) {
        this.selectedElementList = [];
        this.selectedElementList.push(element.aliasElementName);
        //if (element.aliasElementName == "Overall") {
        //    if (event.checked) {
        //        this.selectedElementList = [];
        //    }
        //}
        //else {
        //    if (event.checked) {
        //        if (this.selectedElementList.indexOf(element.aliasElementName) == -1) {
        //            this.selectedElementList.push(element.aliasElementName);
        //        }
        //    }
        //    else {
        //        this.selectedElementList.splice(this.selectedElementList.findIndex(a => a == element.aliasElementName), 1);
        //    }
        //}

        this.getSellingSkillDataByClient(1);
    }

    getSellingSkillDataByClient(valueToCheck) {
        var objParam = {
            UserId: parseInt(this.userId),
            WeekDay: this.selectedWeekDay + 1,
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ElementName: this.selectedElementList.toString() == "Overall" ? "" : this.selectedElementList.toString()
        }
        $('.loder-outer').show();
        this.dashboardService.getSellingSkillDataByClient(objParam).subscribe((result: any) => {
            this.clientCountryDataList = result;
            this.showMapData();

            if (valueToCheck == 0) {
                this.getClientSellingSkillDataByElement();
            }

            //called Region method
            this.getClientSellingSkillDataByRegion();
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }

    showMapData() {
        this.totalNoOfCalls = 0;
        this.mapData = [];
        if (this.clientCountryDataList.length > 0) {
            this.clientLogo = this.clientCountryDataList[0].clientLogo;
            this.clientCountryDataList.forEach(obj => {
                var data = [obj.countryCode, obj.score];
                this.mapData.push(data);

                //this will help to pass the data from parent to its child class.
                this.totalNoOfCalls = this.totalNoOfCalls + obj.totalCalls;
            });
        }
        this.chartOptions.series[0] = {
            type: 'map',
            data: this.mapData,
            name: this.staticTextData.sellingSkills
        }
        this.updateFlag = true;
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getSellingSkillDataByClient(0);
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getSellingSkillDataByClient(0);
    }

    //this is called when week slider will be changed.
    onWeekChange(event) {
        this.selectedWeekDay = event.value;
        this.getSellingSkillDataByClient(0);
    }

    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
        },
        colors: ['#6779aa', '#4d639c', '#344d8e', '#1a3680', '#012072', '#ff0000', '#ff9999', '#ffb3b3', '#ffcccc', '#ffecb3', '#ffe599', '#ffd966', '#ffbf00', '#e6f7ee', '#ccefdc', '#b3e7cb', '#99dfb9', '#4dc885', '#00b050'],
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [{
                from: 0.0,
                to: 0.5,
            },
            {
                from: 0.5,
                to: 0.8,

            }, {
                from: 0.8,
                to: 1.1,
            },
            {
                from: 1.1,
                to: 1.3,
            },
            {
                from: 1.3,
                to: 1.5,
            },
            {
                from: 1.5,
                to: 1.8,
            },
            {
                from: 1.8,
                to: 2.1,

            }, {
                from: 2.1,
                to: 2.3,
            },
            {
                from: 2.3,
                to: 2.5,
            },
            {
                from: 2.5,
                to: 2.8,
            },
            {
                from: 2.8,
                to: 3.1,

            }, {
                from: 3.1,
                to: 3.3,
            },
            {
                from: 3.3,
                to: 3.5,
            },
            {
                from: 3.5,
                to: 3.8,
            },
            {
                from: 3.8,
                to: 4.1,
            },
            {
                from: 4.1,
                to: 4.3,

            }, {
                from: 4.1,
                to: 4.4,
            },
            {
                from: 4.4,
                to: 4.8,
            },
            {
                from: 4.8,
                to: 5.0,
            },
            ]
        },
        series: [{
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        this.getDataByCountryKey(e.point['hc-key'])

                    }
                }
            },
            type: "map",
            name: "Selling Skills",
            states: {
                hover: {
                    color: "#BADA55"
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },
            allAreas: true,
            data: this.mapData
        }]
    };

    getDataByCountryKey(ckey: any) {
        localStorage.setItem('CountryKey', ckey);
        localStorage.setItem('SelectedClientId', this.clientCountryDataList.find(x => x.countryCode == ckey).clientId);
        localStorage.setItem('mapdrill', '1');
        if (ckey == 'gb') {
            this.route.navigate(['dashboard/OA']);
            $('.uk-map').show();
            $('.w-map_1').hide();
        }
    }

    getClientSellingSkillDataByRegion() {
        var objParam = {
            UserId: parseInt(this.userId),
            WeekDay: this.selectedWeekDay + 1,
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ElementName: this.selectedElementList.toString() == "Overall" ? "" : this.selectedElementList.toString(),
            ClientId: localStorage.getItem('CountryKey') != null && this.clientCountryDataList.find(x => x.countryCode == localStorage.getItem('CountryKey')) != undefined ? this.clientCountryDataList.find(x => x.countryCode == localStorage.getItem('CountryKey')).clientId : 0
        }

        this.dashboardService.getClientSellingSkillDataByRegion(objParam).subscribe((result: any) => {
            this.clientRegionDataList = result;
            this.showRegionMapData();
        },
            error => console.error(error));
    }

    showRegionMapData() {
        this.clientRegionData = [];
        if (this.clientRegionDataList.length > 0) {
            this.clientRegionDataList.forEach(objRegion => {
                this.clientRegionData.push({ regionName: objRegion.regionName, score: objRegion.score });
            });
        }
    }
}


