import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../../models/account/login.model';
import { AccountService } from '../../services/account.service';
import { TokenService } from '../../services/token.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    selectedView: string;

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private accountService: AccountService
    ) {
        this.selectedView = 'Rep';
        // Store default value in local storage
        localStorage.setItem('selectedView', this.selectedView);
    }


    objUser: Login;
    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        Password: new FormControl('', [Validators.required, Validators.minLength(2)])
    });
    isUserValid: boolean;

    ngOnInit() {
        this.objUser = new Login();
        this.isUserValid = true;
    }

    Login(data) {
        this.accountService.authenticateUser(data).subscribe((result: any) => {
            if (result.userId != 0) {
                this.tokenService.saveToken(result.token);
                this.SetCookies(result);
                this.isUserValid = true;
                if (result.profileId !== 4) {
                    this.router.navigate(["/staticdashboard/OA"]);
                }
                else {
                    this.router.navigate(["/staticdashboard/AG"])
                }
            }
            else {
                this.isUserValid = false;
            }
        }, error => console.error(error));
    }

    SetCookies(data) {
        window.localStorage['UserId'] = data.userId;
        window.localStorage['UserProfileId'] = data.profileId;
        window.localStorage['FirstName'] = data.firstName;
        window.localStorage['LastName'] = data.lastName;
        window.localStorage['UserClientId'] = data.clientId;
        window.localStorage['UserClientDomain'] = data.clientDomain;
        
    }

    RemoveCookies() {
        window.localStorage.removeItem('UserId');
        window.localStorage.removeItem('UserProfileId');
        window.localStorage.removeItem('AccessToken');
        window.localStorage.removeItem('FirstName');
        window.localStorage.removeItem('LastName');
        window.localStorage.removeItem('IsHiAUser');
        window.localStorage.removeItem('UserClientId');
        window.localStorage.removeItem('UserClientDomain');
        window.localStorage.removeItem('SelectedProductId');
        window.localStorage.removeItem('SelectedFranchiseId');
    }
}
