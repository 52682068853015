import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LivehomeComponent } from './livehome/livehome.component';
import { LiveleftnavComponent } from './liveleftnav/liveleftnav.component';

const routes: Routes = [
    { path: 'demolive/livehome', component: LivehomeComponent},
    { path: 'demolive/liveleftnav', component: LiveleftnavComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DemoliveRoutingModule { }
