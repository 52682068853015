import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
import { SlsmService } from '../../services/slsm.service';
import { LeftMenuData } from '../../models/slsm/left-menu-data.model';
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");

@Component({
    selector: 'app-worldmap',
    templateUrl: './worldmap.component.html',
    styleUrls: ['./worldmap.component.css']
})
export class WorldmapComponent implements OnInit {

    userId: any = window.localStorage.getItem("UserId");

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    updateFlag: boolean = false;

    mapColors: any = [];
    mapData: any = [];
    mapDataClasses: any = [];

    slsmLeftMenuData: any;
    leftMenuData: LeftMenuData;

    constructor(
        private route: Router,
        private slsmService: SlsmService) { }

    ngOnInit() {
        this.leftMenuData = new LeftMenuData();
        this.getMapData();
    }

    getMapData() {
        var langCode = 'En';
        this.slsmService.GetSellingSkillLables(langCode).subscribe((result: any) => {
            if (result.length > 0) {
                result.forEach(obj => {
                    this.mapColors.push(obj.color);
                    var objDataClass = {
                        from: obj.labelId == 1 ? 0.0 : obj.labelId == 2 ? 1.5 : obj.labelId == 3 ? 2.5 : 3.5,
                        to: obj.labelId == 1 ? 1.5 : obj.labelId == 2 ? 2.5 : obj.labelId == 3 ? 3.5 : 5.0,
                        name: obj.labelName + (obj.labelId == 1 ? ' (0.0 - 1.5)' : obj.labelId == 2 ? ' (1.5 - 2.5)' : obj.labelId == 3 ? ' (2.5 - 3.5)' : ' (>3.5)')
                    }
                    this.mapDataClasses.push(objDataClass);
                });
            }
            this.getCountryData();

        },
            error => console.error(error));
    }

    getCountryData() {
        var param = {
            UserId: parseInt(this.userId),
            ViewType: 1
        };
        this.slsmService.GetCountryWiseClientData(param).subscribe((result: any) => {
            if (result.length > 0) {
                var filterData = result.filter(x => x.clientId != 0);
                this.leftMenuData.SellingSkillAvg = result.filter(x => x.clientId == 0)[0].sellingSkillScore;
                filterData.forEach(obj => {
                    var data = [obj.countryCode, obj.sellingSkillScore];
                    this.mapData.push(data);
                });
            }
            this.getCallAverages();
            this.updateFlag = true;
        },
            error => console.error(error));
    }

    getCallAverages() {
        var params = {
            ClientId: 0,
            UserId: parseInt(this.userId),
            ViewType: 1,
            LanguageCode: 'En'
        };
        this.slsmService.GetDashboardDataByClient(params).subscribe((result: any) => {
            if (result.audioCallAverage != null) {
                this.leftMenuData.TotalAudioCallAvg = result.audioCallAverage.totalAudioCallAvg;
                this.leftMenuData.DoctorSpeakTimeAvg = result.audioCallAverage.doctorSpeakTimeAvg;
                this.leftMenuData.RepSpeakTimeAvg = result.audioCallAverage.repSpeakTimeAvg;
            }
            
            //To get selling skill avg by month.
            this.slsmService.GetSellingSkillAvgByMonth(params).subscribe((resultSS: any) => {

                this.slsmLeftMenuData = { SellingSkillAvg: this.leftMenuData.SellingSkillAvg, CampaignImpAvg: 58, TotalAudioCallAvg: this.leftMenuData.TotalAudioCallAvg, DoctorSpeakTimeAvg: this.leftMenuData.DoctorSpeakTimeAvg, RepSpeakTimeAvg: this.leftMenuData.RepSpeakTimeAvg, SellingSkillMonthlyAvg: resultSS };
            });
        });
    }

    chartOptions: Options = {
        chart: {
            map: worldMap as any,
            inverted: false,
        },
        colors: this.mapColors,
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: this.mapDataClasses
        },
        tooltip: {
            valueDecimals: 1
        },
        series: [{
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        this.getDataByCountryKey(e.point['hc-key'])
                    }
                }
            },
            type: "map",
            name: "Data",
            states: {
                hover: {
                    color: "#BADA55"
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                    }
                }
            },
            allAreas: true,
            data: this.mapData
        }]
    };

    getDataByCountryKey(ckey: any) {
        console.log('CountryKey:' + ckey);
        window.localStorage.setItem("CountryKey", ckey);
        this.route.navigate(['/slsm/home']);
    }
}
