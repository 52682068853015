import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-callaction-analysis',
    templateUrl: './callaction-analysis.component.html',
    styleUrls: ['./callaction-analysis.component.css']
})
export class CallactionAnalysisComponent implements OnInit {
    outcomePrompted: number;
    outcomeVolunteered: number;
    NotObserved: number;
    selectedView: string;

    constructor(
        private dashboardService: DashboardService,
    ) {
        this.selectedView = localStorage.getItem('selectedView');
}

    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    selectedLanguage: string = "En";
    selectedLevelId: number = 3;
    outcomeDataList: any;
    ctaDataList: any = [];
    staticTextData: any;
    FilterID: any = window.localStorage.getItem("FilterIdUS");
    startAd:any
    @ViewChild('CallAnalysisPie', { static: true }) CallAnalysisPie: ElementRef;
    @ViewChild('CallAnalysisColumn1', { static: true }) CallAnalysisColumn1: ElementRef;
    @ViewChild('CallAnalysisColumn2', { static: true }) CallAnalysisColumn2: ElementRef;
    @ViewChild('CallAnalysisColumn3', { static: true }) CallAnalysisColumn3: ElementRef;

    ngOnInit() {
        this.getLanguageStaticText();
        this.dataGlanceCode();
        
        //this.selectedLanguage = window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage");

        //this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        //this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        //this.clientLogo = window.localStorage.getItem("ClientLogo");
        //this.getOutcomeDataShare();
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getOutcomeDataShare();
        }, error => console.error(error));
    }

    getOutcomeDataShare() {
        this.totalNoOfCalls = 0;
        this.selectedLevelId = 3;
        $('#CallAnalysisColumn1').hide();
        $('#CallAnalysisColumn2').hide();
        $('#CallAnalysisColumn3').show();

        var obj = {
            LanguageCode: this.selectedLanguage,
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            LevelId: this.selectedLevelId,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }

        this.dashboardService.getOutcomeDataShare(obj).subscribe((result: any) => {
            this.outcomeDataList = result;
            this.totalNoOfCalls = this.totalNoOfCalls + result.totalCalls;
            this.drawOutcomePieGraph(result);
            this.getCTADataShare();

        }, error => console.error(error));
    }

    getCTADataShare() {
        var obj = {
            LanguageCode: this.selectedLanguage,
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            LevelId: this.selectedLevelId,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }

        this.dashboardService.getCTADataShare(obj).subscribe((result: any) => {
            this.ctaDataList = result;
            var dataArray = [];
            if (result.length > 0) {
                result.forEach(obj => {
                    var data = {
                        name: obj.elementNameToShow,
                        y: obj.score,
                        color: this.selectedLevelId == 3 ? '#00b050' : this.selectedLevelId == 2 ? '#ffc00094' : 'red'
                    }
                    dataArray.push(data);
                });
            }
            if (this.selectedLevelId == 1) {
                this.drawCTALevel1Graph(dataArray);
            }
            else if (this.selectedLevelId == 2) {
                this.drawCTALevel2Graph(dataArray);
            }
            else {
                this.drawCTALevel3Graph(dataArray);
            }
        }, error => console.error(error));
    }

    moveToPoint(clickPoint) {

        var points = clickPoint.series.points;
        var startAngle = 0;

        /* var pid = points.id;*/
        for (var i = 0; i < points.length; i++) {
            var p = points[i];
            var pid = p.id

            if (p == clickPoint) {
                break;
            }

            startAngle += (p.percentage / 100.0 * 360.0);
        }
        const reference = this;
        if (pid == 1) {
            $('#CallAnalysisColumn1').show();
            $('#CallAnalysisColumn2').hide();
            $('#CallAnalysisColumn3').hide();

            reference.selectedLevelId = 1;
            reference.getCTADataShare();
        }
        if (pid == 2) {
            $('#CallAnalysisColumn1').hide();
            $('#CallAnalysisColumn2').show();
            $('#CallAnalysisColumn3').hide();

            reference.selectedLevelId = 2;
            reference.getCTADataShare();
        }
        if (pid == 3) {
            $('#CallAnalysisColumn1').hide();
            $('#CallAnalysisColumn2').hide();
            $('#CallAnalysisColumn3').show();
            reference.selectedLevelId = 3;
            reference.getCTADataShare();
        }
        clickPoint.series.update({

            //startAngle: -startAngle + 180 // start at 180
            startAngle: -startAngle + 90 - ((clickPoint.percentage / 100.0 * 360.0) / 2) // center at 180
        });
    }
    pieAngleload(loadPoint) {
        var points = loadPoint;
       
        var startAngle = 0;
   
        for (var i = 0; i < points.length; i++) {
            var p = points[i];
            var pid = p.id

            if (pid == 3 ) {
                break;
            }

            startAngle += (p.percentage / 100.0 * 360.0);
        }
        
        points[2].series.update({  
            startAngle: -startAngle + 90 - ((points[2].percentage / 100.0 * 360.0) / 2)
        });
    }
    dataGlanceCode() {
        if (this.FilterID == 'USA') {
            this.outcomePrompted = 15.4;
            this.outcomeVolunteered = 7.6;
            this.NotObserved = 77.0;
        }
        else if (this.FilterID == 'Alaska') {
            this.outcomePrompted = 16.3;
            this.outcomeVolunteered = 8.6;
            this.NotObserved = 75.1;
        }
        else if (this.FilterID == 'Midwest') {
            this.outcomePrompted = 16.2;
            this.outcomeVolunteered = 6.2;
            this.NotObserved = 77.6
        }
        else if (this.FilterID == 'North Central') {
            this.outcomePrompted = 14.6;
            this.outcomeVolunteered = 9.0;
            this.NotObserved = 76.4;
        }
        else if (this.FilterID == 'North East') {
            this.outcomePrompted = 15.1;
            this.outcomeVolunteered = 6.0;
            this.NotObserved = 78.9;
        }
        else if (this.FilterID == 'North West') {
            this.outcomePrompted = 17.30;
            this.outcomeVolunteered = 4.0;
            this.NotObserved = 78.70;
        }
        else if (this.FilterID == 'Pacific Islands') {
            this.outcomePrompted = 16.40;
            this.outcomeVolunteered = 5.10;
            this.NotObserved = 78.50;
        }
        else if (this.FilterID == 'South Central') {
            this.outcomePrompted = 18.10;
            this.outcomeVolunteered = 4.30;
            this.NotObserved = 77.60;
        }
        else if (this.FilterID == 'South East') {
            this.outcomePrompted = 15.90;
            this.outcomeVolunteered = 7.70;
            this.NotObserved = 76.40;
        }
        else if (this.FilterID == 'South West') {
            this.outcomePrompted = 17.40;
            this.outcomeVolunteered = 7.90;
            this.NotObserved = 74.70;
        }
       
  
    }
    drawOutcomePieGraph(dataValues) {
        const reference = this; // code added
        Highcharts.chart(this.CallAnalysisPie.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    marginBottom: 20,
                    type: 'pie',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    },
                    events: {
                        load: function (e) {
                            this.pieAngleload(e.target.series[0].points)
                        }.bind(this)
                    },
                },
                legend: {
                    enabled: true,
                    y: 20,
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                title: {
                    enabled: false,
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    point: {
                        valueSuffix: '%',
                    }
                },
                plotOptions: {
                    pie: {
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        point: {
                            events: {
                                click: function (e) {
                                    this.moveToPoint(e.point);
                                }.bind(this)
                            }
                        },
                        startAngle: 0,
                        slicedOffset: 20,
                        cursor: 'pointer',
                        allowPointSelect: true,
                        dataLabels: {
                            enabled: true,
                            format: '{y:.1f}',
                            inside: true,
                            distance: -35,
                            style: {
                                fontSize: '20px',
                                color: 'white',
                                textShadow: false,
                                textOutline: false
                            },

                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    valueDecimals: 1
                },
                series: [{
                    colorByPoint: true,
                    data: [
                        {
                            name: this.staticTextData.notObserved,//'Not Observed',
                            y: this.NotObserved,
                            color: '#ffc000',
                            id: 1,
                        },
                        {
                            name: this.staticTextData.outcomePrompted,//'Outcome Prompted',
                            y: this.outcomePrompted,
                            color: '#66ff66',
                            id: 2,
                        },
                        {
                            name: this.staticTextData.outcomeVolunteered,//'Outcome Volunteered',
                            y: this.outcomeVolunteered,
                            color: '#078000',
                            id: 3,
                            sliced: true,
                            selected: true
                        }
                    ]
                }]
            })
        );
    }

    drawCTALevel1Graph(dataValues) {
        let xAxisCategories = ['Prescribe', 'Patient Id', 'MSL', 'Advocacy', 'Guidelines'];
        dataValues = [0.0, 0.0, 0.0, 0.0, 0]
        if (this.selectedView === 'Msl') {
            // Modify xAxis categories if the selected value is 'Msl'

            xAxisCategories = ["Thought Leadership", "Clinical research", "Project Collaboration", "Advocacy", "Guidelines", "Next meeting", "Send Info"]
            dataValues = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,]
        }

        Highcharts.chart(this.CallAnalysisColumn1.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.notObserved,//'Not Observed',
                    style: {
                        color: '#ffc000',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    categories: xAxisCategories,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'
                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                tooltip: {
                    valueDecimals: 1
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name:'Not Observed',
                    color: '#ffc000',
                    data: dataValues
                }]
            })
        );
    }

    drawCTALevel2Graph(dataValues) {
        let xAxisCategories = ['Prescribe', 'Patient Id', 'MSL', 'Advocacy', 'Guidelines'];
        dataValues = [60, 33.8, 23.0, 19.5, 15.3]
        if (this.selectedView === 'Msl') {
            // Modify xAxis categories if the selected value is 'Msl'
            xAxisCategories = ["Thought Leadership", "Clinical research", "Project Collaboration", "Advocacy", "Guidelines", "Next meeting", "Send Info"]
            dataValues = [60, 33.8, 23.0, 19.5, 15.3, 10, 5]
        }
        Highcharts.chart(this.CallAnalysisColumn2.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.outcomePrompted,//'Outcome Prompted',
                    style: {
                        color: '#66ff66',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                 
                    categories: xAxisCategories,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'

                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: 1
                },
                series: [{
                    name:'Outcome Prompted',
                    color:'#66ff66',
                    data: dataValues
                }]
            })
        );
    }

    drawCTALevel3Graph(dataValues) {
        let xAxisCategories = ['Prescribe', 'Patient Id', 'MSL', 'Advocacy', 'Guidelines'];
        dataValues = [80, 23.0, 19.5, 33.8, 15.3]
        if (this.selectedView === 'Msl') {
            // Modify xAxis categories if the selected value is 'Msl'
            xAxisCategories = ["Thought Leadership", "Clinical research", "Project Collaboration", "Advocacy", "Guidelines", "Next meeting", "Send Info"]
            dataValues = [80, 23.0, 19.5, 33.8, 15.3,10 ,5]
        }

        Highcharts.chart(this.CallAnalysisColumn3.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.staticTextData.outcomeVolunteered,//'Outcome Volunteered',
                    style: {
                        color: '#00b050',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    categories: xAxisCategories,
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 100,
                    tickInterval: 10,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#66707f'
                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.23,
                        borderColor: null,
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: 1
                },
                series: [{
                    name: 'Outcome Volunteered',
                    color:'#078000',
                    data: dataValues
                }]
            })
        );
    }
}
