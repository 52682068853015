export class Client {
    ClientId: number;
    ClientName: string;
    ClientLogo: string;
    ClientDomain: string;
    ProductName: any[] = [];
    CountryId: number;
    LanguageId: any[] = [];
    IsActive: boolean;
    ShowDoctorInfo: boolean;
    ShowDashboard: boolean;
    FranchiseName: any[] = [];
    ConsentShortStatement: string;
    ConsentExtendedStatement: string;
}

export class SalesModal {
    SalesModalId: number;
    SalesModalName: string;
    ScaleFactor: number;
    ClientId: number;
    CreatedBy: number;
    IsActive: boolean;
}

export class CategoryModal {
    CategoryId: number;
    CategoryName: string;
    SalesModalId: number;
    IsActive: boolean;
}

export class ElementModal {
    ElementId: number;
    ElementName: string;
    ElementTypeId: number;
    CategoryId: number;
    ScaleDefination: string[] = [];
    IsActive: boolean;
}