import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-sellingskillselement',
    templateUrl: './sellingskillselement.component.html',
    styleUrls: ['./sellingskillselement.component.css']
})
export class SellingskillselementComponent implements OnInit {
    chart: any;
    updateFlag = false;
    Highcharts: typeof Highcharts = Highcharts;
    totalNoOfCalls: any;
    selectedView: string;
    constructor(
        private dashboardService: DashboardService,
        private slsmService: SlsmService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
        this.chartOptions_1.title.text = this.selectedView === "Msl" ? 'Engage' : 'Opening';
        this.chartOptions_2.title.text = this.selectedView === "Msl" ? 'Explore' : 'Questioning';
        this.chartOptions_3.title.text = this.selectedView === "Msl" ? 'Exchange' : 'Presenting';
        this.chartOptions_4.title.text = this.selectedView === "Msl" ? 'Evolve' : 'Closing';
    }
   

    staticTextData: any;
    notObservedVisible: any = false;
    foundationalVisible: any = true;
    developingVisible: any = true;
    accomplishedVisible: any = true;

    ngOnInit() {
        this.getLanguageStaticText();
    }

    getLanguageStaticText() {
        var objParam = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }

        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.totalNoOfCalls = 501
            this.staticTextData = result;         
            $('.loder-outer').hide();
        }, error => console.error(error));
    }

    chartOptions_1: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
         
        },
        title: {
            text: 'Opening',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [], /*['Not observed', 'Foundational', 'Developing', 'Accomplished'],*/
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: true
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',
                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 1
        },

        series: [{
            type: 'column',
            name: 'Not Observed',
            visible: this.notObservedVisible,
            color: '#FF0000',
            data: [8.50, 70.25, 2.50, 2.75, 4.75, 7.00]
        },
            {
                type: 'column',
            name: 'Foundational',
                color: "#FFBF00",
                data: [38.75, 22.25, 26.25, 14.25, 19.00, 21.50]
        },
            {
                type: 'column',
            name: 'Developing',
                color: "#66ff66",
                data: [25.50, 5.00, 56.00, 59.50, 57.25, 58.00]
        },
            {
                type: 'column',
            name: 'Accomplished',
            color: '#078000',
                data: [27.25, 2.50, 15.25, 23.50, 19.00, 13.50]
        },
        ]
    };
    chartOptions_2: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
         
        },
        title: {
            text: 'Questioning',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: true
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',
                
                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },

        series: [{
            type:'column',
            name: 'Not Observed',
             visible: this.notObservedVisible,
            color: '#FF0000',
            data: [4.00, 43.00, 7.25, 5.50, 6.00, 21.50]
        },
        {
            type:'column',
            name: 'Foundational',
            color: "#FFBF00",
            data: [16.00, 47.00, 33.00, 33.50, 34.25, 36.25]
        },
        {
            type:'column',
            name: 'Developing',
            color: "#66ff66",
            data: [42.00, 5.00, 27.75, 31.00, 30.25, 22.50]
        },
        {
            type:'column',
            name: 'Accomplished',
            color: '#078000',
            data: [38.00, 5.00, 32.00, 30.00, 29.50, 19.75]
        },
        ]
   
    };
    chartOptions_3: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
            
        },
        title: {
            text: 'Presenting',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: true
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.23,
            },
            series: {
                stacking: 'normal',

                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },

        series: [{
            type:'column',
            name: 'Not Observed',
             visible: this.notObservedVisible,
            color: '#FF0000',
            data: [3.00, 0.00, 1.25, 0.25, 1.00, 9.25]
        },
        {
            type:'column',
            name: 'Foundational',
            color: "#FFBF00",
            data: [10.00, 14.00, 6.25, 7.00, 7.50, 44.25]
        },
        {
            type:'column',
            name: 'Developing',
            color: "#66ff66",
            data: [42.00, 43.00, 41.50, 43.00, 43.50, 40.50]
        },
        {
            type:'column',
            name: 'Accomplished',
            color: '#078000',
            data: [45.00, 43.00, 51.00, 49.75, 48.00, 6.00]
        },
        ]

    };
    chartOptions_4: Highcharts.Options = {
        chart: {
            type: 'column',
            marginBottom: 50,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
          
        },
        title: {
            text: 'Closing',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        xAxis: {
            min: 1,
            max: 12,
            tickInterval: 1,
            categories: [],//['Not observed', 'Foundational', 'Developing', 'Accomplished'],
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 50,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: true
        },
        legend: {
            symbolRadius: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            floating: false,
            y: 10,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '9px'
            }
        },
        plotOptions: {
            column: {
                   pointPadding: 0.23,
            },
            series: {

                stacking: 'normal',
           
                borderColor: null,
                pointStart: 1,
            }
        },
        credits: {
            enabled: false
        },
         tooltip: {
            valueDecimals: 1
        },

        series: [{
            type:'column',
            name: 'Not Observed',
             visible: this.notObservedVisible,
            color: '#FF0000',
            data: [0.00, 47.50, 8.25, 0.50, 1.00, 11.00,]
        },
        {
            type:'column',
            name: 'Foundational',
            color: "#FFBF00",
            data: [33.00, 5.00, 36.50, 31.00, 32.25, 39.25]
        },
        {
            type:'column',
            name: 'Developing',
            color: "#66ff66",
            data: [50.00, 47.50, 44.75, 49.50, 49.00, 41.25]
        },
        {
            type:'column',
            name: 'Accomplished',
            color: '#078000',
            data: [17.00, 0.00, 10.50, 19.00, 17.75, 8.50]
        },
        ]

    };
     
    UpdateWeek() {
    this.totalNoOfCalls = 223;
    this.chartOptions_1.series[0] = {
        type: 'column',
        data: [76.0, 66.0, 52.0, 43.0, 32.0, 24.0, 17.0, 8.0, -5.0, -13.0, -16.0, -20.0]

    };
    this.chartOptions_1.series[1] = {
        type: 'column',
        data: [20.0, 30.0, 42.0, 50.0, 60.0, 64.0, 66.0, 70.0, 75.0, 73.0, 69.0, 65.0]
    };
    this.chartOptions_1.series[2] = {
        type: 'column',
        data: [2.0, 2.0, 3.0, 4.0, 5.0, 6.0, 8.0, 10.0, 15.0, 20.0, 25.0, 30.0]
    };
    this.chartOptions_1.series[3] = {
        type: 'column',
        data: [2.0, 2.0, 3.0, 3.0, 3.0, 6.0, 9.0, 12.0, 15.0, 20.0, 22.0, 25.0]
    };

    //graph 2
    this.chartOptions_2.series[0] = {
        type: 'column',
        data: [78.0, 75.0, 70.0, 66.0, 62.0, 58.0, 52.0, 45.0, 38.0, 32.0, 18.0, 5.0]
    };
    this.chartOptions_2.series[1] = {
        type: 'column',
        data: [22.0, 25.0, 28.0, 31.0, 34.0, 37.0, 40.0, 43.0, 46.0, 48.0, 51.0, 55.0]
    };
    this.chartOptions_2.series[2] = {
        type: 'column',
        data: [0.0, 0.0, 2.0, 3.0, 4.0, 5.0, 6.0, 8.0, 10.0, 12.0, 15.0, 20.0]
    };
    this.chartOptions_2.series[3] = {
        type: 'column',
        data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 4.0, 6.0, 8.0, 16.0, 20.0]
    };

    //graph 3
    this.chartOptions_3.series[0] = {
        type: 'column',
        data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
    };
    this.chartOptions_3.series[1] = {
        type: 'column',
        data: [85.0, 80.0, 74.0, 68.0, 63.0, 59.0, 55.0, 51.0, 45.0, 41.0, 37.0, 30.0]
    };
    this.chartOptions_3.series[2] = {
        type: 'column',
        data: [10.0, 14.0, 19.0, 24.0, 28.0, 31.0, 34.0, 37.0, 42.0, 45.0, 48.0, 54.0]
    };
    this.chartOptions_3.series[3] = {
        type: 'column',
        data: [5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, 13.0, 14.0, 15.0, 16.0]
    };

    //graph 4
    this.chartOptions_4.series[0] = {
        type: 'column',
        data: [75.0, 74.0, 72.0, 70.0, 67.0, 64.0, 60.0, 56.0, 51.0, 42.0, 35.0, 27.0]
    };
    this.chartOptions_4.series[1] = {
        type: 'column',
        data: [25.0, 26.0, 27.0, 28.0, 29.0, 30.0, 31.0, 32.0, 33.0, 34.0, 35.0, 36.0]
    };
    this.chartOptions_4.series[2] = {
        type: 'column',
        data: [0.0, 0.0, 1.0, 2.0, 4.0, 6.0, 8.0, 10.0, 12.0, 16.0, 20.0, 25.0]
    };
    this.chartOptions_4.series[3] = {
        type: 'column',
        data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 2.0, 4.0, 8.0, 10.0, 12.0]
    };
    this.updateFlag = true;
}

    UpdateMonth() {
       
        this.totalNoOfCalls = 501;
        this.chartOptions_1.series[0] = {
            type: 'column',
            data: [8.50, 70.25, 2.50, 2.75, 4.75, 7.00]
        };
        this.chartOptions_1.series[1] = {
            type: 'column',
            data: [38.75, 22.25, 26.25, 14.25, 19.00, 21.50]
        };
        this.chartOptions_1.series[2] = {
            type: 'column',
            data: [25.50, 5.00, 56.00, 59.50, 57.25, 58.00]
        };
        this.chartOptions_1.series[3] = {
            type: 'column',
            data: [27.25, 2.50, 15.25, 23.50, 19.00, 13.50]
        };

        //graph 2
        this.chartOptions_2.series[0] = {
            type: 'column',
            data: [4.00, 43.00, 7.25, 5.50, 6.00, 21.50]
        };
        this.chartOptions_2.series[1] = {
            type: 'column',
            data: [16.00, 47.00, 33.00, 33.50, 34.25, 36.25]
        };
        this.chartOptions_2.series[2] = {
            type: 'column',
            data: [42.00, 5.00, 27.75, 31.00, 30.25, 22.50]
        };
        this.chartOptions_2.series[3] = {
            type: 'column',
            data: [38.00, 5.00, 32.00, 30.00, 29.50, 19.75]
        };

        //graph 3
        this.chartOptions_3.series[0] = {
            type: 'column',
            data: [3.00, 0.00, 1.25, 0.25, 1.00, 9.25]
        };
        this.chartOptions_3.series[1] = {
            type: 'column',
            data: [10.00, 14.00, 6.25, 7.00, 7.50, 44.25]
        };
        this.chartOptions_3.series[2] = {
            type: 'column',
            data: [42.00, 43.00, 41.50, 43.00, 43.50, 40.50]
        };
        this.chartOptions_3.series[3] = {
            type: 'column',
            data: [45.00, 43.00, 51.00, 49.75, 48.00, 6.00]
        };

        //graph 4
        this.chartOptions_4.series[0] = {
            type: 'column',
            data: [0.00, 47.50, 8.25, 0.50, 1.00, 11.00,]
        };
        this.chartOptions_4.series[1] = {
            type: 'column',
            data: [33.00, 5.00, 36.50, 31.00, 32.25, 39.25]
        };
        this.chartOptions_4.series[2] = {
            type: 'column',
            data: [50.00, 47.50, 44.75, 49.50, 49.00, 41.25]
        };
        this.chartOptions_4.series[3] = {
            type: 'column',
            data: [17.00, 0.00, 10.50, 19.00, 17.75, 8.50]
        };
        this.updateFlag = true;
    }

    UpdateQTR() {
        this.totalNoOfCalls = 501;
        this.chartOptions_1.series[0] = {
            type: 'column',
            data: [26.3, 4.3]
        };
        this.chartOptions_1.series[1] = {
            type: 'column',
            data: [29.1, 18.3]
        };
        this.chartOptions_1.series[2] = {
            type: 'column',
            data: [28.9, 58.3]
        };
        this.chartOptions_1.series[3] = {
            type: 'column',
            data: [15.8, 19.2]
        };
        //graph 2
        this.chartOptions_2.series[0] = {
            type: 'column',
            data: [18.1, 11.0]
        };
        this.chartOptions_2.series[1] = {
            type: 'column',
            data: [32.3, 34.7]
        };
        this.chartOptions_2.series[2] = {
            type: 'column',
            data: [24.9, 27.9]
        };
        this.chartOptions_2.series[3] = {
            type: 'column',
            data: [25.0, 26.4]
        };

        //graph 3
        this.chartOptions_3.series[0] = {
            type: 'column',
            data: [4.0, 4.2]
        };
        this.chartOptions_3.series[1] = {
            type: 'column',
            data: [9.8, 19.6]
        };
        this.chartOptions_3.series[2] = {
            type: 'column',
            data: [42.2, 42.3]
        };
        this.chartOptions_3.series[3] = {
            type: 'column',
            data: [44.0, 33.9]
        };

        //graph 4
        this.chartOptions_4.series[0] = {
            type: 'column',
            data: [18.6, 4.20],
        };
        this.chartOptions_4.series[1] = {
            type: 'column',
            data: [24.8, 34.2],
        };
        this.chartOptions_4.series[2] = {
            type: 'column',
            data: [47.4, 46.6],
        };
        this.chartOptions_4.series[3] = {
            type: 'column',
            data: [9.2, 15.0],
        };
        this.updateFlag = true;
    }
    seiresOnOff($event: Event, selectedSeires) {
        if (selectedSeires === 'NotObserved') {
            this.chartOptions_1.series[0] = {
                type: 'column',
                visible: this.notObservedVisible
            };
            this.chartOptions_2.series[0] = {
                type: 'column',
                visible: this.notObservedVisible
            };
            this.chartOptions_3.series[0] = {
                type: 'column',
                visible: this.notObservedVisible
            };
            this.chartOptions_4.series[0] = {
                type: 'column',
                visible: this.notObservedVisible
            };
            this.updateFlag = true;
        }


        if (selectedSeires === 'Foundational') {
            this.chartOptions_1.series[1] = {
                type: 'column',
                visible: this.foundationalVisible
            };
            this.chartOptions_2.series[1] = {
                type: 'column',
                visible: this.foundationalVisible
            };
            this.chartOptions_3.series[1] = {
                type: 'column',
                visible: this.foundationalVisible
            };
            this.chartOptions_4.series[1] = {
                type: 'column',
                visible: this.foundationalVisible
            };
            this.updateFlag = true;
        }
        if (selectedSeires === 'Developing') {
            this.chartOptions_1.series[2] = {
                type: 'column',
                visible: this.developingVisible
            };
            this.chartOptions_2.series[2] = {
                type: 'column',
                visible: this.developingVisible
            };
            this.chartOptions_3.series[2] = {
                type: 'column',
                visible: this.developingVisible
            };
            this.chartOptions_4.series[2] = {
                type: 'column',
                visible: this.developingVisible
            };
            this.updateFlag = true;
        }
        if (selectedSeires === 'Accomplished') {
            this.chartOptions_1.series[3] = {
                type: 'column',
                visible: this.accomplishedVisible
            };
            this.chartOptions_2.series[3] = {
                type: 'column',
                visible: this.accomplishedVisible
            };
            this.chartOptions_3.series[3] = {
                type: 'column',
                visible: this.accomplishedVisible
            };
            this.chartOptions_4.series[3] = {
                type: 'column',
                visible: this.accomplishedVisible
            };
            this.updateFlag = true;
        }

    }
}
