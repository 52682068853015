import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    getLanguageStaticText(params) {
        return this.http.post('api/Language/GetStaticTextData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getAllLanguages(params) {
        return this.http.post('api/Language/GetAllLanguages', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUserLanguage(params) {
        return this.http.post('api/Language/AddUserLanguage', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getDataList(params) {
        return this.http.post('api/Dashboard/GetDataList', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientWiseOutcomeData(params) {
        return this.http.post('api/Dashboard/GetClientWiseOutcomeData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getOutcomeImpactFactorData(params) {
        return this.http.post('api/Dashboard/GetOutcomeImpactFactorData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientOutcomeDataByRegion(params) {
        return this.http.post('api/Dashboard/GetClientOutcomeDataByRegion', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getSellingSkillDataByElement(params) {
        return this.http.post('api/Dashboard/GetSellingSkillDataByElement', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getSellingSkillDataByClient(params) {
        return this.http.post('api/Dashboard/GetSellingSkillDataByClient', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientSellingSkillDataByRegion(params) {
        return this.http.post('api/Dashboard/GetClientSellingSkillDataByRegion', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCampaignDataByElement(params) {
        return this.http.post('api/Dashboard/GetCampaignDataByElement', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCampaignDataByClient(params) {
        return this.http.post('api/Dashboard/GetCampaignDataByClient', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientCampaignDataByRegion(params) {
        return this.http.post('api/Dashboard/GetClientCampaignDataByRegion', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientAtGlanceData(params) {
        return this.http.post('api/Dashboard/GetClientAtGlanceData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientAtGlanceDataByRegion(params) {
        return this.http.post('api/Dashboard/GetClientAtGlanceDataByRegion', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientAtGlanceDataByUser(params) {
        return this.http.post('api/Dashboard/GetClientAtGlanceDataByUser', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getSellingSkillCategorisedData(params) {
        return this.http.post('api/Dashboard/GetSellingSkillCategorisedData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getOutcomeDataShare(params) {
        return this.http.post('api/Dashboard/GetOutcomeDataShare', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCTADataShare(params) {
        return this.http.post('api/Dashboard/GetCTADataShare', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCategorisedAudioCallData(params) {
        return this.http.post('api/Dashboard/GetCategorisedAudioCallData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCampaignCategorisedData(params) {
        return this.http.post('api/Dashboard/GetCampaignCategorisedData', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCumulativeCalls(params) {
        return this.http.post('api/Dashboard/GetCumulativeCalls', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientElementName(params) {
        return this.http.post('api/Dashboard/GetClientElementName', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCampaignColumnName(params) {
        return this.http.post('api/Dashboard/GetCampaignColumnNameByProduct', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
