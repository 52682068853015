import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { HighchartsChartModule } from 'highcharts-angular';
import { StaticdashboardRoutingModule } from './staticdashboard-routing.module';
import { OutcomeAnalysisComponent } from './outcome-analysis/outcome-analysis.component';
import { SellingskillsComponent } from './sellingskills/sellingskills.component';
import { SellingskillselementComponent } from './sellingskillselement/sellingskillselement.component';
import { SharedTopComponent } from './shared-top/shared-top.component';
import { UkComponent } from './Region/UK-Region/uk/uk.component';
import { GlanceComponent } from './glance/glance.component';
import { CampaignImplementationComponent } from './campaign-implementation/campaign-implementation.component';
import { CampaignelementComponent } from './campaignelement/campaignelement.component';
import { CallCharacteristicsComponent } from './call-characteristics/call-characteristics.component';
import { CallactionAnalysisComponent } from './callaction-analysis/callaction-analysis.component';
import { StaticnavComponent } from './staticnav/staticnav.component';
import { UsRegionComponent } from './Region/US-Region/us-region/us-region.component';


@NgModule({
  declarations: [OutcomeAnalysisComponent, SellingskillsComponent, SellingskillselementComponent, SharedTopComponent, UkComponent, GlanceComponent, CampaignImplementationComponent, CampaignelementComponent, CallCharacteristicsComponent, CallactionAnalysisComponent, StaticnavComponent, UsRegionComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        DragDropModule,
        MatTabsModule,
        HighchartsChartModule,
        MatCheckboxModule,
        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
    StaticdashboardRoutingModule
  ]
})
export class StaticdashboardModule { }
