import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { LeftMenuData } from '../../models/slsm/left-menu-data.model';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

interface product {
    value: string;
    viewValue: string;
}
@Component({
    selector: 'app-profilepage',
    templateUrl: './profilepage.component.html',
    styleUrls: ['./profilepage.component.css']
})
export class ProfilepageComponent implements OnInit {


    isHidden: boolean = true;
    isHiddenMdle: boolean = false;

    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];

    userId: any = window.localStorage.getItem("UserId");

    @ViewChild('ProgCI', { static: true }) ProgCI: ElementRef;
    @ViewChild('ProgCIm', { static: true }) ProgCIm: ElementRef;

    slsmLeftMenuData: any;
    leftMenuData: LeftMenuData;

    countryList: any = [];
    selectedCountry: any = {};
    selectedCountryLogo: any = "";
    isHiddenMdl: boolean = false;

    goodCallCategories: any = [];
    goodCallData: any = [];
    notGoodCallCategories: any = [];
    notGoodCallData: any = [];
    goodCallAverageData: any = {};

    selectedLanguage: string = "En";

    constructor(
        private slsmService: SlsmService
    ) { }

    ngOnInit() {

        //Highcharts.chart('ProgCI', this.poptin);
        //Highcharts.chart('ProgCIm', this.poptino)
        Highcharts.chart('ProgCII', this.poptinn);
        Highcharts.chart('ProgCImm', this.poptinoo);
        $('.cstm-nav-link').click(function () {
            $('.cstm-nav-link.active').removeClass('active');
            $(this).addClass('active');
        });
        $('.show-Status').click(function () {
            $('.show-Status.actStatus').removeClass('actStatus');
            $(this).addClass('actStatus');
        });

        this.leftMenuData = new LeftMenuData();
        this.getCountryData();
    }

    getCountryData() {
        var param = {
            UserId: parseInt(this.userId),
            ViewType: window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : 1
        };
        this.slsmService.GetCountryWiseClientData(param).subscribe((result: any) => {
            if (result.length > 0) {
                this.countryList = result.filter(x => x.clientId != 0);
                if (window.localStorage.getItem("CountryKey") != null) {
                    this.selectedCountry = this.countryList.find(x => x.countryCode == window.localStorage.getItem("CountryKey"));
                    this.selectedCountryLogo = "/CountryFlags/" + this.selectedCountry.countryName + ".png";
                    this.getProducts(this.selectedCountry.clientId);
                }

            }
        }, error => console.error(error));
    }

    onCountrySelect(country) {
        window.localStorage.setItem("CountryKey", country.countryCode);
        this.selectedCountry = this.countryList.find(x => x.countryCode == country.countryCode);
        this.selectedCountryLogo = "/CountryFlags/" + this.selectedCountry.countryName + ".png";
        this.hideDailog();
        this.getProducts(this.selectedCountry.clientId);
    }

    getProducts(clientId) {
        this.getDashboardDataByClient(clientId);
        //call api to get products list based on client.
    }

    getDashboardDataByClient(clientId) {
        this.goodCallCategories = [];
        this.goodCallData = [];
        this.notGoodCallCategories = [];
        this.notGoodCallData = [];

        var params = {
            ClientId: clientId,
            UserId: parseInt(this.userId),
            ViewType: window.localStorage.getItem("ViewType") != null ? parseInt(window.localStorage.getItem("ViewType")) : 1,
            LanguageCode: this.selectedLanguage
        };
        this.slsmService.GetDashboardDataByClient(params).subscribe((result: any) => {

            //filtering good calls data.
            if (result.goodCallsByElement.length > 0) {
                var filterGoodCallData = result.goodCallsByElement.filter(x => x.outcomeValue == 4);
                filterGoodCallData.forEach(obj => {
                    this.goodCallCategories.push(obj.elementName);
                    this.goodCallData.push(obj.score);
                });
                var filterNotGoodCallData = result.goodCallsByElement.filter(x => x.outcomeValue == 3);
                filterNotGoodCallData.forEach(obj => {
                    this.notGoodCallCategories.push(obj.elementName);
                    this.notGoodCallData.push(obj.score);
                });
            }

            //getting audio calls avgs.
            if (result.audioCallAverage != null) {
                this.leftMenuData.TotalAudioCallAvg = result.audioCallAverage.totalAudioCallAvg;
                this.leftMenuData.DoctorSpeakTimeAvg = result.audioCallAverage.doctorSpeakTimeAvg;
                this.leftMenuData.RepSpeakTimeAvg = result.audioCallAverage.repSpeakTimeAvg;
            }

            //getting good calls avgs.
            if (result.goodCallAverage != null) {
                this.goodCallAverageData = result.goodCallAverage;
            }

            this.drawGoodCallNCGraph(this.notGoodCallCategories, this.notGoodCallData);
            this.drawGoodCallVCGraph(this.goodCallCategories, this.goodCallData);

            //To get selling skill avg by month.
            this.slsmService.GetSellingSkillAvgByMonth(params).subscribe((resultSS: any) => {

                this.slsmLeftMenuData = { SellingSkillAvg: this.selectedCountry.sellingSkillScore, CampaignImpAvg: 42, TotalAudioCallAvg: this.leftMenuData.TotalAudioCallAvg, DoctorSpeakTimeAvg: this.leftMenuData.DoctorSpeakTimeAvg, RepSpeakTimeAvg: this.leftMenuData.RepSpeakTimeAvg, SellingSkillMonthlyAvg: resultSS };
            });

        }, error => console.error(error));
    }

    openDialog() {
        this.isHiddenMdl = true;
    }

    hideDailog() {
        this.isHiddenMdl = false;
    }

    drawGoodCallNCGraph(categories, data) {
        Highcharts.chart(this.ProgCI.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'bar',
                    marginTop: 55,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 0,
                    backgroundColor: '#f7f7f7',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        enabled: this.goodCallData.length > 0 ? false : true,
                        align: 'right',
                        x: 460,
                        y: -25,
                        style: {
                            fontSize: '13px',
                        },
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickLength: 0,

                    title: {
                        text: null
                    },
                    gridLineColor: 'transparent',
                },
                yAxis: {
                    reversed: true,
                    title: false,
                    labels: {
                        enabled: false,
                        format: '{value}%',
                        align: 'top',
                        y: -395
                    },
                    min: 0,
                    max: 100,
                    gridLineColor: 'transparent',
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    bar: {

                        borderRadius: 5
                    },
                    series: {
                        pointPadding: 0.2,
                        dataLabels: {
                            enabled: true,
                            format: '{y}% ',
                            x: -7,
                            style: {
                                color: '#012072',
                                fontSize: '15px',
                                fontWeight: 'bolder',
                            }
                        }
                    }
                },
                series: [{
                    color: '#09d6cc',
                    data: data
                }]
            })
        );
    }

    drawGoodCallVCGraph(categories, data) {
        Highcharts.chart(this.ProgCIm.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'bar',
                    marginTop: 55,
                    marginLeft: 10,
                    marginRight: 25,
                    backgroundColor: '#f7f7f7',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '16px',

                    }
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        enabled: true,
                        align: 'left',
                        x: 10,
                        y: -25,
                        style: {
                            fontSize: '13px',
                        },

                    },
                    left: "-1%",
                    title: {
                        text: null
                    },

                    gridLineColor: 'transparent',
                },
                yAxis: {

                    title: false,
                    labels: {
                        enabled: false,
                        format: '{value}%',
                        align: 'top',
                        y: -390,
                        x: -8,
                        lineWidth: 1,
                    },
                    tickLength: 1,
                    tickInterval: 10,
                    min: 0,
                    max: 100,
                    gridLineColor: 'transparent',
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    bar: {

                        borderRadius: 5,

                    },
                    series: {

                        pointPadding: 0.2,
                        dataLabels: {
                            enabled: true,
                            format: '{y}% ',
                            x: 7,
                            style: {
                                color: '#012072',
                                fontSize: '15px',
                                fontWeight: 'bolder',
                            }
                        }
                    }
                },
                series: [{
                    color: '#09d6cc',
                    data: data
                }]
            })
        );
    }

    public poptinn: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 0,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Positioning', 'Primary efficacy', 'Secondary efficacy', 'Safety'],
            labels: {
                enabled: true,
                align: 'left',
                x: 440,
                y: -25,
                style: {
                    fontSize: '13px',
                },
            },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,

            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {
            reversed: true,
            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -395
            },
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5
            },
            series: {
                pointPadding: 0.25,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: -7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{

            color: '#09d6cc',
            data: [21, 51, 16, 12]
        }]
    }
    public poptinoo: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginLeft: 10,
            marginRight: 25,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Positioning', 'Primary efficacy', 'Secondary efficacy', 'Safety'],
            labels: {
                enabled: true,
                align: 'left',
                x: 10,
                y: -25,
                style: {
                    fontSize: '13px',
                },

            },
            left: "-1%",
            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {

            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -390,
                x: -8,
                lineWidth: 1,
            },
            tickLength: 1,
            tickInterval: 10,
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5,

            },
            series: {
                pointPadding: 0.25,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: 7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{
            color: '#09d6cc',
            data: [72, 93, 84, 75]
        }]
    }
    showTab1() {
        this.isHidden = true;
        this.isHiddenMdle = false;
    }
    showTab2() {
        this.isHidden = false;
        this.isHiddenMdle = true;
    }

}
