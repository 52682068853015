import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    headerParam: any = { 'Authorization': 'bearer ' + this.tokenService.getToken() };

    getMasterDataList() {
        return this.http.get('api/Admin/GetMasterDataList', { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getClientList() {
        return this.http.get('api/Admin/GetClientList', { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpateClient(params) {
        return this.http.post('api/Admin/AddUpdateClient', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getSalesModalList(param) {
        return this.http.get('api/Admin/GetSalesModalList/' + param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpdateSalesModal(params) {
        return this.http.post('api/Admin/AddUpdateSalesModal', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getCategoryList(param) {
        return this.http.get('api/Admin/GetCategoryList/' + param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpdateCategory(params) {
        return this.http.post('api/Admin/AddUpdateCategory', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    getElementList(param) {
        return this.http.get('api/Admin/GetElementList/' + param, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }

    addUpdateElement(params) {
        return this.http.post('api/Admin/AddUpdateElement', params, { headers: this.headerParam }).pipe(map((
            responsedata => {
                return responsedata;
            }
        )));
    }
}
