export class User {
    UserId: number;
    FirstName: string;
    LastName: string;
    EmailId: string;
    UserName: string;
    Password: string;
    ProfileId: number;
    ClientId: number;
    ManagerId: any[] = [];
    ProductId: any[] = [];
    IsActive: boolean;
    RegionId: number;
    FranchiseId: number;
}
