import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallCharacteristicsComponent } from './call-characteristics/call-characteristics.component';
import { CallactionAnalysisComponent } from './callaction-analysis/callaction-analysis.component';
import { CampaignImplementationComponent } from './campaign-implementation/campaign-implementation.component';
import { CampaignelementComponent } from './campaignelement/campaignelement.component';
import { GlanceComponent } from './glance/glance.component';
import { OutcomeAnalysisComponent } from './outcome-analysis/outcome-analysis.component';
import { SellingskillsComponent } from './sellingskills/sellingskills.component';
import { SellingskillselementComponent } from './sellingskillselement/sellingskillselement.component';


const routes: Routes = [
    { path: 'staticdashboarduk/SS', component: SellingskillsComponent },
    { path: 'staticdashboarduk/OA', component: OutcomeAnalysisComponent },
    { path: 'staticdashboarduk/CI', component: CampaignImplementationComponent },
    { path: 'staticdashboarduk/AG', component: GlanceComponent },
    { path: 'staticdashboarduk/SE', component: SellingskillselementComponent },
    { path: 'staticdashboarduk/CE', component: CampaignelementComponent },
    { path: 'staticdashboarduk/CAC', component: CallactionAnalysisComponent },
    { path: 'staticdashboarduk/CC', component: CallCharacteristicsComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticdashboardukRoutingModule { }
