import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);


@Component({
    selector: 'app-campaignelement',
    templateUrl: './campaignelement.component.html',
    styleUrls: ['./campaignelement.component.css']
})
export class CampaignelementComponent implements OnInit {

    constructor(
        private dashboardService: DashboardService
    ) { }

    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    selectedViewType: string = "WEEK";
    staticTextData: any;

    categorisedCampaignDataList: any = [];
    campaignColumnName: any = {};

    @ViewChild('CPatientType', { static: true }) CPatientType: ElementRef;
    @ViewChild('CMessage1', { static: true }) CMessage1: ElementRef;
    @ViewChild('CMessage2', { static: true }) CMessage2: ElementRef;
    @ViewChild('CMessage3', { static: true }) CMessage3: ElementRef;
    @ViewChild('CMessage4', { static: true }) CMessage4: ElementRef;

    ngOnInit() {
        this.getLanguageStaticText();

        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        this.clientLogo = window.localStorage.getItem("ClientLogo");
        this.getCampaignColumnName();
        //this.getCategorisedCampaignData();
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getCategorisedCampaignData();
        }, error => console.error(error));
    }

    getCampaignColumnName() {
        var paramObj = {
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage"),
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId
        }
        this.dashboardService.getCampaignColumnName(paramObj).subscribe((result: any) => {
            this.campaignColumnName = result;
        },
            error => console.error(error));
    }

    getCategorisedCampaignData() {
        this.totalNoOfCalls = 0;

        var obj = {
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ViewType: this.selectedViewType,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }
        $('.loder-outer').show();
        this.dashboardService.getCampaignCategorisedData(obj).subscribe((result: any) => {

            this.categorisedCampaignDataList = result;
            $('.loder-outer').hide();
            //Position Data section
            var positionMentionedDataArray = [];
            var positionNotMentionedDataArray = [];
            this.categorisedCampaignDataList.column1DataList.forEach(objPosition => {
                //positionMentionedDataArray.push(objPosition.mentionedCall);
                //positionNotMentionedDataArray.push(objPosition.notMentionedCall);

                positionMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.mentionedCall });
                positionNotMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.notMentionedCall });

                //this will help to pass the data from parent to its child class.
                this.totalNoOfCalls = this.totalNoOfCalls + objPosition.totalCalls;
            });

            positionMentionedDataArray = this.selectedViewType == "MONTH" ? positionMentionedDataArray : positionMentionedDataArray.reverse();
            positionNotMentionedDataArray = this.selectedViewType == "MONTH" ? positionNotMentionedDataArray : positionNotMentionedDataArray.reverse();

            //new position mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newPositionMentionedData = [];
            var newPositionMentionedDataIndex = 0;
            positionMentionedDataArray.forEach(objPositonMentioned => {
                if (objPositonMentioned.totalCall > 0 || newPositionMentionedDataIndex == 1) {
                    newPositionMentionedDataIndex = 1;
                    newPositionMentionedData.push(objPositonMentioned.score);
                }
            });

            for (var i = newPositionMentionedData.length; i < 12; i++) {
                newPositionMentionedData[i] = 0;
            }
            //end here

            //new position not mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newPositionNotMentionedData = [];
            var newPositionNotMentionedDataIndex = 0;
            positionNotMentionedDataArray.forEach(objPositionNotMentioned => {
                if (objPositionNotMentioned.totalCall > 0 || newPositionNotMentionedDataIndex == 1) {
                    newPositionNotMentionedDataIndex = 1;
                    newPositionNotMentionedData.push(objPositionNotMentioned.score);
                }
            });

            for (var i = newPositionNotMentionedData.length; i < 12; i++) {
                newPositionNotMentionedData[i] = 0;
            }
            //end here

            this.drawMessage1Graph(newPositionMentionedData, newPositionNotMentionedData);//(positionMentionedDataArray, positionNotMentionedDataArray);
            //End Position Section

            //Efficacy Data section
            var efficacyMentionedDataArray = [];
            var efficacyNotMentionedDataArray = [];
            this.categorisedCampaignDataList.column2DataList.forEach(objEfficacy => {
                //efficacyMentionedDataArray.push(objEfficacy.mentionedCall);
                //efficacyNotMentionedDataArray.push(objEfficacy.notMentionedCall);
                efficacyMentionedDataArray.push({ totalCall: objEfficacy.totalCalls, score: objEfficacy.mentionedCall });
                efficacyNotMentionedDataArray.push({ totalCall: objEfficacy.totalCalls, score: objEfficacy.notMentionedCall });
            });

            efficacyMentionedDataArray = this.selectedViewType == "MONTH" ? efficacyMentionedDataArray : efficacyMentionedDataArray.reverse();
            efficacyNotMentionedDataArray = this.selectedViewType == "MONTH" ? efficacyNotMentionedDataArray : efficacyNotMentionedDataArray.reverse();

            //new efficacy mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newEfficacyMentionedData = [];
            var newEfficacyMentionedDataIndex = 0;
            efficacyMentionedDataArray.forEach(objEfficacyMentioned => {
                if (objEfficacyMentioned.totalCall > 0 || newEfficacyMentionedDataIndex == 1) {
                    newEfficacyMentionedDataIndex = 1;
                    newEfficacyMentionedData.push(objEfficacyMentioned.score);
                }
            });

            for (var i = newEfficacyMentionedData.length; i < 12; i++) {
                newEfficacyMentionedData[i] = 0;
            }
            //end here

            //new efficacy not mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newEfficacyNotMentionedData = [];
            var newEfficacyNotMentionedDataIndex = 0;
            efficacyNotMentionedDataArray.forEach(objEfficacyNotMentioned => {
                if (objEfficacyNotMentioned.totalCall > 0 || newEfficacyNotMentionedDataIndex == 1) {
                    newEfficacyNotMentionedDataIndex = 1;
                    newEfficacyNotMentionedData.push(objEfficacyNotMentioned.score);
                }
            });

            for (var i = newEfficacyNotMentionedData.length; i < 12; i++) {
                newEfficacyNotMentionedData[i] = 0;
            }
            //end here

            this.drawMessage2Graph(newEfficacyMentionedData, newEfficacyNotMentionedData);//(efficacyMentionedDataArray, efficacyNotMentionedDataArray);
            //End Efficacy Section

            //Safety Data section
            var safetyMentionedDataArray = [];
            var safetyNotMentionedDataArray = [];
            this.categorisedCampaignDataList.column3DataList.forEach(objSafety => {
                //safetyMentionedDataArray.push(objSafety.mentionedCall);
                //safetyNotMentionedDataArray.push(objSafety.notMentionedCall);
                safetyMentionedDataArray.push({ totalCall: objSafety.totalCalls, score: objSafety.mentionedCall });
                safetyNotMentionedDataArray.push({ totalCall: objSafety.totalCalls, score: objSafety.notMentionedCall });
            });

            safetyMentionedDataArray = this.selectedViewType == "MONTH" ? safetyMentionedDataArray : safetyMentionedDataArray.reverse();
            safetyNotMentionedDataArray = this.selectedViewType == "MONTH" ? safetyNotMentionedDataArray : safetyNotMentionedDataArray.reverse();

            //new safety mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newSafetyMentionedData = [];
            var newSafetyMentionedDataIndex = 0;
            safetyMentionedDataArray.forEach(objSafetyMentioned => {
                if (objSafetyMentioned.totalCall > 0 || newSafetyMentionedDataIndex == 1) {
                    newSafetyMentionedDataIndex = 1;
                    newSafetyMentionedData.push(objSafetyMentioned.score);
                }
            });

            for (var i = newSafetyMentionedData.length; i < 12; i++) {
                newSafetyMentionedData[i] = 0;
            }
            //end here

            //new safety not mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
            var newSafetyNotMentionedData = [];
            var newSafetyNotMentionedDataIndex = 0;
            safetyNotMentionedDataArray.forEach(objSafetyNotMentioned => {
                if (objSafetyNotMentioned.totalCall > 0 || newSafetyNotMentionedDataIndex == 1) {
                    newSafetyNotMentionedDataIndex = 1;
                    newSafetyNotMentionedData.push(objSafetyNotMentioned.score);
                }
            });

            for (var i = newSafetyNotMentionedData.length; i < 12; i++) {
                newSafetyNotMentionedData[i] = 0;
            }
            //end here

            this.drawMessage3Graph(newSafetyMentionedData, newSafetyNotMentionedData);//(safetyMentionedDataArray, safetyNotMentionedDataArray);
            //End Safety Section

            this.calculateColumn4Data();
            this.calculateColumn5Data();

        }, error => console.error(error));
    }

    calculateColumn4Data() {
        //Column4 Data section
        var positionMentionedDataArray = [];
        var positionNotMentionedDataArray = [];
        this.categorisedCampaignDataList.column4DataList.forEach(objPosition => {
            //positionMentionedDataArray.push(objPosition.mentionedCall);
            //positionNotMentionedDataArray.push(objPosition.notMentionedCall);

            positionMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.mentionedCall });
            positionNotMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.notMentionedCall });

            //this will help to pass the data from parent to its child class.
            this.totalNoOfCalls = this.totalNoOfCalls + objPosition.totalCalls;
        });

        positionMentionedDataArray = this.selectedViewType == "MONTH" ? positionMentionedDataArray : positionMentionedDataArray.reverse();
        positionNotMentionedDataArray = this.selectedViewType == "MONTH" ? positionNotMentionedDataArray : positionNotMentionedDataArray.reverse();

        //new position mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
        var newPositionMentionedData = [];
        var newPositionMentionedDataIndex = 0;
        positionMentionedDataArray.forEach(objPositonMentioned => {
            if (objPositonMentioned.totalCall > 0 || newPositionMentionedDataIndex == 1) {
                newPositionMentionedDataIndex = 1;
                newPositionMentionedData.push(objPositonMentioned.score);
            }
        });

        for (var i = newPositionMentionedData.length; i < 12; i++) {
            newPositionMentionedData[i] = 0;
        }
        //end here

        //new position not mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
        var newPositionNotMentionedData = [];
        var newPositionNotMentionedDataIndex = 0;
        positionNotMentionedDataArray.forEach(objPositionNotMentioned => {
            if (objPositionNotMentioned.totalCall > 0 || newPositionNotMentionedDataIndex == 1) {
                newPositionNotMentionedDataIndex = 1;
                newPositionNotMentionedData.push(objPositionNotMentioned.score);
            }
        });

        for (var i = newPositionNotMentionedData.length; i < 12; i++) {
            newPositionNotMentionedData[i] = 0;
        }
        //end here

        this.drawMessage4Graph(newPositionMentionedData, newPositionNotMentionedData);//(positionMentionedDataArray, positionNotMentionedDataArray);
        //End Column4 Data Section
    }

    calculateColumn5Data() {
        //Column5 Data section
        var positionMentionedDataArray = [];
        var positionNotMentionedDataArray = [];
        this.categorisedCampaignDataList.column5DataList.forEach(objPosition => {
            //positionMentionedDataArray.push(objPosition.mentionedCall);
            //positionNotMentionedDataArray.push(objPosition.notMentionedCall);

            positionMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.mentionedCall });
            positionNotMentionedDataArray.push({ totalCall: objPosition.totalCalls, score: objPosition.notMentionedCall });

            //this will help to pass the data from parent to its child class.
            this.totalNoOfCalls = this.totalNoOfCalls + objPosition.totalCalls;
        });

        positionMentionedDataArray = this.selectedViewType == "MONTH" ? positionMentionedDataArray : positionMentionedDataArray.reverse();
        positionNotMentionedDataArray = this.selectedViewType == "MONTH" ? positionNotMentionedDataArray : positionNotMentionedDataArray.reverse();

        //new position mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
        var newPositionMentionedData = [];
        var newPositionMentionedDataIndex = 0;
        positionMentionedDataArray.forEach(objPositonMentioned => {
            if (objPositonMentioned.totalCall > 0 || newPositionMentionedDataIndex == 1) {
                newPositionMentionedDataIndex = 1;
                newPositionMentionedData.push(objPositonMentioned.score);
            }
        });

        for (var i = newPositionMentionedData.length; i < 12; i++) {
            newPositionMentionedData[i] = 0;
        }
        //end here

        //new position not mentioned data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
        var newPositionNotMentionedData = [];
        var newPositionNotMentionedDataIndex = 0;
        positionNotMentionedDataArray.forEach(objPositionNotMentioned => {
            if (objPositionNotMentioned.totalCall > 0 || newPositionNotMentionedDataIndex == 1) {
                newPositionNotMentionedDataIndex = 1;
                newPositionNotMentionedData.push(objPositionNotMentioned.score);
            }
        });

        for (var i = newPositionNotMentionedData.length; i < 12; i++) {
            newPositionNotMentionedData[i] = 0;
        }
        //end here

        this.drawMessage5Graph(newPositionMentionedData, newPositionNotMentionedData);//(positionMentionedDataArray, positionNotMentionedDataArray);
        //End Column5 Data Section
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getCategorisedCampaignData();
        this.getCampaignColumnName();
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getCategorisedCampaignData();
    }

    changeViewType(type) {
        this.selectedViewType = type;
        this.getCategorisedCampaignData();
    }


    drawMessage1Graph(mentionedCallData, notMentionedCallData) {
        Highcharts.chart(this.CPatientType.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.campaignColumnName.campaignColumn1Name,//'Target Patient',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: [],//['Mentioned in call', 'Not Mentioned in call'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    align: 'center',
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.staticTextData.mentionedInCall,//'Mentioned in call',
                    color: '#00b050',
                    data: mentionedCallData
                }, {
                    name: this.staticTextData.notMentionedInCall,//'Not Mentioned in call',
                    color: '#fe0000',
                    data: notMentionedCallData
                }],
            })
        );
    }

    drawMessage2Graph(mentionedCallData, notMentionedCallData) {
        Highcharts.chart(this.CMessage1.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.campaignColumnName.campaignColumn2Name,//'Message 1',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: ['Mentioned in call', 'Not Mentioned in call'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    align: 'center',
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.staticTextData.mentionedInCall,//'Mentioned in call',
                    color: '#00b050',
                    data: mentionedCallData
                }, {
                    name: this.staticTextData.notMentionedInCall,//'Not Mentioned in call',
                    color: '#fe0000',
                    data: notMentionedCallData
                }],
            })
        );
    }

    drawMessage3Graph(mentionedCallData, notMentionedCallData) {
        Highcharts.chart(this.CMessage2.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.campaignColumnName.campaignColumn3Name,//'Message 2',
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: ['Mentioned in call', 'Not Mentioned in call'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    align: 'center',
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.staticTextData.mentionedInCall,//'Mentioned in call',
                    color: '#00b050',
                    data: mentionedCallData
                }, {
                    name: this.staticTextData.notMentionedInCall,//'Not Mentioned in call',
                    color: '#fe0000',
                    data: notMentionedCallData
                }],
            })
        );
    }

    drawMessage4Graph(mentionedCallData, notMentionedCallData) {
        Highcharts.chart(this.CMessage3.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.campaignColumnName.campaignColumn4Name,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: ['Mentioned in call', 'Not Mentioned in call'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    align: 'center',
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.staticTextData.mentionedInCall,//'Mentioned in call',
                    color: '#00b050',
                    data: mentionedCallData
                }, {
                    name: this.staticTextData.notMentionedInCall,//'Not Mentioned in call',
                    color: '#fe0000',
                    data: notMentionedCallData
                }],
            })
        );
    }

    drawMessage5Graph(mentionedCallData, notMentionedCallData) {
        Highcharts.chart(this.CMessage4.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'column',
                    marginBottom: 50,
                    marginLeft: 60,
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: this.campaignColumnName.campaignColumn5Name,
                    align: 'left',
                    style: {
                        color: '#012072',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
                xAxis: {
                    min: 1,
                    max: 12,
                    tickInterval: 1,
                    category: ['Mentioned in call', 'Not Mentioned in call'],
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}%'
                    },
                    reversedStacks: false
                },
                legend: {
                    symbolRadius: 0,
                    itemMarginTop: 0,
                    itemMarginBottom: 0,
                    itemMarginRight: 0,
                    itemMarginLeft: 0,
                    floating: false,
                    y: 10,
                    align: 'center',
                    symbolPadding: 0,
                    itemStyle: {
                        fontSize: '9px'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        pointPadding: 0.23,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.staticTextData.mentionedInCall,//'Mentioned in call',
                    color: '#00b050',
                    data: mentionedCallData
                }, {
                    name: this.staticTextData.notMentionedInCall,//'Not Mentioned in call',
                    color: '#fe0000',
                    data: notMentionedCallData
                }],
            })
        );
    }

}
