import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

interface product {
    value: string;
    viewValue: string;
}
export interface PeriodicElement {
    DSSkil: number;
    position: number;
    DCImplem: number;
    imageUrl: string;
    CountryName: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    { imageUrl: 'assets/images/flags/Australia.png', position: 1, CountryName: 'Australia', DSSkil: 3.5, DCImplem: 32, },
    { imageUrl: 'assets/images/flags/UK.png', position: 2, CountryName: 'UK', DSSkil: 2.6, DCImplem: 42, },
    { imageUrl: 'assets/images/flags/Canada.png', position: 3, CountryName: 'Canada', DSSkil: 2.2, DCImplem: 53 },
    { imageUrl: 'assets/images/flags/USA.png', position: 4, CountryName: 'USA', DSSkil: 2.1, DCImplem: 32, },
    { imageUrl: 'assets/images/flags/Brazil.png', position: 5, CountryName: 'Brazil', DSSkil: 1.8, DCImplem: 53 },
];

@Component({
    selector: 'app-demohome',
    templateUrl: './demohome.component.html',
    styleUrls: ['./demohome.component.css']
})
export class DemohomeComponent implements OnInit {

    isHidden: boolean = false;
    isHiddenMdl: boolean = false;
    isHiddenInfoMdl: boolean = false;
    @ViewChild('table', { static: false }) table: MatTable<PeriodicElement>;
    displayedColumns: string[] = ['position', 'imageUrl', 'CountryName', 'DSSkil', 'DCImplem'];
    dataSource = ELEMENT_DATA;

    dropTable(event: CdkDragDrop<PeriodicElement[]>) {
        const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
        moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
        this.table.renderRows();
    }

    public opti: any = {
        chart: {
            type: 'column',
            marginBottom: 25,
            marginLeft: 60,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: 'Selling Skills',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            type: 'category',/* ['Opening', 'Questioning', 'Presenting', 'Closing'],*/
            labels: {
                style: {
                    color: '#66707f'

                }
            }

        },

        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 10,
            title: {
                text: null
            },
            labels: {
                format: '{value}%'
            },
            reversedStacks: true
        },
        legend: {
            symbolRadius: 0,
            align: 'right',
            verticalAlign: 'top',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            itemMarginRight: 0,
            itemMarginLeft: 0,
            floating: false,
            y: -40,
            symbolPadding: 0,
            itemStyle: {
                fontSize: '10px'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                pointPadding: 0.2,
                borderColor: null,
            }
        },
        credits: {
            enabled: false
        },
        //series: [{
        //    name: 'Opening',
        //    color: '#fce315',
        //    data: [4, 14, 6, 15]
        //}, {
        //    name: 'Questioning',
        //    color: '#4f80ff',
        //    data: [5, 3, 12, 6]
        //}, {
        //    name: 'Questioning',
        //    color: '#09d6cc',
        //    data: [5, 3, 12, 6]
        //}, {
        //    name: 'Closing',
        //    color: '#012072',
        //    data: [65, 45, 38, 45]
        //    }],
        series: [{
            visible: false,
            name: 'Not Observed',
            color: '#f1433f',
            data: [{
                name: 'Opening',
                y: 25,
                drilldown: 'Opening-f'
            }, {
                name: 'Questioning',
                y: 22,
                drilldown: 'Questioning-f'
            },
            {
                name: 'Presenting',
                y: 30,
                drilldown: 'Presenting-f'
            },
            {
                name: 'Closing',
                y: 29,
                drilldown: 'closing-f'
            },
            {
                name: 'Outcome',
                y: 25,
                drilldown: 'outcome-f'
            }]
        }, {
            visible: false,
            name: 'Foundational',
            color: '#f7bb00',
            data: [{
                name: 'Opening',
                y: 14,
                drilldown: 'Opening-A'
            },
            {
                name: 'Questioning',
                y: 34,
                drilldown: 'Questioning-A'
            },
            {
                name: 'Presenting',
                y: 14,
                drilldown: 'Presenting-A'
            },
            {
                name: 'Closing',
                y: 32,
                drilldown: 'closing-A'
            },
            {
                name: 'Outcome',
                y: 23,
                drilldown: 'outcome-A'
            }]
        },
        {
            visible: false,
            name: 'Developing',
            color: '#f7f700',
            data: [{
                name: 'Opening',
                y: 31,
                drilldown: 'Opening-G'
            },
            {
                name: 'Questioning',
                y: 2,
                drilldown: 'Questioning-G'
            },
            {
                name: 'Presenting',
                y: 23,
                drilldown: 'Presenting-G'
            },
            {
                name: 'Closing',
                y: 11,
                drilldown: 'closing-G'
            },
            {
                name: 'Outcome',
                y: 23,
                drilldown: 'outcome-G'
            }
            ]
        },
        {
            name: 'Accomplished',
            color: '#00b050',
            data: [{
                name: 'Opening',
                y: 30,
                drilldown: 'Opening-E'
            }, {
                name: 'Questioning',
                y: 42,
                drilldown: 'Questioning-E'
            }, {
                name: 'Presenting',
                y: 33,
                drilldown: 'Presenting-E'
            },
            {
                name: 'Closing',
                y: 28,
                drilldown: 'closing-E'
            },
            {
                name: 'Outcome',
                y: 29,
                drilldown: 'outcome-E'
            }]
        }
        ],
        drilldown: {

            series: [{
                stacking: null,
                id: 'Opening-f',
                name: 'Not Observed',
                data: [
                    ['jun', 14],
                    ['july', 22],
                    ['aug', 31],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'Opening-A',
                name: 'Foundational',
                data: [
                    ['jun', 24],
                    ['july', 32],
                    ['aug', 41],
                    ['sep', 54],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'Opening-G',
                name: 'Developing',
                data: [
                    ['jun', 41],
                    ['july', 22],
                    ['aug', 13],
                    ['sep', 41],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },

            {
                stacking: null,
                id: 'Opening-E',
                name: 'Accomplished',
                data: [
                    ['jun', 20],
                    ['july', 22],
                    ['aug', 26],
                    ['sep', 25],
                    ['oct', 28],
                    ['nov', 30]
                ]
            },
            {
                stacking: null,
                id: 'Questioning-f',
                name: 'Not Observed',
                data: [
                    ['jun', 14],
                    ['july', 22],
                    ['aug', 31],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'Questioning-A',
                name: 'Foundational',
                data: [
                    ['jun', 24],
                    ['july', 32],
                    ['aug', 41],
                    ['sep', 54],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'Questioning-G',
                name: 'Developing',
                data: [
                    ['jun', 41],
                    ['july', 22],
                    ['aug', 13],
                    ['sep', 41],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },

            {
                stacking: null,
                id: 'Questioning-E',
                name: 'Accomplished',
                data: [
                    ['jun', 24],
                    ['july', 23],
                    ['aug', 11],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'Presenting-f',
                name: 'Not Observed',
                data: [
                    ['jun', 14],
                    ['july', 22],
                    ['aug', 31],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'Presenting-A',
                name: 'Foundational',
                data: [
                    ['jun', 24],
                    ['july', 32],
                    ['aug', 41],
                    ['sep', 54],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'Presenting-G',
                name: 'Developing',
                data: [
                    ['jun', 41],
                    ['july', 22],
                    ['aug', 13],
                    ['sep', 41],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },

            {
                stacking: null,
                id: 'Presenting-E',
                name: 'Accomplished',
                data: [
                    ['jun', 24],
                    ['july', 23],
                    ['aug', 11],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'closing-f',
                name: 'Not Observed',
                data: [
                    ['jun', 14],
                    ['july', 22],
                    ['aug', 31],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'closing-A',
                name: 'Foundational',
                data: [
                    ['jun', 24],
                    ['july', 32],
                    ['aug', 41],
                    ['sep', 54],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'closing-G',
                name: 'Developing',
                data: [
                    ['jun', 41],
                    ['july', 22],
                    ['aug', 13],
                    ['sep', 41],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },

            {
                stacking: null,
                id: 'closing-E',
                name: 'Accomplished',
                data: [
                    ['jun', 24],
                    ['july', 23],
                    ['aug', 11],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'outcome-f',
                name: 'Not Observed',
                data: [
                    ['jun', 14],
                    ['july', 22],
                    ['aug', 31],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }, {
                stacking: null,
                id: 'outcome-A',
                name: 'Foundational',
                data: [
                    ['jun', 24],
                    ['july', 32],
                    ['aug', 41],
                    ['sep', 54],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },
            {
                stacking: null,
                id: 'outcome-G',
                name: 'Developing',
                data: [
                    ['jun', 41],
                    ['july', 22],
                    ['aug', 13],
                    ['sep', 41],
                    ['oct', 20],
                    ['nov', 12]
                ]
            },

            {
                stacking: null,
                id: 'outcome-E',
                name: 'Accomplished',
                data: [
                    ['jun', 24],
                    ['july', 23],
                    ['aug', 11],
                    ['sep', 42],
                    ['oct', 20],
                    ['nov', 12]
                ]
            }

            ]
        }

    }
    public optin: any = {
        chart: {
            type: 'bar',
            marginBottom: 10,
            marginLeft: 25,
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: 'Accomplished Outcomes \u002d Call Profile',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Opening', 'Questioning', 'Presenting', 'Closing'],
            labels: {
                enabled: true,
                align: 'left',
                x: 10,
                y: -16
            },

            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {

            title: false,
            labels: {
                enabled: false,
                format: '{value}%'
            },
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5
            },
            series: {
                pointPadding: 0.23,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: 7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{

            color: '#09d6cc',
            data: [63, 73, 68, 64]
        }]
    }

    public optino: any = {
        chart: {
            type: 'column',
            marginBottom: 18,
            marginLeft: 60,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 10,
                depth: 0,
                ViewDistance: 50
            }
        },
        title: {
            text: 'Campaign Implementation',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            type: 'category',   /*categories: ['Positioning', 'Effcacy 1', 'Effcacy 2 ', 'Dosage'],*/
            gridLineColor: 'transparent',
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 10,
            title: {
                enabled: false
            },
            labels: {
                enabled: true,
                format: '{value}%'
            },
            gridLineColor: 'transparent',
        },

        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                depth: 100,
                pointPadding: 0.25,
                borderWidth: 1,
                color: {
                    linearGradient: [40, 40, 40, 744],
                    stops: [
                        [0, '#01ebf6'],
                        [0.4, '#046fbd'],
                        [0.6, 'black'],
                        [0.8, 'black'],
                        [1, 'black']
                    ]
                }
            }
        },
        credits: {
            enabled: false
        },

        series: [{
            data: [{
                name: 'Positioning',
                y: 55,
                drilldown: 'Positioning'
            }, {
                name: 'Efficacy 1⁰',
                y: 82,
                drilldown: 'Efficacy 1'
            }, {
                name: 'Efficacy 2⁰',
                y: 33,
                drilldown: 'Efficacy 2'
            },
            {
                name: 'Safety',
                y: 24,
                drilldown: 'Dosage'
            }]
        }

        ],
        drilldown: {
            series: [
                {
                    depth: 50,
                    stacking: null,
                    id: 'Positioning',
                    name: 'Positioning',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 1',
                    name: 'Efficacy 1⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Efficacy 2',
                    name: 'Efficacy 2⁰',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                },
                {
                    depth: 50,
                    stacking: null,
                    id: 'Dosage',
                    name: 'Safety',
                    data: [
                        ['jun', 24],
                        ['july', 23],
                        ['aug', 11],
                        ['sep', 42],
                        ['oct', 20],
                        ['nov', 12]
                    ]
                }

            ]
        }

    }
    public optinoi: any = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            marginBottom: 18,
            type: 'pie',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            },
        },
        title: {
            text: 'Positive Call Outcome',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            }

        },
        plotOptions: {
            pie: {
                cursor: 'pointer',

                dataLabels: {
                    enabled: true,
                    inside: true,
                    format: '{point.y} %',
                    distance: -35,
                    style: {
                        fontSize: '20px',
                        color: 'white',
                        textShadow: false,
                        textOutline: false
                    }
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Avg. Score',
            colorByPoint: true,
            data: [{
                name: 'Volunteered Commitment',
                y: 29,
                color: '#0abfb2',
                sliced: true,
                selected: true
            }, {
                name: 'No Commitment',
                y: 71,
                color: '#85a7ff',
            }]
        }]
    }


    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];


    constructor(
        private router: Router,
        private userService: UserService,
        private slsmService: SlsmService
    ) { }

    message: string;
    selectedLanguage: string = "En";
    
    elementDescription: any = [];
    labelsDescription: any = [];

    ngOnInit() {
        this.userService.getUserList().subscribe((result: any) => {
            this.message = result.userName;
        }, error => console.error(error));
        Highcharts.chart('SSkills', this.opti);
        Highcharts.chart('BProfile', this.optin);
        Highcharts.chart('CImplem', this.optino);
        Highcharts.chart('GCallD', this.optinoi);


        $('.cstm-Item').click(function () {
            $('.cstm-Item.Selected').removeClass('Selected');
            $(this).addClass('Selected');
        });
        this.getLablesDescription();
    }

    getLablesDescription() {
        this.slsmService.GetLablesDescription(this.selectedLanguage).subscribe((result: any) => {
            if (result.length > 0) {
                this.elementDescription = result.filter(x => x.isElement == true);
                this.labelsDescription = result.filter(x => x.isElement == false);
            }
        }, error => console.error(error));
    }

    CMSPage() {
        this.router.navigate(["client"]);
    }
    showGCallD() {
        this.isHidden = true;
    }
    hideGCallD() {
        this.isHidden = false;
    }
    openDialog() {
        this.isHiddenMdl = true;
    }
    hideDailog() {
        this.isHiddenMdl = false;
        this.isHiddenInfoMdl = false;
    }
    categoryinfoDialog() {
        this.isHiddenInfoMdl = true;
    }
}
