import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-call-characteristics',
    templateUrl: './call-characteristics.component.html',
    styleUrls: ['./call-characteristics.component.css']
})
export class CallCharacteristicsComponent implements OnInit {
    chart: any;
    updateFlag = false;
    Highcharts: typeof Highcharts = Highcharts;
    selectedView: string;
    constructor(
        private dashboardService: DashboardService
    ) {
        this.selectedView = localStorage.getItem('selectedView');
        this.chartOptions_2.yAxis.title.text = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
        this.chartOptions_2.series[0].name = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
        this.chartOptions_2.series[1].name = this.selectedView === "Msl" ? 'Length of interaction' : 'Length of call';
    }

   


    staticTextData: any;

 

    ngOnInit() {
        this.getLanguageStaticText();

       
        //this.getCategorisedAudioCallData();
    }

    getLanguageStaticText() {
        var objParam = {
           
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            
        }, error => console.error(error));
    }

    chartOptions_1 = {
        chart: {
            type: 'columnrange',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            style: {
                color: '#00b050',
                fontWeight: 'normal',
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            lineWidth: 1,
            minorGridLineWidth: 1,
            minorTickLength: 1,
            tickLength: 1,
            gridLineWidth: 1,
            min: 0,
            max: 100,
            tickInterval: 20,
            title: {
                text: '% ' + 'HCP Share of Voice',
                style: {
                    color: '#012072',
                    fontSize: '14px'

                },
            },
            labels: {
                style: {
                    color: '#999999'

                },
                format: '{value}%'
            },
            reversedStacks: false,
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                groupPadding: 0,
                borderWidth: 0,
                pointStart: 1,
            },
            columnrange: {
                dataLabels: {
                    enabled: true,
                    inside: false,
                    crop: false,

                    formatter: function () {
                        if (this.y > 0) {
                            if (this.y === this.point.high) {
                                return '<span style="color:#00b050">Max :' + Highcharts.numberFormat(this.point.y, 1) + '</span>';
                            }
                            if (this.y === this.point.low) {
                                return '<span style="color:#ff0000">Min :' + Highcharts.numberFormat(this.point.y, 1) + '</span>';
                            }
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return this.series.tooltipOptions.customTooltipPerSeries.call(this);
            },
        },
        series: [{
            name: '% ' + 'HCP Share of Voice',
            color: '#09d6cc',
            data: [
                [8.0, 40.0],
                [6.0, 60.0],
                [9.0, 65.0],
                [1.01, 30.0],
                [5.0, 47.0],
                [4.0, 40.0],
                [1.00, 62.0],
                [5.0, 55.0],
                [6.0, 70.0],
                [4.0, 72.0],
                [9.0, 50.0],
                [7.0, 44.0],
            ],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b><br/>' + '<br/>' + 'Max: ' + Highcharts.numberFormat(this.point.high, 1) + '<br/>' + 'Min: ' + Highcharts.numberFormat(this.point.low, 1) + '<br>'
                },

            },
        },
            {
                type: 'spline',
                name: '% ' + 'HCP Share of Voice',
                color: '#012072',
                data: [31.0, 29.0, 26.0, 23.0, 22.0, 27.0, 34.0, 38.0, 34.0, 35.0, 37.0, 34.0],
                tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b>' +  Highcharts.numberFormat(this.point.y, 1) ;
                },
                
            }
        }
        ]
    };
    chartOptions_2 = {
        chart: {
            type: 'columnrange',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            style: {
                color: '#00b050',
                fontWeight: 'normal',
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: '#66707f'
                }
            }
        },
        yAxis: {
            lineWidth: 1,
            minorGridLineWidth: 1,
            minorTickLength: 1,
            tickLength: 1,
            gridLineWidth: 1,
            min: 0,
            max: 50,
            tickInterval: 10,
            title: {
                text: 'Length of call',
                style: {
                    color: '#012072',
                    fontSize: '14px'
                },
            },
            labels: {
                style: {
                    color: '#999999'

                },
                format: '{value}' + 'Mins'
            },
            reversedStacks: false,
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                groupPadding: 0,
                borderWidth: 0,
                pointStart: 1,
            },
            columnrange: {
                dataLabels: {
                    enabled: true,
                    inside: false,
                    crop: false,
                    formatter: function () {
                        if (this.y > 0) {
                            if (this.y === this.point.high) {
                                return '<span style="color:#00b050">Max :' + Highcharts.numberFormat(this.point.y, 1) + '</span>';
                            }
                            if (this.y === this.point.low) {
                                return '<span style="color:#ff0000">Min :' + Highcharts.numberFormat(this.point.y, 1) + '</span>';
                            }
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return this.series.tooltipOptions.customTooltipPerSeries.call(this);
            }
        },
        series: [{
            name: 'Length Of Call',
            color: '#09d6cc',
            data: [
                [4.0, 30.0],
                [5.0, 42.0],
                [4.0, 35.0],
                [3.0, 45.0],
                [4.0, 40.0],
                [6.0, 26.0],
                [1.0, 15.0],
                [5.0, 38.0],
                [3.0, 31.0],
                [2.0, 40.0],
                [6.0, 29.0],
                [4.0, 17.0]
            ],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b><br/>' + '<br/>' + 'Max: ' + Highcharts.numberFormat(this.point.high, 1) + '<br/>' + 'Min: ' + Highcharts.numberFormat(this.point.low, 1) + '<br>'
                }
            },
        },
        {
            type: 'spline',
            name: 'Length of call',
            color: '#012072',
            data: [19.0, 21.0, 23.0, 21.0, 19.0, 18.0, 5.0, 19.0, 17.0, 15.0, 15.0, 13.0],
            tooltip: {
                customTooltipPerSeries: function () {
                    return '<b>' + this.series.name + '<b>' + Highcharts.numberFormat(this.point.y, 1);
                },
            }
        }
        ]
    };
    UpdateWeeK() {
        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data  = [
            [8, 40],
            [6, 60],
            [9, 65],
            [11, 30],
            [5, 47],
            [4, 40],
            [10, 62],
            [5, 55],
            [6, 70],
            [4, 72],
            [9, 50],
            [7, 44],
        ];
        this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [31, 29, 26, 23, 22, 27, 34, 38, 34, 35, 37, 34];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [4, 30],
            [5, 42],
            [4, 35],
            [3, 45],
            [4, 40],
            [6, 26],
            [1, 15],
            [5, 38],
            [3, 31],
            [2, 40],
            [6, 29],
            [4, 17]
        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [19, 21, 23, 21, 19, 18, 5, 19, 17, 15, 15, 13]
        
        this.updateFlag = true;
    }

    UpdateMonth() {

        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data = [
            [6, 65],
            [5, 62],
            [4, 72],
            
        ];
            this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [27, 30, 35];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [3, 45],
            [1, 40],
            [2, 40],
            
        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [21, 15, 15]

    
        this.updateFlag = true;
    }

    UpdateQTR() {
        this.chartOptions_1.series[0].type = 'columnrange';
        this.chartOptions_1.series[0].data = [
            [4, 72],
        ];
        this.chartOptions_1.series[1].type = 'spline';
        this.chartOptions_1.series[1].data = [31];

        this.chartOptions_2.series[0].type = 'columnrange';
        this.chartOptions_2.series[0].data = [
            [1, 45],
     
        ];
        this.chartOptions_2.series[1].type = 'spline';
        this.chartOptions_2.series[1].data = [17]
        //this.chartOptions_1.series[0] = {
        //    type: 'columnrange',
        //    data: [31]
        //};
        //this.chartOptions_2.series[0] = {
        //    type: 'columnrange',
        //     data: [17]
        //};
      
        this.updateFlag = true;
    }

}
