import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-us-region',
    templateUrl: './us-region.component.html',
    styleUrls: ['./us-region.component.css']
})
export class UsRegionComponent implements OnInit {


    @Input() ClientRegionData: any;
    @Input() CurrentPage: any;
    @Input() SliderValue: any;
    @Input() SelectedViewtype: any;
    @Input() SelectedCategory: string;
    @Input() ScoreElement: any;
    @Input() LabelValue: number = 1;
    @Input() SelectedView: String
    LableName: string;
    currentPageName: string = "";
    clientName: string = "";

        alaska: any = {}
        northWest : any ={}
        northCenteral : any ={}
        midWest : any ={}
        northEast : any ={}
        southWest : any ={}
        southCenteral : any ={}
        southEast : any ={}
        pasficIsland : any ={}


    ngOnInit() {
        this.SliderValue = 0;
        this.LabelValue = 1;
        this.LableName = "Positive outcome";
        this.SelectedViewtype = 'WEEK'
        if (localStorage.getItem('UserClientDomain') != null) {
            this.clientName = localStorage.getItem('UserClientDomain');
        }
    }

    ngOnChanges() {
     
        if (this.CurrentPage == 'OA') {
            if (this.LabelValue == 1) {
                this.LableName = "Positive outcome"
                this.SliderValue = 0;
            }
            else {
                this.LableName = "Outcome volunteered"
                this.SliderValue = 0;
            }
            if (this.SelectedViewtype == 'WEEK') {
                if (this.LabelValue === 1) {
                    this.alaska = { regionName: 'alaska', score: 30 }
                    this.northWest = { regionName: 'northWest', score: 20 };
                    this.northCenteral = { regionName: 'northCenteral', score: 30 };
                    this.midWest = { regionName: 'midWest', score: 30 };
                    this.northEast = { regionName: 'northEast', score: 30 };
                    this.southWest = { regionName: 'southWest', score: 30 };
                    this.southCenteral = { regionName: 'southCenteral', score: 30 };
                    this.southEast = { regionName: 'southEast', score: 20 };
                    this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                    if (this.SliderValue == 0) {
                        this.alaska = { regionName: 'alaska', score: 30 }
                        this.northWest = { regionName: 'northWest', score: 20 };
                        this.northCenteral = { regionName: 'northCenteral', score: 30 };
                        this.midWest = { regionName: 'midWest', score: 30 };
                        this.northEast = { regionName: 'northEast', score: 30 };
                        this.southWest = { regionName: 'southWest', score: 30 };
                        this.southCenteral = { regionName: 'southCenteral', score: 30 };
                        this.southEast = { regionName: 'southEast', score: 20 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                    }
                    else if (this.SliderValue == 1) {
                        this.alaska = { regionName: 'alaska', score: 18.0 }
                        this.northWest = { regionName: 'northWest', score: 38.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 38.0 };
                        this.midWest = { regionName: 'midWest', score: 18.0 };
                        this.northEast = { regionName: 'northEast', score: 38.0 };
                        this.southWest = { regionName: 'southWest', score: 38.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 38.0 };
                        this.southEast = { regionName: 'southEast', score: 38.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 38.0 };
                    }
                    else if (this.SliderValue == 2) {
                        this.alaska = { regionName: 'alaska', score: 26.0 }
                        this.northWest = { regionName: 'northWest', score: 20.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 26.0 };
                        this.midWest = { regionName: 'midWest', score: 26.0 };
                        this.northEast = { regionName: 'northEast', score: 26.0 };
                        this.southWest = { regionName: 'southWest', score: 20.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 26.0 };
                        this.southEast = { regionName: 'southEast', score: 26.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 20.0 };
                    }
                    else if (this.SliderValue == 3) {
                        this.alaska = { regionName: 'alaska', score: 26.0 }
                        this.northWest = { regionName: 'northWest', score: 16.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 26.0 };
                        this.midWest = { regionName: 'midWest', score: 26.0 };
                        this.northEast = { regionName: 'northEast', score: 16.0 };
                        this.southWest = { regionName: 'southWest', score: 26.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 26.0 };
                        this.southEast = { regionName: 'southEast', score: 26.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 16.0 };
                    }
                    else if (this.SliderValue == 4) {
                        this.alaska = { regionName: 'alaska', score: 20.0 }
                        this.northWest = { regionName: 'northWest', score: 32.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 32.0 };
                        this.midWest = { regionName: 'midWest', score: 20.0 };
                        this.northEast = { regionName: 'northEast', score: 32.0 };
                        this.southWest = { regionName: 'southWest', score: 12.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 32.0 };
                        this.southEast = { regionName: 'southEast', score: 32.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 32.0 };
                    }
                    else if (this.SliderValue == 5) {
                        this.alaska = { regionName: 'alaska', score: 17.0 }
                        this.northWest = { regionName: 'northWest', score: 27.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 17.0 };
                        this.midWest = { regionName: 'midWest', score: 17.0 };
                        this.northEast = { regionName: 'northEast', score: 17.0 };
                        this.southWest = { regionName: 'southWest', score: 27.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 17.0 };
                        this.southEast = { regionName: 'southEast', score: 17.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 17.0 };
                    }
                    else if (this.SliderValue == 6) {
                        this.alaska = { regionName: 'alaska', score: 13.0 }
                        this.northWest = { regionName: 'northWest', score: 13.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 13.0 };
                        this.midWest = { regionName: 'midWest', score: 23.0 };
                        this.northEast = { regionName: 'northEast', score: 13.0 };
                        this.southWest = { regionName: 'southWest', score: 13.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 13.0 };
                        this.southEast = { regionName: 'southEast', score: 13.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 13.0 };
                    }
                    else if (this.SliderValue == 7) {
                        this.alaska = { regionName: 'alaska', score: 20.0 }
                        this.northWest = { regionName: 'northWest', score: 30.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 30.0 };
                        this.midWest = { regionName: 'midWest', score: 30.0 };
                        this.northEast = { regionName: 'northEast', score: 30.0 };
                        this.southWest = { regionName: 'southWest', score: 30.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 30.0 };
                        this.southEast = { regionName: 'southEast', score: 20.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 20.0 };
                    }
                    else if (this.SliderValue == 8) {
                        this.alaska = { regionName: 'alaska', score: 31.0 }
                        this.northWest = { regionName: 'northWest', score: 31.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 31.0 };
                        this.midWest = { regionName: 'midWest', score: 11.0 };
                        this.northEast = { regionName: 'northEast', score: 31.0 };
                        this.southWest = { regionName: 'southWest', score: 31.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 31.0 };
                        this.southEast = { regionName: 'southEast', score: 31.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 20.0 };
                    }
                    else if (this.SliderValue == 9) {
                        this.alaska = { regionName: 'alaska', score: 17.0 }
                        this.northWest = { regionName: 'northWest', score: 17.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 17.0 };
                        this.midWest = { regionName: 'midWest', score: 17.0 };
                        this.northEast = { regionName: 'northEast', score: 17.0 };
                        this.southWest = { regionName: 'southWest', score: 17.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 17.0 };
                        this.southEast = { regionName: 'southEast', score: 17.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 17.0 };
                    }
                    else if (this.SliderValue == 10) {
                        this.alaska = { regionName: 'alaska', score: 29.0 }
                        this.northWest = { regionName: 'northWest', score: 29.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 29.0 };
                        this.midWest = { regionName: 'midWest', score: 19.0 };
                        this.northEast = { regionName: 'northEast', score: 19.0 };
                        this.southWest = { regionName: 'southWest', score: 29.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 29.0 };
                        this.southEast = { regionName: 'southEast', score: 29.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    }
                    else if (this.SliderValue == 11) {
                        this.alaska = { regionName: 'alaska', score: 30 }
                        this.northWest = { regionName: 'northWest', score: 30 };
                        this.northCenteral = { regionName: 'northCenteral', score: 30 };
                        this.midWest = { regionName: 'midWest', score: 30 };
                        this.northEast = { regionName: 'northEast', score: 20 };
                        this.southWest = { regionName: 'southWest', score: 30 };
                        this.southCenteral = { regionName: 'southCenteral', score: 30 };
                        this.southEast = { regionName: 'southEast', score: 30 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                    }
                }
                if (this.LabelValue == 0) {
                    this.alaska = { regionName: 'alaska', score: 19.0 }
                    this.northWest = { regionName: 'northWest', score: 19.0 };
                    this.northCenteral = { regionName: 'northCenteral', score: 19.0 };
                    this.midWest = { regionName: 'midWest', score: 29.0 };
                    this.northEast = { regionName: 'northEast', score: 19.0 };
                    this.southWest = { regionName: 'southWest', score: 19.0 };
                    this.southCenteral = { regionName: 'southCenteral', score: 19.0 };
                    this.southEast = { regionName: 'southEast', score: 29.0 };
                    this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    if (this.SliderValue == 0) {
                        this.alaska = { regionName: 'alaska', score: 19.0 }
                        this.northWest = { regionName: 'northWest', score: 19.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 19.0 };
                        this.midWest = { regionName: 'midWest', score: 29.0 };
                        this.northEast = { regionName: 'northEast', score: 19.0 };
                        this.southWest = { regionName: 'southWest', score: 19.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 19.0 };
                        this.southEast = { regionName: 'southEast', score: 29.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    }
                    else if (this.SliderValue == 1) {
                        this.alaska = { regionName: 'alaska', score: 20.0 }
                        this.northWest = { regionName: 'northWest', score: 28.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 28.0 };
                        this.midWest = { regionName: 'midWest', score: 28.0 };
                        this.northEast = { regionName: 'northEast', score: 20.0 };
                        this.southWest = { regionName: 'southWest', score: 20.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 28.0 };
                        this.southEast = { regionName: 'southEast', score: 28.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 28.0 };
                    }
                    else if (this.SliderValue == 2) {
                        this.alaska = { regionName: 'alaska', score: 19.0 }
                        this.northWest = { regionName: 'northWest', score: 19.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 19.0 };
                        this.midWest = { regionName: 'midWest', score: 19.0 };
                        this.northEast = { regionName: 'northEast', score: 19.0 };
                        this.southWest = { regionName: 'southWest', score: 29.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 19.0 };
                        this.southEast = { regionName: 'southEast', score: 29.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    }
                    else if (this.SliderValue == 3) {
                        this.alaska = { regionName: 'alaska', score: 29.0 }
                        this.northWest = { regionName: 'northWest', score: 19.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 19.0 };
                        this.midWest = { regionName: 'midWest', score: 29.0 };
                        this.northEast = { regionName: 'northEast', score: 19.0 };
                        this.southWest = { regionName: 'southWest', score: 19.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 19.0 };
                        this.southEast = { regionName: 'southEast', score: 19.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    }
                    else if (this.SliderValue == 4) {
                        this.alaska = { regionName: 'alaska', score: 22.0 }
                        this.northWest = { regionName: 'northWest', score: 21.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 21.0 };
                        this.midWest = { regionName: 'midWest', score: 22.0 };
                        this.northEast = { regionName: 'northEast', score: 21.0 };
                        this.southWest = { regionName: 'southWest', score: 21.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 21.0 };
                        this.southEast = { regionName: 'southEast', score: 21.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 21.0 };
                    }
                    else if (this.SliderValue == 5) {
                        this.alaska = { regionName: 'alaska', score: 13.0 }
                        this.northWest = { regionName: 'northWest', score: 13.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 13.0 };
                        this.midWest = { regionName: 'midWest', score: 23.0 };
                        this.northEast = { regionName: 'northEast', score: 13.0 };
                        this.southWest = { regionName: 'southWest', score: 13.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 13.0 };
                        this.southEast = { regionName: 'southEast', score: 23.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 13.0 };
                    }
                    else if (this.SliderValue == 6) {
                        this.alaska = { regionName: 'alaska', score: 6.0 }
                        this.northWest = { regionName: 'northWest', score: 6.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 6.0 };
                        this.midWest = { regionName: 'midWest', score: 6.0 };
                        this.northEast = { regionName: 'northEast', score: 6.0 };
                        this.southWest = { regionName: 'southWest', score: 6.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 6.0 };
                        this.southEast = { regionName: 'southEast', score: 6.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 6.0 };
                    }
                    else if (this.SliderValue == 7) {
                        this.alaska = { regionName: 'alaska', score: 17.0 }
                        this.northWest = { regionName: 'northWest', score: 27.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 27.0 };
                        this.midWest = { regionName: 'midWest', score: 17.0 };
                        this.northEast = { regionName: 'northEast', score: 27.0 };
                        this.southWest = { regionName: 'southWest', score: 27.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 27.0 };
                        this.southEast = { regionName: 'southEast', score: 17.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 17.0 };
                    }
                    else if (this.SliderValue == 8) {
                        this.alaska = { regionName: 'alaska', score: 17.0 }
                        this.northWest = { regionName: 'northWest', score: 27.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 27.0 };
                        this.midWest = { regionName: 'midWest', score: 20.0 };
                        this.northEast = { regionName: 'northEast', score: 27.0 };
                        this.southWest = { regionName: 'southWest', score: 27.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 27.0 };
                        this.southEast = { regionName: 'southEast', score: 20.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 27.0 };
                    }
                    else if (this.SliderValue == 9) {
                        this.alaska = { regionName: 'alaska', score: 9.0 }
                        this.northWest = { regionName: 'northWest', score: 9.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 9.0 };
                        this.midWest = { regionName: 'midWest', score: 29.0 };
                        this.northEast = { regionName: 'northEast', score: 9.0 };
                        this.southWest = { regionName: 'southWest', score: 9.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 9.0 };
                        this.southEast = { regionName: 'southEast', score: 9.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 9.0 };
                    }
                    else if (this.SliderValue == 10) {
                        this.alaska = { regionName: 'alaska', score: 19.0 }
                        this.northWest = { regionName: 'northWest', score: 19.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 19.0 };
                        this.midWest = { regionName: 'midWest', score: 29.0 };
                        this.northEast = { regionName: 'northEast', score: 19.0 };
                        this.southWest = { regionName: 'southWest', score: 19.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 19.0 };
                        this.southEast = { regionName: 'southEast', score: 29.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.0 };
                    }
                    else if (this.SliderValue == 11) {
                        this.alaska = { regionName: 'alaska', score: 20 }
                        this.northWest = { regionName: 'northWest', score: 20 };
                        this.northCenteral = { regionName: 'northCenteral', score: 20 };
                        this.midWest = { regionName: 'midWest', score: 20 };
                        this.northEast = { regionName: 'northEast', score: 29 };
                        this.southWest = { regionName: 'southWest', score: 20 };
                        this.southCenteral = { regionName: 'southCenteral', score: 20 };
                        this.southEast = { regionName: 'southEast', score: 29 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 20 };
                    }
                }
            }
            if (this.SelectedViewtype == 'MONTH') {
                if (this.LabelValue === 1) {
                    this.alaska = { regionName: 'alaska', score: 29.8 }
                    this.northWest = { regionName: 'northWest', score: 29.8 };
                    this.northCenteral = { regionName: 'northCenteral', score: 29.8 };
                    this.midWest = { regionName: 'midWest', score: 29.8 };
                    this.northEast = { regionName: 'northEast', score: 19.8 };
                    this.southWest = { regionName: 'southWest', score: 29.8 };
                    this.southCenteral = { regionName: 'southCenteral', score: 29.8 };
                    this.southEast = { regionName: 'southEast', score: 29.8 };
                    this.pasficIsland = { regionName: 'pasficIsland', score: 19.8 };
                    if (this.SliderValue == 0) {
                        this.alaska = { regionName: 'alaska', score: 29.8 }
                        this.northWest = { regionName: 'northWest', score: 29.8 };
                        this.northCenteral = { regionName: 'northCenteral', score: 29.8 };
                        this.midWest = { regionName: 'midWest', score: 29.8 };
                        this.northEast = { regionName: 'northEast', score: 19.8 };
                        this.southWest = { regionName: 'southWest', score: 29.8 };
                        this.southCenteral = { regionName: 'southCenteral', score: 29.8 };
                        this.southEast = { regionName: 'southEast', score: 29.8 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 19.8 };
                    }
                    else if (this.SliderValue == 1) {
                        this.alaska = { regionName: 'alaska', score: 20 }
                        this.northWest = { regionName: 'northWest', score: 22.8 };
                        this.northCenteral = { regionName: 'northCenteral', score: 22.8 };
                        this.midWest = { regionName: 'midWest', score: 20 };
                        this.northEast = { regionName: 'northEast', score: 22.8 };
                        this.southWest = { regionName: 'southWest', score: 22.8 };
                        this.southCenteral = { regionName: 'southCenteral', score: 22.8 };
                        this.southEast = { regionName: 'southEast', score: 22.8 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 22.8 };
                    }
                    else if (this.SliderValue == 2) {
                        this.alaska = { regionName: 'alaska', score: 26.7 }
                        this.northWest = { regionName: 'northWest', score: 26.7 };
                        this.northCenteral = { regionName: 'northCenteral', score: 26.7 };
                        this.midWest = { regionName: 'midWest', score: 11.7 };
                        this.northEast = { regionName: 'northEast', score: 16.7 };
                        this.southWest = { regionName: 'southWest', score: 26.7 };
                        this.southCenteral = { regionName: 'southCenteral', score: 26.7 };
                        this.southEast = { regionName: 'southEast', score: 16.7 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 26.7 };
                    }
                }
                if (this.LabelValue == 0) {
                    this.alaska = { regionName: 'alaska', score: 20.0 }
                    this.northWest = { regionName: 'northWest', score: 21.0 };
                    this.northCenteral = { regionName: 'northCenteral', score: 21.0 };
                    this.midWest = { regionName: 'midWest', score: 20.0 };
                    this.northEast = { regionName: 'northEast', score: 21.0 };
                    this.southWest = { regionName: 'southWest', score: 20.0 };
                    this.southCenteral = { regionName: 'southCenteral', score: 21.0 };
                    this.southEast = { regionName: 'southEast', score: 21.0 };
                    this.pasficIsland = { regionName: 'pasficIsland', score: 21.0 };
                    if (this.SliderValue == 0) {
                        this.alaska = { regionName: 'alaska', score: 20.0 }
                        this.northWest = { regionName: 'northWest', score: 21.0 };
                        this.northCenteral = { regionName: 'northCenteral', score: 21.0 };
                        this.midWest = { regionName: 'midWest', score: 20.0 };
                        this.northEast = { regionName: 'northEast', score: 21.0 };
                        this.southWest = { regionName: 'southWest', score: 20.0 };
                        this.southCenteral = { regionName: 'southCenteral', score: 21.0 };
                        this.southEast = { regionName: 'southEast', score: 21.0 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 21.0 };
                    }
                    else if (this.SliderValue == 1) {
                        this.alaska = { regionName: 'alaska', score: 16.9 }
                        this.northWest = { regionName: 'northWest', score: 16.9 };
                        this.northCenteral = { regionName: 'northCenteral', score: 16.9 };
                        this.midWest = { regionName: 'midWest', score: 16.9 };
                        this.northEast = { regionName: 'northEast', score: 16.9 };
                        this.southWest = { regionName: 'southWest', score: 26.9 };
                        this.southCenteral = { regionName: 'southCenteral', score: 16.9 };
                        this.southEast = { regionName: 'southEast', score: 26.9 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 16.9 };
                    }
                    else if (this.SliderValue == 2) {
                        this.alaska = { regionName: 'alaska', score: 28.7 }
                        this.northWest = { regionName: 'northWest', score: 18.7 };
                        this.northCenteral = { regionName: 'northCenteral', score: 18.7 };
                        this.midWest = { regionName: 'midWest', score: 28.7 };
                        this.northEast = { regionName: 'northEast', score: 18.7 };
                        this.southWest = { regionName: 'southWest', score: 18.7 };
                        this.southCenteral = { regionName: 'southCenteral', score: 18.7 };
                        this.southEast = { regionName: 'southEast', score: 18.7 };
                        this.pasficIsland = { regionName: 'pasficIsland', score: 18.7 };
                    }
                }
            }
        }
            if (this.CurrentPage == 'SS') {
                this.alaska = { regionName: 'alaska', score: 2.4 }
                this.northWest = { regionName: 'northWest', score: 1.4 };
                this.northCenteral = { regionName: 'northCenteral', score: 3.1 };
                this.midWest = { regionName: 'midWest', score: 3.6 };
                this.northEast = { regionName: 'northEast', score: 3.1 };
                this.southWest = { regionName: 'southWest', score: 3.1 };
                this.southCenteral = { regionName: 'southCenteral', score: 3.1 };
                this.southEast = { regionName: 'southEast', score: 2.4 };
                this.pasficIsland = { regionName: 'pasficIsland', score: 3.1 };
                if (this.SelectedViewtype == 'WEEK') {
                    //if (this.SelectedView === "Msl") {
                    //    if (this.SelectedCategory === "Opening") {
                    //        this.SelectedCategory = "Engage";
                    //    } else if (this.SelectedCategory === "Questioning") {
                    //        this.SelectedCategory = "Explore";
                    //    } else if (this.SelectedCategory === "Presenting") {
                    //        this.SelectedCategory = "Exchange";
                    //    } else if (this.SelectedCategory === "Closing") {
                    //        this.SelectedCategory = "Evolve";
                    //    }
                    //}
                    if (this.SliderValue == 0) {
  
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.4 }
                            this.northWest = { regionName: 'northWest', score: 1.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.1 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.1 };
                            this.southWest = { regionName: 'southWest', score: 3.1 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.1 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.1 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.0 }
                            this.northWest = { regionName: 'northWest', score: 2.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.4 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.4 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 1.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.4 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.5 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.3 }
                            this.northWest = { regionName: 'northWest', score: 3.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.3 };
                            this.midWest = { regionName: 'midWest', score: 3.3 };
                            this.northEast = { regionName: 'northEast', score: 1.3 };
                            this.southWest = { regionName: 'southWest', score: 3.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.6 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.3 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.1 }
                            this.northWest = { regionName: 'northWest', score: 1.1 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.1 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.1 };
                            this.southWest = { regionName: 'southWest', score: 2.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.1 };
                            this.southEast = { regionName: 'southEast', score: 3.1 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.4 };
                        }
                    }
                    if (this.SliderValue == 1) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.0 }
                            this.northWest = { regionName: 'northWest', score: 3.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.0 };
                            this.midWest = { regionName: 'midWest', score: 1.0 };
                            this.northEast = { regionName: 'northEast', score: 3.0 };
                            this.southWest = { regionName: 'southWest', score: 3.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.0 };
                            this.southEast = { regionName: 'southEast', score: 3.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.4 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.3 }
                            this.northWest = { regionName: 'northWest', score: 2.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.3 };
                            this.midWest = { regionName: 'midWest', score: 2.3 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.3 };
                            this.southEast = { regionName: 'southEast', score: 3.6 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.0 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.6 }
                            this.northWest = { regionName: 'northWest', score: 3.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.3 };
                            this.midWest = { regionName: 'midWest', score: 1.3 };
                            this.northEast = { regionName: 'northEast', score: 3.3 };
                            this.southWest = { regionName: 'southWest', score: 2.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.3 };
                            this.southEast = { regionName: 'southEast', score: 3.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.3 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.4 }
                            this.northWest = { regionName: 'northWest', score: 3.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.4 };
                            this.midWest = { regionName: 'midWest', score: 3.4 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 3.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.4 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.4 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 3.8 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 1.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                    }
                    if (this.SliderValue == 2) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.9 }
                            this.northWest = { regionName: 'northWest', score: 1.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 2.9 };
                            this.northEast = { regionName: 'northEast', score: 3.9 };
                            this.southWest = { regionName: 'southWest', score: 2.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.9 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.4 }
                            this.northWest = { regionName: 'northWest', score: 2.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.4 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 3.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.4 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.6 }
                            this.northWest = { regionName: 'northWest', score: 3.6 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.6 };
                            this.midWest = { regionName: 'midWest', score: 2.6 };
                            this.northEast = { regionName: 'northEast', score: 3.6 };
                            this.southWest = { regionName: 'southWest', score: 3.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.6 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.6 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.5 }
                            this.northWest = { regionName: 'northWest', score: 2.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.5 };
                            this.midWest = { regionName: 'midWest', score: 2.5 };
                            this.northEast = { regionName: 'northEast', score: 2.2 };
                            this.southWest = { regionName: 'southWest', score: 2.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.4 };
                            this.southEast = { regionName: 'southEast', score: 2.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.3 }
                            this.northWest = { regionName: 'northWest', score: 3.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.3 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.3 };
                            this.southWest = { regionName: 'southWest', score: 3.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.3 };
                            this.southEast = { regionName: 'southEast', score: 2.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.3 };
                        }
                    }
                    if (this.SliderValue == 3) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 3.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 2.2 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.3 }
                            this.northWest = { regionName: 'northWest', score: 2.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.3 };
                            this.midWest = { regionName: 'midWest', score: 3.3 };
                            this.northEast = { regionName: 'northEast', score: 2.3 };
                            this.southWest = { regionName: 'southWest', score: 2.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.3 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.3 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.4 }
                            this.northWest = { regionName: 'northWest', score: 2.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 3.9 };
                            this.northEast = { regionName: 'northEast', score: 2.9 };
                            this.southWest = { regionName: 'southWest', score: 2.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 1.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.9 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 2.5 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.4 }
                            this.northWest = { regionName: 'northWest', score: 2.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.4 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.4 };
                        }
                    }
                    if (this.SliderValue == 4) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 1.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 3.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.2 }
                            this.northWest = { regionName: 'northWest', score: 2.2 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.2 };
                            this.midWest = { regionName: 'midWest', score: 2.2 };
                            this.northEast = { regionName: 'northEast', score: 2.2 };
                            this.southWest = { regionName: 'southWest', score: 1.2 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.2 };
                            this.southEast = { regionName: 'southEast', score: 2.2 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.2 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.9 }
                            this.northWest = { regionName: 'northWest', score: 2.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 2.9 };
                            this.northEast = { regionName: 'northEast', score: 2.9 };
                            this.southWest = { regionName: 'southWest', score: 1.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 2.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.4 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 2.5 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.3 }
                            this.northWest = { regionName: 'northWest', score: 2.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.3 };
                            this.midWest = { regionName: 'midWest', score: 2.3 };
                            this.northEast = { regionName: 'northEast', score: 1.3 };
                            this.southWest = { regionName: 'southWest', score: 2.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.3 };
                            this.southEast = { regionName: 'southEast', score: 3.6 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.3 };
                        }
                    }
                    if (this.SliderValue == 5) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 1.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 2.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 3.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.2 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.7 }
                            this.northWest = { regionName: 'northWest', score: 1.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 1.7 };
                            this.northEast = { regionName: 'northEast', score: 1.4 };
                            this.southWest = { regionName: 'southWest', score: 1.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.7 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.5 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 3.8 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.8 };
                            this.southEast = { regionName: 'southEast', score: 2.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 2.5 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.6 }
                            this.northWest = { regionName: 'northWest', score: 2.6 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.6 };
                            this.midWest = { regionName: 'midWest', score: 1.6 };
                            this.northEast = { regionName: 'northEast', score: 2.6 };
                            this.southWest = { regionName: 'southWest', score: 2.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.6 };
                            this.southEast = { regionName: 'southEast', score: 3.6 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.4 };
                        }
                    }
                    if (this.SliderValue == 6) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 3.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 1.4 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.8 }
                            this.northWest = { regionName: 'northWest', score: 1.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.8 };
                            this.midWest = { regionName: 'midWest', score: 2.8 };
                            this.northEast = { regionName: 'northEast', score: 1.8 };
                            this.southWest = { regionName: 'southWest', score: 1.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.8 };
                            this.southEast = { regionName: 'southEast', score: 2.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.8 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 2.8 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 1.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.4 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 1.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.6 }
                            this.northWest = { regionName: 'northWest', score: 2.6 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.6 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.6 };
                            this.southWest = { regionName: 'southWest', score: 2.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.6 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.6 };
                        }
                    }
                    if (this.SliderValue == 7) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 3.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 1.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 2.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.7 }
                            this.northWest = { regionName: 'northWest', score: 1.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.7 };
                            this.midWest = { regionName: 'midWest', score: 2.7 };
                            this.northEast = { regionName: 'northEast', score: 1.7 };
                            this.southWest = { regionName: 'southWest', score: 1.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.4 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.7 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 2.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.5 };
                            this.midWest = { regionName: 'midWest', score: 1.5 };
                            this.northEast = { regionName: 'northEast', score: 2.5 };
                            this.southWest = { regionName: 'southWest', score: 2.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.5 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.5 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 2.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.9 }
                            this.northWest = { regionName: 'northWest', score: 2.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 2.9 };
                            this.southWest = { regionName: 'southWest', score: 1.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 2.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.9 };
                        }
                    }
                    if (this.SliderValue == 8) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 3.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.8 }
                            this.northWest = { regionName: 'northWest', score: 1.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.8 };
                            this.midWest = { regionName: 'midWest', score: 1.4 };
                            this.northEast = { regionName: 'northEast', score: 1.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.8 };
                            this.southEast = { regionName: 'southEast', score: 1.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.8 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.6 }
                            this.northWest = { regionName: 'northWest', score: 2.6 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.6 };
                            this.midWest = { regionName: 'midWest', score: 2.6 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 2.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.6 };
                            this.southEast = { regionName: 'southEast', score: 1.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.6 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.4 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.0 }
                            this.northWest = { regionName: 'northWest', score: 2.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.0 };
                            this.midWest = { regionName: 'midWest', score: 3.0 };
                            this.northEast = { regionName: 'northEast', score: 3.0 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.0 };
                            this.southEast = { regionName: 'southEast', score: 3.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.0 };
                        }
                    }
                    if (this.SliderValue == 9) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 3.9 }
                            this.northWest = { regionName: 'northWest', score: 2.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 1.4 };
                            this.northEast = { regionName: 'northEast', score: 2.9 };
                            this.southWest = { regionName: 'southWest', score: 2.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.9 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.0 }
                            this.northWest = { regionName: 'northWest', score: 2.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.0 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.0 };
                            this.southWest = { regionName: 'southWest', score: 2.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.0 };
                            this.southEast = { regionName: 'southEast', score: 2.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.0 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 2.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 2.4 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.2 }
                            this.northWest = { regionName: 'northWest', score: 3.2 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.2 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.2 };
                            this.southWest = { regionName: 'southWest', score: 3.2 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 3.2 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.2 };
                        }
                    }
                    if (this.SliderValue == 10) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 2.7 };
                            this.northEast = { regionName: 'northEast', score: 3.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.4 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 1.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.7 };
                            this.midWest = { regionName: 'midWest', score: 1.4 };
                            this.northEast = { regionName: 'northEast', score: 1.7 };
                            this.southWest = { regionName: 'southWest', score: 1.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.7 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.7 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 3.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.3 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.3 };
                            this.midWest = { regionName: 'midWest', score: 3.3 };
                            this.northEast = { regionName: 'northEast', score: 3.3 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.3 };
                            this.southEast = { regionName: 'southEast', score: 3.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.3 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.0 }
                            this.northWest = { regionName: 'northWest', score: 3.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.0 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.0 };
                            this.southWest = { regionName: 'southWest', score: 3.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.0 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.0 };
                        }
                    }
                    if (this.SliderValue == 11) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 1.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 3.8 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 1.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.5 }
                            this.northWest = { regionName: 'northWest', score: 1.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.5 };
                            this.midWest = { regionName: 'midWest', score: 1.4 };
                            this.northEast = { regionName: 'northEast', score: 1.5 };
                            this.southWest = { regionName: 'southWest', score: 1.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.5 };
                            this.southEast = { regionName: 'southEast', score: 2.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.5 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 1.8 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.4 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.4 }
                            this.northWest = { regionName: 'northWest', score: 3.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.4 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 3.4 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.4 };
                            this.southEast = { regionName: 'southEast', score: 3.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.4 };
                        }
                    }
                }
                if (this.SelectedViewtype == 'MONTH') {
                    if (this.SliderValue == 0) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.9 }
                            this.northWest = { regionName: 'northWest', score: 2.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.9 };
                            this.midWest = { regionName: 'midWest', score: 1.9 };
                            this.northEast = { regionName: 'northEast', score: 2.9 };
                            this.southWest = { regionName: 'southWest', score: 2.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.9 };
                            this.southEast = { regionName: 'southEast', score: 2.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.4 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 2.4 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.4 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 1.4 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.4 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.4 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.2 }
                            this.northWest = { regionName: 'northWest', score: 3.2 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.2 };
                            this.midWest = { regionName: 'midWest', score: 3.6 };
                            this.northEast = { regionName: 'northEast', score: 3.2 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.2 };
                            this.southEast = { regionName: 'southEast', score: 3.2 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.2 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 3.4 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 3.7 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 1.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                    }
                    if (this.SliderValue == 1) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 3.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.7 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.9 }
                            this.northWest = { regionName: 'northWest', score: 1.9 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.9 };
                            this.midWest = { regionName: 'midWest', score: 2.9 };
                            this.northEast = { regionName: 'northEast', score: 1.9 };
                            this.southWest = { regionName: 'southWest', score: 1.9 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.9 };
                            this.southEast = { regionName: 'southEast', score: 1.9 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.9 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.4 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.6 }
                            this.northWest = { regionName: 'northWest', score: 2.6 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.6 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.6 };
                            this.southWest = { regionName: 'southWest', score: 2.6 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.6 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.6 };
                        }
                    }
                    if (this.SliderValue == 2) {
                        if (this.SelectedCategory == "Overall") {
                            this.alaska = { regionName: 'alaska', score: 2.8 }
                            this.northWest = { regionName: 'northWest', score: 2.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.8 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.8 };
                            this.southWest = { regionName: 'southWest', score: 2.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.8 };
                            this.southEast = { regionName: 'southEast', score: 2.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.8 };
                        }
                        if (['Opening', 'Engage'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 1.8 }
                            this.northWest = { regionName: 'northWest', score: 1.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 1.8 };
                            this.midWest = { regionName: 'midWest', score: 2.5 };
                            this.northEast = { regionName: 'northEast', score: 1.8 };
                            this.southWest = { regionName: 'southWest', score: 1.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 1.8 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 1.8 };
                        }
                        if (['Questioning', 'Explore'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 2.7 }
                            this.northWest = { regionName: 'northWest', score: 2.7 };
                            this.northCenteral = { regionName: 'northCenteral', score: 2.7 };
                            this.midWest = { regionName: 'midWest', score: 2.4 };
                            this.northEast = { regionName: 'northEast', score: 2.7 };
                            this.southWest = { regionName: 'southWest', score: 2.7 };
                            this.southCenteral = { regionName: 'southCenteral', score: 2.7 };
                            this.southEast = { regionName: 'southEast', score: 2.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 2.7 };
                        }
                        if (['Presenting', 'Exchange'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.5 }
                            this.northWest = { regionName: 'northWest', score: 3.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.5 };
                            this.midWest = { regionName: 'midWest', score: 3.5 };
                            this.northEast = { regionName: 'northEast', score: 3.4 };
                            this.southWest = { regionName: 'southWest', score: 3.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.5 };
                            this.southEast = { regionName: 'southEast', score: 3.4 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.5 };
                        }
                        if (['Closing', 'Evolve'].includes(this.SelectedCategory)) {
                            this.alaska = { regionName: 'alaska', score: 3.2 }
                            this.northWest = { regionName: 'northWest', score: 3.2 };
                            this.northCenteral = { regionName: 'northCenteral', score: 3.2 };
                            this.midWest = { regionName: 'midWest', score: 3.2 };
                            this.northEast = { regionName: 'northEast', score: 3.5 };
                            this.southWest = { regionName: 'southWest', score: 2.4 };
                            this.southCenteral = { regionName: 'southCenteral', score: 3.2 };
                            this.southEast = { regionName: 'southEast', score: 3.2 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 3.2 };
                        }
                    }
                }
            }

            if (this.CurrentPage == 'CI') {
                this.alaska = { regionName: 'alaska', score: 67 }
                this.northWest = { regionName: 'northWest', score: 65 };
                this.northCenteral = { regionName: 'northCenteral', score: 65 };
                this.midWest = { regionName: 'midWest', score: 65 };
                this.northEast = { regionName: 'northEast', score: 65 };
                this.southWest = { regionName: 'southWest', score: 67 };
                this.southCenteral = { regionName: 'southCenteral', score: 65 };
                this.southEast = { regionName: 'southEast', score: 65 };
                this.pasficIsland = { regionName: 'pasficIsland', score: 67 };
                if (this.SelectedViewtype == 'WEEK') {
                    if (this.SliderValue == 0) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 67 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 67 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 67 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 80 }
                            this.northWest = { regionName: 'northWest', score: 80 };
                            this.northCenteral = { regionName: 'northCenteral', score: 80 };
                            this.midWest = { regionName: 'midWest', score: 80 };
                            this.northEast = { regionName: 'northEast', score: 45 };
                            this.southWest = { regionName: 'southWest', score: 80 };
                            this.southCenteral = { regionName: 'southCenteral', score: 80 };
                            this.southEast = { regionName: 'southEast', score: 80 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 80 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 45 }
                            this.northWest = { regionName: 'northWest', score: 45 };
                            this.northCenteral = { regionName: 'northCenteral', score: 45 };
                            this.midWest = { regionName: 'midWest', score: 32 };
                            this.northEast = { regionName: 'northEast', score: 45 };
                            this.southWest = { regionName: 'southWest', score: 45 };
                            this.southCenteral = { regionName: 'southCenteral', score: 45 };
                            this.southEast = { regionName: 'southEast', score: 67 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 45 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 35 }
                            this.northWest = { regionName: 'northWest', score: 45 };
                            this.northCenteral = { regionName: 'northCenteral', score: 35 };
                            this.midWest = { regionName: 'midWest', score: 35 };
                            this.northEast = { regionName: 'northEast', score: 35 };
                            this.southWest = { regionName: 'southWest', score: 35 };
                            this.southCenteral = { regionName: 'southCenteral', score: 34 };
                            this.southEast = { regionName: 'southEast', score: 35 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 35 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 20 }
                            this.northWest = { regionName: 'northWest', score: 20 };
                            this.northCenteral = { regionName: 'northCenteral', score: 20 };
                            this.midWest = { regionName: 'midWest', score: 20 };
                            this.northEast = { regionName: 'northEast', score: 40 };
                            this.southWest = { regionName: 'southWest', score: 20 };
                            this.southCenteral = { regionName: 'southCenteral', score: 20 };
                            this.southEast = { regionName: 'southEast', score: 20 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 20 };
                        }
                    }
                    if (this.SliderValue == 1) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 77 }
                            this.northWest = { regionName: 'northWest', score: 77 };
                            this.northCenteral = { regionName: 'northCenteral', score: 77 };
                            this.midWest = { regionName: 'midWest', score: 77 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 77 };
                            this.southCenteral = { regionName: 'southCenteral', score: 77 };
                            this.southEast = { regionName: 'southEast', score: 77 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 77 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 67 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 67 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 65 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 67 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 67 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 55 }
                            this.northWest = { regionName: 'northWest', score: 55 };
                            this.northCenteral = { regionName: 'northCenteral', score: 55 };
                            this.midWest = { regionName: 'midWest', score: 55 };
                            this.northEast = { regionName: 'northEast', score: 67 };
                            this.southWest = { regionName: 'southWest', score: 55 };
                            this.southCenteral = { regionName: 'southCenteral', score: 55 };
                            this.southEast = { regionName: 'southEast', score: 32 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 55 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 30 }
                            this.northWest = { regionName: 'northWest', score: 30 };
                            this.northCenteral = { regionName: 'northCenteral', score: 30 };
                            this.midWest = { regionName: 'midWest', score: 40 };
                            this.northEast = { regionName: 'northEast', score: 30 };
                            this.southWest = { regionName: 'southWest', score: 30 };
                            this.southCenteral = { regionName: 'southCenteral', score: 30 };
                            this.southEast = { regionName: 'southEast', score: 30 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                        }
                    }
                    if (this.SliderValue == 2) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 90 }
                            this.northWest = { regionName: 'northWest', score: 90 };
                            this.northCenteral = { regionName: 'northCenteral', score: 90 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 90 };
                            this.southWest = { regionName: 'southWest', score: 90 };
                            this.southCenteral = { regionName: 'southCenteral', score: 90 };
                            this.southEast = { regionName: 'southEast', score: 90 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 90 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 75 }
                            this.northWest = { regionName: 'northWest', score: 75 };
                            this.northCenteral = { regionName: 'northCenteral', score: 75 };
                            this.midWest = { regionName: 'midWest', score: 75 };
                            this.northEast = { regionName: 'northEast', score: 75 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 75 };
                            this.southEast = { regionName: 'southEast', score: 75 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 32 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 67 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 65 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 50 }
                            this.northWest = { regionName: 'northWest', score: 50 };
                            this.northCenteral = { regionName: 'northCenteral', score: 50 };
                            this.midWest = { regionName: 'midWest', score: 50 };
                            this.northEast = { regionName: 'northEast', score: 50 };
                            this.southWest = { regionName: 'southWest', score: 50 };
                            this.southCenteral = { regionName: 'southCenteral', score: 50 };
                            this.southEast = { regionName: 'southEast', score: 67 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 50 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 30 }
                            this.northWest = { regionName: 'northWest', score: 30 };
                            this.northCenteral = { regionName: 'northCenteral', score: 30 };
                            this.midWest = { regionName: 'midWest', score: 34 };
                            this.northEast = { regionName: 'northEast', score: 30 };
                            this.southWest = { regionName: 'southWest', score: 30 };
                            this.southCenteral = { regionName: 'southCenteral', score: 30 };
                            this.southEast = { regionName: 'southEast', score: 30 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                        }
                    }
                    if (this.SliderValue == 3) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 82 }
                            this.northWest = { regionName: 'northWest', score: 82 };
                            this.northCenteral = { regionName: 'northCenteral', score: 82 };
                            this.midWest = { regionName: 'midWest', score: 64 };
                            this.northEast = { regionName: 'northEast', score: 82 };
                            this.southWest = { regionName: 'southWest', score: 82 };
                            this.southCenteral = { regionName: 'southCenteral', score: 82 };
                            this.southEast = { regionName: 'southEast', score: 82 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 82 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 74 }
                            this.northWest = { regionName: 'northWest', score: 74 };
                            this.northCenteral = { regionName: 'northCenteral', score: 74 };
                            this.midWest = { regionName: 'midWest', score: 74 };
                            this.northEast = { regionName: 'northEast', score: 74 };
                            this.southWest = { regionName: 'southWest', score: 74 };
                            this.southCenteral = { regionName: 'southCenteral', score: 74 };
                            this.southEast = { regionName: 'southEast', score: 64 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 74 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 64 }
                            this.northWest = { regionName: 'northWest', score: 74 };
                            this.northCenteral = { regionName: 'northCenteral', score: 74 };
                            this.midWest = { regionName: 'midWest', score: 74 };
                            this.northEast = { regionName: 'northEast', score: 64 };
                            this.southWest = { regionName: 'southWest', score: 74 };
                            this.southCenteral = { regionName: 'southCenteral', score: 74 };
                            this.southEast = { regionName: 'southEast', score: 74 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 74 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 42 }
                            this.northWest = { regionName: 'northWest', score: 42 };
                            this.northCenteral = { regionName: 'northCenteral', score: 42 };
                            this.midWest = { regionName: 'midWest', score: 42 };
                            this.northEast = { regionName: 'northEast', score: 42 };
                            this.southWest = { regionName: 'southWest', score: 42 };
                            this.southCenteral = { regionName: 'southCenteral', score: 42 };
                            this.southEast = { regionName: 'southEast', score: 67 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 42 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 42 }
                            this.northWest = { regionName: 'northWest', score: 32 };
                            this.northCenteral = { regionName: 'northCenteral', score: 32 };
                            this.midWest = { regionName: 'midWest', score: 32 };
                            this.northEast = { regionName: 'northEast', score: 32 };
                            this.southWest = { regionName: 'southWest', score: 32 };
                            this.southCenteral = { regionName: 'southCenteral', score: 32 };
                            this.southEast = { regionName: 'southEast', score: 42 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 32 };
                        }
                    }
                    if (this.SliderValue == 4) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 84 }
                            this.northWest = { regionName: 'northWest', score: 84 };
                            this.northCenteral = { regionName: 'northCenteral', score: 84 };
                            this.midWest = { regionName: 'midWest', score: 84 };
                            this.northEast = { regionName: 'northEast', score: 64 };
                            this.southWest = { regionName: 'southWest', score: 84 };
                            this.southCenteral = { regionName: 'southCenteral', score: 84 };
                            this.southEast = { regionName: 'southEast', score: 84 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 64 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 95 };
                            this.northCenteral = { regionName: 'northCenteral', score: 95 };
                            this.midWest = { regionName: 'midWest', score: 95 };
                            this.northEast = { regionName: 'northEast', score: 95 };
                            this.southWest = { regionName: 'southWest', score: 95 };
                            this.southCenteral = { regionName: 'southCenteral', score: 95 };
                            this.southEast = { regionName: 'southEast', score: 95 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 95 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 85 };
                            this.northCenteral = { regionName: 'northCenteral', score: 85 };
                            this.midWest = { regionName: 'midWest', score: 85 };
                            this.northEast = { regionName: 'northEast', score: 85 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 85 };
                            this.southEast = { regionName: 'southEast', score: 85 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 85 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 32 }
                            this.northWest = { regionName: 'northWest', score: 35 };
                            this.northCenteral = { regionName: 'northCenteral', score: 35 };
                            this.midWest = { regionName: 'midWest', score: 35 };
                            this.northEast = { regionName: 'northEast', score: 35 };
                            this.southWest = { regionName: 'southWest', score: 35 };
                            this.southCenteral = { regionName: 'southCenteral', score: 35 };
                            this.southEast = { regionName: 'southEast', score: 32 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 35 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 5 }
                            this.northWest = { regionName: 'northWest', score: 5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 5 };
                            this.midWest = { regionName: 'midWest', score: 5 };
                            this.northEast = { regionName: 'northEast', score: 5 };
                            this.southWest = { regionName: 'southWest', score: 5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 5 };
                            this.southEast = { regionName: 'southEast', score: 5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 5 };
                        }
                    }
                    if (this.SliderValue == 5) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 88 }
                            this.northWest = { regionName: 'northWest', score: 88 };
                            this.northCenteral = { regionName: 'northCenteral', score: 88 };
                            this.midWest = { regionName: 'midWest', score: 88 };
                            this.northEast = { regionName: 'northEast', score: 88 };
                            this.southWest = { regionName: 'southWest', score: 88 };
                            this.southCenteral = { regionName: 'southCenteral', score: 64 };
                            this.southEast = { regionName: 'southEast', score: 88 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 88 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 70 }
                            this.northWest = { regionName: 'northWest', score: 70 };
                            this.northCenteral = { regionName: 'northCenteral', score: 70 };
                            this.midWest = { regionName: 'midWest', score: 32 };
                            this.northEast = { regionName: 'northEast', score: 70 };
                            this.southWest = { regionName: 'southWest', score: 70 };
                            this.southCenteral = { regionName: 'southCenteral', score: 70 };
                            this.southEast = { regionName: 'southEast', score: 64 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 70 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 70 }
                            this.northWest = { regionName: 'northWest', score: 70 };
                            this.northCenteral = { regionName: 'northCenteral', score: 70 };
                            this.midWest = { regionName: 'midWest', score: 70 };
                            this.northEast = { regionName: 'northEast', score: 70 };
                            this.southWest = { regionName: 'southWest', score: 60 };
                            this.southCenteral = { regionName: 'southCenteral', score: 70 };
                            this.southEast = { regionName: 'southEast', score: 70 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 70 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 50 }
                            this.northWest = { regionName: 'northWest', score: 50 };
                            this.northCenteral = { regionName: 'northCenteral', score: 50 };
                            this.midWest = { regionName: 'midWest', score: 67 };
                            this.northEast = { regionName: 'northEast', score: 50 };
                            this.southWest = { regionName: 'southWest', score: 50 };
                            this.southCenteral = { regionName: 'southCenteral', score: 50 };
                            this.southEast = { regionName: 'southEast', score: 50 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 50 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 0 }
                            this.northWest = { regionName: 'northWest', score: 0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 0 };
                            this.midWest = { regionName: 'midWest', score: 0 };
                            this.northEast = { regionName: 'northEast', score: 0 };
                            this.southWest = { regionName: 'southWest', score: 0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 0 };
                            this.southEast = { regionName: 'southEast', score: 0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 0 };
                        }
                    }
                    if (this.SliderValue == 6) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 67 }
                            this.northWest = { regionName: 'northWest', score: 40 };
                            this.northCenteral = { regionName: 'northCenteral', score: 40 };
                            this.midWest = { regionName: 'midWest', score: 40 };
                            this.northEast = { regionName: 'northEast', score: 40 };
                            this.southWest = { regionName: 'southWest', score: 40 };
                            this.southCenteral = { regionName: 'southCenteral', score: 40 };
                            this.southEast = { regionName: 'southEast', score: 30 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 40 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 40 }
                            this.northWest = { regionName: 'northWest', score: 30 };
                            this.northCenteral = { regionName: 'northCenteral', score: 30 };
                            this.midWest = { regionName: 'midWest', score: 30 };
                            this.northEast = { regionName: 'northEast', score: 30 };
                            this.southWest = { regionName: 'southWest', score: 40 };
                            this.southCenteral = { regionName: 'southCenteral', score: 30 };
                            this.southEast = { regionName: 'southEast', score: 30 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 69 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 32 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 67 }
                            this.northWest = { regionName: 'northWest', score: 55 };
                            this.northCenteral = { regionName: 'northCenteral', score: 55 };
                            this.midWest = { regionName: 'midWest', score: 55 };
                            this.northEast = { regionName: 'northEast', score: 55 };
                            this.southWest = { regionName: 'southWest', score: 55 };
                            this.southCenteral = { regionName: 'southCenteral', score: 55 };
                            this.southEast = { regionName: 'southEast', score: 55 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 55 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 5 }
                            this.northWest = { regionName: 'northWest', score: 5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 5 };
                            this.midWest = { regionName: 'midWest', score: 5 };
                            this.northEast = { regionName: 'northEast', score: 5 };
                            this.southWest = { regionName: 'southWest', score: 5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 5 };
                            this.southEast = { regionName: 'southEast', score: 5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 5 };
                        }
                    }
                    if (this.SliderValue == 7) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 47 }
                            this.northWest = { regionName: 'northWest', score: 47 };
                            this.northCenteral = { regionName: 'northCenteral', score: 47 };
                            this.midWest = { regionName: 'midWest', score: 77 };
                            this.northEast = { regionName: 'northEast', score: 47 };
                            this.southWest = { regionName: 'southWest', score: 47 };
                            this.southCenteral = { regionName: 'southCenteral', score: 47 };
                            this.southEast = { regionName: 'southEast', score: 47 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 47 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 67 }
                            this.northWest = { regionName: 'northWest', score: 47 };
                            this.northCenteral = { regionName: 'northCenteral', score: 47 };
                            this.midWest = { regionName: 'midWest', score: 47 };
                            this.northEast = { regionName: 'northEast', score: 47 };
                            this.southWest = { regionName: 'southWest', score: 32 };
                            this.southCenteral = { regionName: 'southCenteral', score: 47 };
                            this.southEast = { regionName: 'southEast', score:32 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 47 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 60 }
                            this.northWest = { regionName: 'northWest', score: 67 };
                            this.northCenteral = { regionName: 'northCenteral', score: 60 };
                            this.midWest = { regionName: 'midWest', score: 60 };
                            this.northEast = { regionName: 'northEast', score: 60 };
                            this.southWest = { regionName: 'southWest', score: 60 };
                            this.southCenteral = { regionName: 'southCenteral', score: 60 };
                            this.southEast = { regionName: 'southEast', score: 67 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 60 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 53 }
                            this.northWest = { regionName: 'northWest', score: 53 };
                            this.northCenteral = { regionName: 'northCenteral', score: 53 };
                            this.midWest = { regionName: 'midWest', score: 53 };
                            this.northEast = { regionName: 'northEast', score: 53 };
                            this.southWest = { regionName: 'southWest', score: 53 };
                            this.southCenteral = { regionName: 'southCenteral', score: 53 };
                            this.southEast = { regionName: 'southEast', score: 53 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 53 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 7.0 }
                            this.northWest = { regionName: 'northWest', score: 7.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 7.0 };
                            this.midWest = { regionName: 'midWest', score: 7.0 };
                            this.northEast = { regionName: 'northEast', score: 7.0 };
                            this.southWest = { regionName: 'southWest', score: 7.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 7.0 };
                            this.southEast = { regionName: 'southEast', score: 7.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 7.0 };
                        }
                    }
                    if (this.SliderValue == 8) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 75 }
                            this.northWest = { regionName: 'northWest', score: 75 };
                            this.northCenteral = { regionName: 'northCenteral', score: 75 };
                            this.midWest = { regionName: 'midWest', score: 75 };
                            this.northEast = { regionName: 'northEast', score: 75 };
                            this.southWest = { regionName: 'southWest', score: 75 };
                            this.southCenteral = { regionName: 'southCenteral', score: 75 };
                            this.southEast = { regionName: 'southEast', score: 75 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 75 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 45 }
                            this.northWest = { regionName: 'northWest', score: 45 };
                            this.northCenteral = { regionName: 'northCenteral', score: 45 };
                            this.midWest = { regionName: 'midWest', score: 45 };
                            this.northEast = { regionName: 'northEast', score: 45 };
                            this.southWest = { regionName: 'southWest', score: 45 };
                            this.southCenteral = { regionName: 'southCenteral', score: 45 };
                            this.southEast = { regionName: 'southEast', score: 45 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 45 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 80 }
                            this.northWest = { regionName: 'northWest', score: 80 };
                            this.northCenteral = { regionName: 'northCenteral', score: 80 };
                            this.midWest = { regionName: 'midWest', score: 80 };
                            this.northEast = { regionName: 'northEast', score: 80 };
                            this.southWest = { regionName: 'southWest', score: 80 };
                            this.southCenteral = { regionName: 'southCenteral', score: 80 };
                            this.southEast = { regionName: 'southEast', score: 80 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 80 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 35 }
                            this.northWest = { regionName: 'northWest', score: 35 };
                            this.northCenteral = { regionName: 'northCenteral', score: 35 };
                            this.midWest = { regionName: 'midWest', score: 35 };
                            this.northEast = { regionName: 'northEast', score: 35 };
                            this.southWest = { regionName: 'southWest', score: 35 };
                            this.southCenteral = { regionName: 'southCenteral', score: 35 };
                            this.southEast = { regionName: 'southEast', score: 35 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 35 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 25 }
                            this.northWest = { regionName: 'northWest', score: 25 };
                            this.northCenteral = { regionName: 'northCenteral', score: 25 };
                            this.midWest = { regionName: 'midWest', score: 25 };
                            this.northEast = { regionName: 'northEast', score: 25 };
                            this.southWest = { regionName: 'southWest', score: 25 };
                            this.southCenteral = { regionName: 'southCenteral', score: 25 };
                            this.southEast = { regionName: 'southEast', score: 25 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 25 };
                        }
                    }
                    if (this.SliderValue == 9) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 75 }
                            this.northWest = { regionName: 'northWest', score: 75 };
                            this.northCenteral = { regionName: 'northCenteral', score: 75 };
                            this.midWest = { regionName: 'midWest', score: 75 };
                            this.northEast = { regionName: 'northEast', score: 75 };
                            this.southWest = { regionName: 'southWest', score: 75 };
                            this.southCenteral = { regionName: 'southCenteral', score: 75 };
                            this.southEast = { regionName: 'southEast', score: 75 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 75 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 30 }
                            this.northWest = { regionName: 'northWest', score: 30 };
                            this.northCenteral = { regionName: 'northCenteral', score: 30 };
                            this.midWest = { regionName: 'midWest', score: 30 };
                            this.northEast = { regionName: 'northEast', score: 30 };
                            this.southWest = { regionName: 'southWest', score: 30 };
                            this.southCenteral = { regionName: 'southCenteral', score: 30 };
                            this.southEast = { regionName: 'southEast', score: 30 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 30 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 85 }
                            this.northWest = { regionName: 'northWest', score: 85 };
                            this.northCenteral = { regionName: 'northCenteral', score: 85 };
                            this.midWest = { regionName: 'midWest', score: 85 };
                            this.northEast = { regionName: 'northEast', score: 85 };
                            this.southWest = { regionName: 'southWest', score: 85 };
                            this.southCenteral = { regionName: 'southCenteral', score: 85 };
                            this.southEast = { regionName: 'southEast', score: 85 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 85 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 35 }
                            this.northWest = { regionName: 'northWest', score: 35 };
                            this.northCenteral = { regionName: 'northCenteral', score: 35 };
                            this.midWest = { regionName: 'midWest', score: 35 };
                            this.northEast = { regionName: 'northEast', score: 35 };
                            this.southWest = { regionName: 'southWest', score: 35 };
                            this.southCenteral = { regionName: 'southCenteral', score: 35 };
                            this.southEast = { regionName: 'southEast', score: 35 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 35 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 15 }
                            this.northWest = { regionName: 'northWest', score: 15 };
                            this.northCenteral = { regionName: 'northCenteral', score: 15 };
                            this.midWest = { regionName: 'midWest', score: 15 };
                            this.northEast = { regionName: 'northEast', score: 15 };
                            this.southWest = { regionName: 'southWest', score: 15 };
                            this.southCenteral = { regionName: 'southCenteral', score: 15 };
                            this.southEast = { regionName: 'southEast', score: 15 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 15 };
                        }
                    }
                    if (this.SliderValue == 10) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 65 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 0 }
                            this.northWest = { regionName: 'northWest', score: 0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 0 };
                            this.midWest = { regionName: 'midWest', score: 0 };
                            this.northEast = { regionName: 'northEast', score: 0 };
                            this.southWest = { regionName: 'southWest', score: 0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 0 };
                            this.southEast = { regionName: 'southEast', score: 0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 0 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 65 }
                            this.northWest = { regionName: 'northWest', score: 65 };
                            this.northCenteral = { regionName: 'northCenteral', score: 65 };
                            this.midWest = { regionName: 'midWest', score: 65 };
                            this.northEast = { regionName: 'northEast', score: 65 };
                            this.southWest = { regionName: 'southWest', score: 65 };
                            this.southCenteral = { regionName: 'southCenteral', score: 65 };
                            this.southEast = { regionName: 'southEast', score: 65 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 65 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 55 }
                            this.northWest = { regionName: 'northWest', score: 55 };
                            this.northCenteral = { regionName: 'northCenteral', score: 55 };
                            this.midWest = { regionName: 'midWest', score: 55 };
                            this.northEast = { regionName: 'northEast', score: 55 };
                            this.southWest = { regionName: 'southWest', score: 55 };
                            this.southCenteral = { regionName: 'southCenteral', score: 55 };
                            this.southEast = { regionName: 'southEast', score: 55 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 55 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 60 }
                            this.northWest = { regionName: 'northWest', score: 60 };
                            this.northCenteral = { regionName: 'northCenteral', score: 60 };
                            this.midWest = { regionName: 'midWest', score: 60 };
                            this.northEast = { regionName: 'northEast', score: 60 };
                            this.southWest = { regionName: 'southWest', score: 60 };
                            this.southCenteral = { regionName: 'southCenteral', score: 60 };
                            this.southEast = { regionName: 'southEast', score: 60 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 60 };
                        }
                    }
                    if (this.SliderValue == 11) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 95 }
                            this.northWest = { regionName: 'northWest', score: 95 };
                            this.northCenteral = { regionName: 'northCenteral', score: 95 };
                            this.midWest = { regionName: 'midWest', score: 95 };
                            this.northEast = { regionName: 'northEast', score: 95 };
                            this.southWest = { regionName: 'southWest', score: 95 };
                            this.southCenteral = { regionName: 'southCenteral', score: 95 };
                            this.southEast = { regionName: 'southEast', score: 95 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 95 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 23 }
                            this.northWest = { regionName: 'northWest', score: 23 };
                            this.northCenteral = { regionName: 'northCenteral', score: 23 };
                            this.midWest = { regionName: 'midWest', score: 23 };
                            this.northEast = { regionName: 'northEast', score: 23 };
                            this.southWest = { regionName: 'southWest', score: 23 };
                            this.southCenteral = { regionName: 'southCenteral', score: 23 };
                            this.southEast = { regionName: 'southEast', score: 23 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 23 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 100 }
                            this.northWest = { regionName: 'northWest', score: 100 };
                            this.northCenteral = { regionName: 'northCenteral', score: 100 };
                            this.midWest = { regionName: 'midWest', score: 100 };
                            this.northEast = { regionName: 'northEast', score: 100 };
                            this.southWest = { regionName: 'southWest', score: 100 };
                            this.southCenteral = { regionName: 'southCenteral', score: 100 };
                            this.southEast = { regionName: 'southEast', score: 100 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 100 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 0 }
                            this.northWest = { regionName: 'northWest', score: 0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 0 };
                            this.midWest = { regionName: 'midWest', score: 0 };
                            this.northEast = { regionName: 'northEast', score: 0 };
                            this.southWest = { regionName: 'southWest', score: 0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 0 };
                            this.southEast = { regionName: 'southEast', score: 0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 0 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 100 }
                            this.northWest = { regionName: 'northWest', score: 100 };
                            this.northCenteral = { regionName: 'northCenteral', score: 100 };
                            this.midWest = { regionName: 'midWest', score: 100 };
                            this.northEast = { regionName: 'northEast', score: 100 };
                            this.southWest = { regionName: 'southWest', score: 100 };
                            this.southCenteral = { regionName: 'southCenteral', score: 100 };
                            this.southEast = { regionName: 'southEast', score: 100 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 100 };
                        }
                    }
                }
                if (this.SelectedViewtype == 'MONTH') {
                    if (this.SliderValue == 0) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 78.5 }
                            this.northWest = { regionName: 'northWest', score: 78.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 78.5 };
                            this.midWest = { regionName: 'midWest', score: 64.5 };
                            this.northEast = { regionName: 'northEast', score: 48.5 };
                            this.southWest = { regionName: 'southWest', score: 78.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 78.5 };
                            this.southEast = { regionName: 'southEast', score: 64.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 28.5 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 73.5 }
                            this.northWest = { regionName: 'northWest', score: 73.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 73.5 };
                            this.midWest = { regionName: 'midWest', score: 73.5 };
                            this.northEast = { regionName: 'northEast', score: 48.5 };
                            this.southWest = { regionName: 'southWest', score: 73.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 73.5 };
                            this.southEast = { regionName: 'southEast', score: 48.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 28.5 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 67.3 }
                            this.northWest = { regionName: 'northWest', score: 62.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 62.3 };
                            this.midWest = { regionName: 'midWest', score: 62.3 };
                            this.northEast = { regionName: 'northEast', score: 67.3 };
                            this.southWest = { regionName: 'southWest', score: 62.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 62.3 };
                            this.southEast = { regionName: 'southEast', score: 62.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 67.3 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 25.5 }
                            this.northWest = { regionName: 'northWest', score: 45.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 45.5 };
                            this.midWest = { regionName: 'midWest', score: 45.5 };
                            this.northEast = { regionName: 'northEast', score: 45.5 };
                            this.southWest = { regionName: 'southWest', score: 25.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 45.5 };
                            this.southEast = { regionName: 'southEast', score: 25.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 45.5 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 28.0 }
                            this.northWest = { regionName: 'northWest', score: 28.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 28.0 };
                            this.midWest = { regionName: 'midWest', score: 48.0 };
                            this.northEast = { regionName: 'northEast', score: 28.0 };
                            this.southWest = { regionName: 'southWest', score: 28.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 28.0 };
                            this.southEast = { regionName: 'southEast', score: 48.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 28.0 };
                        }
                    }
                    if (this.SliderValue == 1) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 74.8 }
                            this.northWest = { regionName: 'northWest', score: 64.8 };
                            this.northCenteral = { regionName: 'northCenteral', score: 64.8 };
                            this.midWest = { regionName: 'midWest', score: 64.8 };
                            this.northEast = { regionName: 'northEast', score: 64.8 };
                            this.southWest = { regionName: 'southWest', score: 74.8 };
                            this.southCenteral = { regionName: 'southCenteral', score: 64.8 };
                            this.southEast = { regionName: 'southEast', score: 64.8 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 74.8 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 60.5 }
                            this.northWest = { regionName: 'northWest', score: 70.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 60.5 };
                            this.midWest = { regionName: 'midWest', score: 60.5 };
                            this.northEast = { regionName: 'northEast', score: 60.5 };
                            this.southWest = { regionName: 'southWest', score: 70.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 60.5 };
                            this.southEast = { regionName: 'southEast', score: 60.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 60.5 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 60.0 }
                            this.northWest = { regionName: 'northWest', score: 70.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 70.0 };
                            this.midWest = { regionName: 'midWest', score: 60.0 };
                            this.northEast = { regionName: 'northEast', score: 70.0 };
                            this.southWest = { regionName: 'southWest', score: 70.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 70.0 };
                            this.southEast = { regionName: 'southEast', score: 60.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 70.0 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 48.3 }
                            this.northWest = { regionName: 'northWest', score: 28.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 48.3 };
                            this.midWest = { regionName: 'midWest', score: 48.3 };
                            this.northEast = { regionName: 'northEast', score: 48.3 };
                            this.southWest = { regionName: 'southWest', score: 28.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 48.3 };
                            this.southEast = { regionName: 'southEast', score: 48.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 48.3 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 4.3 }
                            this.northWest = { regionName: 'northWest', score: 4.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 4.3 };
                            this.midWest = { regionName: 'midWest', score: 4.3 };
                            this.northEast = { regionName: 'northEast', score: 4.3 };
                            this.southWest = { regionName: 'southWest', score: 4.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 4.3 };
                            this.southEast = { regionName: 'southEast', score: 4.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 4.3 };
                        }
                    }
                    if (this.SliderValue == 2) {
                        if (this.SelectedCategory == "Patient 1") {
                            this.alaska = { regionName: 'alaska', score: 77.5 }
                            this.northWest = { regionName: 'northWest', score: 64.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 77.5 };
                            this.midWest = { regionName: 'midWest', score: 77.5 };
                            this.northEast = { regionName: 'northEast', score: 64.5 };
                            this.southWest = { regionName: 'southWest', score: 77.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 77.5 };
                            this.southEast = { regionName: 'southEast', score: 64.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 77.5 };
                        }
                        if (this.SelectedCategory == "Patient 2") {
                            this.alaska = { regionName: 'alaska', score: 44.5 }
                            this.northWest = { regionName: 'northWest', score: 24.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 24.5 };
                            this.midWest = { regionName: 'midWest', score: 24.5 };
                            this.northEast = { regionName: 'northEast', score: 44.5 };
                            this.southWest = { regionName: 'southWest', score: 24.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 24.5 };
                            this.southEast = { regionName: 'southEast', score: 24.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 24.5 };
                        }
                        if (this.SelectedCategory == "Message 1") {
                            this.alaska = { regionName: 'alaska', score: 82.5 }
                            this.northWest = { regionName: 'northWest', score: 82.5 };
                            this.northCenteral = { regionName: 'northCenteral', score: 82.5 };
                            this.midWest = { regionName: 'midWest', score: 64.5 };
                            this.northEast = { regionName: 'northEast', score: 82.5 };
                            this.southWest = { regionName: 'southWest', score: 64.5 };
                            this.southCenteral = { regionName: 'southCenteral', score: 82.5 };
                            this.southEast = { regionName: 'southEast', score: 64.5 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 82.5 };
                        }
                        if (this.SelectedCategory == "Message 2") {
                            this.alaska = { regionName: 'alaska', score: 67.3 }
                            this.northWest = { regionName: 'northWest', score: 31.3 };
                            this.northCenteral = { regionName: 'northCenteral', score: 31.3 };
                            this.midWest = { regionName: 'midWest', score: 31.3 };
                            this.northEast = { regionName: 'northEast', score: 31.3 };
                            this.southWest = { regionName: 'southWest', score: 45.3 };
                            this.southCenteral = { regionName: 'southCenteral', score: 31.3 };
                            this.southEast = { regionName: 'southEast', score: 31.3 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 31.3 };
                        }
                        if (this.SelectedCategory == "Message 3") {
                            this.alaska = { regionName: 'alaska', score: 67.0 }
                            this.northWest = { regionName: 'northWest', score: 50.0 };
                            this.northCenteral = { regionName: 'northCenteral', score: 50.0 };
                            this.midWest = { regionName: 'midWest', score: 50.0 };
                            this.northEast = { regionName: 'northEast', score: 50.0 };
                            this.southWest = { regionName: 'southWest', score: 20.0 };
                            this.southCenteral = { regionName: 'southCenteral', score: 50.0 };
                            this.southEast = { regionName: 'southEast', score: 50.0 };
                            this.pasficIsland = { regionName: 'pasficIsland', score: 20.0 };
                        }
                    }
                }
            }
            if (this.CurrentPage != undefined) {
                this.currentPageName = this.CurrentPage;
            }
        }
    }

