import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/itish-isles.topo.json")*/
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import * as $ from 'jquery';
import { SellingSkillElements } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';

@Component({
    selector: 'app-sellingskills',
    templateUrl: './sellingskills.component.html',
    styleUrls: ['./sellingskills.component.css']
})
export class SellingskillsComponent implements OnInit {

    event: any;
    element: any;
    valueToCheck: any;
    totalNoOfCalls: any;
    selectedCategory: any = 'Overall'
    selectedViewtype: any = 'MONTH';
    sliderValue: any;
    value: number = 0;
    selectedView: string;
    constructor(
        private route: Router,
        private dashboardService: DashboardService,

    ) {
        this.selectedView = localStorage.getItem('selectedView');
        this.updateElementNames();
}

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";


    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }
        if (value >= 1) {
            return ' - ' + Math.round(value / 1) + '';

        }
        return value;
    }

    ngOnInit() {
        //$('.uk-map').hide();
        //if (localStorage.getItem('mapdrill') == '1') {
        //    $('.uk-map').show();
        //    $('.w-map_1').hide();
        //}
        this.getSellingSkillDataByClient(this.valueToCheck)
        this.StaticonWeekChange(this.event)

       
        this.selectedCategory = 'Overall';
        this.selectedViewtype = 'MONTH';
        this.showMapData();
    }



    mapData: any = [['gb', 2.8],['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ];
    selectedElementList: any = [];

    updateFlag = false;
    currentPage: string = "SS";

    clientRegionDataList: any = [];
    clientRegionData: any = [];
    staticTextData: any;
    isMonthlySelected: boolean = true;
    @ViewChild('table', { static: false }) table: MatTable<SellingSkillElements>;

    displayedColumns: string[] = ['ElementName', 'Score', 'Checked'];

 dataSource = [{
    "elementNameToShow": "Overall",
    "aliasElementName": "Overall",
    "score": 2.80
},
{
    "elementNameToShow": "Opening",
    "aliasElementName": "Opening",
    "score": 3.00
},
{
    "elementNameToShow": "Questioning",
    "aliasElementName": "Questioning",
    "score": 2.80
},
{
    "elementNameToShow": "Presenting",
    "aliasElementName": "Presenting",
    "score": 2.50
},
{
    "elementNameToShow": "Closing",
    "aliasElementName": "Closing",
    "score": 2.70
}
];
    updateElementNames() {
        if (this.selectedView === "Msl") {
            this.dataSource.forEach(item => {
                if (item.aliasElementName === "Opening") {
                    item.elementNameToShow = "Engage";
                }
                if (item.aliasElementName === "Questioning") {
                    item.elementNameToShow = "Explore";
                }
                if (item.aliasElementName === "Presenting") {
                    item.elementNameToShow = "Exchange";
                }
                if (item.aliasElementName === "Closing") {
                    item.elementNameToShow = "Evolve";
                }
            });
        }
    }
    getSellingSkillDataByClient(valueToCheck) {
        $('.loder-outer').show();
        var objParam = {

        }
        this.dashboardService.getSellingSkillDataByClient(objParam).subscribe((result: any) => {

            this.totalNoOfCalls = 84



            ////called Region method
            //this.getClientSellingSkillDataByRegion();
            $('.loder-outer').hide();
        },
            error => console.error(error));
    }
    changeViewType(view) {
        this.selectedViewtype = view;
        this.value = 0;
        this.sliderValue = 0;
        this.selectedElementList = [];
        this.selectedCategory = 'Overall'
        if (this.selectedViewtype == 'WEEK') {
            this.totalNoOfCalls = 20;
            this.mapData = [];
            this.dataSource = [{
                "elementNameToShow": "Overall",
                "aliasElementName": "Overall",
                "score": 2.66
            },
            {
                "elementNameToShow": "Opening",
                "aliasElementName": "Opening",
                "score": 2.85
            },
            {
                "elementNameToShow": "Questioning",
                "aliasElementName": "Questioning",
                "score": 2.80
            },
            {
                "elementNameToShow": "Presenting",
                "aliasElementName": "Presenting",
                "score": 2.40
            },
            {
                "elementNameToShow": "Closing",
                "aliasElementName": "Closing",
                "score": 2.60
            }
            ];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
             if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', score], ['fr', 1.4], ['de', 3.4], ['jp', 2.4], ['us', 3.6], ],
                name: Selectedname,

            }
            if (this.selectedView === "Msl") {
                this.dataSource.forEach(item => {
                    if (item.aliasElementName === "Opening") {
                        item.elementNameToShow = "Engage";
                    }
                    if (item.aliasElementName === "Questioning") {
                        item.elementNameToShow = "Explore";
                    }
                    if (item.aliasElementName === "Presenting") {
                        item.elementNameToShow = "Exchange";
                    }
                    if (item.aliasElementName === "Closing") {
                        item.elementNameToShow = "Evolve";
                    }
                });
            }
            this.updateFlag = true;
        }
        if (this.selectedViewtype == 'MONTH') {
            this.totalNoOfCalls = 84;
            this.mapData = [['us', 2.8],['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ];
            this.dataSource = [{
                "elementNameToShow": "Overall",
                "aliasElementName": "Overall",
                "score": 2.8
            },
            {
                "elementNameToShow": "Opening",
                "aliasElementName": "Opening",
                "score": 3.0
            },
            {
                "elementNameToShow": "Questioning",
                "aliasElementName": "Questioning",
                "score": 2.8
            },
            {
                "elementNameToShow": "Presenting",
                "aliasElementName": "Presenting",
                "score": 2.5
            },
            {
                "elementNameToShow": "Closing",
                "aliasElementName": "Closing",
                "score": 2.7
            }
            ];
            var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
             if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
            this.chartOptions.series[0] = {
                type: 'map',
                data: [['gb', score],['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ],
                name: Selectedname,

            }
              if (this.selectedView === "Msl") {
            this.dataSource.forEach(item => {
                if (item.aliasElementName === "Opening") {
                    item.elementNameToShow = "Engage";
                }
                if (item.aliasElementName === "Questioning") {
                    item.elementNameToShow = "Explore";
                }
                if (item.aliasElementName === "Presenting") {
                    item.elementNameToShow = "Exchange";
                }
                if (item.aliasElementName === "Closing") {
                    item.elementNameToShow = "Evolve";
                }
            });
        }
            this.updateFlag = true;
         
        }
    }
    onSelectElement(element, event) {
        this.selectedElementList = [];
        this.selectedElementList.push(element.aliasElementName);
        this.selectedCategory = element.aliasElementName
        if (this.sliderValue === 0 && this.selectedViewtype == 'WEEK') {
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
        
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.7], ['fr', 1.4], ['de', 3.4], ['jp', 2.4], ['us', 3.6],],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['fr', 1.4], ['de', 3.9], ['jp', 2.9], ['us', 3.7],
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.3], ['de', 3.2], ['jp', 2.2], ['us', 3.8],
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.4], ['fr', 1.2], ['de', 3.4], ['jp', 2.4], ['us', 3.4],
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.6], ['fr', 1.5], ['de', 3.2], ['jp', 2.2], ['us', 3.3],
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
            
        }
        if (this.sliderValue === 1 && this.selectedViewtype == 'WEEK') {
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
        
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.6], ['fr', 1.6], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['fr', 1.5], ['de', 3.7], ['jp', 2.7], ['us', 3.6], ],
                  
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['fr', 1.0], ['de', 3.6], ['jp', 2.6], ['us', 3.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.4], ['fr', 1.9], ['de', 3.8], ['jp', 2.8], ['us', 3.6], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.6], ['fr', 1.8], ['de', 3.1], ['jp', 2.1], ['us', 3.8], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 2 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
        
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.2], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.4], ['fr', 1.3], ['de', 3.5], ['jp', 2.5], ['us', 3.9], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['fr', 1.1], ['de', 3.4], ['jp', 2.4], ['us', 3.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.5], ['fr', 1.2], ['de', 3.7], ['jp', 2.7], ['us', 3.7], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['fr', 1.1], ['de', 3.9], ['jp', 2.9], ['us', 3.4], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 3 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.6], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['fr', 1.4], ['de', 3.8], ['jp', 2.8], ['us', 3.8], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.5], ['de', 3.7], ['jp', 2.7], ['us', 3.7], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.1], ['de', 3.6], ['jp', 2.6], ['us', 3.6], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['fr', 1.7], ['de', 3.4], ['jp', 2.4], ['us', 3.4], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 4 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['fr', 1.7], ['de', 2.5], ['jp', 2.5], ['us', 3.0], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 2.0], ['de', 2.5], ['jp', 2.5], ['us', 3.4], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.5], ['de', 2.5], ['jp', 2.5], ['us', 3.5], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 1.4], ['de', 2.1], ['jp', 2.1], ['us', 2.1], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.6], ['fr', 1.9], ['de', 2.9], ['jp', 2.9], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 5 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.9], ['de', 2.7], ['jp', 2.9], ['us', 3.0], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.2], ['fr', 1.8], ['de', 2.8], ['jp', 2.8], ['us', 2.8], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 2.1], ['de', 2.1], ['jp', 3.1], ['us', 3.4], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 2.1], ['de', 2.6], ['jp', 2.6], ['us', 2.6], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.4], ['de', 3.1], ['jp', 3.1], ['us', 3.1], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 6 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
            
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['fr', 1.4], ['de', 2.8], ['jp', 2.6], ['us', 3.1], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.4], ['de', 2.6], ['jp', 2.6], ['us', 3.1], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.3], ['de', 3.3], ['jp', 2.3], ['us', 3.3], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.6], ['fr', 1.2], ['de', 3.1], ['jp', 3.1], ['us', 3.1], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.3], ['fr', 1.5], ['de', 2.2], ['jp', 2.2], ['us', 2.7], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 7 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
         
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.6], ['de', 2.8], ['jp', 2.9], ['us', 3.0], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.5], ['de', 2.9], ['jp', 2.9], ['us', 2.9], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['fr', 1.0], ['de', 2.4], ['jp', 2.4], ['us', 3.2], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 1.9], ['de', 2.9], ['jp', 3.1], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.3], ['fr', 1.8], ['de', 2.8], ['jp', 3.2], ['us', 2.8], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 8 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.2], ['de', 2.9], ['jp', 2.7], ['us', 3.1], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.2], ['fr', 1.3], ['de', 2.5], ['jp', 2.5], ['us', 2.5], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.1], ['de', 2.5], ['jp', 2.5], ['us', 3.1], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.6], ['fr', 1.2], ['de', 3.6], ['jp', 3.0], ['us', 3.6], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.2], ['fr', 1.1], ['de', 3.1], ['jp', 2.8], ['us', 3.1], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }

        if (this.sliderValue === 9 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
         
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.4], ['de', 2.7], ['jp', 2.4], ['us', 3.0], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 1.4], ['de', 2.5], ['jp', 2.5], ['us', 3.1], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['fr', 1.5], ['de', 2.6], ['jp', 2.6], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 1.1], ['de', 2.9], ['jp', 2.1], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.1], ['fr', 1.7], ['de', 2.9], ['jp', 2.5], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 10 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
           
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['fr', 1.7], ['de', 3.2], ['jp', 2.8], ['us', 3.2], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 1.1], ['de', 3.4], ['jp', 3.4], ['us', 3.4], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 2.1], ['de', 2.9], ['jp', 2.9], ['us', 2.9], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 2.2], ['de', 3.2], ['jp', 2.2], ['us', 3.2], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.3], ['fr', 1.3], ['de', 3.3], ['jp', 2.8], ['us', 3.3], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 11 && this.selectedViewtype == 'WEEK') {
             var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
           
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.2], ['fr', 1.6], ['de', 2.6], ['jp', 2.6], ['us', 2.6], ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 1.2], ['de', 2.2], ['jp', 2.2], ['us', 2.2], ],

                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['fr', 1.4], ['de', 2.5], ['jp', 2.5], ['us', 2.5], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['fr', 1.3], ['de', 2.6], ['jp', 2.6], ['us', 2.6], 
                    ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.5], ['fr', 2.5], ['de', 2.9], ['jp', 3.1], ['us', 2.9],                     ],
                    name:Selectedname,
                }

                this.updateFlag = true;
            }

        }
      
        //Month data

        if (this.sliderValue === 0 && this.selectedViewtype == 'MONTH') {
            var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
           
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 3.6], ['fr', 1.4], ['jp', 2.6], ['us', 3.7], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['de', 3.7], ['fr', 1.4], ['jp', 2.7], ['us', 3.8], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 3.5], ['fr', 1.2], ['jp', 2.5], ['us', 3.8], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.5], ['de', 3.6], ['fr', 1.4], ['jp', 2.6], ['us', 3.6], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['de', 3.4], ['fr', 1.5], ['jp', 2.4], ['us', 3.5], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 1 && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['de', 2.7], ['fr', 1.6], ['jp', 2.7], ['us', 3.0], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['de', 2.7], ['fr', 1.7], ['jp', 2.7], ['us', 3.1], ],

                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['de', 2.6], ['fr', 1.5], ['jp', 2.6], ['us', 3.4], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['de', 2.7], ['fr', 1.7], ['jp', 2.7], ['us', 2.7], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.5], ['de', 2.8], ['fr', 1.7], ['jp', 2.9], ['us', 2.9], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 2 && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
          
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['de', 2.9], ['fr', 1.5], ['jp', 2.6], ['us', 2.9], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.3], ['de', 2.7], ['fr', 1.3], ['jp', 2.7], ['us', 2.8], ],

                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.2], ['de', 2.6], ['fr', 1.5], ['jp', 2.6], ['us', 2.9], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['de', 3.1], ['fr', 1.5], ['jp', 2.5], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.3], ['de', 3.1], ['fr', 1.7], ['jp', 2.8], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 3 && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
            
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['de', 3.0], ['fr', 2.2], ['jp', 2.9], ['us', 3.0], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['de', 2.9], ['fr', 2.1], ['jp', 2.4], ['us', 2.9], ],

                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.1], ['de', 3.1], ['fr', 2.1], ['jp', 3.1], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.4], ['de', 3.0], ['fr', 2.5], ['jp', 3.0], ['us', 3.0], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.4], ['de', 3.0], ['fr', 2.0], ['jp', 3.0], ['us', 3.0], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 4 && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
    
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 1.8], ['de', 2.8], ['fr', 1.7], ['jp', 2.8], ['us', 2.8], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 1.4], ['de', 2.7], ['fr', 1.4], ['jp', 2.4], ['us', 2.7], ],

                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 1.4], ['de', 3.1], ['fr', 1.3], ['jp', 2.9], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.4], ['de', 2.4], ['fr', 2.4], ['jp', 2.7], ['us', 2.4], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 1.6], ['de', 3.1], ['fr', 1.6], ['jp', 3.1], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === 5 && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }

            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['de', 2.6], ['fr', 1.7], ['jp', 2.6], ['us', 2.9], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 2.1], ['fr', 1.4], ['jp', 2.0], ['us', 2.7], ],

                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['de', 3.1], ['fr', 1.5], ['jp', 2.8], ['us', 3.2], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.5], ['de', 2.7], ['fr', 2.4], ['jp', 2.7], ['us', 2.7], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 2.6], ['fr', 1.6], ['jp', 3.0], ['us', 3.1], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
        if (this.sliderValue === undefined && this.selectedViewtype == 'WEEK') {
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['fr', 1.4], ['de', 3.4], ['jp', 2.4], ['us', 3.6], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.9], ['fr', 1.4], ['de', 3.9], ['jp', 2.9], ['us', 3.7], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['fr', 1.3], ['de', 3.2], ['jp', 2.2], ['us', 3.8], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.4], ['fr', 1.2], ['de', 3.4], ['jp', 2.4], ['us', 3.4], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.6], ['fr', 1.5], ['de', 3.2], ['jp', 2.2], ['us', 3.3], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
        }
        if (this.sliderValue === undefined && this.selectedViewtype == 'MONTH') {
                      var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
            if (this.selectedView === "Msl") {
                switch (this.selectedCategory) {
                    case "Opening":
                        Selectedname = "Engage";
                        break;
                    case "Questioning":
                        Selectedname = "Explore";
                        break;
                    case "Presenting":
                        Selectedname = "Exchange";
                        break;
                    case "Closing":
                        Selectedname = "Evolve";
                        break;
                }
            }
            if (this.selectedCategory == "Overall") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 3.6], ['fr', 1.4], ['jp', 2.6], ['us', 3.7], ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Opening") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 3.0], ['de', 3.7], ['fr', 1.4], ['jp', 2.7], ['us', 3.8], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Questioning") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.8], ['de', 3.5], ['fr', 1.2], ['jp', 2.5], ['us', 3.8], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

            if (this.selectedCategory == "Presenting") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.5], ['de', 3.6], ['fr', 1.4], ['jp', 2.6], ['us', 3.6], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }
            if (this.selectedCategory == "Closing") {
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', 2.7], ['de', 3.4], ['fr', 1.5], ['jp', 2.4], ['us', 3.5], 
                    ],
                    name: Selectedname,
                }

                this.updateFlag = true;
            }

        }
    }

    showMapData() {
        this.mapData = []
        var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
        var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
         if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['gb', score], /*['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.7], */],
            name: Selectedname,

        }
        this.updateFlag = true;
        this.totalNoOfCalls = 84;
    }


    StaticonWeekChange(event) {
        this.sliderValue = event.value
        if (this.selectedViewtype == 'WEEK') {
            if (event.value === 0) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.66
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 2.85
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.40
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.60
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.4], ['de', 3.4], ['jp', 2.4], ['us', 3.6], ],
                        name: Selectedname,

                    }
                  
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['fr', 1.4], ['de', 3.9], ['jp', 2.9], ['us', 3.7], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.3], ['de', 3.2], ['jp', 2.2], ['us', 3.8], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.4], ['fr', 1.2], ['de', 3.4], ['jp', 2.4], ['us', 3.4], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.6], ['fr', 1.5], ['de', 3.2], ['jp', 2.2], ['us', 3.3], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 20;

                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.64
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 2.85
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.70
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.40
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.60
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.6], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ],
                        name: Selectedname,

                    }
                 
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['fr', 1.5], ['de', 3.7], ['jp', 2.7], ['us', 3.6], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.7], ['fr', 1.0], ['de', 3.6], ['jp', 2.6], ['us', 3.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.4], ['fr', 1.9], ['de', 3.8], ['jp', 2.8], ['us', 3.6], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.6], ['fr', 1.8], ['de', 3.1], ['jp', 2.1], ['us', 3.8], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 22;

                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.81
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.35
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.70
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.70
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.2], ['de', 3.6], ['jp', 2.6], ['us', 3.7], ],
                        name: Selectedname,

                    }
                 
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.4], ['fr', 1.3], ['de', 3.5], ['jp', 2.5], ['us', 3.9], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.7], ['fr', 1.1], ['de', 3.4], ['jp', 2.4], ['us', 3.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.5], ['fr', 1.2], ['de', 3.7], ['jp', 2.7], ['us', 3.7], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.7], ['fr', 1.1], ['de', 3.9], ['jp', 2.9], ['us', 3.4], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 22;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.90
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.90
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.6], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.1], ['fr', 1.4], ['de', 3.8], ['jp', 2.8], ['us', 3.8], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.5], ['de', 3.7], ['jp', 2.7], ['us', 3.7], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.1], ['de', 3.6], ['jp', 2.6], ['us', 3.6], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['fr', 1.7], ['de', 3.4], ['jp', 2.4], ['us', 3.4], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.00
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.60
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.7], ['de', 2.5], ['jp', 2.5], ['us', 3.0], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 2.0], ['de', 2.5], ['jp', 2.5], ['us', 3.4], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['fr', 1.5], ['de', 2.5], ['jp', 2.5], ['us', 3.5], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 1.4], ['de', 2.1], ['jp', 2.1], ['us', 2.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.6], ['fr', 1.9], ['de', 2.9], ['jp', 2.9], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.20
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.80
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.9], ['de', 2.7], ['jp', 2.9], ['us', 3.0], 
                        ],
                        name: Selectedname
                    }
                 
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.2], ['fr', 1.8], ['de', 2.8], ['jp', 2.8], ['us', 2.8], ],

                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 2.1], ['de', 2.1], ['jp', 3.1], ['us', 3.4], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 2.1], ['de', 2.6], ['jp', 2.6], ['us', 2.6], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.4], ['de', 3.1], ['jp', 3.1], ['us', 3.1], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.90
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 2.95
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.60
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.30
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.4], ['de', 2.8], ['jp', 2.6], ['us', 3.1], 
                        ],
                        name: Selectedname
                    }
                  
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['fr', 1.4], ['de', 2.6], ['jp', 2.6], ['us', 3.1], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['fr', 1.3], ['de', 3.3], ['jp', 2.3], ['us', 3.3], 
                        ],
                        name:Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.6], ['fr', 1.2], ['de', 3.1], ['jp', 3.1], ['us', 3.1], 
                        ],
                        name:Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.3], ['fr', 1.5], ['de', 2.2], ['jp', 2.2], ['us', 2.7], 
                        ],
                        name:Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 15;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.00
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.30
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.6], ['de', 2.8], ['jp', 2.9], ['us', 3.0], 
                        ],
                        name: Selectedname
                    }
                    
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['fr', 1.5], ['de', 2.9], ['jp', 2.9], ['us', 2.9], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.1], ['fr', 1.0], ['de', 2.4], ['jp', 2.4], ['us', 3.2], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 1.9], ['de', 2.9], ['jp', 3.1], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.3], ['fr', 1.8], ['de', 2.8], ['jp', 3.2], ['us', 2.8], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 8) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.20
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.00
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.60
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.20
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.2], ['de', 2.9], ['jp', 2.7], ['us', 3.1], 
                        ],
                        name: Selectedname
                    }
                  
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.2], ['fr', 1.3], ['de', 2.5], ['jp', 2.5], ['us', 2.5], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['fr', 1.1], ['de', 2.5], ['jp', 2.5], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.6], ['fr', 1.2], ['de', 3.6], ['jp', 3.0], ['us', 3.6], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.2], ['fr', 1.1], ['de', 3.1], ['jp', 2.8], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.10
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.4], ['de', 2.7], ['jp', 2.4], ['us', 3.0], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 1.4], ['de', 2.5], ['jp', 2.5], ['us', 3.1], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.1], ['fr', 1.5], ['de', 2.6], ['jp', 2.6], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 1.1], ['de', 2.9], ['jp', 2.1], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.1], ['fr', 1.7], ['de', 2.9], ['jp', 2.5], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 20;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.25
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.30
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.7], ['de', 3.2], ['jp', 2.8], ['us', 3.2], ],
                        name: Selectedname,

                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 1.1], ['de', 3.4], ['jp', 3.4], ['us', 3.4], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 2.1], ['de', 2.9], ['jp', 2.9], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 2.2], ['de', 3.2], ['jp', 2.2], ['us', 3.2], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.3], ['fr', 1.3], ['de', 3.3], ['jp', 2.8], ['us', 3.3], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 4;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.20
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.50
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.6], ['de', 2.6], ['jp', 2.6], ['us', 2.6], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 1.2], ['de', 2.2], ['jp', 2.2], ['us', 2.2], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['fr', 1.4], ['de', 2.5], ['jp', 2.5], ['us', 2.5], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['fr', 1.3], ['de', 2.6], ['jp', 2.6], ['us', 2.6], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.5], ['fr', 2.5], ['de', 2.9], ['jp', 3.1], ['us', 2.9],                         ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
            }
            if (this.selectedView === "Msl") {
                this.dataSource.forEach(item => {
                    if (item.aliasElementName === "Opening") {
                        item.elementNameToShow = "Engage";
                    }
                    if (item.aliasElementName === "Questioning") {
                        item.elementNameToShow = "Explore";
                    }
                    if (item.aliasElementName === "Presenting") {
                        item.elementNameToShow = "Exchange";
                    }
                    if (item.aliasElementName === "Closing") {
                        item.elementNameToShow = "Evolve";
                    }
                });
            }
        }

        if (this.selectedViewtype == 'MONTH') {
            if (event.value === 0) {
                this.totalNoOfCalls = 84;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.70
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.4], ['de', 3.6], ['jp', 2.6], ['us', 3.7], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['de', 3.7], ['fr', 1.4], ['jp', 2.7], ['us', 3.8], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['de', 3.5], ['fr', 1.2], ['jp', 2.5], ['us', 3.8], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.5], ['de', 3.6], ['fr', 1.4], ['jp', 2.6], ['us', 3.6], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.7], ['de', 3.4], ['fr', 1.5], ['jp', 2.4], ['us', 3.5], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 75;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 2.90
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.5
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.6], ['de', 2.7], ['jp', 2.7], ['us', 3.0], 
                        ],
                        name: Selectedname
                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.1], ['de', 2.7], ['fr', 1.7], ['jp', 2.7], ['us', 3.1], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['de', 2.6], ['fr', 1.5], ['jp', 2.6], ['us', 3.4], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['de', 2.7], ['fr', 1.7], ['jp', 2.7], ['us', 2.7], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.5], ['de', 2.8], ['fr', 1.7], ['jp', 2.9], ['us', 2.9], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 64;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 3.30
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.20
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.30
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.5], ['de', 2.9], ['jp', 2.6], ['us', 2.9], 
                        ],
                        name: Selectedname
                    }
                    
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.3], ['de', 2.7], ['fr', 1.3], ['jp', 2.7], ['us', 2.8], ],

                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.2], ['de', 2.6], ['fr', 1.5], ['jp', 2.6], ['us', 2.9], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['de', 3.1], ['fr', 1.5], ['jp', 2.5], ['us', 3.1], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.3], ['de', 3.1], ['fr', 1.7], ['jp', 2.8], ['us', 3.1], 
                        ],
                        name: Selectedname
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 93;

                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 2.90
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 2.90
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.10
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.40
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.40
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 2.2], ['de', 3.0], ['jp', 2.9], ['us', 3.0], ],
                        name: Selectedname,

                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.9], ['de', 2.9], ['fr', 2.1], ['jp', 2.4], ['us', 2.9], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.1], ['de', 3.1], ['fr', 2.1], ['jp', 3.1], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.4], ['de', 3.0], ['fr', 2.5], ['jp', 3.0], ['us', 3.0], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.4], ['de', 3.0], ['fr', 2.0], ['jp', 3.0], ['us', 3.0], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 77;

                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 1.80
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 1.40
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 1.40
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 2.40
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 1.60
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.7], ['de', 2.8], ['jp', 2.8], ['us', 2.8], ],
                        name: Selectedname,

                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 1.4], ['de', 2.7], ['fr', 1.4], ['jp', 2.4], ['us', 2.7], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 1.4], ['de', 3.1], ['fr', 1.3], ['jp', 2.9], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.4], ['de', 2.4], ['fr', 2.4], ['jp', 2.7], ['us', 2.4], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 1.6], ['de', 3.1], ['fr', 1.6], ['jp', 3.1], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 108;
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 2.80
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 3.0
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 3.50
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 2.80
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                if (this.selectedCategory == "Overall") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', score], ['fr', 1.7], ['de', 2.6], ['jp', 2.6], ['us', 2.9], ],
                        name: Selectedname,

                    }
                   
                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Opening") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['de', 2.1], ['fr', 1.4], ['jp', 2.0], ['us', 2.7], ],

                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Questioning") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.0], ['de', 3.1], ['fr', 1.5], ['jp', 2.8], ['us', 3.2], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }

                if (this.selectedCategory == "Presenting") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 3.5], ['de', 2.7], ['fr', 2.4], ['jp', 2.7], ['us', 2.7], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedCategory == "Closing") {
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['gb', 2.8], ['de', 2.6], ['fr', 1.6], ['jp', 3.0], ['us', 3.1], 
                        ],
                        name: Selectedname,
                    }

                    this.updateFlag = true;
                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
            }

            else {
                this.dataSource = [{
                    "elementNameToShow": "Overall",
                    "aliasElementName": "Overall",
                    "score": 0
                },
                {
                    "elementNameToShow": "Opening",
                    "aliasElementName": "Opening",
                    "score": 0
                },
                {
                    "elementNameToShow": "Questioning",
                    "aliasElementName": "Questioning",
                    "score": 0
                },
                {
                    "elementNameToShow": "Presenting",
                    "aliasElementName": "Presenting",
                    "score": 0
                },
                {
                    "elementNameToShow": "Closing",
                    "aliasElementName": "Closing",
                    "score": 0
                }
                ];
                var score = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).score;
                var Selectedname = this.dataSource.find(x => x.aliasElementName == this.selectedCategory).aliasElementName
                 if (this.selectedView === "Msl") {
                    switch (this.selectedCategory) {
                        case "Opening":
                            Selectedname = "Engage";
                            break;
                        case "Questioning":
                            Selectedname = "Explore";
                            break;
                        case "Presenting":
                            Selectedname = "Exchange";
                            break;
                        case "Closing":
                            Selectedname = "Evolve";
                            break;
                    }
                }
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['gb', score]],
                    name: Selectedname,

                }
                if (this.selectedView === "Msl") {
                    this.dataSource.forEach(item => {
                        if (item.aliasElementName === "Opening") {
                            item.elementNameToShow = "Engage";
                        }
                        if (item.aliasElementName === "Questioning") {
                            item.elementNameToShow = "Explore";
                        }
                        if (item.aliasElementName === "Presenting") {
                            item.elementNameToShow = "Exchange";
                        }
                        if (item.aliasElementName === "Closing") {
                            item.elementNameToShow = "Evolve";
                        }
                    });
                }
                this.updateFlag = true;
            }
            
          
             
        }
    }
    chartOptions: Options = {

        chart: {
            map: worldMap as any,
            inverted: false,
            animation: false,
        },
        colors: ['#ffcccc', '#ffb3b3', '#ff9999', '#ff0000', '#ffecb3', '#ffe599', '#ffd966', '#ffbf00', '#d1ffd1', '#b3ffb3', '#94ff94', '#66ff66', '#9ccc99', '#83c080', '#6ab366', '#51a64d', '#399933', '#208d1a', '#078000'],
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {

            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [{
                from: 0.0,
                to: 0.6,

            }, {
                from: 0.6,
                to: 0.8,
            },
            {
                from: 1.0,
                to: 1.2,
            },
            {
                from: 1.2,
                to: 1.4,

            },
            {
                from: 1.5,
                to: 1.8,
            },
            {
                from: 1.8,
                to: 2.0,
            },
            {
                from: 2.0,
                to: 2.2,

            }, {
                from: 2.2,
                to: 2.4,
            },
            {
                from: 2.5,
                to: 2.8,
            },
            {
                from: 2.8,
                to: 3.0,
            },
            {
                from: 3.0,
                to: 3.2,

            }, {
                from: 3.2,
                to: 3.4,
            },
            {
                from: 3.5,
                to: 3.7,
            },
            {
                from: 3.7,
                to: 3.9,
            },
            {
                from: 3.9,
                to: 4.1,
            },
            {
                from: 4.1,
                to: 4.3,
            },
            {
                from: 4.3,
                to: 4.5,
            },
            {
                from: 4.5,
                to: 4.8,
            },
            {
                from: 4.8,
                to: 5.0,
            }
            ]
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true
                }
            }
        },
        series: [{
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                        /*this.getDataByCountryKey(e.point['hc-key'])*/

                    }
                }
            },
            type: "map",
            name: "Overall",
            states: {
                hover: {
                    enabled: false,
                }
            },
            tooltip: {
                valueDecimals: 1,
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        if (this.point['hc-key'] === 'gb') {
                            return 'U.K....'
                        }
                        return this.point.name;
                    }
                }
            },
            allAreas: true,
            data: this.mapData
        }]
    };



}


