import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

interface product {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'app-demoprofilepage',
  templateUrl: './demoprofilepage.component.html',
  styleUrls: ['./demoprofilepage.component.css']
})
export class DemoprofilepageComponent implements OnInit {

    public poptin: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 0,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Opening', 'Questioning', 'Presenting', 'Closing'],
            labels: {
                enabled: false,
                align: 'left',
                x: 10,
                y: -16,
            },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,

            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {
            reversed: true,
            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -395
            },
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5
            },
            series: {
                pointPadding: 0.2,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: -7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{

            color: '#09d6cc',
            data: [15, 22, 25, 16]
        }]
    }
    public poptino: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginLeft: 10,
            marginRight: 25,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Opening', 'Questioning', 'Presenting', 'Closing'],
            labels: {
                enabled: true,
                align: 'left',
                x: 10,
                y: -25,
                style: {
                    fontSize: '13px',
                },

            },
            left: "-1%",
            title: {
                text: null
            },

            gridLineColor: 'transparent',
        },
        yAxis: {

            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -390,
                x: -8,
                lineWidth: 1,
            },
            tickLength: 1,
            tickInterval: 10,
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5,

            },
            series: {

                pointPadding: 0.2,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: 7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{

            color: '#09d6cc',
            data: [62, 73, 68, 64]
        }]
    }
    public poptinn: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 0,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Positioning', 'Primary efficacy', 'Secondary efficacy', 'Safety'],
            labels: {
                enabled: false,
                align: 'left',
                x: 10,
                y: -16,
            },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,

            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {
            reversed: true,
            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -395
            },
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5
            },
            series: {
                pointPadding: 0.25,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: -7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{

            color: '#09d6cc',
            data: [21, 51, 16, 12]
        }]
    }
    public poptinoo: any = {
        chart: {
            type: 'bar',
            marginTop: 55,
            marginLeft: 10,
            marginRight: 25,
            backgroundColor: '#f7f7f7',
            style: {
                fontFamily: 'inherit',
                fontWeight: 'normal',
            }
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#012072',
                fontWeight: 'bold',
                fontSize: '16px',

            }
        },
        xAxis: {
            categories: ['Positioning', 'Primary efficacy', 'Secondary efficacy', 'Safety'],
            labels: {
                enabled: true,
                align: 'left',
                x: 10,
                y: -25,
                style: {
                    fontSize: '13px',
                },

            },
            left: "-1%",
            title: {
                text: null
            },
            gridLineColor: 'transparent',
        },
        yAxis: {

            title: false,
            labels: {
                enabled: false,
                format: '{value}%',
                align: 'top',
                y: -390,
                x: -8,
                lineWidth: 1,
            },
            tickLength: 1,
            tickInterval: 10,
            min: 0,
            max: 100,
            gridLineColor: 'transparent',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {

                borderRadius: 5,

            },
            series: {
                pointPadding: 0.25,
                dataLabels: {
                    enabled: true,
                    format: '{y}% ',
                    x: 7,
                    style: {
                        color: '#012072',
                        fontSize: '15px',
                        fontWeight: 'bolder',
                    }
                }
            }
        },
        series: [{
            color: '#09d6cc',
            data: [72, 93, 84, 75]
        }]
    }

    selected = 'Gaboderm';
    product: product[] = [
        { value: 'Gaboderm', viewValue: 'Gaboderm' },
        //{ value: 'US', viewValue: 'US' },
        //{ value: 'India', viewValue: 'India' },
    ];

    constructor() { }

    ngOnInit() {

        Highcharts.chart('ProgCI', this.poptin);
        Highcharts.chart('ProgCIm', this.poptino)
        Highcharts.chart('ProgCII', this.poptinn);
        Highcharts.chart('ProgCImm', this.poptinoo);
        $('.cstm-nav-link').click(function () {
            $('.cstm-nav-link.active').removeClass('active');
            $(this).addClass('active');
        });
        $('.show-Status').click(function () {
            $('.show-Status.actStatus').removeClass('actStatus');
            $(this).addClass('actStatus');
        });
    }

    isHidden: boolean = true;
    isHiddenMdle: boolean = false;
    isHiddenMdl: boolean = false;
    openDialog() {
        this.isHiddenMdl = true;
    }
    hideDailog() {
        this.isHiddenMdl = false;
    }
    showTab1() {
        this.isHidden = true;
        this.isHiddenMdle = false;
    }
    showTab2() {
        this.isHidden = false;
        this.isHiddenMdle = true;
    }

}
