import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent],
  imports: [
    CommonModule,
      AccountRoutingModule,
      FormsModule,
      ReactiveFormsModule
    ],
    exports: [
        LoginComponent,
        ForgotPasswordComponent
    ]
})
export class AccountModule { }
