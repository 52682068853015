import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { HomeComponent } from './home/home.component'
import { MapComponent } from './map/map.component'
import { ChangeoverTimeComponent } from './changeover-time/changeover-time.component';
const routes: Routes = [
    { path: 'flsm/home', component: HomeComponent, canActivate: [AuthGuardService] },
    { path: 'flsm/map', component: MapComponent, canActivate: [AuthGuardService] },
    { path: 'flsm/cot', component: ChangeoverTimeComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlsmRoutingModule { }
