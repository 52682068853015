import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
declare var require: any;
var HighchartsMap = require("highcharts/highmaps.js");
import { Options } from "highcharts";
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
var worldMap = require("@highcharts/map-collection/custom/world.geo.json");
/*var topology = require("@highcharts/map-collection/countries/gb/custom/gb-countries.topo.json")*/
var topology = require("@highcharts/map-collection/countries/gb/gb-all.topo.json")
/*var topology = require("@highcharts/map-collection/custom/british-isles.topo.json")*/
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { UserService } from '../../services/user.service';
import { DashboardService } from '../../services/dashboard.service';
import { ImpactFactorElement } from '../../models/dashboard/dashboard.model';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-outcome-analysis',
  templateUrl: './outcome-analysis.component.html',
  styleUrls: ['./outcome-analysis.component.css']
})
export class OutcomeAnalysisComponent implements OnInit {
    clientRegionData: any;
    clientRegionDataToFilter: any;
    clientRegionDataList: any;
    sliderValue: any;

     
    selectedView: string;

    constructor(
        private route: Router,
        private userService: UserService,
        private dashboardService: DashboardService
    ) {
      
        this.selectedView = localStorage.getItem('selectedView');
    }

    Highcharts: typeof Highcharts = HighchartsMap;
    chartConstructor = "mapChart";
    aboveCountry: number = 20;
    updateFlag = false;
    tottalcalls: number
    value: number[] = [10, 90];
    formatLabel(value: number | null) {
        if (!value) {
            return 0;
        }

        if (value >= 1) {
            return  ' - ' + Math.round(value / 1) ;
        }

        return value;
    }
    formatLabel1(value: number | null) {
        if (!value) {
            return 0;
        }

        if (value >= 1) {
            return  Math.round(value / 1);
        }

        return value;
    }

    @ViewChild('table', { static: false }) table: MatTable<ImpactFactorElement>;
    displayedColumns: string[] = ['ElementName', 'Score', 'QTR'];
    dataSource=[]
    //dropTable(event: CdkDragDrop<ImpactFactorElement[]>) {
    //    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    //    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    //    this.table.renderRows();
    //}

/*    clientName: string = "";*/

    ngOnInit() {
        this.getLanguageStaticText();
        this.selectedImpactFactorLevelValue = 4;
        this.getOutcomeImpactFactorData();
        this.selectedViewtype = 'WEEK';
        $('.uk-map').show();
    }

    totalNoOfCalls: any; 
    selectedWeekDay: number = 0;
    selectedClientId: number = 0;
    selectedImpactFactorLevelValue: number = 4;
    labelValue: number = 1;
    clientCountryDataList: any = [];
    mapData: any = [];
    dataRangeValue: number = 20;
    currentPage: string = "OA";
    staticTextData: any;
    isMonthlySelected: boolean = true
    selectedViewtype = 'WEEK';
    Slidervalue: number = 0;
    getLanguageStaticText() {
        var objParam = {
           /* UserId: parseInt(this.userId),*/
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.showMapData();
        }, error => console.error(error));
    }
    changeViewType(view) {
        this.selectedViewtype = view;
        this.Slidervalue = 0
        if (this.selectedViewtype == 'WEEK') {
            this.totalNoOfCalls = 19
            if (this.labelValue === 0) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]],
                    name: 'Outcome volunteered'
                }
                this.updateFlag = true;

            }
            if (this.labelValue === 1) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 30]],
                    name: 'Positive outcome'
                }
                this.updateFlag = true;

            }
        }
        if (this.selectedViewtype == 'MONTH') {
            this.totalNoOfCalls = 76
            if (this.labelValue === 0) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 21.0]],
                    name: 'Outcome volunteered'
                }
                this.updateFlag = true;

            }
            if (this.labelValue === 1) {
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 29.8]],
                    name: 'Positive outcome'
                }
                this.updateFlag = true;

            }
  
        }
    }
    getOutcomeImpactFactorData() {
        $('.loder-outer').show();
        this.dataSource = [{
            "elementName": "Questioning",
            "score": 1.7,
            "qtrScore": 1.7,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/SE"
        }, {
            "elementName": "Opening",
            "score": 1.5,
            "qtrScore": 1.5,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/SE"
        },
        {
            "elementName": "Patient 1",
            "score": 1.4,
            "qtrScore": 1.4,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/CE"
        }, {
            "elementName": "Closing",
            "score": 1.4,
            "qtrScore": 1.4,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/SE"
        }, {
            "elementName": "HCP Share of Voice",
            "score": 1.3,
            "qtrScore": 1.3,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/CC"
        }, {
            "elementName": "Message 1",
            "score": 1.2,
            "qtrScore": 1.2,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/CE"
        }, {
            "elementName": "Message 2",
            "score": 1.2,
            "qtrScore": 1.2,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/CE"
        }, {
            "elementName": "Presenting",
            "score": 1.1,
            "qtrScore": 1.1,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/SE"
        }, {
            "elementName": "Length of call",
            "score": 1.1,
            "qtrScore": 1.1,
            "ctaElement": "",
            "qtrCTAElement": "",
            "redirectLink": "/staticdashboard/CC"
            }, {
                "elementName": "Message 3",
                "score": 1.0,
                "qtrScore": 1.0,
                "ctaElement": "Patient Id",
                "qtrCTAElement": "Prescribe",
                "redirectLink": ""
            },
            {
                
                "elementName": "Most common CTA",
                "score": -1,
                "qtrScore": 0,
                "ctaElement": "Advocacy",
                "qtrCTAElement": "",
                "redirectLink": ""
            
            }, 



        ];
        if (this.selectedView === "Msl") {
            this.dataSource = this.dataSource.map(item => {
                if (item.elementName === "Opening") {
                    return { ...item, elementName: "Engage" };
                }
                if (item.elementName === "Questioning") {
                    return { ...item, elementName: "Explore" };
                }
                if (item.elementName === "Presenting") {
                    return { ...item, elementName: "Exchange" };
                }
                if (item.elementName === "Closing") {
                    return { ...item, elementName: "Evolve" };
                }
                if (item.elementName === "Most common CTA") {
                    return { ...item, elementName: "Most common next steps" };
                }
                if (item.elementName === "Length of call") {
                    return { ...item, elementName: "Length of interaction" };
                }
                // Add more conditions for other elements if needed
                return item;
            });
        }
        $('.loder-outer').hide();
    
    }

    changeImpactFactorView(value) {
        this.selectedImpactFactorLevelValue = value;
        if (this.selectedImpactFactorLevelValue === 4) {
            this.dataSource = [{
                "elementName": "Questioning",
                "score": 1.7,
                "qtrScore": 1.7,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Opening",
                "score": 1.5,
                "qtrScore": 1.5,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Patient 1",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Closing",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "HCP Share of Voice",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            }, {
                "elementName": "Message 1",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Message 2",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Presenting",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Length of call",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
                }, {
                    "elementName": "Message 3",
                    "score": 1.0,
                    "qtrScore": 1.0,
                    "ctaElement": "Patient Id",
                    "qtrCTAElement": "Prescribe",
                    "redirectLink": ""
                },
                {

                    "elementName": "Most common CTA",
                    "score": -1,
                    "qtrScore": 0,
                    "ctaElement": "Advocacy",
                    "qtrCTAElement": "",
                    "redirectLink": ""

                },



            ];     }
        if (this.selectedImpactFactorLevelValue === 3) {
            this.dataSource = [{
                "elementName": "Questioning",
                "score": 1.7,
                "qtrScore": 1.7,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Opening",
                "score": 1.5,
                "qtrScore": 1.5,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            },
            {
                "elementName": "Patient 1",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Closing",
                "score": 1.4,
                "qtrScore": 1.4,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "HCP Share of Voice",
                "score": 1.3,
                "qtrScore": 1.3,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
            }, {
                "elementName": "Message 1",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Message 2",
                "score": 1.2,
                "qtrScore": 1.2,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CE"
            }, {
                "elementName": "Presenting",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/SE"
            }, {
                "elementName": "Length of call",
                "score": 1.1,
                "qtrScore": 1.1,
                "ctaElement": "",
                "qtrCTAElement": "",
                "redirectLink": "/staticdashboard/CC"
                }, {
                    "elementName": "Message 3",
                    "score": 1.0,
                    "qtrScore": 1.0,
                    "ctaElement": "Patient Id",
                    "qtrCTAElement": "Prescribe",
                    "redirectLink": ""
                },
                {

                    "elementName": "Most common CTA",
                    "score": -1,
                    "qtrScore": 0,
                    "ctaElement": "Advocacy",
                    "qtrCTAElement": "",
                    "redirectLink": ""

                },



            ];
        }
        if (this.selectedView === "Msl") {
            this.dataSource = this.dataSource.map(item => {
                if (item.elementName === "Opening") {
                    return { ...item, elementName: "Engage" };
                }
                if (item.elementName === "Questioning") {
                    return { ...item, elementName: "Explore" };
                }
                if (item.elementName === "Presenting") {
                    return { ...item, elementName: "Exchange" };
                }
                if (item.elementName === "Closing") {
                    return { ...item, elementName: "Evolve" };
                }
                if (item.elementName === "Most common CTA") {
                    return { ...item, elementName: "Most common next steps" };
                }
                if (item.elementName === "Length of call") {
                    return { ...item, elementName: "Length of interaction" };
                }
                // Add more conditions for other elements if needed
                return item;
            });
        }
    }
    showMapData() {
        this.mapData = [];
        this.chartOptions.series[0] = {
            type: 'map',
            data: [['us', 30]],
            name: 'Positive outcome',

        }
        this.totalNoOfCalls = 19 
        this.updateFlag = true;
      
    }
  
    showDatalbl(value) {
        this.Slidervalue = 0
        this.labelValue = value;
        if (this.selectedViewtype == 'WEEK') {  
            if (this.labelValue === 0) {
                this.totalNoOfCalls = 4
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]],
                    name: 'Outcome volunteered'
                }
                this.updateFlag = true;

            }
            if (this.labelValue === 1) {
                this.totalNoOfCalls = 19
                this.selectedWeekDay = 0
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 30]],
                    name: 'Positive outcome'
                }
                this.updateFlag = true;

            }
        }
        if (this.selectedViewtype == 'MONTH') {
            if (this.labelValue === 1) {
                    this.totalNoOfCalls = 76
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 29.8]],

                    }
                    this.updateFlag = true;
            }
            if (this.labelValue === 0) {
                    this.totalNoOfCalls = 76
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 21.0]],

                    }
                    this.updateFlag = true;
                }
        }
    }

    //onRangeChange(event) {
    //    var filterdData = this.mapData.filter(x => x[1] >= event.value);
    //    this.dataRangeValue = event.value;
    //    this.chartOptions.series[0] = {
    //        type: 'map',
    //        data: filterdData,
    //        name: this.staticTextData.outcomeAgreed
    //    }
    //    this.updateFlag = true;

 
    //    this.clientRegionData = this.clientRegionDataToFilter.filter(x => x.score >= event.value);
    //}

    StaticonWeekChange(event) {
        this.selectedWeekDay = event.value
        this.sliderValue = event.value
        if (this.selectedViewtype == 'WEEK') {
          
        if (this.labelValue === 1) {
            if (event.value === 0) {
                this.totalNoOfCalls = 19
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 30]],

                }
                this.updateFlag = true;

            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 19
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 38]],

                }
                this.updateFlag = true;
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 19
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 26]],

                }
                this.updateFlag = true;
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 19
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 26]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 32]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 17]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 13]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 15
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 30]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 8) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 31]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 17]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 29]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 4
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 30]
                    ],

                }
                this.updateFlag = true;
            }

        }

        else if (this.labelValue === 0) {
            if (event.value === 0) {
                this.totalNoOfCalls = 4
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]],

                }
                this.updateFlag = true;
            }
            else if (event.value === 1) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 28]],

                }
                this.updateFlag = true;
            }
            else if (event.value === 2) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]],

                }
                this.updateFlag = true;
            }
            else if (event.value === 3) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 4) {
                this.totalNoOfCalls = 15
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 21]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 5) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 13]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 6) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 6]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 7) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 27]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 8) {
                this.totalNoOfCalls = 19
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 27]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 9) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 9]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 10) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 19]
                    ],

                }
                this.updateFlag = true;
            }
            else if (event.value === 11) {
                this.totalNoOfCalls = 20
                this.chartOptions.series[0] = {
                    type: 'map',
                    data: [['us', 20]
                    ],

                }
                this.updateFlag = true;
            }
            //else if (event.value === 12) {
            //    this.totalNoOfCalls = 20
            //    this.chartOptions.series[0] = {
            //        type: 'map',
            //        data: [['us', 2.5]
            //        ],

            //    }
            //    this.updateFlag = true;
            //}
            this.updateFlag = true;
        }
    }
        if (this.selectedViewtype == 'MONTH') {
            if (this.labelValue === 1) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 76
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 29.8]],

                    }
                    this.updateFlag = true;

                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 75
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 22.8]],

                    }
                    this.updateFlag = true;
                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 64
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 26.7]],

                    }
                    this.updateFlag = true;
                }
                else {
                    this.totalNoOfCalls = 0
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 0]],

                    }
                    this.updateFlag = true;
                }
            }
            else if (this.labelValue === 0) {
                if (event.value === 0) {
                    this.totalNoOfCalls = 76
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 21.0]],

                    }
                    this.updateFlag = true;
                }
                else if (event.value === 1) {
                    this.totalNoOfCalls = 75
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 16.9]],

                    }
                    this.updateFlag = true;
                }
                else if (event.value === 2) {
                    this.totalNoOfCalls = 64
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 18.7]],

                    }
                    this.updateFlag = true;
                }
                else {
                    this.totalNoOfCalls = 0
                    this.chartOptions.series[0] = {
                        type: 'map',
                        data: [['us', 0]],

                    }
                    this.updateFlag = true;
                }
            }

        }
    }

    chartOptions: Options = {
        chart: {
            map: worldMap ,
            inverted: false,
            animation: false,
        },
        title: {
            text: ""
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: false,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14,
            y: -30,
            reversed: true
        },
        series: [{
            animation: false,
            nullColor: '#c9d1d9',
            point: {
                events: {
                    click: (e) => {
                       /* this.getDataByCountryKey(e.point['hc-key'])*/
                    }
                }
            },
            color: '#078000',
            type: "map",
            name: "Positive outcome",
            states: {
                hover: {
                    enabled: false,
                }
            },
            dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.options.value) {
                        return this.point.name;
                           
                    }
                }
            },
            tooltip: {
                valueDecimals:1,
                valueSuffix: '%'
            },
            allAreas: false,
            data: this.mapData
        }]
    };

    //getDataByCountryKey(ckey: any) {
    //    this.selectedClientId = this.clientCountryDataList.find(x => x.countryCode == ckey).clientId;
    //    localStorage.setItem('CountryKey', ckey);
    //    localStorage.setItem('SelectedClientId', this.clientCountryDataList.find(x => x.countryCode == ckey).clientId);
    //    localStorage.setItem('mapdrill', '1');
    //    this.getOutcomeImpactFactorData();
    //    this.getClientOutcomeDataByRegion();
    //    if (ckey == 'gb') {
    //        $('.uk-map').show();
    //      
    //        $('.rep-template').show();
    //        //$('.g2').show();
    //        //$('.g1').hide();
    //    }
    //}



}
