import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import * as Highcharts from 'highcharts/highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { SlsmService } from '../../services/slsm.service';
import { DashboardService } from '../../services/dashboard.service';
Drilldown(Highcharts);
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
    selector: 'app-call-characteristics',
    templateUrl: './call-characteristics.component.html',
    styleUrls: ['./call-characteristics.component.css']
})
export class CallCharacteristicsComponent implements OnInit {

    constructor(
        private dashboardService: DashboardService
    ) { }

    userId: any = window.localStorage.getItem("UserId");

    totalNoOfCalls: any;
    clientLogo: string = "";
    selectedProductId: number = 0;
    selectedFranchiseId: number = 0;
    selectedViewType: string = "WEEK";
    staticTextData: any;

    @ViewChild('DrVoice', { static: true }) DrVoice: ElementRef;
    @ViewChild('CallLength', { static: true }) CallLength: ElementRef;

    ngOnInit() {
        this.getLanguageStaticText();

        this.selectedFranchiseId = localStorage.getItem('SelectedFranchiseId') != null ? parseInt(localStorage.getItem('SelectedFranchiseId')) : 0;
        this.selectedProductId = localStorage.getItem('SelectedProductId') != null ? parseInt(localStorage.getItem('SelectedProductId')) : 0;

        this.clientLogo = window.localStorage.getItem("ClientLogo");
        //this.getCategorisedAudioCallData();
    }

    getLanguageStaticText() {
        var objParam = {
            UserId: parseInt(this.userId),
            LanguageCode: window.localStorage.getItem("SelectedLanguage") == null ? "En" : window.localStorage.getItem("SelectedLanguage")
        }
        this.dashboardService.getLanguageStaticText(objParam).subscribe((result: any) => {
            this.staticTextData = result;
            this.getCategorisedAudioCallData();
        }, error => console.error(error));
    }

    getCategorisedAudioCallData() {
        this.totalNoOfCalls = 0;
        var obj = {
            UserId: parseInt(this.userId),
            ProductId: this.selectedProductId,
            FranchiseId: this.selectedFranchiseId,
            ViewType: this.selectedViewType,
            ClientId: localStorage.getItem('SelectedClientId') != null ? parseInt(localStorage.getItem('SelectedClientId')) : 0
        }

        this.dashboardService.getCategorisedAudioCallData(obj).subscribe((result: any) => {

            if (result.length > 0) {
                var dataArrayForCallLength = [];
                var dataArrayForDoctorCallShareLength = [];
                result.forEach(objAudioCall => {
                    //dataArrayForCallLength.push(objAudioCall.callLengthAvg);
                    //dataArrayForDoctorCallShareLength.push(objAudioCall.doctorCallShareAvg);
                    dataArrayForCallLength.push({ totalCall: objAudioCall.totalCalls, score: objAudioCall.callLengthAvg });
                    dataArrayForDoctorCallShareLength.push({ totalCall: objAudioCall.totalCalls, score: objAudioCall.doctorCallShareAvg });

                    this.totalNoOfCalls = this.totalNoOfCalls + objAudioCall.totalCalls;
                });

                var callData = this.selectedViewType == "MONTH" ? dataArrayForCallLength : dataArrayForCallLength.reverse();
                var doctorCallShareData = this.selectedViewType == "MONTH" ? dataArrayForDoctorCallShareLength : dataArrayForDoctorCallShareLength.reverse();

                //new call data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newCallData = [];
                var newCallDataIndex = 0;
                callData.forEach(objCallData => {
                    if (objCallData.totalCall > 0 || newCallDataIndex == 1) {
                        newCallDataIndex = 1;
                        newCallData.push(objCallData.score);
                    }
                });

                for (var i = newCallData.length; i < 12; i++) {
                    newCallData[i] = 0;
                }
                //end here

                //new doctor call share data code to show very first week/month/qtr data from index 1 to 12. added on 3rd May 2023
                var newDoctorCallShareData = [];
                var newDoctorDataIndex = 0;
                doctorCallShareData.forEach(objDoctorData => {
                    if (objDoctorData.totalCall > 0 || newDoctorDataIndex == 1) {
                        newDoctorDataIndex = 1;
                        newDoctorCallShareData.push(objDoctorData.score);
                    }
                });

                for (var i = newDoctorCallShareData.length; i < 12; i++) {
                    newDoctorCallShareData[i] = 0;
                }
                //end here

                this.drawDoctorCallShareAvgGraph(newDoctorCallShareData);//(doctorCallShareData);
                this.drawCallAvgGraph(newCallData);//(callData);
                this.drawCallAvgGraphDemo(newCallData);//(callData);
            }
        }, error => console.error(error));
    }

    //this is called when product will be changed from its child class.
    productChange(productId) {
        this.selectedProductId = productId;
        this.getCategorisedAudioCallData();
    }

    //this is called when franchise will be changed from its child class.
    franchiseChange(franchiseId) {
        this.selectedFranchiseId = franchiseId;
        this.getCategorisedAudioCallData();
    }

    changeViewType(type) {
        this.selectedViewType = type;
        this.getCategorisedAudioCallData();
    }

    drawDoctorCallShareAvgGraph(dataValues) {
        Highcharts.chart(this.DrVoice.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'spline',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: '#00b050',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 60,
                    tickInterval: 20,
                    title: {
                        text: '% ' + this.staticTextData.doctorShareOfVoice,
                        style: {
                            color: '#012072',
                            fontSize: '14px'

                        },
                    },
                    labels: {
                        style: {
                            color: '#999999'

                        },
                        format: '{value}%'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        color: '#09d6cc',
                        lineWidth: 1,
                        borderColor: 'red',
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} %'
                },
                series: [{
                    name: '% ' + this.staticTextData.doctorShareOfVoice,
                    data: dataValues
                }]
            })
        );
    }

    drawCallAvgGraph(dataValues) {
        Highcharts.chart(this.CallLength.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'spline',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: '#00b050',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
                    min: 0,
                    max: 50,
                    tickInterval: 10,
                    title: {
                        text: this.staticTextData.lengthOfCall,//'Length of call',
                        style: {
                            color: '#012072',
                            fontSize: '14px'
                        },
                    },
                    labels: {
                        style: {
                            color: '#999999'

                        },
                        format: '{value}' + this.staticTextData.mins//'{value}Mins'
                    },
                    reversedStacks: false,
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        color: '#012072',
                        lineWidth: 1,
                        borderColor: null,
                        pointStart: 1,
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ' + this.staticTextData.mins
                },
                series: [{
                    name: this.staticTextData.lengthOfCall,//'Length of call',
                    data: dataValues
                }]
            })
        );
    }

    drawCallAvgGraphDemo(dataValues) {
        Highcharts.chart(this.CallLength.nativeElement,
            Highcharts.merge(null, {
                chart: {
                    type: 'columnrange',
                    style: {
                        fontFamily: 'inherit',
                        fontWeight: 'normal',
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: '#00b050',
                        fontWeight: 'normal',
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            color: '#66707f'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    minorGridLineWidth: 1,
                    minorTickLength: 1,
                    tickLength: 1,
                    gridLineWidth: 1,
               
                    title: {
                        text: this.staticTextData.lengthOfCall,//'Length of call',
                        style: {
                            color: '#012072',
                            fontSize: '14px'
                        },
                    },
                    labels: {
                        style: {
                            color: '#999999'

                        },
                        format: '{value}'//'{value}Mins'
                    },
              
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    columnrange: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                if (this.y === this.point.high) {

                                    return '<span>' + this.y + 'high</span>';
                                }
                                if (this.y === this.point.low) {
                                    return '<span>' + this.y + 'low</span>';
                                }
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<span style="font-size:11px">{this.point.high}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{this.point.low}</span>: <b>{point.y} '
                },
                series: [{
                    name: 'Temperatures',
                    data: [
                        [0, 4, 8],
                        [1, 4, 18],
                        [2, 7, 12],
                        [3, 4, 19],
                        [4, 6, 22],
                        [5, 9, 29],
                        [6, 12, 30],
                        [7, 7, 26],
                        [8, 4, 18],
                        [9, 3, 11],
                        [10, 5, 10],
                        [11, 13, 9]
                    ]
                },
                {
                    type: 'spline',
                    name: 'average',
                    data: [6, 9.5, 11.5, 14, 19, 21, 16.5, 11, 7, 7.5, 11]
                    }
                ]
            })
        );
    }
}
