import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as $ from 'jquery';
import { MatChipsModule } from '@angular/material/chips';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import Swal from 'sweetalert2'
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TokenService } from '../../services/token.service';
import { MatListModule } from '@angular/material/list';
import { ClientService } from '../../services/client.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { Campaign } from '../../models/cms/campaign.model';

@Component({
    selector: 'app-clientcampaign',
    templateUrl: './clientcampaign.component.html',
    styleUrls: ['./clientcampaign.component.css']
})
export class ClientcampaignComponent implements OnInit {

    addCampaignForm = new FormGroup({
        CampaignName: new FormControl('', [Validators.required, Validators.minLength(2)]),
        CampaignAlias: new FormControl('', [Validators.required]),
        CommonCampaignName: new FormControl('', [Validators.required]),
        ColumnID: new FormControl('', [Validators.required]),
        isActive: new FormControl()
    });

    objCampaign: Campaign;

    clientList: any = [];
    productList: any = [];
    campaignList: any = [];

    selectedClientId: number = 0;
    selectedProductId: number = 0;

    client_List: boolean = true;
    product_List: boolean = false;
    campaign_List: boolean = false;

    add_CampaignForm: boolean = false;

    activeElement1 = '';
    activeElement2 = '';
    activeElement3 = '';

    constructor(
        private clientService: ClientService,
        private userService: UserService,
        private campaignService: CampaignService
    ) { }

    ngOnInit() {
        this.objCampaign = new Campaign();
        this.objCampaign.CampaignId = 0;

        this.getClientList();
    }

    getClientList() {
        this.clientService.getClientList().subscribe((result: any) => {
            this.clientList = result;
        },
            error => console.error(error));
    }

    onClientSelect(client) {
        this.selectedClientId = client.clientId;
        this.client_List = true;
        this.product_List = true;
        this.campaign_List = false;
        this.add_CampaignForm = false;
        this.activeElement1 = client;
        this.getProductList(client.clientId);

    }

    getProductList(clientId) {
        this.userService.getUserMasterList(clientId).subscribe((result: any) => {
            this.productList = result.productList.filter(x => x.clientId == clientId);
        },
            error => console.error(error));
    }

    onProductSelect(product) {
        this.selectedProductId = product.productId;
        this.client_List = true;
        this.product_List = true;
        this.campaign_List = true;
        this.add_CampaignForm = false;
        this.activeElement2 = product;
        this.getCampaignList();
    }

    getCampaignList() {
        this.campaignService.getCampaignList(this.selectedProductId).subscribe((result: any) => {
            this.campaignList = result;
        },
            error => console.error(error));
    }

    editCampaign(campaign) {
        this.client_List = true;
        this.product_List = true;
        this.campaign_List = true;
        this.add_CampaignForm = true;
        this.activeElement3 = campaign;

        this.objCampaign.CampaignId = campaign.campaignId;
        this.objCampaign.CampaignName = campaign.campaignName;
        this.objCampaign.AliasCampaignName = campaign.aliasCampaignName;
        this.objCampaign.CommonCampaignName = campaign.commonCampaignName;
        this.objCampaign.ColumnId = campaign.columnId;
        this.objCampaign.IsActive = campaign.isActive;
    }

    campaignForm() {
        this.client_List = true;
        this.product_List = true;
        this.campaign_List = true;
        this.add_CampaignForm = true;
        this.resetCampaign();
    }

    submitCampaign(campaign) {
        campaign.IsActive = campaign.IsActive == null ? true : campaign.IsActive;
        campaign.ProductId = this.selectedProductId;
        this.campaignService.addUpateCampaign(campaign).subscribe((result: any) => {
            if (result.code == 200) {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: result.description, icon: 'success', });
                this.getCampaignList();
                this.resetCampaign();
            }
            else {
                Swal.fire({ toast: true, position: 'bottom-end', showConfirmButton: false, timer: 3000, title: 'Error!', text: result.description, icon: 'error', });
            }
        }, error => console.error(error));
    }

    resetCampaign() {
        this.objCampaign = new Campaign();
        this.addCampaignForm.reset();
    }

}
